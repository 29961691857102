import { Colors, Lightning } from '@lightningjs/sdk';
import { ttsTagRemoval } from 'support/generalUtils';
import { translate } from 'support/translate';

export interface EmptySearchResultsTemplateSpec
  extends Lightning.Component.TemplateSpec {
  search: string;

  Title: Lightning.textures.TextTexture;
  Description: Lightning.textures.TextTexture;
}

const DEFAULT_WIDTH = 533;
const TEXT_SETTINGS: Lightning.textures.TextTexture.Settings = {
  advancedRenderer: true,
  fontSize: 28,
  lineHeight: 37,
  w: DEFAULT_WIDTH,
};

const DESCRIPTION_MARGIN_TOP = 28;

export default class EmptySearchResults
  extends Lightning.Component<EmptySearchResultsTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<EmptySearchResultsTemplateSpec>
{
  private _Title = this.getByRef('Title')!;
  private _Description = this.getByRef('Description')!;

  set search(search: string) {
    const text = translate('search.emptyResultsTitle', search);
    this._Title.patch({ text: { text } });
  }

  static override _template(): Lightning.Component.Template<EmptySearchResultsTemplateSpec> {
    return {
      w: DEFAULT_WIDTH,
      flex: {
        direction: 'column',
      },
      Title: {
        text: {
          ...TEXT_SETTINGS,
          textColor: Colors('text').get(),
        },
      },
      Description: {
        flexItem: { marginTop: DESCRIPTION_MARGIN_TOP },
        text: {
          ...TEXT_SETTINGS,
          text: translate('search.emptyResultsDescription'),
          textColor: Colors('text').get(),
        },
      },
    };
  }

  fireAnnounce() {
    const announcement = `${this._Title.text?.text}. ${this._Description.text?.text}`;
    this.fireAncestors('$announce', ttsTagRemoval(announcement), {
      append: true,
    });
  }
}
