import { withSeparator } from '../../support/textUtils';
import { translate } from '../../support/translate';
import {
  getDurationMinutesFormatted,
  getSeasonEpisodeFormatted,
  isFullContent,
} from '../../support/contentUtils';
import HorizontalMediaItem from './HorizontalMediaItem';
import MediaProgressBar from './progressBar/MediaProgressBar';
import { Lightning } from '@lightningjs/sdk';
import { updateImageSize } from '../../support/cwImageUtils';

const WIDTH = 1465;

const IMAGE_WIDTH = 400;
const IMAGE_HEIGHT = 225;
const IMAGE_MARGIN_RIGHT = 30;

const CONTENT_Y = 10;

const DEFAULT_FONT_SIZE = 29;
const TITLE_FONT_SIZE = 38;

const DEFAULT_MAX_LINES = 1;
const DESCRIPTION_MAX_LINES = 2;

export default class HorizontalEpisodeItem extends HorizontalMediaItem {
  static override get width() {
    return WIDTH;
  }

  static override get height() {
    return IMAGE_HEIGHT;
  }

  static override get imageSpacing() {
    return IMAGE_MARGIN_RIGHT;
  }

  static override get imageWidth() {
    return IMAGE_WIDTH;
  }

  static override get imageHeight() {
    return IMAGE_HEIGHT;
  }

  static override get progressBarWidth() {
    return MediaProgressBar.defaultWidth;
  }

  static override get progressBarBottomPadding() {
    return CONTENT_Y;
  }

  static override get contentY() {
    return CONTENT_Y;
  }

  static override get titleTextStyle(): Lightning.textures.TextTexture.Settings {
    return {
      fontSize: TITLE_FONT_SIZE,
      maxLines: DEFAULT_MAX_LINES,
    };
  }

  static override get metadataTextStyle(): Lightning.textures.TextTexture.Settings {
    return {
      fontSize: DEFAULT_FONT_SIZE,
      maxLines: DEFAULT_MAX_LINES,
    };
  }

  static override get descriptionTextStyle(): Lightning.textures.TextTexture.Settings {
    return {
      fontSize: DEFAULT_FONT_SIZE,
      maxLines: DESCRIPTION_MAX_LINES,
      lineHeight: DEFAULT_FONT_SIZE + 5,
    };
  }

  override updateText() {
    if (!this.mediaItem) return;

    const video = this.mediaItem;
    const { title, description } = video;
    let metadata = '';

    if (isFullContent(video)) {
      const { airDate } = video;

      const seasonEpisodeText = getSeasonEpisodeFormatted(video);
      const durationText = getDurationMinutesFormatted(video);
      const airDateText = translate('seriesDetail.airDate', airDate);
      metadata = withSeparator(seasonEpisodeText, durationText, airDateText);
    } else {
      metadata = getDurationMinutesFormatted(video);
    }

    this._Title.patch({ text: { text: title } });
    this._Metadata.patch({ text: { text: metadata } });
    this._Description.patch({ text: { text: description } });
  }

  override updateImages() {
    if (!this.mediaItem?.images.thumbnail) return;

    const src = updateImageSize(this.mediaItem.images.thumbnail, IMAGE_WIDTH);
    this._imageUrl = src;
    this._EpisodeImage.patch({ alpha: 1, src });
  }
}
