import { Colors, Lightning } from '@lightningjs/sdk';
import { formatVideoTime } from 'support/contentUtils';
import { translate } from 'support/translate';

const FONT_SIZE = 29;

interface AdDetailsTemplateSpec extends Lightning.Component.TemplateSpec {
  countdown: number;
  isPreroll: boolean;

  AdCount: typeof Text;
  Countdown: Lightning.textures.TextTexture;
}

export default class AdDetails
  extends Lightning.Component<AdDetailsTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<AdDetailsTemplateSpec>
{
  private _countdown = 0;
  private _isPreroll = false;

  private _Countdown = this.getByRef('Countdown')!;

  get countdown() {
    return this._countdown;
  }

  set countdown(countdown: AdDetailsTemplateSpec['countdown']) {
    this._countdown = countdown < 0 ? 0 : countdown;
    this._Countdown.patch({
      text: {
        text: translate(
          this.isPreroll
            ? 'playback.adCountPrerollLabel'
            : 'playback.adCountLabel',
          formatVideoTime(this._countdown),
        ),
      },
    });
  }

  get isPreroll() {
    return this._isPreroll;
  }

  set isPreroll(isPreroll: AdDetailsTemplateSpec['isPreroll']) {
    this._isPreroll = isPreroll;
  }

  static override _template(): Lightning.Component.Template<AdDetailsTemplateSpec> {
    return {
      rect: true,
      flex: {
        direction: 'row',
        alignItems: 'center',
        paddingLeft: 36,
        paddingRight: 34,
        paddingTop: 16,
        paddingBottom: 14,
      },
      shader: { type: Lightning.shaders.RoundedRectangle, radius: 11 },
      color: Colors('background').alpha(0.7).get(),
      Countdown: {
        text: {
          fontSize: FONT_SIZE,
          advancedRenderer: true,
        },
      },
    };
  }
}
