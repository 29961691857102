import { Lightning } from '@lightningjs/sdk';
import { List } from '@lightningjs/ui';
import Tab, { TabTemplateSpec } from './Tab';
import { IndexData, LightningBuilder } from 'types/lightning';
import constants from '../../../../static/constants.json';
import {
  HoverableComponent,
  HoverableComponentSignalMap,
  HoverableComponentTypeConfig,
} from '../HoverableComponent';
import { ListWithIndexing } from '../CollectionWrappersWithIndexing';

const TAB_SPACING = 85;

interface TabsSignalMap extends HoverableComponentSignalMap {
  onIndexChanged(indexData: IndexData): void;
}

interface TabsTypeConfig extends HoverableComponentTypeConfig {
  SignalMapType: TabsSignalMap;
}

export interface TabsTemplateSpec extends Lightning.Component.TemplateSpec {
  tabs: LightningBuilder<typeof Tab, TabTemplateSpec>[];
  direction: 'row' | 'column';
  spacing: number;
  labelStyles: TabTemplateSpec['labelStyles'];
  containerStyles: TabTemplateSpec['containerStyles'];
  activeTab: number | null;
  Tabs: typeof List;
}

export default class Tabs
  extends HoverableComponent<TabsTemplateSpec, TabsTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<TabsTemplateSpec>
{
  private _tabs: TabsTemplateSpec['tabs'] = [];
  private _direction: 'row' | 'column' = 'row';
  private _activeTab: TabsTemplateSpec['activeTab'] = null;
  private _labelStyles = { focused: {}, unfocused: {}, active: {} };
  private _containerStyles = { focused: {}, unfocused: {}, active: {} };
  private _initializationComplete = false;

  private _Tabs: List = this.getByRef('Tabs')!;

  get tabs() {
    return this._Tabs.items;
  }

  set tabs(tabs: TabsTemplateSpec['tabs']) {
    this.clear();
    this._tabs = tabs;

    if (this._initializationComplete) this.setupTabs();
  }

  get direction() {
    return this._direction;
  }

  set direction(direction: TabsTemplateSpec['direction']) {
    this._direction = direction;
    this._Tabs.direction = direction;
  }

  get index() {
    return this._Tabs.index;
  }

  set index(index: number) {
    if (this._Tabs.hasItems) this._Tabs.index = index;
  }

  get activeTab() {
    return this._activeTab;
  }

  set activeTab(index: TabsTemplateSpec['activeTab']) {
    this._activeTab = index;
    this._Tabs.items.forEach((tab: Tab, tabIndex: number) => {
      tab.activeTab = tabIndex === index;
    });
  }

  set spacing(spacing: TabsTemplateSpec['spacing']) {
    this._Tabs.spacing = spacing;
  }

  get labelStyles() {
    return this._labelStyles;
  }

  set labelStyles(labelStyles: TabsTemplateSpec['labelStyles']) {
    this._labelStyles = labelStyles;

    if (this._initializationComplete) {
      this.setupTabs();
    } else {
      this._Tabs.items.forEach((item: Tab) => {
        item.labelStyles = this.labelStyles;
      });
    }
  }

  get containerStyles() {
    return this._containerStyles;
  }

  set containerStyles(containerStyles: TabsTemplateSpec['containerStyles']) {
    this._containerStyles = containerStyles;

    if (this._initializationComplete) {
      this.setupTabs();
    } else {
      this._Tabs.items.forEach((item: Tab) => {
        item.containerStyles = containerStyles;
      });
    }
  }

  static override _template(): Lightning.Component.Template<TabsTemplateSpec> {
    return {
      Tabs: {
        type: ListWithIndexing,
        signals: { onIndexChanged: true, onItemsRepositioned: true },
        spacing: TAB_SPACING,
        forceLoad: true,
      },
    };
  }

  override _setup() {
    super._setup();
    this._Tabs.w = this.w;
    this._Tabs.h = this.h;

    this.setupTabs();
    this._initializationComplete = true;
  }

  override _getFocused() {
    return this._Tabs;
  }

  private async setupTabs() {
    // Since we need to give the tabs an initial width when adding them in order for reposition() to work properly,
    // we will make the tabs to be invisible until they are setup. This is so that tabs with the arbitrary initial width
    // will not be seen.
    this._Tabs.alpha = constants.ui.invisible;
    this._tabs.forEach(tab => {
      tab.labelStyles = this.labelStyles;
      tab.containerStyles = this.containerStyles;
      tab.w = 1; // setting arbitrary initial width here
      tab.onLoad = this.onTabRendered.bind(this);
      this._Tabs.add(tab);
    });

    if (this._Tabs.hasItems) this._Tabs.reposition();
  }

  private onTabRendered() {
    this._Tabs.reposition();
  }

  private onIndexChanged(indexData: IndexData) {
    this.signal('onIndexChanged', indexData);
  }

  private onItemsRepositioned() {
    // Displaying tabs once they are repositioned properly
    this._Tabs.alpha = 1;
  }

  clear() {
    this._tabs = [];
    this._Tabs.clear();
  }
}
