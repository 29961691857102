import { SpeechType } from '@lightningjs/ui-components';
import {
  createAbstractAnnouncerIntegration,
  AnnounceOptions,
} from '../AbstractAnnouncerIntegration';

// we use a function so createAbstractAnnouncerIntegration only runs when it needs too
export const createUnknownAnnouncerIntegration = () =>
  class UnknownAnnouncerIntegration extends createAbstractAnnouncerIntegration() {
    override _build(...args: unknown[]) {
      return super._build.apply(this, args as any);
    }

    override _firstEnable(...args: unknown[]) {
      return super._firstEnable.apply(this, args as any);
    }

    override _disable(...args: unknown[]) {
      return super._disable.apply(this, args as any);
    }

    override _focusChange(...args: unknown[]) {
      return super._focusChange.apply(this, args as any);
    }

    override _announceFocusChanges(...args: unknown[]) {
      return super._announceFocusChanges.apply(this, args as any);
    }

    override $announce(...args: [SpeechType, AnnounceOptions | undefined]) {
      return super.$announce.apply(this, args);
    }

    override $announcerCancel() {
      return super.$announcerCancel.apply(this);
    }

    override $announcerRefresh(depth?: number) {
      return super.$announcerRefresh.apply(this, [depth]);
    }
  };
