import { Lightning } from '@lightningjs/sdk';
import HorizontalEpisodeItem from 'components/common/HorizontalEpisodeItem';
import { ListWithSpeech } from 'components/common/CollectionWrappersWithSpeech';
import SizedColumnList from 'components/common/SizedColumnList';
import SeasonTab, {
  WIDTH as SEASON_TAB_WIDTH,
  HEIGHT_EXPANDED as SEASON_TAB_HEIGHT,
} from 'components/pages/series-details/SeasonTab';
import { translate } from 'support/translate';
import { Episode, Media, Season, ShowVideos, Video } from 'types/api/media';
import { SelectItemContext, ListItemContext } from 'types/events';
import { StaticViewContexts, ViewContext } from 'types/analytics';
import { PageId } from 'types/pageId';
import { getThumbnail } from 'support/contentUtils';

export interface MediaSectionTemplateSpec
  extends Lightning.Component.TemplateSpec {
  episodes: Array<ShowVideos>;
  extras: ShowVideos;
  featuredEpisode: Episode | null;

  viewContext: ViewContext;
  fromPageId: PageId;

  SeasonsContainer: typeof ListWithSpeech;
  EpisodesList: typeof SizedColumnList;
}

const UNFOCUSED_Y = 40;
const FOCUSED_Y = 183;

const SEASONS_CONTAINER_SPACING = 10;
const SEASONS_LIST_SCROLL_MAX = 8;

const EPISODES_CONTAINER_X = 380;
const EPISODES_CONTAINER_W = 1505;

const EPISODES_LIST_SCROLL_MAX = 2;
const EPISODES_LIST_HIGHLIGHT_PADDING = 10;

export default class MediaSection
  extends Lightning.Component<MediaSectionTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<MediaSectionTemplateSpec>
{
  private _episodes: MediaSectionTemplateSpec['episodes'] | null = null;
  private _extras: MediaSectionTemplateSpec['extras'] | null = null;
  private _featuredEpisode: MediaSectionTemplateSpec['featuredEpisode'] = null;

  private _pageId = PageId.SERIES_DETAIL;
  private _viewContext: ViewContext = StaticViewContexts.SERIES_DETAIL_PAGE;
  private _listItemContext: ListItemContext | undefined = undefined;

  private _SeasonsContainer = this.getByRef('SeasonsContainer')!;
  private _EpisodesList = this.getByRef('EpisodesList')!;

  set viewContext(viewContext: ViewContext) {
    this._viewContext = viewContext;
  }

  set fromPageId(fromPageId: PageId) {
    this._pageId = fromPageId;
  }

  set episodes(episodes: MediaSectionTemplateSpec['episodes']) {
    this._episodes = episodes;

    this.updateSeasonContainerTabs();
    this.updateEpisodeListTiles();
  }

  set extras(extras: MediaSectionTemplateSpec['extras']) {
    this._extras = extras;

    this.updateSeasonContainerTabs();
    this.updateEpisodeListTiles();
  }

  set featuredEpisode(
    featuredEpisode: MediaSectionTemplateSpec['featuredEpisode'],
  ) {
    this._featuredEpisode = featuredEpisode;

    this.updateSelectedSeason();
    this.updateEpisodeListTiles();
    this.updateSelectedEpisode();
  }

  static override _template(): Lightning.Component.Template<MediaSectionTemplateSpec> {
    return {
      y: UNFOCUSED_Y,
      SeasonsContainer: {
        type: ListWithSpeech,
        itemType: SeasonTab,
        direction: 'column',
        spacing: SEASONS_CONTAINER_SPACING,
        scroll: { after: SEASONS_LIST_SCROLL_MAX },
        signals: { onIndexChanged: 'onSeasonIndexChanged' },
        clipping: true,
        w: SEASON_TAB_WIDTH,
        h: (y: number) => 1080 - y,
      },
      EpisodesList: {
        type: SizedColumnList,
        scrollMax: EPISODES_LIST_SCROLL_MAX,
        highlightPadding: EPISODES_LIST_HIGHLIGHT_PADDING,
        clipping: true,
        x: EPISODES_CONTAINER_X,
        w: EPISODES_CONTAINER_W,
        h: (y: number) => 1080 - y,
        signals: {
          $onHover: '$onHover',
          $onUnhover: '$onUnhover',
        },
      },
    };
  }

  override _setup() {
    this._setState('SeasonsContainer');
  }

  override _active() {
    const seasonTabs = this._SeasonsContainer.items;
    if (!seasonTabs) return;

    const currentIndex = this._SeasonsContainer.index;
    seasonTabs.forEach((tab: SeasonTab, index: number) => {
      tab.selected = index === currentIndex;
    });
  }

  override _unfocus() {
    this.updateSectionPosition();
  }

  override _focus() {
    this.updateSectionPosition();
    this._SeasonsContainer.items.forEach((tab: SeasonTab, i: number) => {
      tab.selected = i === this._SeasonsContainer.index;
    });
  }

  updateSectionPosition() {
    const y = this.hasFocus() ? FOCUSED_Y : UNFOCUSED_Y;
    this.setSmooth('y', y);
  }

  onSeasonIndexChanged(event: { index: number; previousIndex: number }) {
    const { index } = event;
    this.updateSelectedSeason(index);
    this.updateEpisodeListTiles();
    this.updateSelectedEpisode();
  }

  $onHover(target: any) {
    if (target === this._EpisodesList) {
      this.signal('$mediaSectionFocused');
      this._setState('EpisodesList');
    }
  }

  $onUnhover() {
    this._setState('SeasonsContainer');
  }

  $onTileSelected(data?: Media, context?: SelectItemContext) {
    const listItemContext: ListItemContext = {
      rowIndex: this._EpisodesList.index,
      itemIndex: this._SeasonsContainer.index,
      imageUrl: data ? getThumbnail(data) : '',
    };

    const updatedContext: SelectItemContext | undefined = context
      ? {
          ...context,
          listItemContext,
          pageId: this._pageId,
          viewContext: this._viewContext,
        }
      : undefined;

    if (data) {
      this.signal(
        '$onMediaSectionItemActivation',
        data,
        this._EpisodesList.index,
        this._SeasonsContainer.index,
      );
    }

    this.fireAncestors('$onTileSelected', data, updatedContext);
  }

  private updateSeasonContainerTabs() {
    const seasonTabs = this.buildSeasonsTabs();
    const extrasTab = this.buildExtrasTab();

    const items: Partial<SeasonTab>[] = [];
    if (seasonTabs) items.push(...seasonTabs);
    if (extrasTab) items.push(extrasTab);

    if (!items.length) {
      this._SeasonsContainer.clear();
    } else {
      this._SeasonsContainer.patch({ items });
    }
  }

  private buildSeasonsTabs() {
    const episodes = this._episodes;
    if (!episodes || episodes.length === 0) return;

    return episodes.map((showVideos: ShowVideos) => {
      const season = showVideos.season!;

      const subheadingKey =
        season.episodeCount > 1
          ? 'seriesDetail.episodesCount'
          : 'seriesDetail.episodeCount';
      const subheading = translate(subheadingKey, season.episodeCount);
      const title = season.title;

      return this.buildSeasonTabTemplate(title, subheading, season);
    });
  }

  private buildExtrasTab() {
    const extras = this._extras;
    if (!extras || extras.items.length === 0) return;

    const subheadingKey =
      extras.items.length > 1
        ? 'seriesDetail.extrasCount'
        : 'seriesDetail.extraCount';
    const subheading = translate(subheadingKey, extras.items.length);
    const title = translate('seriesDetail.extras');

    return this.buildSeasonTabTemplate(title, subheading);
  }

  private buildSeasonTabTemplate(
    seasonTitle: string,
    subheading: string,
    season?: Season,
  ): Partial<SeasonTab> {
    return {
      w: SEASON_TAB_WIDTH,
      h: SEASON_TAB_HEIGHT,
      seasonTitle,
      subheading,
      season,
    };
  }

  private updateSelectedSeason(selectedIndex?: number) {
    const seasonTabs = this._SeasonsContainer.items;
    const featuredSeasonCode = this._featuredEpisode?.season;

    let index = this._SeasonsContainer.index;
    if (selectedIndex !== undefined) {
      index = selectedIndex;
    } else if (featuredSeasonCode) {
      const featuredSeasonIndex = seasonTabs.findIndex(
        (tab: SeasonTab) => featuredSeasonCode === tab.season?.code,
      );
      if (featuredSeasonIndex !== -1) index = featuredSeasonIndex;
    }

    this._SeasonsContainer.patch({ index });
    seasonTabs.forEach((tab: SeasonTab, i: number) => {
      tab.selected = i === index;
    });
  }

  private updateEpisodeListTiles() {
    const currentSeasonIndex = this._SeasonsContainer.index;

    let episodes: Video[] | null = null;
    if (this._episodes?.length && currentSeasonIndex < this._episodes.length) {
      episodes = this._episodes[currentSeasonIndex]!.items;
    } else if (this._extras) {
      episodes = this._extras.items;
    }

    if (!episodes || !episodes.length) {
      this._EpisodesList.clear();
    } else {
      const items = episodes.map((episode: Video) => {
        return {
          type: HorizontalEpisodeItem,
          w: HorizontalEpisodeItem.width,
          h: HorizontalEpisodeItem.height,
          mediaItem: episode,
        };
      });

      this._EpisodesList.patch({ items });
    }
  }

  private updateSelectedEpisode() {
    const episodeTiles = this._EpisodesList.items as HorizontalEpisodeItem[];
    const featuredEpisodeGuid = this._featuredEpisode?.guid;
    if (!episodeTiles || !featuredEpisodeGuid) return;

    const featuredEpisodeIndex = episodeTiles.findIndex(
      (tile: HorizontalEpisodeItem) =>
        featuredEpisodeGuid === tile.mediaItem?.guid,
    );
    if (featuredEpisodeIndex === -1) return;

    this._EpisodesList.patch({ index: featuredEpisodeIndex });
  }

  clear() {
    this._episodes = null;
    this._extras = null;
    this._featuredEpisode = null;

    this._SeasonsContainer.clear();
    this._EpisodesList.clear();
  }

  static override _states() {
    return [
      class SeasonsContainer extends this {
        override _getFocused() {
          return this._SeasonsContainer;
        }

        override _handleEnter() {
          this._setState('EpisodesList');
        }

        override _handleRight() {
          this._setState('EpisodesList');
        }
      },
      class EpisodesList extends this {
        override _getFocused() {
          return this._EpisodesList;
        }

        override _handleLeft() {
          this._setState('SeasonsContainer');
        }

        override _handleBack() {
          this._setState('SeasonsContainer');
        }
      },
    ];
  }
}
