import { AppData, Lightning } from '@lightningjs/sdk';
import { PageId } from 'types/pageId';

import Page from '../../Page';
import MediaSection from './MediaSection';
import constants from '../../../../static/constants.json';
import ShowcaseSection, {
  SHOWCASE_SECTION_HEIGHT,
} from '../../common/ShowcaseSection';
import { debounce, getImageTextureObj } from 'support/generalUtils';
import {
  Episode,
  FeaturedTrailer,
  Media,
  Show,
  ShowVideos,
  Video,
} from 'types/api/media';
import { translate } from 'support/translate';
import { navigateToPlay } from 'support/routerUtils';
import { StaticViewContexts } from 'types/analytics';
import { reportShowPageEngagement } from '../../../services/analytics/reportingServicePage';
import { ListItemContext, SelectItemContext } from 'types/events';
import { ContentHub } from 'services/cwData';
import { getFirstEpisodeFromShowVideos } from 'support/contentUtils';

export interface SeriesDetailsPageSpec
  extends Lightning.Component.TemplateSpec {
  pageData: {
    show: Show;
    episodes: Array<ShowVideos>;
    extras: ShowVideos | undefined;
    season: string | undefined;
    listItemContext: ListItemContext;
    fromPageId: PageId;
  };

  Content: {
    Sections: {
      ShowcaseSection: typeof ShowcaseSection;
      MediaSection: typeof MediaSection;
    };
    Logo: Lightning.Texture;
  };
}

const LOGO_X_SHOWCASE = 96;
const LOGO_Y_SHOWCASE = 85;
const LOGO_W_SHOWCASE = 565;
const LOGO_H_SHOWCASE = 230;

const LOGO_X_MEDIA = 96;
const LOGO_Y_MEDIA = SHOWCASE_SECTION_HEIGHT + 60;
const LOGO_W_MEDIA = 270;
const LOGO_H_MEDIA = 110;

export default class SeriesDetailsPage
  extends Page<SeriesDetailsPageSpec>
  implements Lightning.Component.ImplementTemplateSpec<SeriesDetailsPageSpec>
{
  protected override _pageId = PageId.SERIES_DETAIL;
  protected override _viewContext = StaticViewContexts.SERIES_DETAIL_PAGE;
  private _listItemContext: ListItemContext | null = null;

  private _extras: ShowVideos | null = null;
  private _show: Show | null = null;
  private _featuredEpisode: Episode | null = null;
  private _featuredTrailer: FeaturedTrailer | null = null;
  private _selectedEpisode: Episode | null = null;
  private _selectedSeason: string | null = null;

  private _Content = this.getByRef('Content')!;
  private _Logo = this._Content.getByRef('Logo')!;
  private _Sections = this._Content.getByRef('Sections')!;
  private _ShowcaseSection = this._Sections.getByRef('ShowcaseSection')!;
  private _MediaSection = this._Sections.getByRef('MediaSection')!;

  get announce() {
    return translate('ttsPrompts.seriesDetail');
  }

  static override _template(): Lightning.Component.Template<SeriesDetailsPageSpec> {
    return {
      ...super._template(),
      Content: {
        h: 1080,
        Sections: {
          flex: { direction: 'column' },
          ShowcaseSection: {
            type: ShowcaseSection,
            signals: {
              $playContent: '$playFeaturedContent',
              $playTrailer: '$playTrailer',
            },
          },
          MediaSection: {
            type: MediaSection,
            signals: {
              $onMediaSectionItemActivation: '$onMediaSectionItemActivation',
              $mediaSectionFocused: '$mediaSectionFocused',
            },
          },
        },
        Logo: {
          x: LOGO_X_SHOWCASE,
          y: LOGO_Y_SHOWCASE,
        },
      },
    };
  }

  override _setup() {
    super._setup();
    this._setState('ShowcaseSection');
  }

  override _onChanged() {
    this.updateFeaturedEpisode();
  }

  override _onDataProvided() {
    const { show, episodes, extras, listItemContext, season } = this.pageData;
    const { featuredTrailer } = show;

    this._extras = extras ?? null;
    this._show = show;
    this._featuredTrailer = featuredTrailer?.id ? featuredTrailer : null;
    this._listItemContext = listItemContext ?? null;
    this._selectedSeason = season ?? null;

    this._ShowcaseSection.patch({
      episodes,
      extras,
      show,
    });
    this._MediaSection.patch({
      episodes,
      extras,
      viewContext: this._viewContext,
      fromPageId: this._pageId,
    });

    this.updateLogo();
  }

  private async updateFeaturedEpisode() {
    if (!this._show) return;
    const { showSlug } = this._show;

    const episodes = this.pageData.episodes;
    const historyService = AppData!.historyService;
    const selectedEpisode = this._selectedEpisode;
    const selectedSeason = this._selectedSeason;

    if (selectedEpisode) {
      // Should always trigger after the user has selected to play some content
      // on the series detail page. Handled first so we don't default to the
      // deep linked season each time the user returns to series details
      const { guid } = selectedEpisode;
      const selectedEpisodeHistory = historyService.getVideoHistory(
        showSlug,
        guid,
      );
      const isSelectedEpisodeComplete =
        selectedEpisodeHistory?.isComplete ?? false;

      if (isSelectedEpisodeComplete) {
        const firstEpisode = getFirstEpisodeFromShowVideos(episodes);
        const nextEpisode = await historyService.getShowNextEpisode(showSlug);
        this._featuredEpisode = nextEpisode ?? firstEpisode ?? selectedEpisode;
      } else {
        this._featuredEpisode = selectedEpisode;
      }

      this._selectedEpisode = null;
    } else if (selectedSeason) {
      const firstEpisode = getFirstEpisodeFromShowVideos(
        episodes,
        selectedSeason,
      );
      const nextEpisode = await historyService.getShowNextEpisode(showSlug);
      this._featuredEpisode = firstEpisode ?? nextEpisode ?? null;

      // Scroll down if season is provided
      this._handleDown();
    } else {
      const firstEpisode = getFirstEpisodeFromShowVideos(episodes);
      const nextEpisode = await historyService.getShowNextEpisode(showSlug);
      this._featuredEpisode = nextEpisode ?? firstEpisode ?? null;
    }

    this._ShowcaseSection.patch({ featuredEpisode: this._featuredEpisode });
    this._MediaSection.patch({ featuredEpisode: this._featuredEpisode });
  }

  private updateLogo() {
    const images = this._show!.images;
    const logoSrc = images.image_logo ?? images.image_logo_white ?? '';
    const logoTexture = getImageTextureObj(
      logoSrc,
      LOGO_W_SHOWCASE,
      LOGO_H_SHOWCASE,
    );

    this._Logo.patch(logoTexture);
  }

  override _handleUp() {
    this._setState('ShowcaseSection');
  }

  override _handleDown() {
    const { episodes, extras } = this.pageData;
    const hasEpisodes = !!(episodes && episodes.length);
    const hasExtras = !!(extras && extras.items && extras.items.length);

    if (hasEpisodes || hasExtras) this._setState('MediaSection');
  }

  override $onTileSelected(
    data?: Media | ContentHub,
    context?: SelectItemContext,
  ) {
    const updatedContext = context ? { ...context } : undefined;
    if (updatedContext && this._listItemContext) {
      updatedContext.listItemContext = this._listItemContext;
    }

    super.$onTileSelected(data, updatedContext);
  }

  $playFeaturedContent() {
    const featuredEpisode = this._featuredEpisode;
    const extras = this._extras;

    const context = {
      viewContext: this.viewContext,
      // TODO: determine how to handle this (EL-744)
      listItemContext: this._listItemContext ?? undefined,
      pageId: this.pageId,
    };

    if (featuredEpisode) {
      this._selectedEpisode = featuredEpisode;
      navigateToPlay(featuredEpisode.guid, context, 'show');
    } else if (extras && extras?.items.length) {
      // Play the first video found in extras
      const showVideo = extras.items[0]!;
      this._selectedEpisode = showVideo;
      navigateToPlay(showVideo.guid, context, 'show');
    }
  }

  $onMediaSectionItemActivation(
    mediaItem: Media,
    episodeIndex: number,
    seasonIndex: number,
  ) {
    this._selectedEpisode = mediaItem as Episode;
    reportShowPageEngagement(mediaItem as Video, episodeIndex, seasonIndex);
  }

  $playTrailer() {
    this._featuredTrailer &&
      navigateToPlay(this._featuredTrailer.id, {
        viewContext: this.viewContext,
        // TODO: determine how to handle this
        listItemContext: this._listItemContext ?? undefined,
        pageId: this.pageId,
      });
  }

  $mediaSectionFocused() {
    if (this._getState() === 'ShowcaseSection') {
      const debouncedScroll = debounce(() => {
        this._setState('MediaSection');
      }, constants.timers.seriesDetailScrollDebounce);
      debouncedScroll();
    }
  }

  static override _states() {
    return [
      class ShowcaseSection extends this {
        override $enter() {
          this._Content.setSmooth('y', 0);
          this._Logo.patch({
            smooth: {
              x: LOGO_X_SHOWCASE,
              y: LOGO_Y_SHOWCASE,
              w: LOGO_W_SHOWCASE,
              h: LOGO_H_SHOWCASE,
            },
          });
        }

        override _getFocused() {
          return this._ShowcaseSection;
        }
      },
      class MediaSection extends this {
        override $enter() {
          this._Content.setSmooth('y', -SHOWCASE_SECTION_HEIGHT);
          this._Logo.patch({
            smooth: {
              x: LOGO_X_MEDIA,
              y: LOGO_Y_MEDIA,
              w: LOGO_W_MEDIA,
              h: LOGO_H_MEDIA,
            },
          });
        }

        override _getFocused() {
          return this._MediaSection;
        }

        override _handleBack() {
          this._setState('ShowcaseSection');
          return true;
        }
      },
    ];
  }
}
