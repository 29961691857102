import { Colors, Lightning } from '@lightningjs/sdk';
import Button from 'components/common/Button';
import MetadataLabel from 'components/common/MetadataLabel';
import MoreInfo from 'components/common/MoreInfo';
import MoreInfoModal from 'components/common/MoreInfoModal';
import { getImageTextureObj } from 'support/generalUtils';
import { translate } from 'support/translate';
import { Live } from 'types/api/media';

export interface LiveBillboardSectionTemplateSpec
  extends Lightning.Component.TemplateSpec {
  live: Live | null;
  Billboard: Lightning.Component;
  HorizontalGradient: Lightning.textures.RectangleTexture;
  VerticalGradient: Lightning.textures.RectangleTexture;
  Content: {
    Metadata: typeof MetadataLabel;
    MoreInfo: typeof MoreInfo;
    PlayButton: typeof Button;
  };
  MoreInfoModal: typeof MoreInfoModal;
}

const BILLBOARD_W = 1758;
const BILLBOARD_H = 702;

const HORIZ_GRADIENT_W = 1065;
const HORIZ_GRADIENT_H = 702;

const CONTENT_X = 96;
const CONTENT_Y = 329;

const MORE_INFO_PADDING = 20;
const MORE_INFO_MARGIN = 19;

const PLAY_BUTTON_MARGIN = 40;
const PLAY_BUTTON_WIDTH = 219;
const PLAY_BUTTON_FONT_SIZE = 23;

const BUTTON_HEIGHT = 60;
const PLAY_ICON_UNFOCUSED = getImageTextureObj(
  'static/images/playback/play-icon-light.svg',
  28,
  30,
);
const PLAY_ICON_FOCUSED = getImageTextureObj(
  'static/images/playback/play-icon-dark.svg',
  28,
  30,
);

export default class LiveBillboardSection
  extends Lightning.Component<LiveBillboardSectionTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<LiveBillboardSectionTemplateSpec>
{
  private _live: Live | null = null;

  private _Billboard = this.getByRef('Billboard')!;
  private _Content = this.getByRef('Content')!;
  private _Metadata = this._Content.getByRef('Metadata')!;
  private _MoreInfo = this._Content.getByRef('MoreInfo')!;
  private _PlayButton = this._Content.getByRef('PlayButton')!;
  private _MoreInfoModal = this.getByRef('MoreInfoModal')!;

  static get h() {
    return BILLBOARD_H;
  }

  get announce() {
    if (!this.live) return;
    const { title, liveTuneIn, description } = this.live;

    return [title, liveTuneIn, description];
  }

  get live() {
    return this._live;
  }

  set live(live: Live | null) {
    this._live = live;
    if (!live) return;

    this.updateBillboardImage(live);
    this.updateMetadata(live);
    this.updateMoreInfo(live);
    this.updateMoreInfoModal(live);
  }

  static override _template(): Lightning.Component.Template<LiveBillboardSectionTemplateSpec> {
    return {
      Billboard: {
        w: BILLBOARD_W,
        h: BILLBOARD_H,
      },
      VerticalGradient: {
        rect: true,
        w: BILLBOARD_W,
        h: BILLBOARD_H,
        colorTop: Colors('appBackground').alpha(0).get(),
        colorBottom: Colors('appBackground').get(),
      },
      HorizontalGradient: {
        rect: true,
        w: HORIZ_GRADIENT_W,
        h: HORIZ_GRADIENT_H,
        colorRight: Colors('appBackground').alpha(0).get(),
        colorLeft: Colors('appBackground').get(),
      },
      Content: {
        x: CONTENT_X,
        y: CONTENT_Y,
        flex: {
          direction: 'column',
        },
        Metadata: {
          type: MetadataLabel,
        },
        MoreInfo: {
          x: -MORE_INFO_PADDING,
          type: MoreInfo,
          action: '$showModal',
          isLive: true,
          signals: {
            $showModal: '$showModal',
            $onHover: '$onHover',
          },
          flexItem: {
            marginBottom: MORE_INFO_MARGIN,
          },
        },
        PlayButton: {
          type: Button,
          minWidth: PLAY_BUTTON_WIDTH,
          height: BUTTON_HEIGHT,
          label: translate('global.watchNow'),
          fontSize: PLAY_BUTTON_FONT_SIZE,
          action: '$playContent',
          startIcon: {
            focused: PLAY_ICON_FOCUSED,
            unfocused: PLAY_ICON_UNFOCUSED,
          },
          passSignals: {
            $playContent: '$playContent',
          },
          signals: {
            $onHover: '$onHover',
          },
          flexItem: {
            marginRight: PLAY_BUTTON_MARGIN,
          },
        },
      },
      MoreInfoModal: {
        alpha: 0,
        zIndex: 999,
        x: -162,
        type: MoreInfoModal,
      },
    };
  }

  override _setup() {
    this._setState('PlayButton');
  }

  override _inactive() {
    this._setState('PlayButton');
  }

  private updateBillboardImage(live: Live) {
    const { showcaseThumbnail } = live;
    const src = showcaseThumbnail ?? '';

    this._Billboard.patch({ src });
  }

  private updateMetadata(live: Live) {
    this._Metadata.patch({ mediaContent: live });
  }

  private updateMoreInfo(live: Live) {
    const { description } = live;
    this._MoreInfo.patch({ description });
  }

  private updateMoreInfoModal(live: Live) {
    this._MoreInfoModal.patch({ mediaContent: live });
  }

  private hideModal() {
    this._MoreInfoModal.patch({
      smooth: {
        alpha: 0,
      },
    });
    this.signal('$showNavBar');
  }

  $showModal() {
    this._MoreInfoModal.patch({
      smooth: {
        alpha: 1,
      },
    });
    this.signal('$hideNavBar');
    this._setState('MoreInfoModal');
  }

  private $onHover(target: any) {
    switch (target) {
      case this._MoreInfo:
        this._setState('MoreInfo');
        break;
      case this._PlayButton:
        this._setState('PlayButton');
        break;
    }
    this.signal('$onBillboardHover');
  }

  static override _states() {
    return [
      class MoreInfo extends this {
        override _getFocused() {
          return this._MoreInfo;
        }

        override _handleDown() {
          this._setState('PlayButton');
        }
      },

      class MoreInfoModal extends this {
        override _getFocused() {
          return this._MoreInfoModal;
        }

        override _handleBack() {
          this._setState('MoreInfo');
          this.hideModal();
        }

        override _handleLeft() {
          // prevent getting to NavBar
          return;
        }

        override _handleDown() {
          return;
        }
      },
      class PlayButton extends this {
        override _getFocused() {
          return this._PlayButton;
        }

        override _handleUp() {
          this._setState('MoreInfo');
        }
      },
    ];
  }
}
