import Lightning from '@lightningjs/sdk/src/Lightning';
import {
  HoverableComponent,
  HoverableComponentTypeConfig,
} from './HoverableComponent';
import { AppData } from '@lightningjs/sdk';

export class HoverableListItem<
  TemplateSpec extends Lightning.Component.TemplateSpec = Lightning.Component.TemplateSpec,
  TypeConfig extends Lightning.Component.TypeConfig &
    HoverableComponentTypeConfig = Lightning.Component.TypeConfig &
    HoverableComponentTypeConfig,
> extends HoverableComponent<TemplateSpec, TypeConfig> {
  collectionWrapper: any; // set by CollectionWrapper
  itemIndex: any; // set by CollectionWrapperWithSpeech

  override _setup() {
    super._setup();
  }

  override _handleHover(target: any) {
    if (AppData?.device.hoverDisabled()) {
      return true;
    }

    if (this.collectionWrapper) {
      this.collectionWrapper.isHovered = true;
      this.collectionWrapper.index = this.itemIndex;
    }
    return super._handleHover(target);
  }
}
