import { Show, Video } from 'types/api/media';
import Tile, { TileTemplateSpec } from './Tile';

export interface LandscapeTileTemplateSpec extends TileTemplateSpec {
  data: Show | Video;
}

const TILE_WIDTH = 355;
const TILE_HEIGHT = 200;

export default class LandscapeTile extends Tile<LandscapeTileTemplateSpec> {
  static override get width() {
    return TILE_WIDTH;
  }

  static override get height() {
    return TILE_HEIGHT;
  }

  static override get highlightWidth() {
    return TILE_WIDTH;
  }

  static override get highlightHeight() {
    return TILE_HEIGHT;
  }
}
