export default class BackgroundingService {
  private onBackgroundingActions: Record<string, () => void> = {};
  private onForegroundingActions: Record<string, () => void> = {};

  constructor() {
    document.addEventListener(
      'visibilitychange',
      () => {
        const isBackgrounding = document.hidden;
        console.log(
          `[backgrounding service]: ${
            isBackgrounding ? 'backgrounding' : 'foregrounding'
          }`,
        );

        let actions: Array<() => void> = [];
        if (isBackgrounding) {
          actions = Object.values(this.onBackgroundingActions);
        } else {
          actions = Object.values(this.onForegroundingActions);
        }

        actions.forEach(action => action());
      },
      false,
    );
  }

  addBackgroundingAction(key: string, action: () => void) {
    this.onBackgroundingActions[key] = action;
  }

  removeBackgroundingAction(key: string) {
    delete this.onBackgroundingActions[key];
  }

  addForegroundingAction(key: string, action: () => void) {
    this.onForegroundingActions[key] = action;
  }

  removeForegroundingAction(key: string) {
    delete this.onForegroundingActions[key];
  }
}
