import { Colors, Lightning } from '@lightningjs/sdk';

export interface PlaybackProgressBarTemplateSpec
  extends Lightning.Component.TemplateSpec {
  value: number;
  progressColor: number;
  backgroundColor: number;
  progressRadius: number;
  ProgressMain: Lightning.textures.RectangleTexture;
  ProgressEnd: Lightning.textures.RectangleTexture;
}

export default class PlaybackProgressBar
  extends Lightning.Component<PlaybackProgressBarTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<PlaybackProgressBarTemplateSpec>
{
  private _value = 0;
  private _progressColor = Colors('appAccent').get();
  private _backgroundColor = Colors('white').alpha(0.6).get();
  private _progressRadius = 0;

  private _ProgressMain = this.getByRef('ProgressMain')!;
  private _ProgressEnd = this.getByRef('ProgressEnd')!;

  get value() {
    return this._value;
  }

  set value(value: PlaybackProgressBarTemplateSpec['value']) {
    this._value = value;

    let sanitizedValue = value;
    if (value < 0) sanitizedValue = 0;
    if (value > 1) sanitizedValue = 1;

    const maxMainWidth = this.w - this.progressRadius;
    const actualProgressWidth = this.w * sanitizedValue;

    if (actualProgressWidth > maxMainWidth) {
      this._ProgressMain.w = maxMainWidth;
      this._ProgressEnd.w = actualProgressWidth - maxMainWidth;
    } else {
      this._ProgressMain.w = actualProgressWidth;
      this._ProgressEnd.w = 0;
    }
  }

  get progressColor() {
    return this._progressColor;
  }

  set progressColor(
    progressColor: PlaybackProgressBarTemplateSpec['progressColor'],
  ) {
    this._progressColor = progressColor;
    this.updateProgressColor();
  }

  get backgroundColor() {
    return this._backgroundColor;
  }

  set backgroundColor(
    backgroundColor: PlaybackProgressBarTemplateSpec['backgroundColor'],
  ) {
    this._backgroundColor = backgroundColor;
    this.updateBackgroundColor();
  }

  get progressRadius() {
    return this._progressRadius;
  }

  set progressRadius(
    progressRadius: PlaybackProgressBarTemplateSpec['progressRadius'],
  ) {
    this._progressRadius = progressRadius;

    this.shader = {
      type: Lightning.shaders.RoundedRectangle,
      radius: progressRadius,
    };
    this._ProgressMain.shader = {
      type: Lightning.shaders.RoundedRectangle,
      topLeft: progressRadius,
      bottomLeft: progressRadius,
    };
    this._ProgressEnd.shader = {
      type: Lightning.shaders.RoundedRectangle,
      topRight: progressRadius,
      bottomRight: progressRadius,
    };

    this._ProgressEnd.x = this.w - progressRadius - 1;
  }

  static override _template(): Lightning.Component.Template<PlaybackProgressBarTemplateSpec> {
    return {
      rect: true,
      ProgressMain: {
        h: h => h,
        rect: true,
        color: Colors('appAccent').get(),
      },
      ProgressEnd: {
        h: h => h,
        rect: true,
        color: Colors('appAccent').get(),
      },
    };
  }

  override _setup() {
    super._setup();

    this.updateBackgroundColor();
    this.updateProgressColor();
  }

  private updateBackgroundColor() {
    this.color = this._backgroundColor;
  }

  private updateProgressColor() {
    this._ProgressMain.color = this._progressColor;
    this._ProgressEnd.color = this._progressColor;
  }
}
