import { Colors, Lightning } from '@lightningjs/sdk';
import {
  hoursToMilliseconds,
  minutesToMilliseconds,
  timeTo12HourClockHoursMinutes,
} from 'support/dateUtils';
import constants from '../../../../static/constants.json';
import EpgProgressIndicator from './EpgProgressIndicator';

export interface EpgTimelineTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Timeline: object;
  ProgressIndicator: typeof EpgProgressIndicator;
}

const TIMELINE_Y = 13;
const TIMELINE_TAB_WIDTH = constants.ui.epgWidthPerThirtyMin;
const TIMELINE_TAB_HEIGHT = 20;

const LINE_WIDTH = 1;

const TIME_FONT_SIZE = 18;
const TIME_PADDING_LEFT = 9;

export default class EpgTimeline
  extends Lightning.Component<EpgTimelineTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<EpgTimelineTemplateSpec>
{
  private _startTime?: Date;

  private _Timeline = this.getByRef('Timeline')!;
  private _ProgressIndicator = this.getByRef('ProgressIndicator')!;

  static override _template(): Lightning.Component.Template<EpgTimelineTemplateSpec> {
    return {
      Timeline: {
        flex: { direction: 'row' },
        y: TIMELINE_Y,
      },
    };
  }

  updateTime(currentTime: Date, startTime: Date) {
    if (this._startTime?.getTime() !== startTime.getTime()) {
      this._startTime = startTime;
      this.initializeTimeline();
    }
  }

  private initializeTimeline() {
    const timelineTimes = this.getTimelineDates();
    const children = timelineTimes.map(time => this.buildTimelineTab(time));
    this._Timeline.patch({ children });
  }

  private getTimelineDates() {
    if (!this._startTime) return [];

    const startTime = new Date(this._startTime);
    // Get the next 12 hours
    const endTime = new Date(startTime.getTime() + hoursToMilliseconds(12));

    const timelineDates: string[] = [];
    while (startTime <= endTime) {
      const date = timeTo12HourClockHoursMinutes(startTime);
      timelineDates.push(date);

      // Increase start time by 30 minutes
      startTime.setTime(startTime.getTime() + minutesToMilliseconds(30));
    }

    return timelineDates;
  }

  private buildTimelineTab(date: string) {
    return {
      flex: { direction: 'row', alignItems: 'center' },
      w: TIMELINE_TAB_WIDTH,
      h: TIMELINE_TAB_HEIGHT,
      Line: {
        rect: true,
        w: LINE_WIDTH,
        h: TIMELINE_TAB_HEIGHT,
        color: Colors('white').get(),
      },
      Time: {
        text: {
          text: date,
          textColor: Colors('text').get(),
          fontSize: TIME_FONT_SIZE,
          paddingLeft: TIME_PADDING_LEFT,
        },
      },
    };
  }
}
