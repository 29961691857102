import { AppData, Lightning } from '@lightningjs/sdk';
import Player from 'components/pages/playback/Player';
import { EpgChannel } from 'types/api/media';
import PlayerEventConsumer from 'components/pages/playback/PlayerEventConsumer';
import FastPlaybackOverlay from 'components/pages/epg/FastPlaybackOverlay';
import { debounce } from 'support/generalUtils';
import { constants } from 'aliases';
import { getDashItem, getDrmData } from 'services/drmService';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { StaticViewContexts, ViewContext } from 'types/analytics';
import TextTrackDisplay from 'components/pages/playback/TextTrackDisplay';

export interface EpgPlayerTemplateSpec
  extends Lightning.Component.TemplateSpec {
  channel: EpgChannel | null;
  absoluteY: number;
  absoluteX: number;
  xAbsoluteOffset: number;
  viewContext: ViewContext;

  Content: {
    Player: typeof Player;
    PlayerEventConsumer: typeof PlayerEventConsumer;
    TextTrackDisplay: typeof TextTrackDisplay;
    Overlay: typeof FastPlaybackOverlay;
    LoadingSpinner: typeof LoadingSpinner;
  };
}

export const MINI_PLAYER_X = 1053;
export const MINI_PLAYER_Y = 113;
export const MINI_PLAYER_WIDTH = 562;
export const MINI_PLAYER_HEIGHT = 313;
export const MINI_PLAYER_RADIUS = 10;

const MINI_PLAYER_LOADING_SPINNER_X = MINI_PLAYER_WIDTH / 2;
const MINI_PLAYER_LOADING_SPINNER_Y = MINI_PLAYER_HEIGHT / 2;

const FULL_SCREEN_PLAYER_X = 0;
const FULL_SCREEN_PLAYER_Y = 0;
const FULL_SCREEN_PLAYER_WIDTH = 1920;
const FULL_SCREEN_PLAYER_HEIGHT = 1080;

const FULL_SCREEN_PLAYER_LOADING_SPINNER_X = FULL_SCREEN_PLAYER_WIDTH / 2;
const FULL_SCREEN_PLAYER_LOADING_SPINNER_Y = FULL_SCREEN_PLAYER_HEIGHT / 2;

const INTERACTIVE_TO_IDLE_TRANSITION_TIME = 5000;

export default class EpgPlayer
  extends Lightning.Component<EpgPlayerTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<EpgPlayerTemplateSpec>
{
  private _Content = this.getByRef('Content')!;
  private _Player = this._Content.getByRef('Player')!;
  private _PlayerEventConsumer = this._Content.getByRef('PlayerEventConsumer')!;
  private _TextTrackDisplay = this._Content.getByRef('TextTrackDisplay')!;
  private _Overlay = this._Content.getByRef('Overlay')!;
  private _LoadingSpinner = this._Content.getByRef('LoadingSpinner')!;

  viewContext = StaticViewContexts.EPG;

  private _channel: EpgChannel | null = null;
  private _ccEnabled = false;

  private _restartIdleTimer = debounce(() => {
    const isCcOverlayOpen = this._Overlay.isCcOverlayOpen();

    if (this._getState() === 'FullscreenInteractive' && !isCcOverlayOpen)
      this._setState('FullscreenIdle');
  }, INTERACTIVE_TO_IDLE_TRANSITION_TIME);

  xAbsoluteOffset = 0;

  set channel(channel: EpgChannel | null) {
    // don't update the channel if it's currently selected
    if (!!channel?.id && channel.id === this._channel?.id) return;

    this._Overlay.patch({ channel });

    this._channel = channel;
    this.updateChannel();
  }

  get channel() {
    return this._channel;
  }

  get player() {
    return this._Player;
  }

  set absoluteX(x: number) {
    this.x = x - this.xAbsoluteOffset;
    this._Player.patch({ absoluteX: x });
  }

  set absoluteY(y: number) {
    this.y = y;
    this._Player.patch({ absoluteY: y });
  }
  override set w(w: number) {
    super.w = w;
    this._Player.patch({ w });
  }

  override set h(h: number) {
    super.h = h;
    this._Player.patch({ h });
  }

  static override _template(): Lightning.Component.Template<EpgPlayerTemplateSpec> {
    return {
      Content: {
        Player: {
          type: Player,
        },
        PlayerEventConsumer: {
          type: PlayerEventConsumer,
          signals: {
            $bufferStart: '$bufferStart',
            $bufferEnd: '$bufferEnd',
            $onManualTextTrackChange: '$onManualTextTrackChange',
            $fatalError: '$fatalError',
          },
        },
        TextTrackDisplay: {
          type: TextTrackDisplay,
        },
        Overlay: {
          type: FastPlaybackOverlay,
          passSignals: {
            backToGuide: true,
          },
          signals: {
            ccAudio: '$ccAudio',
            onSubtitlesChange: '$onSubtitlesChange',
            onAudioChange: '$onAudioChange',
          },
        },
        LoadingSpinner: {
          type: LoadingSpinner,
          visible: false,
        },
      },
    };
  }

  override _setup() {
    this._ccEnabled =
      AppData?.storageService.subtitle.get() !== constants.closedCaption.none ||
      !!AppData?.device.getCcSettings().enabled;
  }

  override _active() {
    this._Player.open();
  }

  override _enable() {
    this._Player.initialize(this._PlayerEventConsumer);
  }

  override _inactive() {
    this.endPlayback();
  }

  override _getFocused() {
    return this._Overlay;
  }

  private _handleClick() {
    if (this._getState() === 'FullscreenIdle') {
      this._setState('FullscreenInteractive');
    }
  }

  private async updateChannel() {
    this._TextTrackDisplay.reset();
    this.endPlayback();
    if (!this._channel) return;

    const { isDrm } = this._channel;
    this._Player.hasDrm = isDrm;

    let streamUrl = this._channel.streamUrl;
    if (isDrm) {
      const drmItem = getDashItem(this._channel.drmItems);
      const url = drmItem?.selectorUrl;

      if (url) {
        try {
          const drmData = await getDrmData(url);

          this._Player.drmLicenseUrl = drmData?.licenseUrl;
          streamUrl = drmData?.videoSrc ?? streamUrl;
        } catch (e) {
          // Do nothing, attempt to use the channel's stream url. Stream errors
          // will still be caught in the Player layer
        }
      }
    }

    this._PlayerEventConsumer.initialize(
      this._Player,
      this._channel,
      null,
      this.viewContext,
      undefined,
      0,
    );

    this._Player.liveStreamUrl = streamUrl || null;
    this._Player.liveStreamType = this._channel.streamType;
    this._Player.adZone = this._channel.adZone;
    this._Player.open();
  }

  private endPlayback() {
    this._PlayerEventConsumer.endPlayback({ hasReachedEnd: false });
    this._Player.close();
  }

  fullscreen() {
    this.patch({
      absoluteX: FULL_SCREEN_PLAYER_X,
      absoluteY: FULL_SCREEN_PLAYER_Y,
      w: FULL_SCREEN_PLAYER_WIDTH,
      h: FULL_SCREEN_PLAYER_HEIGHT,
    });
    this._LoadingSpinner.patch({
      x: FULL_SCREEN_PLAYER_LOADING_SPINNER_X,
      y: FULL_SCREEN_PLAYER_LOADING_SPINNER_Y,
    });

    this._setState('FullscreenIdle');
  }

  minify() {
    this.patch({
      absoluteX: MINI_PLAYER_X,
      absoluteY: MINI_PLAYER_Y,
      w: MINI_PLAYER_WIDTH,
      h: MINI_PLAYER_HEIGHT,
    });
    this._LoadingSpinner.patch({
      x: MINI_PLAYER_LOADING_SPINNER_X,
      y: MINI_PLAYER_LOADING_SPINNER_Y,
    });

    this._setState('Mini');
  }

  updateTime(currentTime: Date) {
    this._Overlay.updateTime(currentTime);
  }

  pause() {
    this._Player.pause();
  }

  resume() {
    this._Player.resume();
  }

  $ccAudio() {
    const currSubtitle = AppData!.storageService.subtitle.get();
    const currAudio = AppData!.storageService.audio.get();
    const subtitles = this._Player.getSubtitles();

    let ccIndex = -1;
    let audioIndex = -1;

    if (subtitles.length) {
      if (currSubtitle) ccIndex = this._Player.getCcIndex(currSubtitle);

      // If no cc option is saved, but device cc is enabled, use the first subtitles
      if (ccIndex === -1 && AppData?.device.getCcSettings().enabled)
        ccIndex = 0;
    }

    const audio = this._Player.getAudioOptions();
    if (currAudio) {
      audioIndex = audio.findIndex(audioOption => audioOption === currAudio);
    }
    // If no audio index selected but we have audio options, use the first one (Default)
    if (audioIndex === -1 && audio.length) {
      audioIndex = 0;
    }

    const cc = [constants.closedCaption.none, ...subtitles];

    ccIndex += 1; // Adding one to account for 'none'

    this._Overlay.updateCc(ccIndex, audioIndex, cc, audio);
  }

  $onSubtitlesChange(option: string) {
    this._ccEnabled = option !== constants.closedCaption.none;
    if (!this._ccEnabled) {
      this._Player.disableCc();
      this._TextTrackDisplay.reset();
    } else {
      this._Player.enableCc(option);
    }

    AppData!.storageService.subtitle.set(option);
    window.analytics.mParticle.video.reportCloseCaptions(option);
  }

  $onManualTextTrackChange(textTrack: TextTrack) {
    if (this._ccEnabled) {
      this._TextTrackDisplay.displayTextTrack(textTrack);
    }
  }

  private $fatalError() {
    this.endPlayback();
    this.signal('$fatalError');
  }

  private $onAudioChange(option: string) {
    this._Player.setAudioTrack(option);
    AppData!.storageService.audio.set(option);
  }

  private $bufferStart() {
    this._LoadingSpinner.patch({ visible: true });
  }

  private $bufferEnd() {
    this._LoadingSpinner.patch({ visible: false });
  }

  static override _states() {
    return [
      class Mini extends this {
        override $enter() {
          this._Overlay.hide();
        }
      },

      class FullscreenIdle extends this {
        override $enter() {
          this._Overlay.hide();
          this.signal('onFullscreenIdle');
        }

        override _captureKey() {
          this._setState('FullscreenInteractive');
          this.signal('onFullscreenInteractive');
        }
      },

      class FullscreenInteractive extends this {
        override $enter() {
          this._Overlay.show();
          this._restartIdleTimer();
        }

        override _captureKey() {
          this._restartIdleTimer();
          return false;
        }
      },
    ];
  }
}
