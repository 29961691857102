export enum VizioCcStyleMenuValue {
  CAPTION_COLOR_DEFAULT = 'As Broadcast',
  CAPTION_COLOR_BLACK = 'Black',
  CAPTION_COLOR_WHITE = 'White',
  CAPTION_COLOR_GREEN = 'Green',
  CAPTION_COLOR_BLUE = 'Blue',
  CAPTION_COLOR_RED = 'Red',
  CAPTION_COLOR_CYAN = 'Cyan',
  CAPTION_COLOR_YELLOW = 'Yellow',
  CAPTION_COLOR_MAGENTA = 'Magenta',
  CAPTION_OPACITY_DEFAULT = 'As Broadcast',
  CAPTION_OPACITY_SOLID = 'Solid',
  CAPTION_OPACITY_TRANSLUCENT = 'Translucent',
  CAPTION_OPACITY_TRANSPARENT = 'Transparent',
  CAPTION_OPACITY_FLASH = 'Flashing',
  CAPTION_EDGE_DEFAULT = 'As Broadcast',
  CAPTION_EDGE_NONE = 'None',
  CAPTION_EDGE_RAISED = 'Raised',
  CAPTION_EDGE_DEPRESSED = 'Depressed',
  CAPTION_EDGE_UNIFORM = 'Uniform',
  CAPTION_EDGE_DROP_SHADOWED = 'Drop Shadow',
  CAPTION_SIZE_DEFAULT = 'As Broadcast',
  CAPTION_SIZE_SMALL = 'Small',
  CAPTION_SIZE_MEDIUM = 'Medium',
  CAPTION_SIZE_LARGE = 'Large',
  CAPTION_FONT_DEFAULT = 'As Broadcast',
  CAPTION_FONT_STYLE0 = 'Playbill',
  CAPTION_FONT_STYLE1 = 'Newsprint',
  CAPTION_FONT_STYLE2 = 'Computer',
  CAPTION_FONT_STYLE3 = 'Modern',
  CAPTION_FONT_STYLE4 = 'Casual',
  CAPTION_FONT_STYLE5 = 'Script',
  CAPTION_FONT_STYLE6 = 'Capitals',
}

export const VizioCcStyleKey = {
  /**
   * Background color of text, behind the text
   * expected value: VizioCcStyleMenuValue
   * default string 'As Broadcast'
   * string 'Black'
   * string 'White'
   * string 'Green;
   * string 'Blue'
   * string 'Red'
   * string 'Cyan'
   * string 'Yellow'
   * string 'Magenta'
   */
  BACKGROUND_COLOR: 'background_color',
  /**
   * Background Opacity of Text, behind the text
   * expected value: VizioCcStyleMenuValue
   * default string 'As Broadcast'
   * string 'Solid',
   * string 'Translucent',
   * string 'Transparent',
   * string 'Flashing',
   */
  BACKGROUND_OPACITY: 'background_opacity',
  /**
   * Color of Text
   * expected value: VizioCcStyleMenuValue
   * default string 'As Broadcast'
   * string 'Black'
   * string 'White'
   * string 'Green;
   * string 'Blue'
   * string 'Red'
   * string 'Cyan'
   * string 'Yellow'
   * string 'Magenta'
   */
  TEXT_COLOR: 'text_color',
  /**
   * Text Edge type
   * expected value: VizioCcStyleMenuValue
   * default string 'As Broadcast'
   * string 'None',
   * string 'Raised',
   * string 'Depressed',
   * string 'Uniform',
   * string 'Drop Shadow',
   */
  TEXT_EDGES: 'text_edges',
  /**
   * Edge Color.
   * expected value: VizioCcStyleMenuValue
   * default string 'As Broadcast'
   * string 'Back'
   * string 'White'
   * string 'Green;
   * string 'Blue'
   * string 'Red'
   * string 'Cyan'
   * string 'Yellow'
   * string 'Magenta'
   */
  TEXT_EDGES_COLOR: 'text_edges_color',
  /**
   * Opacity of Text
   * expected value: VizioCcStyleMenuValue
   * default string 'As Broadcast'
   * string 'Solid',
   * string 'Translucent',
   * string 'Transparent',
   * string 'Flashing',
   */
  TEXT_OPACITY: 'text_opacity',
  /**
   * Opacity of Text
   * expected value: VizioCcStyleMenuValue
   * default string 'As Broadcast'
   * string 'Small',
   * string 'Medium',
   * string 'Large',
   */
  TEXT_SIZE: 'text_size',
  /**
   * Opacity of Text
   * expected value: VizioCcStyleMenuValue
   * default string 'As Broadcast'
   * string 'As Broadcast',
   * string 'Playbill',
   * string 'Newsprint',
   * string 'Computer',
   * string 'Modern',
   * string 'Casual',
   * string 'Script',
   * string 'Capitals',
   */
  TEXT_STYLE: 'text_style',
  /**
   * Window Color next to the text area
   * expected value: VizioCcStyleMenuValue
   * default string 'As Broadcast'
   * string 'Black'
   * string 'White'
   * string 'Green;
   * string 'Blue'
   * string 'Red'
   * string 'Cyan'
   * string 'Yellow'
   * string 'Magenta'
   */
  WINDOW_COLOR: 'window_color',
  /**
   * Opacity of Window area
   * expected value: VizioCcStyleMenuValue
   * default string 'As Broadcast'
   * string 'Solid',
   * string 'Translucent',
   * string 'Transparent',
   * string 'Flashing',
   */
  WINDOW_OPACITY: 'window_opacity',
};
