import { Lightning } from '@lightningjs/sdk';
import Grid from 'components/common/Grid';
import PortraitTile from 'components/common/tiles/PortraitTile';
import { Lane, Swimlanes } from 'services/cwData';
import { PlayableMediaImages } from 'types/api/media';
import { GridIndexData } from 'types/lightning';
import {
  HoverableComponent,
  HoverableComponentSignalMap,
  HoverableComponentTypeConfig,
} from 'components/common/HoverableComponent';
import { TileParentContext } from 'components/pages/content-hub/ContentHubPage';

export interface GridSectionTemplateSpec
  extends Lightning.Component.TemplateSpec {
  data: Swimlanes | null;

  GridSection: {
    Grid: typeof Grid;
  };
}

interface GridSectionSignalMap extends HoverableComponentSignalMap {
  scrollToPromo(): void;
  scrollToContent(options?: { index?: number }): void;
}

interface GridSectionTypeConfig extends HoverableComponentTypeConfig {
  SignalMapType: GridSectionSignalMap;
}

export default class GridSection
  extends HoverableComponent<GridSectionTemplateSpec, GridSectionTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<GridSectionTemplateSpec>
{
  private _data: GridSectionTemplateSpec['data'] = null;
  private _GridSection = this.getByRef('GridSection')!;
  private _Grid = this._GridSection.getByRef('Grid')!;

  get data() {
    return this._data;
  }

  set data(data: GridSectionTemplateSpec['data']) {
    this._data = data;
    if (!data) return;
    this.updateGrid(data);
  }

  get index() {
    return this._Grid.index;
  }

  override _getFocused() {
    return this._Grid;
  }

  static override _template(): Lightning.Component.Template<GridSectionTemplateSpec> {
    return {
      GridSection: {
        w: 1920,
        h: 1080,
        Grid: {
          type: Grid,
          spacing: 20,
          columns: 6,
          // @ts-ignore
          listType: PortraitTile,
          signals: {
            onIndexChanged: '_onIndexChanged',
          },
        },
      },
    };
  }

  override _setup() {
    super._setup();
  }

  private updateGrid(swimlanes: Swimlanes) {
    const firstGridLane = swimlanes.lanes.find(
      lane => lane.itemType !== 'promo',
    ) as Lane;
    if (!firstGridLane) return this._Grid.clear();
    this._Grid.items = this.buildGridTiles(firstGridLane);
  }

  private buildGridTiles(lane: Lane) {
    return lane.items.map((item, index) => {
      const parentContext: TileParentContext = {
        crossIndex: index,
        mainIndex: 0,
        rowTitle: lane.title ?? '',
        isTivoSource: lane.itemType === 'tivo-api',
      };

      return {
        type: PortraitTile,
        data: item,
        imageUrl: (item.images as PlayableMediaImages).image_vertical,
        notifyWhenFullyVisible: true,
        parentContext: parentContext,
      };
    });
  }

  private _onIndexChanged(indexData: GridIndexData) {
    const { mainIndex } = indexData;

    if (mainIndex === 0) {
      this.signal('firstLaneScroll');
    } else if (mainIndex === 1 || mainIndex === 2) {
      this.signal('scrollToContent', {
        index: mainIndex,
      });
    }
  }
}
