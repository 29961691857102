import { Colors, Lightning } from '@lightningjs/sdk';

export interface BadgeTemplateSpec extends Lightning.Component.TemplateSpec {
  label: string;
}

export default class Badge
  extends Lightning.Component<BadgeTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<BadgeTemplateSpec>
{
  set label(label: string) {
    if (label) {
      this.patch({ visible: true, text: { text: label } });
    } else {
      this.patch({ visible: false });
    }
  }

  static override _template(): Lightning.Component.Template<BadgeTemplateSpec> {
    return {
      visible: false,
      h: 40,
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: 5,
      },
      text: {
        offsetY: 29,
        highlight: true,
        highlightHeight: 40,
        highlightColor: Colors('appAccent').alpha(0.8).get(),
        highlightPaddingLeft: 11,
        highlightPaddingRight: 11,
        fontSize: 25,
        paddingLeft: 11,
        paddingRight: 11,
        textColor: Colors('text').get(),
      },
    };
  }
}
