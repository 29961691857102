import { Media } from 'types/api/media';
import Tile, { TileTemplateSpec } from './Tile';

export interface PortraitTileTemplateSpec extends TileTemplateSpec {
  data: Media;
}

const TILE_WIDTH = 250;
const TILE_HEIGHT = 375;

export default class PortraitTile extends Tile<PortraitTileTemplateSpec> {
  static override get width() {
    return TILE_WIDTH;
  }

  static override get height() {
    return TILE_HEIGHT;
  }

  static override get highlightWidth() {
    return TILE_WIDTH;
  }

  static override get highlightHeight() {
    return TILE_HEIGHT;
  }
}
