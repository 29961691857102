import { Lightning, Router } from '@lightningjs/sdk';
import Page from '../../Page';
import { PageId } from 'types/pageId';
import {
  ContentHub,
  Promo,
  PromoChannel,
  PromoLane,
  Swimlanes,
} from 'services/cwData';
import BillboardCarousel from 'components/common/BillboardCarousel';
import CarouselSection, { SWIMLANES_HEIGHT } from './CarouselSection';
import TabbedSection from './TabbedSection';
import { Media } from 'types/api/media';
import constants from '../../../../static/constants.json';
import {
  getGenericContentHubBillboardViewContext,
  getGenericContentHubSwimlaneViewContext,
  StaticViewContexts,
} from 'types/analytics';
import { ContentHubSlugs } from 'types/contentHubs';
import { navigateToChannel, navigateToContentHub } from 'support/routerUtils';
import { isContentHub, isPromoChannel, isShow } from 'support/contentUtils';
import { NavigationItemLayout } from 'types/api/config';
import { ListItemContext, SelectItemContext } from 'types/events';
import {
  reportCategoryEngagement,
  reportItemSelected,
  reportPromoSelected,
  reportScrollTracking,
  reportViewPromotion,
} from '../../../services/analytics/reportingServicePage';
import { ViewItemProps } from 'services/analytics/MParticle';
import ContentHubTitle from 'components/common/ContentHubTitle';
import { HoverableComponent } from 'components/common/HoverableComponent';

const PADDING_X = constants.ui.contentHubPagePaddingX;
const CONTENT_TOP_MARGIN = 65;
const LAYOUT_MARGIN_TOP = 16;

// mParticle Analytics
const QUARTILES = [0, 25, 50, 75, 100];

export interface ContentHubPageParams extends Router.PageParams {
  hubSlug?: string;
  nested?: boolean;
}

export type TileParentContext = {
  crossIndex: number;
  mainIndex: number;
  group?: number;
  rowTitle: string;
  isTivoSource: boolean;
};

export type CollectionVisibilityItem = { obj: ViewItemProps };

export interface ContentHubPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  pageData: {
    swimlanes: Swimlanes;
    template: NavigationItemLayout;
  };
  hubSlug: string;
  nested: boolean;

  Content: {
    HeroBillboardCarousel: typeof HoverableComponent;
    Title: typeof ContentHubTitle;
    CarouselLayout: typeof CarouselSection;
    TabbedLayout: typeof TabbedSection;
  };
}

export default class ContentHubPage
  extends Page<ContentHubPageTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<ContentHubPageTemplateSpec>
{
  protected override _pageId = PageId.CONTENT_HUB;

  private _hubSlug = '';
  private _hubTitle = '';
  nested = false;

  // Internal properties
  private _shouldUpdate = false;
  private _isBillboardFocusable = false;
  private _quartilesFired = 0;
  private _viewPromoTrackedIndices: number[] = [];
  private _selectedCrossIndex: number | undefined = undefined;

  private _Content = this.getByRef('Content')!;
  private _HeroBillboardCarousel = this._Content.getByRef(
    'HeroBillboardCarousel',
  )!;
  private _Title = this._Content.getByRef('Title')!;
  private _CarouselLayout = this._Content.getByRef('CarouselLayout')!;
  private _TabbedLayout = this._Content.getByRef('TabbedLayout')!;

  get title() {
    return this._Title.text?.text ?? '';
  }

  get hubSlug() {
    return this._hubSlug;
  }

  static override _template(): Lightning.Component.Template<ContentHubPageTemplateSpec> {
    const LAYOUT_SIGNALS = {
      scrollToContent: '_scrollToContent',
      firstLaneScroll: '_firstLaneScroll',
      $onHover: '$onHover',
    };

    return {
      ...super._template(),
      Content: {
        x: constants.sizing.navbar.collapsedWidth,
        w: 1920 - constants.sizing.navbar.collapsedWidth,
        h: 1080,
        flex: { direction: 'column' },
        // Workaround #1: We are using BillboardCarousel as a placeholder here because there seems to be a bug with LightningJS
        // where it doesn't render the other sections in the BillboardCarousel component when we update its `lane` field
        HeroBillboardCarousel: {
          type: HoverableComponent,
          h: BillboardCarousel.height,
          visible: false,
          signals: {
            $onHover: '$onHover',
          },
        },
        Title: {
          type: ContentHubTitle,
          h: 60,
          x: PADDING_X,
          visible: false,
        },
        CarouselLayout: {
          x: PADDING_X,
          type: CarouselSection,
          visible: false,
          signals: LAYOUT_SIGNALS,
        },
        TabbedLayout: {
          x: PADDING_X,
          w: 1920 - constants.sizing.navbar.collapsedWidth - 2 * PADDING_X,
          type: TabbedSection,
          visible: false,
          signals: {
            ...LAYOUT_SIGNALS,
            onTabSelected: '_onTabSelected',
          },
        },
      },
    };
  }

  override _setup() {
    super._setup();
  }

  private async pageSetup() {
    window.collectionVisibilityService.reset();

    const reportItems = (items: CollectionVisibilityItem[]) => {
      const products: ViewItemProps[] = items.map(item => item.obj);
      window.analytics.mParticle.reportViewItemList(products);
    };
    window.collectionVisibilityService.initialize<ViewItemProps>(
      reportItems.bind(this),
    );

    if (this._CarouselLayout.visible)
      await this._CarouselLayout.updateContinueLane();

    if (this._hubSlug !== ContentHubSlugs.HOME) return;

    // reset Quartiles
    this._quartilesFired = 0;
    reportScrollTracking(0);
    if (this._getState() !== 'CarouselLayout') return;

    const { mainIndex, crossIndex } = this._CarouselLayout.index;
    if (
      this._selectedCrossIndex !== undefined &&
      crossIndex !== this._selectedCrossIndex
    ) {
      this._CarouselLayout.setCrossIndex({
        mainIndex,
        crossIndex: this._selectedCrossIndex,
      });
      this._selectedCrossIndex = undefined;
    }

    if (mainIndex >= 1) {
      const swimlaneBottomY =
        -this._CarouselLayout.swimlaneWrapperY + SWIMLANES_HEIGHT;
      this._scrollToContent({
        index: mainIndex,
        scrollTarget: swimlaneBottomY,
      });
    }
  }

  override _onUrlParams(params: ContentHubPageParams) {
    const hubSlug = params?.hubSlug ?? '';
    const nested = !!params?.nested;
    const currentState = this._getState();

    // We only update content hub page if:
    // 1. The hub slug is new, OR
    // 2. The nested state changed, OR
    // 3. The state is unset
    this._shouldUpdate =
      this._hubSlug !== hubSlug ||
      this.nested !== nested ||
      currentState === '';

    if (this._shouldUpdate) this._selectedCrossIndex = undefined;
    this._hubSlug = hubSlug;
    this.nested = nested;
  }

  override async _onDataProvided() {
    this._viewPromoTrackedIndices = [];
    this.updateNavbar();

    // Only update content hub if we need to
    if (this._shouldUpdate) {
      const { swimlanes, template } = this.pageData;
      this._hubTitle = swimlanes.title;

      this.resetContentHub();
      this.buildBillboard(swimlanes, true);
      this.buildTitle(swimlanes);

      if (template === 'carousels') {
        this.buildCarousels(swimlanes);
      } else if (template === 'tabbed') {
        this.buildTabs(swimlanes);
      }

      if (this._isBillboardFocusable) {
        this._setState('Billboard');
      } else if (template === 'carousels') {
        this._setState('CarouselLayout');
      } else if (template === 'tabbed') {
        this._setState('TabbedLayout');
      }

      const x = this.isNavBarVisible ? PADDING_X : 160;
      this._Title.patch({ x });
      if (this._CarouselLayout.visible) {
        this._CarouselLayout.patch({ x });
      } else if (this._TabbedLayout.visible) {
        this._TabbedLayout.patch({ x });
      }

      // Always scroll to the first lane when the page is updated
      this._firstLaneScroll();
    }

    // call additional page setup after onDataProvided instead of _active
    await this.pageSetup();
  }

  override _handleBack() {
    if (!this.isNavBarVisible) return false;
    Router.focusWidget('NavBar');
  }

  private buildBillboard(swimlanes: Swimlanes, isHero: boolean) {
    const promoLane = swimlanes.lanes.find(
      lane => lane.itemType === 'promo', // if we ever support billboard to tivo we will need to update this and onBillboardSelect args
    ) as PromoLane | undefined;
    if (!promoLane || !promoLane.items.length) return;

    this._isBillboardFocusable = true;
    this._HeroBillboardCarousel.visible = true;
    // Refer to Workaround #1
    this._HeroBillboardCarousel.children = [
      {
        type: BillboardCarousel,
        hasNavbar: this.isNavBarVisible,
        pageTitle: swimlanes.title,
        isHero, // this must be set before fade and lane
        // Should be a gradient under the Tabs, but since there is issue rendering things around the BillboardCarousel
        // adding a fade here instead
        fade: 176 * 2,
        lane: promoLane,
        signals: {
          $onHover: '$onHover',
        },
      },
    ];
  }

  private buildTitle(swimlanes: Swimlanes) {
    const { titleDisplayed, title } = swimlanes;
    const isHeroVisible = this._HeroBillboardCarousel.visible;
    const marginTop = isHeroVisible ? -this._Title.h : CONTENT_TOP_MARGIN;

    this._Title.patch({
      flexItem: { marginTop },
      visible: !!titleDisplayed,
      isBillboard: false,
      title: title,
    });
  }

  private buildCarousels(swimlanes: Swimlanes) {
    const isTitleVisible = this._Title.visible;
    const isHeroVisible = this._HeroBillboardCarousel.visible;
    const marginTop =
      isTitleVisible || isHeroVisible ? LAYOUT_MARGIN_TOP : CONTENT_TOP_MARGIN;

    this._CarouselLayout.patch({
      data: swimlanes,
      flexItem: { marginTop },
      visible: true,
    });
  }

  private buildTabs(swimlanes: Swimlanes, layout?: NavigationItemLayout) {
    const isTitleVisible = this._Title.visible;
    const isHeroVisible = this._HeroBillboardCarousel.visible;
    const marginTop =
      isTitleVisible || isHeroVisible ? LAYOUT_MARGIN_TOP : CONTENT_TOP_MARGIN;

    this._TabbedLayout.patch({
      data: swimlanes,
      flexItem: { marginTop },
      useTabs: layout === 'tabbed',
      visible: true,
      topPadding: marginTop,
    });
  }

  private resetContentHub() {
    this._isBillboardFocusable = false;

    this._HeroBillboardCarousel.visible = false;
    this._CarouselLayout.visible = false;
    this._TabbedLayout.visible = false;

    this._HeroBillboardCarousel.alpha = 1;
    this._Content.y = 0;
  }

  private updateNavbar() {
    this.isNavBarVisible = this.nested ? false : true;

    // TODO: probably need to update this on EL-565
    this._Content.patch({
      x: this.isNavBarVisible ? constants.sizing.navbar.collapsedWidth : 0,
    });
  }

  _onTabSelected() {
    const category = this._hubTitle;
    const tabIndex = this._TabbedLayout.activeTabIndex ?? 0;
    const tabName = this._TabbedLayout.activeTabName ?? '';

    reportCategoryEngagement({
      category,
      tabIndex,
      tabName,
    });
  }

  private scrollToPromo() {
    this._Title.setSmooth('alpha', 1);
    this._Content.setSmooth('y', 0);
  }

  private _scrollToContent(
    options: { index?: number; scrollTarget?: number } = {},
  ) {
    const { index } = options;

    const isCarouselSectionVisible = this._CarouselLayout.visible;
    const isHeroBillboardVisible = this._HeroBillboardCarousel.visible;
    const isTabbedSectionVisible = this._TabbedLayout.visible;
    const isTitleVisible = this._Title.visible;
    let contentY = 0;

    if (isCarouselSectionVisible) {
      const carouselSectionTopMargin =
        this._CarouselLayout.flexItem.marginTop ?? 0;
      contentY = 0 - carouselSectionTopMargin;

      if (isHeroBillboardVisible) {
        // The title is inside the billboard, so there's no need to account for the title here
        const heroBillboardHeight = BillboardCarousel.height;
        contentY -= heroBillboardHeight;
      } else if (isTitleVisible) {
        const titleTopMargin = this._Title.flexItem.marginTop ?? 0;
        const titleHeight = this._Title.h;
        contentY -= titleTopMargin + titleHeight;
      }

      this._Content.setSmooth('y', contentY);
    } else if (isTabbedSectionVisible && isHeroBillboardVisible) {
      const tabbedSectionTopMargin = this._TabbedLayout.flexItem.marginTop ?? 0;
      const heroBillboardHeight = BillboardCarousel.height;
      contentY =
        0 - heroBillboardHeight - tabbedSectionTopMargin + CONTENT_TOP_MARGIN;

      this._Content.setSmooth('y', contentY);
      this._Title.setSmooth('alpha', constants.ui.invisible);
    }

    if (isCarouselSectionVisible) {
      const { scrollTarget = 0 } = options;
      const amountCarouselScrolled =
        -contentY + scrollTarget - SWIMLANES_HEIGHT;

      // all scrollToContent calls from CarouselLayout guarantees index
      this._reportQuartile(amountCarouselScrolled, index!);
    }
  }

  private _firstLaneScroll(
    options: {
      scrollTarget?: number;
      laneHeight?: number;
    } = {},
  ) {
    const { scrollTarget = 0, laneHeight = 0 } = options;

    const shouldScrollToPromo =
      1080 - BillboardCarousel.height - laneHeight >= 0;

    if (shouldScrollToPromo) {
      // Note that we don't need to worry about reporting quartiles when scrolling to the promo (since we'd never scroll downwards)
      this.scrollToPromo();
    } else {
      this._scrollToContent({ index: 0, scrollTarget });
    }
  }

  private _reportQuartile(amountScrolled: number, index: number) {
    if (this._hubSlug !== ContentHubSlugs.HOME) return;

    const adjustedCarouselLayoutHeight =
      this._CarouselLayout.scrollHeight - SWIMLANES_HEIGHT;

    const isHeroBillboardVisible = this._HeroBillboardCarousel.visible;
    const billboardH = isHeroBillboardVisible ? BillboardCarousel.height : 0;
    const totalScrollable = adjustedCarouselLayoutHeight + billboardH;
    const percentScrolled = amountScrolled / totalScrollable;

    for (const quartile of QUARTILES) {
      if (quartile > this._quartilesFired) {
        const percentQuartile = quartile / 100;
        if (percentScrolled >= percentQuartile) {
          this._quartilesFired = quartile;
          reportScrollTracking(quartile);
        } else {
          break;
        }
      }
    }
  }

  $onBillboardSelect(
    promo: Promo,
    isHero: boolean,
    imageUri: string,
    position: number | undefined,
  ) {
    if (isHero) {
      reportPromoSelected({
        imageFileTitle: promo.promoImageId,
        index: position ?? 0,
        promo,
      });
    }

    if (isContentHub(promo)) {
      this.onContentHubBillboardSelected(promo as ContentHub);
    } else if (isPromoChannel(promo)) {
      this.onChannelBillboardSelected(promo as PromoChannel);
    } else {
      this.onMediaBillboardSelected(promo as Media, imageUri, position);
    }
  }

  private onContentHubBillboardSelected(promo: ContentHub) {
    const { slug } = promo;
    navigateToContentHub(slug, { nested: true });
  }

  private onChannelBillboardSelected(promo: PromoChannel) {
    const { slug } = promo;
    const viewContext = this.getBillboardViewContext();
    navigateToChannel(slug, { viewContext });
  }

  private onMediaBillboardSelected(
    promo: Media,
    imageUri: string,
    position: number | undefined,
  ) {
    const action = isShow(promo) ? 'details' : 'play';
    const viewContext = this.getBillboardViewContext();

    const listItemContext: ListItemContext = {
      rowIndex: 0,
      itemIndex: position ?? 0,
      imageUrl: imageUri,
    };
    // super is used so we don't report row engagement for the hero
    super.$onTileSelected(promo, {
      action: action,
      viewContext,
      listItemContext,
    });
  }

  private getBillboardViewContext() {
    if (this._hubSlug === ContentHubSlugs.HOME) {
      return StaticViewContexts.HOME_BILLBOARD;
    } else {
      const hubTitle = this._hubTitle;
      const hubSlug = this._hubSlug;
      return getGenericContentHubBillboardViewContext(hubTitle || hubSlug);
    }
  }

  override $onTileSelected(
    data?: Media | ContentHub,
    context: SelectItemContext<TileParentContext> = {},
  ) {
    let rowTitle: string | undefined;
    let mainIndex: number | undefined;
    let crossIndex: number | undefined;

    if (!context.viewContext) {
      if (this._hubSlug === ContentHubSlugs.HOME) {
        context.viewContext = StaticViewContexts.HOME_EPISODES_SWIMLANES;
      } else {
        const hubTitle = this._hubTitle;
        const hubSlug = this._hubSlug;
        context.viewContext = getGenericContentHubSwimlaneViewContext(
          hubTitle || hubSlug,
        );
      }
    }

    if (this._CarouselLayout.visible) {
      rowTitle = this._CarouselLayout.currentRow.title;

      const carouselIndex = this._CarouselLayout.index;
      mainIndex = carouselIndex.mainIndex;
      crossIndex = carouselIndex.crossIndex;
      this._selectedCrossIndex = crossIndex;
    } else if (this._TabbedLayout.visible) {
      rowTitle = this._TabbedLayout.activeTabName ?? '';
      mainIndex = this._TabbedLayout.activeTabIndex ?? 0;
      crossIndex = this._TabbedLayout.index ?? 0;
    }

    reportItemSelected({
      imageUrl: context?.imageUrl,
      crossIndex,
      mainIndex,
      data,
      rowTitle,
      isTivoTileSource: !!context?.parentContext?.isTivoSource,
    });

    if (context.action === 'play' || context.action === 'details') {
      context.listItemContext = {
        rowIndex: mainIndex ?? 0,
        itemIndex: crossIndex ?? 0,
        imageUrl: context?.imageUrl ?? '',
        rowTitle,
      };
    }

    super.$onTileSelected(data, context);
  }

  private $onTileFullyVisible(
    parentContext: TileParentContext,
    data: Media | ContentHub,
    imageUrl: string,
  ) {
    const { crossIndex, mainIndex, rowTitle, group, isTivoSource } =
      parentContext;

    const props: ViewItemProps = {
      crossIndex: crossIndex,
      imageUrl,
      mainIndex: mainIndex,
      data,
      rowTitle,
      isTivoTileSource: isTivoSource,
    };

    window.collectionVisibilityService.onFullyVisibleItem({
      row: mainIndex,
      col: crossIndex,
      group,
      obj: props,
    });
  }

  $onBillboardFullyVisible(promo: Promo, index: number) {
    if (this._viewPromoTrackedIndices.includes(index)) return;

    this._viewPromoTrackedIndices.push(index);
    reportViewPromotion({ promo, index });
  }

  $onHover(target: unknown) {
    switch (target) {
      case this._CarouselLayout:
        this._setState('CarouselLayout');
        break;
      case this._TabbedLayout:
        this._setState('TabbedLayout');
        break;
      case this._HeroBillboardCarousel:
        this._setState('Billboard');
        break;
    }
  }

  static override _states() {
    return [
      class Billboard extends this {
        override $enter() {
          this.scrollToPromo();
        }

        override _getFocused(): Lightning.Component {
          // Refer to Workaround #1
          const billboards = this._HeroBillboardCarousel.childList.first;
          if (
            this._HeroBillboardCarousel.visible &&
            billboards &&
            (billboards as any).lane.items.length > 1
          ) {
            return billboards as Lightning.Component;
          } else {
            return super._getFocused();
          }
        }

        override _handleDown() {
          if (this._CarouselLayout.visible) {
            this._setState('CarouselLayout');
          } else if (this._TabbedLayout.visible) {
            this._setState('TabbedLayout');
          }
        }
      },
      class CarouselLayout extends this {
        override _getFocused() {
          return this._CarouselLayout;
        }

        override _handleUp() {
          if (this._isBillboardFocusable) this._setState('Billboard');
        }
      },
      class TabbedLayout extends this {
        override _getFocused() {
          return this._TabbedLayout;
        }

        override _handleUp() {
          if (this._isBillboardFocusable) this._setState('Billboard');
        }
      },
    ];
  }
}
