import { Lightning } from '@lightningjs/sdk';

interface VisibilityIndicatorTemplateSpec
  extends Lightning.Component.TemplateSpec {
  TopLeftIndicator: typeof Indicator;
  BottomRightIndicator: typeof Indicator;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IndicatorTemplateSpec extends Lightning.Component.TemplateSpec {}

export default class VisibilityIndicator
  extends Lightning.Component<VisibilityIndicatorTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<VisibilityIndicatorTemplateSpec>
{
  private isTopLeftActive = false;
  private isBottomRightActive = false;
  private hasFired = false;

  static override _template(): Lightning.Component.Template<VisibilityIndicatorTemplateSpec> {
    return {
      TopLeftIndicator: {
        type: Indicator,
        x: 0,
        y: 0,
        signals: {
          active: '$topLeftActive',
        },
      },
      BottomRightIndicator: {
        type: Indicator,
        x: (w: number) => w,
        y: (y: number) => y,
        signals: {
          active: '$bottomRightActive',
        },
      },
    };
  }

  private handleIndicatorActivation() {
    const bothActive = this.isTopLeftActive && this.isBottomRightActive;

    if (bothActive && !this.hasFired) {
      this.signal('indicateFullyVisible');
      this.hasFired = true;
    }
  }

  private $topLeftActive() {
    if (this.isTopLeftActive) return;

    this.isTopLeftActive = true;
    this.handleIndicatorActivation();
  }

  private $bottomRightActive() {
    if (this.isBottomRightActive) return;

    this.isBottomRightActive = true;
    this.handleIndicatorActivation();
  }
}

class Indicator
  extends Lightning.Component<IndicatorTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<IndicatorTemplateSpec>
{
  static override _template(): Lightning.Component.Template<IndicatorTemplateSpec> {
    return {};
  }

  override _active() {
    this.signal('active');
  }
}
