import { Colors, Lightning } from '@lightningjs/sdk';
import { getCurrentEpgProgram } from 'support/contentUtils';
import { EpgChannel, EpgProgram } from 'types/api/media';
import constants from '../../../../static/constants.json';
import { getFontFaceFromStyle } from 'support/textUtils';
import { millisecondsToContentDuration } from 'support/dateUtils';

interface FastPlaybackDetailsTemplateSpec
  extends Lightning.Component.TemplateSpec {
  channel: EpgChannel | null;

  Content: {
    LogoWrapper: {
      Logo: Lightning.textures.ImageTexture;
    };
    DetailsWrapper: {
      Title: object;
      Subtitle: object;
      Duration: object;
    };
  };
}

const HEIGHT = 126;

const LOGO_WRAPPER_WIDTH = constants.ui.epgLogoWidth;
const LOGO_WRAPPER_HEIGHT = constants.ui.epgLogoHeight;
const LOGO_WRAPPER_MARGIN_RIGHT = 12;

const LOGO_WIDTH = 75;
const LOGO_HEIGHT = 75;

const DETAILS_HEIGHT = HEIGHT;
const DETAILS_ITEM_MARGIN = 9 / 2;

export default class FastPlaybackDetails extends Lightning.Component<FastPlaybackDetailsTemplateSpec> {
  private _Content = this.getByRef('Content')!;
  private _LogoWrapper = this._Content.getByRef('LogoWrapper')!;
  private _Logo = this._LogoWrapper.getByRef('Logo')!;
  private _DetailsWrapper = this._Content.getByRef('DetailsWrapper')!;
  private _Title = this._DetailsWrapper.getByRef('Title')!;
  private _Subtitle = this._DetailsWrapper.getByRef('Subtitle')!;
  private _Duration = this._DetailsWrapper.getByRef('Duration')!;

  private _channel: EpgChannel | null = null;
  private _currentProgram: EpgProgram | null = null;

  set channel(channel: EpgChannel | null) {
    this._channel = channel;

    if (!channel) return;

    this._currentProgram = getCurrentEpgProgram(channel) ?? null;
    this.updateDetails(new Date());
  }

  static override _template(): Lightning.Component.Template<FastPlaybackDetailsTemplateSpec> {
    return {
      Content: {
        h: HEIGHT,
        flex: { direction: 'row', alignItems: 'center' },
        LogoWrapper: {
          rect: true,
          flexItem: { marginRight: LOGO_WRAPPER_MARGIN_RIGHT },
          w: LOGO_WRAPPER_WIDTH,
          h: LOGO_WRAPPER_HEIGHT,
          color: Colors('alternateBackground').alpha(0.65).get(),
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 10,
          },
          Logo: {
            mount: 0.5,
            x: w => w / 2,
            y: h => h / 2,
            w: LOGO_WIDTH,
            h: LOGO_HEIGHT,
          },
        },
        DetailsWrapper: {
          h: DETAILS_HEIGHT,
          flex: { direction: 'column', justifyContent: 'center' },
          Title: {
            flexItem: { marginBottom: DETAILS_ITEM_MARGIN },
            text: {
              text: 'title',
              fontSize: 39,
              fontFace: getFontFaceFromStyle('medium'),
              textBaseline: 'bottom',
            },
          },
          Subtitle: {
            flexItem: {
              marginTop: DETAILS_ITEM_MARGIN,
              marginBottom: DETAILS_ITEM_MARGIN,
            },
            text: {
              text: 'title',
              fontSize: 29,
              textBaseline: 'bottom',
            },
          },
          Duration: {
            flexItem: { marginTop: DETAILS_ITEM_MARGIN },
            text: {
              fontSize: 24,
              textBaseline: 'bottom',
            },
          },
        },
      },
    };
  }

  private updateDuration(millisecondDuration: number) {
    const duration = millisecondsToContentDuration(millisecondDuration);
    this._Duration.patch({ text: { text: duration } });
  }

  private updateDetails(currentTime: Date) {
    if (!this._channel) return;

    this._Logo.patch({ src: this._channel.images.logoUnfocused ?? '' });

    if (!this._currentProgram) return;

    const { title, subtitle, endTime } = this._currentProgram;

    const programEndTime = new Date(endTime);
    const durationLeft = programEndTime.getTime() - currentTime.getTime();

    this.updateDuration(durationLeft);

    this._Title.patch({ text: title ?? '', visible: !!title });
    this._Subtitle.patch({ text: subtitle ?? '', visible: !!subtitle });
  }

  updateTime(currentTime: Date) {
    if (!this._channel) return;

    if (!this._currentProgram) return;

    let programEndTime = new Date(this._currentProgram.endTime);

    if (programEndTime <= currentTime) {
      this._currentProgram = getCurrentEpgProgram(this._channel)!;

      programEndTime = new Date(this._currentProgram.endTime);
      this.updateDetails(currentTime);
    } else {
      const durationLeft = programEndTime.getTime() - currentTime.getTime();
      this.updateDuration(durationLeft);
    }
  }
}
