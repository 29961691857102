import { AppData, Metadata, Registry } from '@lightningjs/sdk';

import Conviva, { ConvivaMetadata } from '@convivainc/conviva-js-coresdk';
import ConvivaHtml5Module from '@convivainc/conviva-js-html5';
import ConvivaGoogledaiModule from '@convivainc/conviva-js-daisdk';
import { EpgChannel, Live, Video } from 'types/api/media';
import {
  getCurrentEpgProgram,
  isLive,
  isLiveProgram,
} from 'support/contentUtils';
import { Merge, ValueOf } from 'types';
import { Platform } from 'models/platforms/platform';
import { DeviceInfo } from 'models/platforms/deviceInfo';
import { getBaseDomainFromUrl, roundMilliseconds } from 'support/generalUtils';
import { ViewContext } from 'types/analytics';

type BitRateData = {
  averageBitRate: number;
  lastBitRateMeasure: number;
  firstBitRateMeasure: number;
  notMonitoredTime: number;
};

export default class ConvivaAnalytics {
  private _videoAnalytics: Conviva.VideoAnalytics | undefined;
  private _adAnalytics: Conviva.AdAnalytics | undefined;
  private _videoPlayerElement: HTMLVideoElement | undefined;
  private _isAdPlaying = false;
  private _currentBitRate: null | number = null;
  private _bitRateData: null | BitRateData = null;
  private _adBitRateData: null | BitRateData = null;

  static async AsyncConstruct() {
    if (AppData === undefined) {
      throw new Error('AppData not set');
    }

    const deviceInfo = await AppData.device.getDeviceInfo();
    return new ConvivaAnalytics(deviceInfo);
  }

  private constructor(deviceInfo: DeviceInfo) {
    if (AppData === undefined) {
      throw new Error('AppData not set');
    }

    // this is modified from https://pulse.conviva.com/learning-center/content/sensor_developer_center/sensor_integration/javascript/javascript_stream_sensor.htm
    const callbackFunctions: Conviva.ConvivaUtils = {
      [Conviva.Constants.CallbackFunctions.CONSOLE_LOG]: (
        message,
        logLevel,
      ) => {
        if (typeof console === 'undefined') return;
        if (
          (console.log && logLevel === Conviva.Constants.LogLevel.DEBUG) ||
          logLevel === Conviva.Constants.LogLevel.INFO
        ) {
          console.log(message);
        } else if (
          console.warn &&
          logLevel === Conviva.Constants.LogLevel.WARNING
        ) {
          console.warn(message);
        } else if (
          console.error &&
          logLevel === Conviva.Constants.LogLevel.ERROR
        ) {
          console.error(message);
        }
      },

      [Conviva.Constants.CallbackFunctions.MAKE_REQUEST]: (
        httpMethod,
        url,
        data,
        contentType,
        timeoutMs,
        callback,
      ) => {
        const xmlHttpReq = new XMLHttpRequest();

        xmlHttpReq.open(httpMethod, url, true);

        if (contentType && xmlHttpReq.overrideMimeType) {
          // @ts-ignore
          xmlHttpReq.overrideMimeType = contentType;
        }
        if (contentType && xmlHttpReq.setRequestHeader) {
          xmlHttpReq.setRequestHeader('Content-Type', contentType);
        }
        if (timeoutMs > 0) {
          xmlHttpReq.timeout = timeoutMs;
          xmlHttpReq.ontimeout = function () {
            // Often this callback will be called after onreadystatechange.
            // The first callback called will cleanup the other to prevent duplicate responses.
            xmlHttpReq.ontimeout = xmlHttpReq.onreadystatechange = null;
            if (callback) callback(false, 'timeout after ' + timeoutMs + ' ms');
          };
        }

        xmlHttpReq.onreadystatechange = function () {
          if (xmlHttpReq.readyState === 4) {
            xmlHttpReq.ontimeout = xmlHttpReq.onreadystatechange = null;
            if (xmlHttpReq.status == 200) {
              if (callback) callback(true, xmlHttpReq.responseText);
            } else {
              if (callback) callback(false, 'http status ' + xmlHttpReq.status);
            }
          }
        };

        xmlHttpReq.send(data);
      },

      [Conviva.Constants.CallbackFunctions.SAVE_DATA]: (
        storageSpace,
        storageKey,
        data,
        callback,
      ) => {
        try {
          // Updated this to use Storage
          AppData!.storageService.conviva.set(storageSpace, storageKey, data);
          callback(true, null as unknown as string);
        } catch (e: any) {
          callback(false, e.toString());
        }
      },

      [Conviva.Constants.CallbackFunctions.LOAD_DATA]: (
        storageSpace,
        storageKey,
        callback,
      ) => {
        try {
          // Updated this to use Storage
          const data =
            AppData!.storageService.conviva.get(storageSpace, storageKey) ?? '';
          callback(true, data);
        } catch (e: any) {
          callback(false, e.toString());
        }
      },

      [Conviva.Constants.CallbackFunctions.GET_EPOCH_TIME_IN_MS]: () => {
        const d = new Date();
        return d.getTime();
      },

      [Conviva.Constants.CallbackFunctions.CREATE_TIMER]: (
        timerAction,
        intervalMs,
      ) => {
        // Updated this to use Registry
        let timerId = Registry.setInterval(timerAction, intervalMs);
        const cancelTimerFunc = function () {
          if (timerId !== -1) {
            Registry.clearInterval(timerId);
            timerId = -1;
          }
        };
        return cancelTimerFunc;
      },
    };

    // Initialize Conviva
    if (!AppData.isProduction) {
      const settings: Conviva.ConvivaOptions = {};
      settings[Conviva.Constants.GATEWAY_URL] = AppData.conviva.gatewayUrl;
      settings[Conviva.Constants.LOG_LEVEL] = Conviva.Constants.LogLevel.DEBUG;
      Conviva.Analytics.init(
        AppData.conviva.customerKey,
        callbackFunctions,
        settings,
      );
    } else {
      Conviva.Analytics.init(
        AppData.conviva.customerKey,
        callbackFunctions,
        {},
      );
    }

    let deviceCategory: ValueOf<typeof Conviva.Constants.DeviceCategory>;
    switch (AppData.device.getPlatform()) {
      case Platform.TIZEN:
        deviceCategory = Conviva.Constants.DeviceCategory.SAMSUNG_TV;
        break;
      case Platform.LG:
        deviceCategory = Conviva.Constants.DeviceCategory.LG_TV;
        break;
      case Platform.VIZIO:
      default:
        deviceCategory = Conviva.Constants.DeviceCategory.SMART_TV;
    }

    // Setup Metadata
    const deviceMetadata: Conviva.ConvivaDeviceMetadata = {
      [Conviva.Constants.DeviceMetadata.CATEGORY]: deviceCategory,
      [Conviva.Constants.DeviceMetadata.BRAND]: deviceInfo.manufacturer,
      [Conviva.Constants.DeviceMetadata.MODEL]: deviceInfo.model,
      [Conviva.Constants.DeviceMetadata.OS_VERSION]: deviceInfo.osVersion,
      [Conviva.Constants.DeviceMetadata.TYPE]:
        Conviva.Constants.DeviceType.SMARTTV,
    };
    Conviva.Analytics.setDeviceMetadata(deviceMetadata);
  }

  private getVideoPlaybackData(
    content: Video,
    viewContext: ViewContext | null,
  ) {
    const constants = AppData!.conviva;

    const customMetadata: Record<string, string> = {
      appVersion: Metadata.appVersion() ?? '',
      publishDate: content.startTime,
      ['c3.cm.genreList']: content.genre,
      episodeNumber: content.episode,
      seasonNumber: content.season || '*null',
      tmsId: content.tmsId,
      assetId: content.guid,
      contentType: content.contentType || '*null',
      seriesTitle: content.seriesName,
      viewContext: viewContext ?? '*null',
      brand: constants.brand,
    };

    const contentInfo = {
      [Conviva.Constants.ASSET_NAME]: content.title,
      [Conviva.Constants.IS_LIVE]: Conviva.Constants.StreamType.VOD,
      [Conviva.Constants.PLAYER_NAME]: constants.playerName,
      [Conviva.Constants.DURATION]: Number(content.durationSecs),
    } as ConvivaMetadata;

    return { contentInfo, customMetadata };
  }

  private getLiveVideoPlaybackData(
    content: Live,
    viewContext: ViewContext | null,
  ) {
    const constants = AppData!.conviva;

    const customMetadata: Record<string, string> = {
      appVersion: Metadata.appVersion() ?? '',
      publishDate: content.startTime,
      ['c3.video.isLive']: Conviva.Constants.StreamType.LIVE,
      assetId: content.analytics.guid!,
      seriesTitle: content.analytics.title ?? '*null',
      eventName: content.title,
      viewContext: viewContext ?? '*null',
      brand: constants.brand,
    };

    const contentInfo = {
      [Conviva.Constants.ASSET_NAME]: content.title,
      [Conviva.Constants.IS_LIVE]: Conviva.Constants.StreamType.LIVE,
      [Conviva.Constants.PLAYER_NAME]: constants.playerName,
      [Conviva.Constants.DURATION]: Number(content.durationSecs),
    } as ConvivaMetadata;

    return { contentInfo, customMetadata };
  }

  private getEpgPlaybackData(
    channel: EpgChannel,
    viewContext: ViewContext | null,
  ) {
    const constants = AppData!.conviva;

    const epgProgram = getCurrentEpgProgram(channel);

    const customMetadata: Record<string, string> = {
      appVersion: Metadata.appVersion() ?? '',
      publishDate: epgProgram?.startTime ?? '*null',
      ['c3.video.isLive']: Conviva.Constants.StreamType.LIVE,
      assetId: epgProgram?.assetId || '*null',
      seriesTitle: epgProgram?.title || '*null',
      ['c3.cm.channel']: channel.slug,
      viewContext: viewContext ?? '*null',
      brand: constants.brand,
    };

    const contentInfo = {
      [Conviva.Constants.ASSET_NAME]: epgProgram?.convivaAssetName ?? '*null',
      [Conviva.Constants.IS_LIVE]: Conviva.Constants.StreamType.LIVE,
      [Conviva.Constants.PLAYER_NAME]: constants.playerName,
      [Conviva.Constants.DURATION]: Number(epgProgram?.durationSecs ?? 0),
    } as ConvivaMetadata;

    return { contentInfo, customMetadata };
  }

  private initializeBitRateData() {
    if (this._currentBitRate === null) return;

    if (this._isAdPlaying) {
      this._adBitRateData = {
        averageBitRate: this._currentBitRate,
        firstBitRateMeasure: Date.now(),
        lastBitRateMeasure: Date.now(),
        notMonitoredTime: 0,
      };
    } else {
      this._bitRateData = {
        averageBitRate: this._currentBitRate,
        firstBitRateMeasure: Date.now(),
        lastBitRateMeasure: Date.now(),
        notMonitoredTime: 0,
      };
    }
  }

  private updateBitRateDataNotMonitored() {
    let bitRateData: BitRateData | null = null;

    if (this._isAdPlaying) {
      bitRateData = this._adBitRateData;
    } else {
      bitRateData = this._bitRateData;
    }

    if (bitRateData === null || this._currentBitRate === null) return;

    const currentTime = Date.now();

    bitRateData.notMonitoredTime +=
      currentTime - bitRateData.lastBitRateMeasure;

    // prevent lastBitRateMeasure being less then firstBitRate + notMonitoredTime
    bitRateData.lastBitRateMeasure = currentTime;
  }

  private updateBitRateData() {
    let bitRateData: BitRateData | null = null;

    if (
      (this._isAdPlaying && this._adBitRateData === null) ||
      (!this._isAdPlaying && this._bitRateData === null)
    ) {
      this.initializeBitRateData();
    }

    if (this._isAdPlaying) {
      bitRateData = this._adBitRateData;
    } else {
      bitRateData = this._bitRateData;
    }

    if (bitRateData === null || this._currentBitRate === null) return;

    // firstMeasure is offset by not monitored time so we don't take ad time into the proportion calculation
    const firstMeasure =
      bitRateData.firstBitRateMeasure + bitRateData.notMonitoredTime;
    const lastMeasure = bitRateData.lastBitRateMeasure;
    const currentTime = Date.now();

    const timeElapsed = currentTime - firstMeasure;

    // if a negligible amount of time has pasted return
    if (roundMilliseconds(timeElapsed) <= 0) {
      return;
    }

    const previousProportion = (lastMeasure - firstMeasure) / timeElapsed;
    const newProportion = (currentTime - lastMeasure) / timeElapsed;

    const previousWeightedAverage =
      bitRateData.averageBitRate * previousProportion;
    const newWeightedAverage = this._currentBitRate * newProportion;

    const averageBitRate = roundMilliseconds(
      previousWeightedAverage + newWeightedAverage,
    );

    if (this._isAdPlaying) {
      this._adBitRateData!.averageBitRate = averageBitRate;
      this._adBitRateData!.lastBitRateMeasure = currentTime;
    } else {
      this._bitRateData!.averageBitRate = averageBitRate;
      this._bitRateData!.lastBitRateMeasure = currentTime;
    }
  }

  releaseAdAnalytics() {
    this._adAnalytics?.release?.();
    this._adAnalytics = undefined;
  }

  releaseVideoAnalytics() {
    this._videoAnalytics?.release?.();
    this._videoAnalytics = undefined;
  }

  release() {
    this.releaseAdAnalytics();
    this.releaseVideoAnalytics();
    Conviva.Analytics.release();
  }

  setStreamUrl(url: string) {
    this._videoAnalytics?.setContentInfo({
      [Conviva.Constants.STREAM_URL]: url,
    });
  }

  setContentInfo(
    content: Merge<Merge<Video, Live>, EpgChannel>,
    viewContext: ViewContext | null,
  ) {
    this._bitRateData = null;
    this._adBitRateData = null;

    let metadata: any;
    if (isLiveProgram(content)) {
      metadata = this.getEpgPlaybackData(content as EpgChannel, viewContext);
    } else if (isLive(content)) {
      metadata = this.getLiveVideoPlaybackData(content as Live, viewContext);
    } else {
      metadata = this.getVideoPlaybackData(content as Video, viewContext);
    }

    const mpid = window.analytics.mParticle.getMpid();
    if (mpid) {
      metadata.customMetadata.cwViewerId = mpid;
      metadata.contentInfo[Conviva.Constants.VIEWER_ID] = mpid;
    }

    this._videoAnalytics?.setContentInfo({
      ...metadata.contentInfo,
      ...metadata.customMetadata,
    });
  }

  reportContentPlayback(
    content: Merge<Merge<Video, Live>, EpgChannel>,
    viewContext: ViewContext | null,
  ) {
    if (this._videoAnalytics) this.releaseVideoAnalytics();
    this._videoAnalytics = Conviva.Analytics.buildVideoAnalytics();

    this.setContentInfo(content, viewContext);

    this._videoAnalytics?.reportPlaybackRequested();
  }

  setVideoPlayer(
    htmlVideoElement: HTMLVideoElement,
    content: Merge<Merge<Video, Live>, EpgChannel>,
  ) {
    const options = {
      [Conviva.Constants.CONVIVA_MODULE]: ConvivaHtml5Module,
    };

    // We need to override the duration of the HTMLVideoElement for live content, so we create a proxy to intercept that property
    const handler = {
      get(target: any, propKey: any, receiver: any) {
        if (propKey === 'duration') {
          return isLiveProgram(content)
            ? getCurrentEpgProgram(content as EpgChannel)?.durationSecs
            : content.durationSecs;
        }

        if (typeof target[propKey] === 'function') {
          return function (...args: unknown[]) {
            // eslint-disable-next-line prefer-spread
            return target[propKey].apply(target, args);
          };
        }

        return target[propKey];
      },
    };

    let element: HTMLVideoElement;
    if (isLive(content)) {
      element = new Proxy<HTMLVideoElement>(htmlVideoElement, handler);
    } else {
      element = htmlVideoElement;
    }

    this._videoAnalytics?.setPlayer(element, options as any);

    this._videoPlayerElement = element;
  }

  setAdListener(streamManager: object | null) {
    if (this._videoPlayerElement === undefined) {
      throw new Error('video player element undefined');
    }

    if (this._adAnalytics) this.releaseAdAnalytics();
    this._adAnalytics = Conviva.Analytics.buildAdAnalytics(
      this._videoAnalytics,
    );

    if (streamManager) {
      const extraListeners = {
        [Conviva.Constants.IMASDK_CONTENT_PLAYER]: this._videoPlayerElement,
        [Conviva.Constants.CONVIVA_MODULE]: ConvivaGoogledaiModule,
      };
      this._adAnalytics.setAdListener(streamManager, extraListeners);
    }
  }

  reportPlaybackFailed(errorMessage: string) {
    if (this._isAdPlaying) {
      this._adAnalytics?.reportAdFailed(errorMessage);
    }

    this._videoAnalytics?.reportPlaybackFailed(errorMessage);
  }

  reportPlaybackWarning(errorMessage: string) {
    if (this._isAdPlaying) {
      this._adAnalytics?.reportAdError(
        errorMessage,
        Conviva.Constants.ErrorSeverity.WARNING,
      );
    } else {
      this._videoAnalytics?.reportPlaybackError(
        errorMessage,
        Conviva.Constants.ErrorSeverity.WARNING,
      );
    }
  }

  reportPlaybackEnded(isAdPlaying: boolean) {
    if (isAdPlaying) {
      this._adAnalytics?.reportAdEnded();
    }
    this._videoAnalytics?.reportPlaybackEnded();
    this._isAdPlaying = false;

    this.releaseAdAnalytics();
    this.releaseVideoAnalytics();
  }

  reportCdn(url: string) {
    const baseDomain = getBaseDomainFromUrl(url);
    this._videoAnalytics?.reportPlaybackMetric(
      Conviva.Constants.Playback.CDN_IP,
      baseDomain,
    );
  }

  reportBitrate(bitrate: number) {
    this._currentBitRate = bitrate;

    if (this._isAdPlaying) {
      this._adAnalytics?.reportAdMetric(
        Conviva.Constants.Playback.BITRATE,
        bitrate,
      );
    } else {
      this._videoAnalytics?.reportPlaybackMetric(
        Conviva.Constants.Playback.BITRATE,
        bitrate,
      );
    }

    this.reportAverageBitRate();
  }

  reportAdPodStart() {
    // report average bitrate of content
    this.reportAverageBitRate();

    this._isAdPlaying = true;

    // report average bitrate of ad
    this.reportAverageBitRate();
  }

  reportAdPodEnd() {
    this._isAdPlaying = false;
    this._adBitRateData = null;

    this.updateBitRateDataNotMonitored();

    // report average bitrate of content
    this.reportAverageBitRate();
  }

  reportBackgrounded() {
    Conviva.Analytics.reportAppBackgrounded();
  }

  reportForegrounded() {
    Conviva.Analytics.reportAppForegrounded();
  }

  reportAverageBitRate() {
    this.updateBitRateData();

    if (this._isAdPlaying && this._adBitRateData !== null) {
      this._adAnalytics?.reportAdMetric(
        Conviva.Constants.Playback.AVG_BITRATE,
        this._adBitRateData!.averageBitRate,
      );
    } else if (this._bitRateData !== null) {
      this._videoAnalytics?.reportPlaybackMetric(
        Conviva.Constants.Playback.AVG_BITRATE,
        this._bitRateData!.averageBitRate,
      );
    }
  }
}
