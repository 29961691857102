import { Lightning, Colors } from '@lightningjs/sdk';
import { getFontFaceFromStyle } from 'support/textUtils';

const HIGHLIGHT_HEIGHT = 4;
const HIGHLIGHT_OFFSET = 28;
const DEFAULT_FONT_SIZE = 32;

const BASE_STYLE: FocusableTextTemplateSpec['styles'] = {
  focused: {
    highlight: true,
    fontFace: getFontFaceFromStyle('bold'),
  },
  unfocused: {
    highlight: false,
    fontFace: getFontFaceFromStyle('regular'),
  },
};

export interface FocusableTextTemplateSpec
  extends Lightning.Component.TemplateSpec {
  styles: {
    focused: Lightning.textures.TextTexture.Settings;
    unfocused: Lightning.textures.TextTexture.Settings;
  };
}

export default class FocusableText
  extends Lightning.Component<FocusableTextTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<FocusableTextTemplateSpec>
{
  private _styles = BASE_STYLE;

  get styles() {
    return this._styles;
  }

  set styles(styles: FocusableTextTemplateSpec['styles']) {
    this._styles = {
      focused: { ...this._styles.focused, ...styles.focused },
      unfocused: { ...this._styles.unfocused, ...styles.unfocused },
    };
  }

  static override _template(): Lightning.Component.Template<FocusableTextTemplateSpec> {
    return {
      text: {
        highlightHeight: HIGHLIGHT_HEIGHT,
        highlightOffset: DEFAULT_FONT_SIZE + HIGHLIGHT_OFFSET,
        highlightColor: Colors('appAccent').get(),
        fontSize: DEFAULT_FONT_SIZE,
      },
    };
  }

  override _setup() {
    this._unfocus();
  }

  override _getFocused() {
    return this;
  }

  override _focus() {
    const { focused } = this.styles;
    const height = this.getHeight(focused);
    this.patch({ h: height, text: { ...focused, h: height } });
  }

  override _unfocus() {
    const { unfocused } = this.styles;
    const height = this.getHeight(unfocused);
    this.patch({ h: height, text: { ...unfocused, h: height } });
  }

  private getHeight(style: Lightning.textures.TextTexture.Settings) {
    return (
      style.lineHeight ||
      (style.fontSize ?? DEFAULT_FONT_SIZE) +
        (style.highlightOffset ?? HIGHLIGHT_OFFSET) +
        (style.highlightHeight ?? HIGHLIGHT_HEIGHT)
    );
  }
}
