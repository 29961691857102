import { Colors, Lightning } from '@lightningjs/sdk';
import { EpgChannel } from 'types/api/media';
import EpgTimeline from './EpgTimeline';
import EpgRow from './EpgRow';
import constants from '../../../../static/constants.json';
import { getEpgPage } from 'services/cwService';
import { cleanupEpgChannelData } from 'services/cwData';
import { IndexData } from 'types/lightning';
import EpgCell from './EpgCell';
import EpgProgressIndicator from './EpgProgressIndicator';
import { minutesToMilliseconds } from 'support/dateUtils';
import { List } from '@lightningjs/ui';
import { ListWithIndexing } from 'components/common/CollectionWrappersWithIndexing';

export interface EpgGuideTemplateSpec extends Lightning.Component.TemplateSpec {
  initialChannels: EpgChannel[];
  focusedChannel: EpgChannel | null;
  maxChannels: number;
  addedChannelSlug: string | undefined;

  TimelineWrapper: {
    TimelineClippingWrapper: {
      Timeline: typeof EpgTimeline;
    };
    ProgressIndicatorWrapper: {
      ProgressIndicator: typeof EpgProgressIndicator;
    };
  };
  ChannelsWrapper: {
    Channels: typeof List;
  };
  OverlayGradient: Lightning.textures.RectangleTexture;
}

const EPG_GUIDE_WIDTH = 1758;
const PADDING_LEFT = 41;
const TIMELINE_OFFSET_X =
  PADDING_LEFT + constants.ui.epgLogoWidth + constants.ui.epgSpacing;
const CHANNEL_LIST_HEIGHT = 500;
const CHANNEL_LIST_OFFSET_Y = 50;
const GRADIENT_W = 118;
const TIMELINE_TAB_WIDTH = constants.ui.epgWidthPerThirtyMin;
const PROGRESS_Y = 8;
const PROGRESS_PADDING_PER_MS = TIMELINE_TAB_WIDTH / minutesToMilliseconds(30);

// Number of rows before the end before we need to fetch more data
const REQUEST_THRESHOLD = 1;

export default class EpgGuide
  extends Lightning.Component<EpgGuideTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<EpgGuideTemplateSpec>
{
  private _channels: EpgChannel[] = [];
  private _maxChannels = 0;
  private _addedChannelSlug: string | undefined = undefined;

  // Focus Position
  private _originCellStartX = 0;
  private _verticalLock = false;
  private _targetScrollPosition = 0;
  private _isEpgLoading = false;

  // Internal properties
  private _currentTime?: Date;
  private _startTime?: Date;

  private _TimelineWrapper = this.getByRef('TimelineWrapper')!;
  private _TimelineClippingWrapper = this._TimelineWrapper.getByRef(
    'TimelineClippingWrapper',
  )!;
  private _Timeline = this._TimelineClippingWrapper.getByRef('Timeline')!;
  private _ProgressIndicatorWrapper = this._TimelineWrapper.getByRef(
    'ProgressIndicatorWrapper',
  )!;
  private _ProgressIndicator =
    this._ProgressIndicatorWrapper.getByRef('ProgressIndicator')!;
  private _ChannelsWrapper = this.getByRef('ChannelsWrapper')!;
  private _Channels = this._ChannelsWrapper.getByRef('Channels')!;

  set initialChannels(channels: EpgChannel[]) {
    this._Channels.clear();
    if (!channels.length) return;

    this._channels = channels;
    this.initializeChannels();
  }

  get focusedChannel() {
    const currentRow = this._Channels.currentItem as EpgRow | undefined;
    return currentRow?.epgChannel ?? null;
  }

  set maxChannels(maxChannels: number) {
    this._maxChannels = maxChannels;
    this.initializeChannels();
  }

  set addedChannelSlug(slug: string) {
    this._addedChannelSlug = slug;
  }

  set isEpgLoading(isLoading: boolean) {
    this._isEpgLoading = isLoading;
    this.signal('loading', isLoading);
  }

  static override _template(): Lightning.Component.Template<EpgGuideTemplateSpec> {
    return {
      w: EPG_GUIDE_WIDTH,
      h: (y: number) => 1080 - y,
      TimelineWrapper: {
        x: TIMELINE_OFFSET_X,
        TimelineClippingWrapper: {
          w: 1580,
          h: 34,
          clipping: true,
          Timeline: {
            type: EpgTimeline,
          },
        },
        ProgressIndicatorWrapper: {
          ProgressIndicator: {
            type: EpgProgressIndicator,
            y: PROGRESS_Y,
          },
        },
      },
      ChannelsWrapper: {
        x: PADDING_LEFT,
        y: CHANNEL_LIST_OFFSET_Y,
        w: EPG_GUIDE_WIDTH - PADDING_LEFT,
        h: h => h - CHANNEL_LIST_OFFSET_Y,
        clipping: true,
        Channels: {
          h: CHANNEL_LIST_HEIGHT,
          type: ListWithIndexing,
          direction: 'column',
          itemType: EpgRow,
          spacing: constants.ui.epgSpacing,
          requestThreshold: REQUEST_THRESHOLD,
          signals: {
            onRequestItems: '_onRequestChannels',
            onRequestItemsAdded: '_onRequestChannelsAdded',
          },
          forceLoad: true,
        },
      },
      OverlayGradient: {
        x: w => w,
        w: GRADIENT_W,
        h: h => h,
        mountX: 1,
        rect: true,
        colorLeft: Colors('transparent').get(),
        colorRight: Colors('background').get(),
      },
    };
  }

  override _focus() {
    const currentRow = this._Channels.currentItem as EpgRow;
    const cellProgram = currentRow.currentCell?.epgProgram ?? null;

    this.signal('focusedProgramChange', cellProgram);
  }

  override _unfocus() {
    this.signal('focusedProgramChange', null);
  }

  override _getFocused() {
    return this._Channels;
  }

  override _focusChange(
    newFocusedComponent: Lightning.Component | EpgCell,
    prevFocusedComponent: Lightning.Component,
  ) {
    super._focusChange(newFocusedComponent, prevFocusedComponent);

    const cellProgram =
      'epgProgram' in newFocusedComponent
        ? newFocusedComponent.epgProgram
        : null;

    this.signal('focusedProgramChange', cellProgram);
  }

  private async _onRequestChannels(indexData: IndexData) {
    const { dataLength } = indexData;
    const maxChannels = this._maxChannels!;
    if (dataLength >= maxChannels) return false;

    this.isEpgLoading = true;
    try {
      const pageSize = constants.epg.pageSize;
      const pageIndex = Math.floor(dataLength / pageSize) + 1;

      let { channels } = await getEpgPage(pageIndex, pageSize);
      if (channels.length === 0) {
        this.isEpgLoading = false;
        return false;
      }

      if (this._addedChannelSlug) {
        const duplicateChannelIndex = channels.findIndex(
          channel => channel.slug === this._addedChannelSlug,
        );

        if (duplicateChannelIndex !== -1) {
          // Remove duplicate channel from paginated channels
          channels.splice(duplicateChannelIndex, 1);
          this._addedChannelSlug = undefined;
        }
      }

      channels = cleanupEpgChannelData(channels, this._startTime ?? new Date());
      this._channels.push(...channels);

      this.isEpgLoading = false;
      return channels.map(this.buildChannels.bind(this));
    } catch (e) {
      console.error(e);

      // Do nothing if getting more channels from the API fails
      this.isEpgLoading = false;
      return false;
    }
  }

  private _onRequestChannelsAdded(obj: any) {
    this.$onProgramIndexChanged(this._targetScrollPosition);
  }

  private initializeChannels() {
    const channels = this._channels;
    if (!channels.length) return;

    const w = this._ChannelsWrapper.w;
    const items = channels.map(this.buildChannels.bind(this));
    const enableRequests = channels.length < this._maxChannels;

    this._Channels.patch({
      w,
      items,
      enableRequests,
    });

    this._Channels.scroll = this.handleChannelsScroll.bind(this);
  }

  private handleChannelsScroll(itemWrapper: unknown, { index }: any) {
    if (this._Channels.isHovered) {
      this._Channels.isHovered = false;
    } else {
      const rowHeight = constants.ui.epgCellHeight + constants.ui.epgSpacing;
      const maxScrollIndex = Math.floor(CHANNEL_LIST_HEIGHT / rowHeight) - 1;

      let listY = 0;
      if (index > maxScrollIndex) {
        listY = rowHeight * (maxScrollIndex - index);
      }
      return listY;
    }
  }

  private buildChannels(channel: EpgChannel) {
    const currentTime = this._currentTime;
    const startTime = this._startTime;
    const updateTime = { startTime, currentTime };

    return {
      w: this._Channels.w,
      h: constants.ui.epgCellHeight,
      epgChannel: channel,
      isSelected: false,
      updateTime,
    };
  }

  selectChannel(channel: EpgChannel) {
    this._Channels.items.forEach((row: EpgRow, index: number) => {
      const isSelected = !!row.epgChannel && row.epgChannel.id === channel.id;
      row.isSelected = isSelected;

      if (isSelected) {
        this._Channels.setIndex(index);
      }
    });
  }

  updateTime(currentTime: Date, startTime: Date) {
    this._startTime = startTime;
    this._currentTime = currentTime;

    this._Channels.items.forEach((channelRow: EpgRow) => {
      // We can't call public functions for list items, using setter as workaround
      channelRow.updateTime = { startTime, currentTime };
    });
    this._Timeline.updateTime(currentTime, startTime);
    this.updateProgress(currentTime);
  }

  private updateProgress(currentTime: Date) {
    if (!this._startTime) return;
    const startTime = this._startTime;

    const millisecondsOffset = currentTime.getTime() - startTime.getTime();
    const x = millisecondsOffset * PROGRESS_PADDING_PER_MS;

    this._ProgressIndicator.patch({ x, time: currentTime });
  }

  $onProgramIndexChanged(targetScrollPosition: number) {
    this._targetScrollPosition = targetScrollPosition;
    this._Channels.items.forEach((row: EpgRow, index: number) => {
      if (index === this._Channels.index) return;
      const programs = row.programs;
      if (programs) {
        // EpgRow set scrollTarget will handle the scrolling
        row.scrollTarget = targetScrollPosition;
      }
    });
    this._Timeline.setSmooth('x', targetScrollPosition, {
      timingFunction: 'ease',
    });
    this._ProgressIndicatorWrapper.setSmooth('x', targetScrollPosition, {
      timingFunction: 'ease',
    });
    this._ProgressIndicatorWrapper.setSmooth(
      'alpha',
      targetScrollPosition > -TIMELINE_TAB_WIDTH ? 1 : 0,
    );
  }

  override _captureKey(event: KeyboardEvent) {
    if (this._isEpgLoading) return;
    const currentIndex = this._Channels.index;

    if (
      (event.key === 'ArrowUp' && currentIndex > 0) ||
      (event.key === 'ArrowDown' && currentIndex < this._Channels.length - 1)
    ) {
      const nextIndex = currentIndex + (event.key === 'ArrowUp' ? -1 : 1);
      const currentCell =
        this._Channels.items[currentIndex].programs.currentItemWrapper;
      const currentCellStart = currentCell.assignedX;

      if (!this._verticalLock) {
        this._verticalLock = true;
        this._originCellStartX = currentCellStart;
      }
      const nextRow = this._Channels.items[nextIndex];

      nextRow.preventScroll = false;
      let prevAbsDifferenceX = nextRow.programs.wrapper.w || nextRow.w;
      let focusIndex = 0;

      // check every item in the next row to be focused
      for (let i = 0; i < nextRow.programs.wrapper.children.length; i++) {
        const { assignedX } = nextRow.programs.wrapper.children[i];
        // check absolute value of difference in X value between the currently checked index and
        // the origin X point
        const currentAbsDifferenceX = Math.abs(
          this._originCellStartX - assignedX,
        );
        /**
          check if the absolute value of the current index being checked to be focused has a
          smaller DifferenceX than the absolute Value of the previous index's DifferenceX

          if current Index is smaller, record and check the next index (is the index to use if
            it is the last index)
          if prev Index is smaller, break and use the prev Index
        */
        if (currentAbsDifferenceX <= prevAbsDifferenceX) {
          prevAbsDifferenceX = currentAbsDifferenceX;
          focusIndex = i;
        } else {
          break;
        }
      }
      nextRow.setIndex(focusIndex);
    } else {
      this._Channels.items[currentIndex].preventScroll = false;
      this._verticalLock = false;
    }
    return false;
  }

  override _handleBack() {
    const currentRow = this._Channels.currentItem;
    const programs = currentRow.programs;

    // Ignore if we're already on the first program
    if (programs.index === 0) return false;

    this.$onProgramIndexChanged(0);
    currentRow.setIndex(0);
    programs.setIndex(0);

    this._verticalLock = false;
    return true;
  }
}
