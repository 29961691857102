import { RoutePath } from 'config/routes';
import { PageId } from 'types/pageId';
import { Router } from '@lightningjs/sdk';
import {
  isEpisode,
  isMovie,
  isSeries,
  isVideo,
  isChannel,
  isLive,
} from './contentUtils';
import { Channel, Episode, Live, Media, Show, Video } from 'types/api/media';
import { SelectItemContext } from 'types/events';
import { ContentHubSlugs } from 'types/contentHubs';
import { ContentHubPageParams } from '../components/pages/content-hub/ContentHubPage';
import { ErrorCause } from 'components/common/ErrorModal';
import { ContentHub } from 'services/cwData';

/**
 * Gets route path from page ID. Uses slug if the page ID is Content Hub
 * @param pageId The page ID we want to route to
 * @param slug Used for linking to Content Hub pages
 * @returns The route to the page ID provided
 */
export const getRoutePathFromPageId = (
  pageId: PageId,
  slug?: string,
): string => {
  switch (pageId) {
    case PageId.SPLASH:
      return RoutePath.SPLASH;
    case PageId.SEARCH:
      return RoutePath.SEARCH;
    case PageId.LIVE:
      return RoutePath.LIVE;
    case PageId.EPG:
      return RoutePath.EPG;
    case PageId.CONTENT_HUB:
      if (!slug) return RoutePath.ERROR;
      return `${RoutePath.CONTENT_HUB}/${slug}`;
    case PageId.SETTINGS:
      return RoutePath.SETTINGS;
    case PageId.MOVIE_DETAIL:
      return RoutePath.MOVIE_DETAIL;
    case PageId.SERIES_DETAIL:
      return RoutePath.SERIES_DETAIL;
    case PageId.PLAYBACK:
      return RoutePath.PLAY;
    case PageId.UNKNOWN:
    case PageId.ERROR:
    default:
      return RoutePath.ERROR;
  }
};

export const getPageIdFromHash = (hash: string): string => {
  const baseRoute = hash.split('/')[0];

  switch (baseRoute) {
    case RoutePath.ERROR:
      return PageId.ERROR;
    case RoutePath.SPLASH:
      return PageId.SPLASH;
    case RoutePath.SEARCH:
      return PageId.SEARCH;
    case RoutePath.LIVE:
      return PageId.LIVE;
    case RoutePath.EPG:
      return PageId.EPG;
    case RoutePath.CONTENT_HUB:
      return PageId.CONTENT_HUB;
    case RoutePath.SETTINGS:
      return PageId.SETTINGS;
    case RoutePath.MOVIE_DETAIL:
      return PageId.MOVIE_DETAIL;
    case RoutePath.SERIES_DETAIL:
      return PageId.SERIES_DETAIL;
    case RoutePath.PLAY:
      return PageId.PLAYBACK;
    default:
      return PageId.UNKNOWN;
  }
};

export const navigateToPlay = (
  guid: string,
  context: Pick<
    SelectItemContext,
    'viewContext' | 'pageId' | 'listItemContext'
  >,
  contentType: 'show' | 'live' = 'show',
) => {
  Router.navigate(`${RoutePath.PLAY}/${contentType}/${guid}`, {
    viewContext: context.viewContext,
    listItemContext: context.listItemContext,
    fromPageId: context.pageId,
  });
};

/**
 * Navigates to content hub slug provided
 * @param hubSlug The hub we want to navigate to
 * @param params Additional parameters we want to pass to content hub
 */
export const navigateToContentHub = (
  hubSlug: string,
  params?: ContentHubPageParams,
) => {
  let path = `${RoutePath.CONTENT_HUB}/${hubSlug}`;
  if (params?.nested) path += '/true';

  Router.navigate(path, {
    ...params,
    hubSlug,
  });
};

/**
 * The home page is a content hub page
 * @param params Additional parameters we want to pass to home page
 */
export const navigateHome = (params?: ContentHubPageParams) => {
  navigateToContentHub(ContentHubSlugs.HOME, params);
};

export const onTileSelectedHandler = (
  // @ts-ignore
  data: Media | ContentHub,
  context: SelectItemContext = {},
) => {
  const { viewContext, listItemContext, pageId, action } = context;
  if (action === 'play' && viewContext && pageId) {
    // Assume data is of type Media
    data = data as Media;

    let id = '';
    let contentType: 'show' | 'live' = 'show';
    if (isEpisode(data) || isVideo(data)) {
      id = (data as Episode).guid;
    } else if (isMovie(data) || isSeries(data)) {
      id = (data as Show).firstEpisode?.guid ?? '';
    } else if (isLive(data)) {
      id = (data as Live).id;
      contentType = 'live';
    }

    navigateToPlay(id, { viewContext, listItemContext, pageId }, contentType);
  } else if (action === 'details') {
    // Assume data is of type Media
    data = data as Media;

    if (isSeries(data)) {
      navigateToSeriesDetailsPage((data as Show).id, {
        listItemContext,
        pageId,
      });
    } else if (isMovie(data)) {
      navigateToMovieDetailsPage((data as Show).id, {
        listItemContext,
        pageId,
      });
    } else if (isChannel(data)) {
      navigateToChannel((data as Channel).slug, { viewContext });
    }
  } else if (action === 'navigation') {
    // Assume data is of type ContentHub
    data = data as ContentHub;

    navigateToContentHub(data.slug, { nested: true });
  }
};

export const navigateToSeriesDetailsPage = (
  slug: string,
  context: Pick<SelectItemContext, 'pageId' | 'listItemContext'>,
  params?: Router.PageParams,
) => {
  Router.navigate(`${RoutePath.SERIES_DETAIL}/${slug}`, {
    listItemContext: context.listItemContext,
    fromPageId: context.pageId,
    ...params,
  });
};

export const navigateToMovieDetailsPage = (
  slug: string,
  context: Pick<SelectItemContext, 'pageId' | 'listItemContext'>,
  params?: Router.PageParams,
) => {
  Router.navigate(`${RoutePath.MOVIE_DETAIL}/${slug}`, {
    listItemContext: context.listItemContext,
    fromPageId: context.pageId,
    params,
  });
};

export const navigateToChannel = (
  slug?: string,
  params?: Router.PageParams,
) => {
  let route = `${RoutePath.EPG}`;
  if (slug) {
    route += `/${slug}`;
  }

  Router.navigate(route, { ...params });
};

export const getHomeHistoryEntry = (): Router.HistoryEntry => {
  return {
    hash: `${RoutePath.CONTENT_HUB}/${ContentHubSlugs.HOME}`,
    state: null,
  };
};

export const getMovieDetailsHistoryEntry = (
  slug: string,
): Router.HistoryEntry => {
  return {
    hash: `${RoutePath.MOVIE_DETAIL}/${slug}`,
    state: null,
  };
};

export const getSeriesDetailsHistoryEntry = (
  slug: string,
  season?: number,
): Router.HistoryEntry => {
  let hash = `${RoutePath.SERIES_DETAIL}/${slug}`;
  if (season !== undefined) hash += `/${season}`;

  return {
    hash: hash,
    state: null,
  };
};

export const getVodPlaybackHistoryEntry = (
  guid: string,
): Router.HistoryEntry => {
  return {
    hash: `${RoutePath.PLAY}/show/${guid}`,
    state: null,
  };
};

export const getLivePlaybackHistoryEntry = (
  guid?: string,
): Router.HistoryEntry => {
  let hash = `${RoutePath.PLAY}/live`;
  if (guid !== undefined) hash += `/${guid}`;

  return {
    hash,
    state: null,
  };
};

export const getLiveHistoryEntry = (): Router.HistoryEntry => {
  return {
    hash: RoutePath.LIVE,
    state: null,
  };
};

// Hard resets the History Stack to "Home > Detail"
export const navigateDetailsFromVideo = (mediaItem: Video) => {
  const { showSlug } = mediaItem;
  const homeHistoryEntry = getHomeHistoryEntry();
  const historyEntries: Router.HistoryEntry[] = [homeHistoryEntry];

  if (isSeries(mediaItem)) {
    const seriesDetailsHistoryEntry = getSeriesDetailsHistoryEntry(showSlug);
    historyEntries.push(seriesDetailsHistoryEntry);
    Router.setHistory(historyEntries);
  } else if (isMovie(mediaItem)) {
    const movieDetailsHistoryEntry = getMovieDetailsHistoryEntry(showSlug);
    historyEntries.push(movieDetailsHistoryEntry);
    Router.setHistory(historyEntries);
  }
  Router.back();
};

/*
  This triggers the unavailable error modal whenever there is no content to handle deep link errors + general errors.
  Otherwise it will return the content/result of the first parameter (i.e. asyncFunc).
*/
export const getContentWrapper = async <T = unknown>(
  asyncFunc: () => Promise<T>,
  navigateToErrorPage = true,
  handleUndefinedAsError = true,
): Promise<T | undefined> => {
  try {
    const result = await asyncFunc();
    if (handleUndefinedAsError && result === undefined) {
      // If the result is undefined and handleUndefinedAsError is true, handle as an error
      throw new Error('Content is undefined');
    }
    return result;
  } catch (error) {
    if (navigateToErrorPage) {
      Router.navigate(RoutePath.ERROR, { cause: ErrorCause.UNAVAILABLE });
    }
    return undefined;
  }
};
