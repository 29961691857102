import { Metadata } from '@lightningjs/sdk';
import getMigrationFunctions from 'services/migrations';
import { AbstractDeviceIntegration } from 'config/platforms/AbstractDeviceIntegration';
import { isVideo } from 'support/contentUtils';
import { MediaIdentifier, Video } from 'types/api/media';
import { getMediaDataFromIdentifiers } from 'services/tivoService';
import AbstractHistoryService from 'services/history/AbstractHistoryService';
import PrivacyService from 'services/PrivacyService';
import StorageService from 'services/StorageService';
import { isVersionNewer } from 'support/generalUtils';

// Previous app storage keys
const PREVIOUS_BOOTSTRAP_CACHE_KEY = 'nexstar.cw.bootstrap_config';
const PREVIOUS_CACHE_KEY = 'nexstar.cw.cw';
const PREVIOUS_HISTORY_KEY = 'nexstar.cw.continue_watching_cache';
const PREVIOUS_PRIVACY_KEY = 'nexstar.cw.ccpa_key';

enum PreviousPrivacyStatus {
  OFF,
  OFF_VIEWED,
  ON,
}

type PreviousHistory = {
  [guid: string]: {
    guid: string;
    media: {
      progressPercentage: number; // 0 - 100
      slug: string;
    };
    timestamp: number;
  };
};

type ConstructorArgs = {
  deviceIntegration: AbstractDeviceIntegration;
  historyService: AbstractHistoryService;
  privacyService: PrivacyService;
  storageService: StorageService;
};

// The minimum version for migration to be possible
const MINIMUM_MIGRATE_VERSION = '2.4.3';

export default class MigrationService {
  private readonly deviceIntegration: AbstractDeviceIntegration;
  private readonly historyService: AbstractHistoryService;
  private readonly privacyService: PrivacyService;
  private readonly storageService: StorageService;

  private storedAppVersion: string | null = null;

  constructor({
    deviceIntegration,
    historyService,
    privacyService,
    storageService,
  }: ConstructorArgs) {
    this.deviceIntegration = deviceIntegration;
    this.historyService = historyService;
    this.privacyService = privacyService;
    this.storageService = storageService;
  }

  //#region Previous App Migration
  shouldMigratePreviousAppStorage() {
    return (
      !!this.getPreviousBootstrapCache() ||
      !!this.getPreviousCache() ||
      !!this.getPreviousAppHistory() ||
      !!this.getPreviousAppPrivacyStatus()
    );
  }

  async migratePreviousAppStorage() {
    // Wait analytics initialization so privacy value can be updated
    await window.analytics.initializationPromise;

    // The previous app exclusively used local storage and did not encode
    // storage to JSON
    this.clearPreviousBootstrapCache();
    this.clearPreviousCache();

    await this.migrateHistoryFromPreviousApp();
    this.migratePrivacyStatusFromPreviousApp();
  }

  private getPreviousBootstrapCache() {
    return this.deviceIntegration.getLocalStorageRaw<unknown>(
      PREVIOUS_BOOTSTRAP_CACHE_KEY,
    );
  }

  private clearPreviousBootstrapCache() {
    this.deviceIntegration.removeLocalStorage(PREVIOUS_BOOTSTRAP_CACHE_KEY);
  }

  private getPreviousCache() {
    return this.deviceIntegration.getLocalStorageRaw<unknown>(
      PREVIOUS_CACHE_KEY,
    );
  }

  private clearPreviousCache() {
    this.deviceIntegration.removeLocalStorage(PREVIOUS_CACHE_KEY);
  }

  private async migrateHistoryFromPreviousApp() {
    const previousHistory = this.getPreviousAppHistory();
    if (previousHistory === null || typeof previousHistory !== 'object') return;

    // The previous app only saved movies / last viewed episode of a series
    const identifiers = Object.keys(previousHistory).map(
      (guid): MediaIdentifier => {
        return {
          objectType: 'Episode',
          guid: guid,
        };
      },
    );

    // Fetching video data to fill in missing data from previous app's history
    const mediaData = await getMediaDataFromIdentifiers(identifiers);

    for (const media of mediaData) {
      // We should never receive show data. Added check just in case
      if (!isVideo(media)) return;

      try {
        const { id, creditSecs, durationSecs } = media as Video;
        const mediaHistory = previousHistory[id];
        if (!mediaHistory) return;

        const { progressPercentage } = mediaHistory.media;

        const progress = (progressPercentage / 100) * parseFloat(durationSecs);
        const isComplete =
          progress >= parseFloat(durationSecs) - parseFloat(creditSecs);

        this.historyService.add(media as Video, progress, isComplete);
      } catch (e) {
        // Assume there's an issue parsing value. Ignore and keep going
      }
    }

    // Purge any expired content
    this.historyService.purgeInvalid();
    this.deviceIntegration.removeLocalStorage(PREVIOUS_HISTORY_KEY);
  }

  private getPreviousAppHistory() {
    return this.deviceIntegration.getLocalStorageRaw<PreviousHistory>(
      PREVIOUS_HISTORY_KEY,
    );
  }

  private migratePrivacyStatusFromPreviousApp() {
    const previousPrivacyValue = this.getPreviousAppPrivacyStatus();
    if (previousPrivacyValue === null) return;

    switch (previousPrivacyValue) {
      case PreviousPrivacyStatus.ON:
        this.privacyService.setDoNotSell(true);
        break;
      case PreviousPrivacyStatus.OFF_VIEWED:
        this.privacyService.setDoNotSell(false);
        break;
      case PreviousPrivacyStatus.OFF:
      default:
        // Do nothing, default case
        break;
    }

    this.deviceIntegration.removeLocalStorage(PREVIOUS_PRIVACY_KEY);
  }

  private getPreviousAppPrivacyStatus() {
    return this.deviceIntegration.getLocalStorageRaw<PreviousPrivacyStatus>(
      PREVIOUS_PRIVACY_KEY,
    );
  }
  //#endregion

  //#region Previous Version Migration
  shouldMigratePreviousVersionStorage() {
    this.storedAppVersion = this.storageService.appVersion.get();
    const currentVersion = Metadata.appVersion();

    return this.storedAppVersion !== currentVersion;
  }

  async migratePreviousVersionStorage() {
    // Wait analytics initialization so privacy value can be updated
    await window.analytics.initializationPromise;

    const storedVersion = this.storedAppVersion;
    const canMigrateVersion = this.canMigrateVersion(storedVersion);

    if (!canMigrateVersion) {
      this.clearLocalStorage();
    } else {
      try {
        // migrationFunctions will be an empty list if no migration is necessary
        const migrationFunctions = getMigrationFunctions(storedVersion!);
        for (let i = 0; i < migrationFunctions.length; i++) {
          await migrationFunctions[i]!();
        }
      } catch (e) {
        console.error('Failed local storage migration, clearing data', e);
        this.clearLocalStorage();
      }
    }

    this.updateVersion();
  }

  private canMigrateVersion(version: string | null) {
    const minimumVersion = MINIMUM_MIGRATE_VERSION;
    if (version === null) {
      return false;
    } else if (version === minimumVersion) {
      return true;
    }

    return isVersionNewer(version, minimumVersion);
  }

  private clearLocalStorage() {
    const storageService = this.storageService;

    storageService.audio.remove();
    storageService.autoplaySettings.remove();
    storageService.history.remove();
    storageService.privacy.remove();
    storageService.recentSearches.remove();
    storageService.subtitle.remove();
    storageService.termsAcceptedTimestamp.remove();
  }

  private updateVersion() {
    const currentVersion = Metadata.appVersion();
    this.storageService.appVersion.set(currentVersion);
  }
  //#endregion
}
