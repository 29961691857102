import { Colors, Lightning } from '@lightningjs/sdk';
import Grid from 'components/common/Grid';
import PortraitTile from 'components/common/tiles/PortraitTile';
import Tabs from 'components/common/Tabs/Tabs';
import { Lane, Swimlanes } from 'services/cwData';
import { getFontFaceFromStyle } from 'support/textUtils';
import { PlayableMediaImages } from 'types/api/media';
import { GridIndexData, LightningBuilder } from 'types/lightning';
import Tab, { TabTemplateSpec } from 'components/common/Tabs/Tab';
import constants from '../../../../static/constants.json';
import {
  HoverableComponent,
  HoverableComponentSignalMap,
  HoverableComponentTypeConfig,
} from 'components/common/HoverableComponent';
import { TileParentContext } from './ContentHubPage';

const TAB_PADDING_BOTTOM = 15;
const TAB_MARGIN_BOTTOM = 33;
const TAB_HEIGHT = 68;
const TAB_WIDTH = 1535;

const GRID_COLUMNS = 6;
const GRID_HEIGHT = 1080 - TAB_HEIGHT;

const HIGHLIGHT_POSITION_OFFSET = constants.ui.highlightPositionOffset;

export interface TabbedSectionTemplateSpec
  extends Lightning.Component.TemplateSpec {
  data: Swimlanes | null;
  useTabs: boolean;
  topPadding: number;

  Tabs: typeof Tabs;
  GridWrapper: {
    Grid: typeof Grid;
  };
}

interface TabbedSectionSignalMap extends HoverableComponentSignalMap {
  scrollToPromo(): void;
  scrollToContent(options?: { index?: number }): void;
  onTabSelected(): void;
}

interface TabbedSectionTypeConfig extends HoverableComponentTypeConfig {
  SignalMapType: TabbedSectionSignalMap;
}

export default class TabbedSection
  extends HoverableComponent<TabbedSectionTemplateSpec, TabbedSectionTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<TabbedSectionTemplateSpec>
{
  private _data: TabbedSectionTemplateSpec['data'] = null;
  private _useTabs = false;
  private _topPadding = 0;

  private _Tabs = this.getByRef('Tabs')!;
  private _GridWrapper = this.getByRef('GridWrapper')!;
  private _Grid = this._GridWrapper.getByRef('Grid')!;

  get data() {
    return this._data;
  }

  set data(data: TabbedSectionTemplateSpec['data']) {
    this._data = data;
    if (!data) return;

    this.updateTabs(data);
    this.updateGrid(data);

    this.resetState();
  }

  get useTabs() {
    return this._useTabs;
  }

  set useTabs(useTabs: TabbedSectionTemplateSpec['useTabs']) {
    this._useTabs = useTabs;

    if (useTabs && this.data) {
      this.updateTabs(this.data);
    }

    this._GridWrapper.patch({
      clipping: this._Tabs.visible,
      rtt: this._Tabs.visible,
      shader: this._Tabs.visible
        ? {
            type: Lightning.shaders.FadeOut,
            top: TAB_MARGIN_BOTTOM + TAB_PADDING_BOTTOM,
          }
        : {},
    });

    this.resetState();
  }

  get topPadding() {
    return this._topPadding;
  }

  set topPadding(topPadding: number) {
    this._topPadding = topPadding;
    this.updateGridHeight();
  }

  get index() {
    return this._Grid.index;
  }

  get activeTabIndex() {
    return this._Tabs.index;
  }

  get activeTabName(): string {
    const tabs = this._Tabs;
    return tabs.tabs[tabs.activeTab ?? 0]?.label ?? '';
  }

  static override _template(): Lightning.Component.Template<TabbedSectionTemplateSpec> {
    const TAB_TEXT_STYLES = {
      fontFace: getFontFaceFromStyle('regular'),
      highlight: false,
      lineHeight: 38,
    };

    const TAB_CONTAINER_STYLES = {
      rect: true,
      shader: { type: Lightning.shaders.RoundedRectangle, radius: 10 },
      flex: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 13, // adjusting for centering issues
        paddingBottom: TAB_PADDING_BOTTOM,
      },
    };

    return {
      flex: { direction: 'column' },
      Tabs: {
        w: TAB_WIDTH,
        h: TAB_HEIGHT,
        type: Tabs,
        labelStyles: {
          focused: {
            ...TAB_TEXT_STYLES,
            textColor: Colors('unsafeBlack').get(),
          },
          unfocused: {
            ...TAB_TEXT_STYLES,
            textColor: Colors('white').alpha(0.6).get(),
          },
          active: { textColor: Colors('white').get() },
        },
        containerStyles: {
          focused: {
            ...TAB_CONTAINER_STYLES,
            color: Colors('white').get(),
          },
          unfocused: {
            ...TAB_CONTAINER_STYLES,
            color: Colors('transparent').get(),
          },
          active: { color: Colors('unfocusedBackground').get() },
        },
        signals: {
          $onHover: '$onHover',
        },
      },
      GridWrapper: {
        x: HIGHLIGHT_POSITION_OFFSET,
        y: -TAB_PADDING_BOTTOM,
        w: 1920,
        h: GRID_HEIGHT,
        clipping: true,
        rtt: true,
        shader: {
          type: Lightning.shaders.FadeOut,
          top: TAB_MARGIN_BOTTOM + TAB_PADDING_BOTTOM,
        },
        Grid: {
          x: -HIGHLIGHT_POSITION_OFFSET,
          y: TAB_MARGIN_BOTTOM + TAB_PADDING_BOTTOM,
          // Assuming Grid only will only have PortraitTiles (shows) for now, as there are no designs that indicate otherwise yet
          h: (h: number) => h,
          type: Grid,
          spacing: 22,
          crossSpacing: 19,
          columns: GRID_COLUMNS,
          // @ts-ignore
          listType: PortraitTile,
          signals: {
            onIndexChanged: '_onIndexChanged',
            $onHover: '$onHover',
          },
        },
      },
    };
  }

  override _setup() {
    this._setState('Tabs');
  }

  private updateTabs(swimlanes: Swimlanes) {
    const numberOfNonPromoLanes = swimlanes.lanes.filter(
      lane => lane.itemType !== 'promo',
    ).length;
    this._Tabs.visible = this.useTabs || numberOfNonPromoLanes > 1;
    if (!this._Tabs.visible) return;

    const tabs: LightningBuilder<typeof Tab, TabTemplateSpec>[] = [];
    swimlanes.lanes.forEach(lane => {
      if (lane.itemType !== 'promo' && lane.items.length) {
        tabs.push({
          type: Tab,
          label: lane.title,
        });
      }
    });
    this._Tabs.tabs = tabs;

    if (this._Tabs.tabs.length) this._Tabs.activeTab = 0;
  }

  private updateGrid(swimlanes: Swimlanes) {
    this.resetGridPosition();

    const firstGridLane = swimlanes.lanes.find(
      lane => lane.itemType !== 'promo',
    ) as Lane;
    if (!firstGridLane) return this._Grid.clear();
    this._Grid.items = this.buildGridTiles(firstGridLane);
  }

  private updateGridHeight() {
    const gridHeight = GRID_HEIGHT - this._topPadding;
    this._GridWrapper.h = gridHeight;
  }

  private buildGridTiles(lane: Lane) {
    return lane.items.map((item, index) => {
      const parentContext: TileParentContext = {
        crossIndex: index,
        mainIndex: this._Tabs.index ?? 0,
        group: this._Tabs.activeTab ?? 0,
        rowTitle: lane.title ?? '',
        isTivoSource: lane.itemType === 'tivo-api',
      };

      return {
        type: PortraitTile,
        data: item,
        imageUrl: (item.images as PlayableMediaImages).image_vertical,
        notifyWhenFullyVisible: true,
        parentContext: parentContext,
      };
    });
  }

  private resetGridPosition() {
    this._GridWrapper.setSmooth('y', 0);
  }

  private resetState() {
    if (!this._Tabs.visible) {
      this._setState('Grid');
    } else {
      this._setState('Tabs');
    }
  }

  private $onTabSelected(tab: Tab) {
    const isSameTab = this._Tabs.activeTab === this._Tabs.index;
    if (!this.data || isSameTab) return;

    this._Tabs.activeTab = this._Tabs.index;
    const lane = this.data.lanes.find(lane => lane.title === tab.label) as
      | Lane
      | undefined;

    if (lane) this._Grid.items = this.buildGridTiles(lane);
    this.signal('onTabSelected');
  }

  private _onIndexChanged(indexData: GridIndexData) {
    const { mainIndex } = indexData;

    if (this._Tabs.visible) return;

    if (mainIndex === 0) {
      this.signal('scrollToPromo');
      this.resetGridPosition();
    } else if (mainIndex === 1 || mainIndex === 2) {
      this.signal('scrollToContent', {
        index: mainIndex,
      });
      this._GridWrapper.setSmooth('y', -this._Grid.getScrollPosition());
    }
  }

  $onHover(target: unknown) {
    switch (target) {
      case this._Grid:
        this._setState('Grid');
        break;
      case this._Tabs:
        this._setState('Tabs');
        break;
    }
  }

  static override _states() {
    return [
      class Tabs extends this {
        override $enter() {
          this.signal('scrollToPromo');
          if (!this._Tabs.visible) {
            this._setState('Grid');
          }
        }

        override _getFocused() {
          return this._Tabs;
        }

        override _handleDown() {
          this._setState('Grid');
        }
      },
      class Grid extends this {
        override $enter() {
          if (this._Tabs.visible) {
            this.signal('scrollToContent');
          }
        }

        override _getFocused() {
          return this._Grid;
        }

        override _handleUp() {
          if (!this._Tabs.visible) return false;
          this._setState('Tabs');
        }

        override _handleBack() {
          if (!this._Tabs.visible) return false;
          this._setState('Tabs');
        }
      },
    ];
  }
}
