import { Colors, Lightning } from '@lightningjs/sdk';
import { HoverableListItem } from 'components/common/HoverableListItem';

export interface ListSelectionTemplateSpec
  extends Lightning.Component.TemplateSpec {
  label: string;
  isSelected: boolean;
  Content: {
    Indicator: Lightning.Component;
    LabelText: Lightning.Component;
  };
  Background: Lightning.Component;
}

const CONTENT_HEIGHT = 60;
const CONTENT_WIDTH = 772;

const LABEL_OFFSET = 20;
const SLIDE_X = 30;

const INDICATOR_WIDTH = 5;
const INDICATOR_HEIGHT = 45;

export default class ListSelection
  extends HoverableListItem<ListSelectionTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<ListSelectionTemplateSpec>
{
  private _Content = this.getByRef('Content')!;
  private _Indicator = this._Content.getByRef('Indicator')!;
  private _LabelText = this._Content.getByRef('LabelText')!;
  private _Background = this.getByRef('Background')!;

  private _label = '';
  private _isSelected = false;

  get label() {
    return this._label;
  }

  set label(label: string) {
    this._label = label;
    this._LabelText.patch({ text: { text: label } });
  }

  get isSelected() {
    return this._isSelected;
  }

  set isSelected(isSelected: boolean) {
    this._isSelected = isSelected;

    this._Indicator.setSmooth('alpha', isSelected ? 1 : 0);
    this._Content.setSmooth('x', isSelected ? SLIDE_X : 0);
  }

  get title() {
    return this.label ?? '';
  }

  static get height() {
    return CONTENT_HEIGHT;
  }
  static get width() {
    return CONTENT_WIDTH;
  }

  static override _template(): Lightning.Component.Template<ListSelectionTemplateSpec> {
    return {
      Content: {
        w: CONTENT_WIDTH,
        h: this.height,
        flex: {
          direction: 'row',
          alignContent: 'center',
        },
        transitions: { x: { duration: 0.3, timingFunction: 'ease-in-out' } },
        Indicator: {
          zIndex: 2,
          rect: true,
          mountX: 0.5,
          transitions: {
            alpha: { duration: 0.3, timingFunction: 'ease-in-out' },
          },
          flexItem: { alignSelf: 'center', marginLeft: 0 },
          alpha: 0,
          w: INDICATOR_WIDTH,
          h: INDICATOR_HEIGHT,
          color: Colors('appAccent').get(),
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 1.5,
          },
        },
        LabelText: {
          zIndex: 2,
          flexItem: { marginLeft: LABEL_OFFSET },
          color: Colors('text').get(),
          text: {
            fontSize: 32,
            lineHeight: this.height,
            textBaseline: 'middle',
          },
        },
      },
      Background: {
        visible: false,
        rect: true,
        zIndex: 1,
        w: w => w,
        h: h => h,
        color: Colors('background').get(),
      },
    };
  }

  override _focus() {
    this._Background.patch({ visible: true });
  }

  override _unfocus() {
    this._Background.patch({ visible: false });
  }
}
