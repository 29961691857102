import { Lightning, Colors } from '@lightningjs/sdk';
import { HoverableListItem } from 'components/common/HoverableListItem';
import { getImageTextureObj } from 'support/generalUtils';
import { translate } from 'support/translate';

export interface EpgModalButtonTemplateSpec
  extends Lightning.Component.TemplateSpec {
  icon: {
    focused: string;
    unfocused: string;
  };
  iconSizing: {
    width?: number;
    height?: number;
  };
  titleKey: string;

  Icon: Lightning.textures.ImageTexture;
  Title: Lightning.textures.TextTexture;
}

const DEFAULT_ICON_SIZING = 22;
const ICON_MARGIN_LEFT = 48;
const ICON_MARGIN_RIGHT = 13;

const TITLE_FONT_SIZE = 23;

export default class EpgModalButton
  extends HoverableListItem<EpgModalButtonTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<EpgModalButtonTemplateSpec>
{
  private _icon: EpgModalButtonTemplateSpec['icon'] = {
    focused: '',
    unfocused: '',
  };
  private _iconSizing: Required<EpgModalButtonTemplateSpec['iconSizing']> = {
    width: DEFAULT_ICON_SIZING,
    height: DEFAULT_ICON_SIZING,
  };
  private _titleKey = '';

  private _Icon = this.getByRef('Icon')!;
  private _Title = this.getByRef('Title')!;

  get title() {
    return this._Title.text!.text ?? '';
  }

  set icon(icon: EpgModalButtonTemplateSpec['icon']) {
    this._icon = icon;
    this.updateIcon();
  }

  set iconSizing(sizing: EpgModalButtonTemplateSpec['iconSizing']) {
    const { width: prevWidth, height: prevHeight } = this._iconSizing;

    this._iconSizing = {
      width: sizing.width ?? prevWidth,
      height: sizing.height ?? prevHeight,
    };
    this.updateIcon();
  }

  get titleKey() {
    return this._titleKey;
  }

  set titleKey(titleKey: string) {
    this._titleKey = titleKey;

    const title = translate(titleKey);
    this._Title.patch({ text: title });
  }

  static override _template(): Lightning.Component.Template<EpgModalButtonTemplateSpec> {
    return {
      flex: {
        direction: 'row',
        alignItems: 'center',
      },
      w: (w: number) => w,
      h: (h: number) => h,
      rect: true,
      color: Colors('buttonInactive').get(),
      Icon: {
        flexItem: {
          marginLeft: ICON_MARGIN_LEFT,
          marginRight: ICON_MARGIN_RIGHT,
        },
      },
      Title: {
        y: 2, // Offset for centering text
        text: {
          fontSize: TITLE_FONT_SIZE,
          textColor: Colors('text').get(),
          textAlign: 'center',
        },
      },
    };
  }

  override _getFocused() {
    return this;
  }

  override _focus() {
    this.patch({ color: Colors('highlight').get() });
    this._Title.patch({ text: { textColor: Colors('activeText').get() } });

    this.updateIcon();
  }

  override _unfocus() {
    this.patch({ color: Colors('buttonInactive').get() });
    this._Title.patch({ text: { textColor: Colors('text').get() } });

    this.updateIcon();
  }

  private updateIcon() {
    const { focused, unfocused } = this._icon;
    const { width, height } = this._iconSizing;

    const icon = this.hasFocus() ? focused : unfocused;
    const iconTexture = getImageTextureObj(icon, width, height);
    this._Icon.patch(iconTexture);
  }
}
