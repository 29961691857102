import { Colors, Lightning } from '@lightningjs/sdk';
import { getFontFaceFromStyle } from 'support/textUtils';
import { Swimlanes } from 'services/cwData';
import constants from '../../../static/constants.json';

const TITLE_FONT_SIZE = 44;
const TITLE_FONT_STYLE = 'italic bold';
const TITLE_LINE_HEIGHT = 52;

const PADDING_X = constants.ui.contentHubPagePaddingX;
export interface ContentHubTitleSpec extends Lightning.Component.TemplateSpec {
  hasNavbar: boolean;
  title: string;
  isBillboard: boolean;

  Title: Lightning.textures.TextTexture;
}

export default class ContentHubTitle
  extends Lightning.Component<ContentHubTitleSpec>
  implements Lightning.Component.ImplementTemplateSpec<ContentHubTitleSpec>
{
  private _isBillboard = false;
  private _Title = this.getByRef('Title')!;

  get isBillboard() {
    return this._isBillboard;
  }

  set isBillboard(isBillboard: boolean) {
    this._isBillboard = isBillboard;
  }

  set hasNavbar(hasNavbar: boolean) {
    if (this.isBillboard) {
      this._Title.patch({
        x: hasNavbar ? PADDING_X : 96,
      });
    } else {
      this._Title.patch({
        x: hasNavbar ? PADDING_X : 160,
      });
    }
  }

  set title(pageTitle: string) {
    pageTitle = pageTitle || '';

    if (this._isBillboard) {
      this._Title.patch({
        text: {
          text: pageTitle,
          fontSize: TITLE_FONT_SIZE,
          textColor: Colors('text').get(),
          fontStyle: TITLE_FONT_STYLE,
          lineHeight: TITLE_LINE_HEIGHT,
        },
      });
    } else {
      this._Title.patch({
        text: {
          text: pageTitle,
        },
      });
      return;
    }
  }

  static override _template(): Lightning.Component.Template<ContentHubTitleSpec> {
    return {
      ...super._template(),
      Title: {
        w: 1920,
        text: {
          fontSize: 36,
          fontFace: getFontFaceFromStyle('medium'),
        },
      },
    };
  }

  override _setup() {
    super._setup();

    this._Title.alpha = 1;
    this._Title.visible = true;
  }
}
