import { Promo } from 'services/cwData';
import { Video } from '../../types/api/media';
import {
  CategoryEngagementProps,
  SelectItemProps,
  SelectPromoProps,
} from './MParticle';

export const reportNavBarNavigation = (title: string) => {
  window.analytics.mParticle.reportNavBarNavigation(title);
};

export const reportShowPageEngagement = (
  mediaItem: Video,
  episodeIndex: number,
  seasonIndex: number,
) => {
  window.analytics.mParticle.reportShowPageEngagement(
    mediaItem,
    episodeIndex,
    seasonIndex,
  );
};

export const reportScrollTracking = (quartile: number) => {
  window.analytics.mParticle.reportScrollTracking(quartile);
};

export const reportViewPromotion = (props: { promo: Promo; index: number }) => {
  window.analytics.mParticle.reportViewPromotion(props);
};

export const reportPromoSelected = (props: SelectPromoProps) => {
  window.analytics.mParticle.reportPromoSelected(props);
};

export const reportItemSelected = (props: SelectItemProps) => {
  window.analytics.mParticle.reportItemSelected(props);
};

export const reportCategoryEngagement = (props: CategoryEngagementProps) => {
  window.analytics.mParticle.reportCategoryEngagement(props);
};

export const updatePrivacyStatus = (ConsentStateOn: boolean) => {
  window.analytics.comscore.updatePrivacyStatus(ConsentStateOn);
  window.analytics.mParticle.updatePrivacyStatus(ConsentStateOn);
  window.analytics.nielsen.updatePrivacyStatus(ConsentStateOn);
};
