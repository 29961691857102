import { AppData, Lightning } from '@lightningjs/sdk';
import { IndexData } from 'types/lightning';
import constants from '../../../static/constants.json';
import SizedColumnList, {
  SizedColumnListTemplateSpec,
} from './SizedColumnList';
import { VideoLaneItem } from 'services/cwData';
import HorizontalWatchedItem from './HorizontalWatchedItem';

export interface WatchHistoryColumnListTemplateSpec
  extends SizedColumnListTemplateSpec {
  selectedItems: Record<string, boolean>;
  isViewMode: boolean;
}

export class WatchHistoryColumnList
  extends SizedColumnList
  implements
    Lightning.Component
      .ImplementTemplateSpec<WatchHistoryColumnListTemplateSpec>
{
  private _selectedItems: Record<string, boolean> = {};
  private _isViewMode = false;

  override set items(items: VideoLaneItem[]) {
    const laneItems = this.buildHistoryItems(items);
    super.items = laneItems;
  }

  set selectedItems(selectedItems: Record<string, boolean>) {
    this._selectedItems = selectedItems;
  }

  set isViewMode(value: boolean) {
    this._isViewMode = value;
  }

  async _onRequestItems(indexData: IndexData) {
    const { dataLength } = indexData;
    const requestPage =
      Math.ceil(dataLength / constants.watchHistory.pageSize) + 1;

    try {
      const watchHistoryLaneItems =
        await AppData?.historyService.getWatchHistoryItems(
          requestPage,
          constants.watchHistory.pageSize,
        );
      if (watchHistoryLaneItems && watchHistoryLaneItems.length > 0) {
        return this.buildHistoryItems(watchHistoryLaneItems);
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  private buildHistoryItems(historyItems: VideoLaneItem[]) {
    return historyItems.map(media => {
      return {
        type: HorizontalWatchedItem,
        mediaItem: media,
        edit: !this._isViewMode,
        isActive: !!this._selectedItems[media.showSlug!],
        w: HorizontalWatchedItem.width,
        h: HorizontalWatchedItem.height,
      };
    });
  }
}
