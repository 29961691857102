export const loadVizioScript = () => {
  return new Promise<void>((resolve, reject) => {
  const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'http://localhost:12345/scfs/cl/js/vizio-companion-lib.js';
    script.onload = () => {
      resolve();
    }
    script.onerror = (e) => {
      reject(e);
    };
    document.head.appendChild(script);
  });
}