export type CcEdgeType = `${number}px ${number}px ${number}px`;

export type CcSettings = {
  enabled: boolean;
  fontSize: number;
  fontFace: string;
  fontStyle: string;
  color: string;
  colorOpacity: string;
  colorFlashing: boolean;
  backgroundColor: string;
  backgroundOpacity: string;
  backgroundFlashing: boolean;
  edgeType: CcEdgeType;
  edgeColor: string;
  windowColor: string;
  windowOpacity: string;
  windowFlashing: boolean;
};

export const translateCcSettingToCss = (ccSettings: Partial<CcSettings>) => {
  const {
    backgroundColor,
    backgroundOpacity,
    color,
    colorOpacity,
    fontFace,
    fontSize = 48,
    fontStyle,
    edgeColor,
    edgeType,
    windowColor,
    windowOpacity,
  } = ccSettings;
  return `
  ::cue {
    ${color ? `color: ${color}${colorOpacity ?? ''};` : ''}
    ${
      backgroundColor
        ? `background: ${backgroundColor}${backgroundOpacity ?? ''};`
        : ''
    }
    ${`font-size: ${fontSize}px;`}
    ${
      // Note: Most fonts don't seem to apply on Tizen and Vizio
      fontFace ? `font-family: ${fontFace};` : 'Roboto'
    }
    ${fontStyle ? `font-style: ${fontStyle};` : ''}
    ${
      edgeType
        ? `text-shadow: ${edgeType} ${edgeColor ? edgeColor : 'black'};`
        : ''
    }
    ${
      // Note: Outline does not seem to work on Tizen
      windowColor
        ? `outline: 4px solid ${windowColor}${windowOpacity ?? ''}`
        : ''
    }
    ${windowColor ? `line-height: ${fontSize * 2}px` : ''}
  }`;
};
