import { Router } from '@lightningjs/sdk';
import { SpeechType, withAnnouncer } from '@lightningjs/ui-components';
import abbreviationsConfig from '../../support/abbreviations';

export type AnnounceOptions = {
  append: boolean;
  notification: boolean;
};

export type DefaultVoiceOutReturn = {
  series: Promise<void>;
  readonly active: boolean;
  append: (toSpeak: SpeechType) => void;
  cancel: () => void;
} | void;

export type SpeechFunction = (toSpeak: SpeechType) => SpeechType;

export type AnnouncerIntegration = ReturnType<
  typeof createAbstractAnnouncerIntegration
>;

export const createAbstractAnnouncerIntegration = (
  speechFn?: SpeechFunction,
) => {
  /*
     There seems to be some type issues between the SDK Lightning.Component and the Core lng.Component
     Using the ignore to force withAnnouncer to use Lightning.Component type
  */
  //@ts-ignore
  const withAnnouncerInstance = withAnnouncer<typeof Router.App>(
    Router.App,
    speechFn,
    {
      voiceOutDelay: 0,
      abbreviationsConfig,
    },
  );

  return class AbstractAnnouncerIntegration extends withAnnouncerInstance {
    override _voiceOut(toSpeak: SpeechType) {
      this.onVoiceOutStart();

      try {
        const speech = super._voiceOut.apply(this, [
          toSpeak,
        ]) as unknown as DefaultVoiceOutReturn;

        if (speech && speech.series) {
          // speech.series is a promise containing the speech synthesis call
          speech.series.catch(this.onVoiceOutDone.bind(this));
        }

        return speech;
      } catch (e) {
        // Catch any immediate errors with speech synthesis event
        this.onVoiceOutDone();
      }
    }

    protected onVoiceOutStart() {
      // Overwritten in Announcer class to update state
    }

    protected onVoiceOutDone() {
      // Overwritten in Announcer class to update state
    }

    override $announcerRefresh(depth?: number) {
      // withAnnouncer's $announcerRefresh function does not check if _lastFocusPath is
      // initialized, which will cause errors when it slices an undefined variable. Added
      // a manual check here
      if (this._lastFocusPath) {
        return super.$announcerRefresh.apply(this, [depth]);
      } else {
        return super.$announcerRefresh.apply(this);
      }
    }
  };
};
