export const STANDARD_TRANSLATION = {
  x: { duration: 0.3, timingFunction: 'ease-in-out' },
  y: { duration: 0.3, timingFunction: 'ease-in-out' },
} as const;

export const STANDARD_EXPANSION = {
  w: { duration: 0.2 },
  h: { duration: 0.2 },
} as const;

export const STANDARD_FADE = {
  alpha: { duration: 0.1, timingFunction: 'ease-in-out' },
} as const;

export const SEARCH_TILE_FADE = {
  alpha: { duration: 0.2, timingFunction: 'ease-in-out' },
} as const;

export const OVERLAY_FADE = {
  alpha: { duration: 0.3, timingFunction: 'ease-in-out' },
} as const;
