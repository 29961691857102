import Hls, { HlsConfig, ManifestParsedData } from 'hls.js';
import AbstractPlayerInstance from './AbstractPlayerInstance';
import { AppData } from '@lightningjs/sdk';
import { Platform } from '../../../models/platforms/platform';
export default class HlsPlayerInstance extends AbstractPlayerInstance<never> {
  private hls: Hls;
  private manualTextTrack: TextTrack | undefined;
  private bindCueChangeHandler = this.cueChangeHandler.bind(this);

  constructor(videoElement: HTMLMediaElement, isLiveStream: boolean) {
    super(videoElement);

    const options: Partial<HlsConfig> = {
      debug: !AppData?.isProduction,
      autoStartLoad: false, // set to false so we can use startLoad
      maxMaxBufferLength: 10, // default is 600, Setting this to 10 to prevent error when attempting to append buffer to source buffer while buffer is full
    };

    this.hls = new Hls(options);
    this.updateOptions(isLiveStream);
  }

  override async destroy() {
    this.cleanUpTextTracks();
    this.hls.destroy();
  }

  override async load(url: string, startTime: number | undefined) {
    this.hls.on(
      Hls.Events.MANIFEST_PARSED,
      (eventName: string, manifest: ManifestParsedData) => {
        this.hls.startLoad(startTime ?? 0);

        this.updateSubtitles();
      },
    );

    this.hls.on(Hls.Events.SUBTITLE_TRACKS_UPDATED, () => {
      this.updateSubtitles();
    });

    this.hls.on(Hls.Events.BUFFER_CREATED, () => {
      // There's an issue where changing the audio track before the buffer is created
      // results in a buffer append error
      const audioSetting = AppData!.storageService.audio.get() ?? '';
      this.setAudioTrack(audioSetting);
    });

    this.hls.loadSource(url);
    this.hls.attachMedia(this.videoElement);
  }

  override onAdaption(onAdaptionCallback: (bitrate: number) => void): void {
    this.hls.on(Hls.Events.LEVEL_SWITCHED, (event, { level }) => {
      // throw new Error('test error');
      const newLevel = this.hls.levels[level];
      const bitRate = newLevel!.bitrate / 1000;
      this.currentBitrate = bitRate;
      onAdaptionCallback(bitRate);
    });
  }

  override onError(
    handleError: (isFatal: boolean, errorMessage: string) => void,
  ): void {
    this.hls.on(Hls.Events.ERROR, (event, data) => {
      const { type, details, fatal } = data;
      handleError(fatal, `${type}: ${details}`);
    });
  }

  override getAudioOptions(): string[] {
    const audioTracks: string[] = [];
    this.hls.audioTracks.forEach(track => {
      if (track.lang) audioTracks.push(track.lang);
    });
    return audioTracks;
  }

  override setAudioTrack(lang: string): void {
    const selectedAudioTrack = this.hls.audioTracks.find(
      track => track.lang === lang,
    );
    this.hls.setAudioOption(selectedAudioTrack);
  }

  override getPlayerMedia(): HTMLMediaElement | null {
    return this.hls?.media;
  }

  override enableCc(lang: string): void {
    if (this.hls.subtitleTracks?.length) {
      this.enableSubtitleTracksCC(lang);
    } else if (this.hls?.media?.textTracks.length) {
      this.enableManualTrackCC(lang);
    }
  }

  override disableCc(): void {
    this.hls.subtitleDisplay = false;
    this.hls.subtitleTrack = -1;

    this.cleanUpTextTracks();
  }

  override extractSubtitleOptions(): string[] {
    if (this.hls.subtitleTracks.length) {
      return this.extractSubtitleTracks();
    } else if (this.hls?.media?.textTracks.length) {
      return this.extractManualTextTracks();
    } else {
      return [];
    }
  }

  override handleManualTextTrack(): boolean {
    // if subtitle tracks exist we don't need to add tracks manually
    if (this.hls.subtitleTracks.length) return true;

    const textTracks = this.hls?.media?.textTracks;
    if (!textTracks?.length) return false;

    const validTrack = Array.from(textTracks).findIndex(
      track => track.language && track.mode !== 'disabled',
    );

    if (validTrack === -1) return false;

    this.updateSubtitles();

    return true;
  }

  private extractSubtitleTracks() {
    const subtitleOptions: string[] = [];
    this.hls.subtitleTracks.forEach(track => {
      const { type, lang } = track;
      if (lang && type === 'SUBTITLES') subtitleOptions.push(lang);
    });

    return subtitleOptions;
  }

  private extractManualTextTracks(): string[] {
    const textTracks = this.hls?.media?.textTracks;
    if (!textTracks?.length) return [];

    const validTracks = Array.from(textTracks).filter(textTrack => {
      if (!textTrack.language || textTrack.mode === 'disabled') return false;
      if (textTrack.kind !== 'subtitles' && textTrack.kind !== 'captions')
        return false;

      return true;
    });

    const addedLanguages: string[] = [];
    const languageCodes: string[] = [];

    validTracks.forEach(track => {
      const commonLangCode = this.getCommonLanguageCode(track.language);

      if (!addedLanguages.includes(commonLangCode)) {
        addedLanguages.push(commonLangCode);
        languageCodes.push(track.language);
      }
    });

    return languageCodes;
  }

  private enableSubtitleTracksCC(lang: string) {
    if (!this.hls.subtitleTracks?.length) return;

    const trackIndex = this.hls.subtitleTracks.findIndex(
      track => track.lang === lang,
    );

    this.hls.subtitleDisplay = trackIndex >= 0;
    this.hls.subtitleTrack = trackIndex;
  }

  private cleanUpTextTracks() {
    if (this.manualTextTrack) {
      this.manualTextTrack.removeEventListener(
        'cuechange',
        this.bindCueChangeHandler,
      );
      this.manualTextTrack = undefined;
    }
  }

  private enableManualTrackCC(lang: string) {
    const textTracks = this.hls?.media?.textTracks;
    if (!textTracks?.length) return;

    Array.from(textTracks).forEach(textTrack => {
      if (textTrack.mode !== 'disabled' && textTrack.language === lang) {
        this.manualTextTrack = textTrack;
        textTrack.mode = 'hidden';
        textTrack.removeEventListener('cuechange', this.bindCueChangeHandler);
        textTrack.addEventListener('cuechange', this.bindCueChangeHandler);
      }
    });
  }

  private cueChangeHandler(event: any) {
    this.handleManualTextTrackChange?.(event.currentTarget as TextTrack);
  }

  private getCommonLanguageCode(lang: string) {
    switch (lang) {
      case 'en':
      case 'eng':
      case 'en-ca':
      case 'english':
        return 'english';
      default:
        return lang;
    }
  }

  // documentation on HLS options can be found here: https://github.com/video-dev/hls.js/blob/master/docs/API.md
  private updateOptions(isLiveStream: boolean) {
    /*
      this makes sure we don't use levels that have content larger then our player
      this should prevent us from using levels that will take excessively long to load

      This improves the stalling on vizio considerably
    */
    this.hls.config.capLevelToPlayerSize = true;

    /*
      this adds a restriction on capLevelToPlayerSize above so we use only levels for our player size (not defined by our device)
    */
    this.hls.config.ignoreDevicePixelRatio = true;

    /*
      this lowers our level when we have performance issues
      if we're stalling due to performance, we'll switch to a lower level (which should improve performance)
    */
    this.hls.config.capLevelOnFPSDrop = true;

    if (!isLiveStream) return;

    /*
    increasing buffer size, we won't usually have enough content to fill the buffer to this point but having more room will be useful if we need to sync with live playback
    */
    this.hls.config.maxMaxBufferLength = 60;

    /*
    without this setting our back buffer defaults to infinity (and it is up to the player to clear the buffer) this can cause memory issues
    we don't expect to seek backwards, so we keep this length short (it should be non-zero)
    */
    this.hls.config.backBufferLength = 2;

    /*
    this defines how close to the edge of the live content we are
    the increments seem to usually be in 7 seconds (as defined by the EXT-X-TARGETDURATION properties), so we will have a delay of 21 seconds
    this should give us enough time to fill our buffer without stalling
    */
    this.hls.config.liveSyncDurationCount = 3;

    /*
    this is how far back we can get behind the live edge
    like liveSyncDurationCount its in increments of EXT-X-TARGETDURATION (about 7 seconds) making sure we don't fall 70 seconds behind
    this allows us to recover from stalling, if we stall for too long we'll jump ahead back to the live edge 
    */
    this.hls.config.liveMaxLatencyDurationCount = 10;

    if (AppData!.device.getPlatform() === Platform.VIZIO) {
      /*
      lowLatencyMode trades latency for stability but for Vizio we should prioritize stability
      */
      this.hls.config.lowLatencyMode = false;
    }
  }
}
