import { Colors, Lightning } from '@lightningjs/sdk';
import { STANDARD_FADE } from 'support/animations';
import { getLastChildLeaf } from 'support/generalUtils';

export interface ScrollableTemplateSpec
  extends Lightning.Component.TemplateSpec {
  content: any;
  backgroundColor: { focused?: number; unfocused?: number };
  backgroundAlpha: { focused?: number; unfocused?: number };
  scrollAmount: number;
  Background: Lightning.Component;
  Container: {
    Content: Lightning.Component;
    ScrollBar: Lightning.Component;
  };
}

const DEFAULT_VERTICAL_FADE = 0;

const DEFAULT_CONTENT_X_PADDING = 20;
const DEFAULT_CONTENT_Y_PADDING = 17;

const DEFAULT_SCROLL_BAR_WIDTH = 8;
const DEFAULT_SCROLL_BAR_HEIGHT = 31;
const DEFAULT_SCROLL_PADDING = 10;
const DEFAULT_SCROLL_AMOUNT = 120;

const INVISIBLE = 0;

const DEFAULT_TRANSITION_DURATION = 0.2;

export default class Scrollable
  extends Lightning.Component<ScrollableTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<ScrollableTemplateSpec>
{
  private _Container = this.getByRef('Container')!;
  private _Content = this._Container.getByRef('Content')!;
  private _ScrollBar = this._Container.getByRef('ScrollBar')!;
  private _Background = this.getByRef('Background')!;

  private _scrollAmount = DEFAULT_SCROLL_AMOUNT;
  private _backgroundColor: { focused: number; unfocused: number } = {
    focused: Colors('white').get(),
    unfocused: Colors('white').get(),
  };
  private _backgroundAlpha: { focused: number; unfocused: number } = {
    focused: INVISIBLE,
    unfocused: INVISIBLE,
  };

  private _contentMinY = DEFAULT_CONTENT_Y_PADDING;
  private _contentMaxY = DEFAULT_CONTENT_Y_PADDING;
  private _scrollBarMinY = DEFAULT_SCROLL_PADDING;
  private _scrollBarMaxY = DEFAULT_SCROLL_PADDING;
  private _scrollBarScrollAmount = 0;

  /**
   * sets the content for Scrollable and updates the Scrollbar
   * @param value Lightning.Component(s)
   *
   * The Lightning.Component(s) need to handle their own width
   * and wordWrapWidth as the content will not inherit the
   * width of the content container.
   *
   * Single text component height limit: 16370px;
   */
  set content(value: any) {
    this._Content.children = [];
    this._Content.patch(value);

    getLastChildLeaf(this._Content).on('txLoaded', () => {
      this.stage.update();
      this.updateScrollBar();
      this.updateScrollBarIndicator();
    });

    this._Content.children[0]?.on('txLoaded', () => {
      this.stage.update();
      this.updateScrollBar();
    });
  }

  set backgroundColor(value: { focused?: number; unfocused?: number }) {
    this._backgroundColor = {
      focused:
        value.focused ?? this._backgroundColor.focused ?? Colors('white').get(),
      unfocused:
        value.unfocused ??
        this._backgroundColor.unfocused ??
        Colors('white').get(),
    };
    this._Background.patch({
      smooth: { color: this._backgroundColor.unfocused },
    });
  }

  set backgroundAlpha(value: { focused?: number; unfocused?: number }) {
    this._backgroundAlpha = {
      focused: value.focused ?? this._backgroundAlpha.focused ?? INVISIBLE,
      unfocused:
        value.unfocused ?? this._backgroundAlpha.unfocused ?? INVISIBLE,
    };
    this._Background.patch({
      smooth: { color: this._backgroundAlpha.unfocused },
    });
  }

  set scrollAmount(value: number) {
    this._scrollAmount = value;
  }

  static override _template(): Lightning.Component.Template<ScrollableTemplateSpec> {
    return {
      Background: {
        w: (w: number) => w,
        h: (h: number) => h,
        rect: true,
        color: Colors('white').get(),
        alpha: INVISIBLE,
      },
      Container: {
        w: (w: number) => w,
        h: (h: number) => h,
        shader: {
          type: Lightning.shaders.FadeOut,
          top: DEFAULT_VERTICAL_FADE,
          bottom: DEFAULT_VERTICAL_FADE,
        },
        clipping: true,
        rtt: true,
        Content: {
          w: (w: number) => w - DEFAULT_CONTENT_X_PADDING * 2,
          x: DEFAULT_CONTENT_X_PADDING,
          y: DEFAULT_CONTENT_Y_PADDING,
          transitions: {
            y: {
              duration: DEFAULT_TRANSITION_DURATION,
              timingFunction: 'ease-in-out',
            },
          },
        },
        ScrollBar: {
          x: w => w - DEFAULT_SCROLL_BAR_WIDTH - DEFAULT_SCROLL_PADDING,
          y: DEFAULT_SCROLL_PADDING,
          w: DEFAULT_SCROLL_BAR_WIDTH,
          h: DEFAULT_SCROLL_BAR_HEIGHT,
          rect: true,
          transitions: {
            ...STANDARD_FADE,
            y: {
              duration: DEFAULT_TRANSITION_DURATION,
              timingFunction: 'ease-in-out',
            },
          },
          alpha: INVISIBLE,
          color: Colors('white').get(),
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 4,
          },
        },
      },
    };
  }

  override _focus() {
    this.updateScrollBarIndicator();
    if (this._Background.visible) {
      this._Background.patch({
        smooth: {
          color: this._backgroundColor.focused,
          alpha: this._backgroundAlpha.focused,
        },
      });
    }
  }

  override _unfocus() {
    if (this.isScrollable()) {
      this._ScrollBar.patch({
        smooth: {
          alpha: INVISIBLE,
        },
      });
    }
    if (this._Background.visible) {
      this._Background.patch({
        smooth: {
          color: this._backgroundColor.unfocused,
          alpha: this._backgroundAlpha.unfocused,
        },
      });
    }
  }

  override _handleUp() {
    if (!this.isScrollable()) return;

    let scrollY = this._ScrollBar.y - this._scrollBarScrollAmount;
    let contentY = this._Content.y + this._scrollAmount;

    if (scrollY < this._scrollBarMinY) {
      scrollY = this._scrollBarMinY;
    }

    if (contentY > this._contentMinY) {
      contentY = this._contentMinY;
    }

    if (this._Content.y === contentY && this._ScrollBar.y === scrollY)
      return false;
    this.animateMovement(scrollY, contentY);
  }

  override _handleDown() {
    if (!this.isScrollable()) return;

    let scrollY = this._ScrollBar.y + this._scrollBarScrollAmount;
    let contentY = this._Content.y - this._scrollAmount;

    if (scrollY > this._scrollBarMaxY) {
      scrollY = this._scrollBarMaxY;
    }

    if (contentY < this._contentMaxY) {
      contentY = this._contentMaxY;
    }

    if (this._Content.y === contentY && this._ScrollBar.y === scrollY)
      return false;
    this.animateMovement(scrollY, contentY);
  }

  private updateScrollBar() {
    // TODO: Investigate multiple large Text Components not rendering, causing
    // finalH to be inaccurate. (2 text component 8190px tall)
    if (this.isScrollable()) {
      const contentHeight = this.getContentHeight();

      // Amount of overflow hidden content, accounting for padding
      const contentTravelAmount =
        contentHeight - this.h + DEFAULT_CONTENT_Y_PADDING * 2;

      // Number of steps to reveal all of overflow hidden content
      const contentTravelSteps = contentTravelAmount / this._scrollAmount;

      // How much Scrollbar can move within the confines of Container
      const scrollBarTrackLength =
        this.h - DEFAULT_SCROLL_BAR_HEIGHT - DEFAULT_SCROLL_PADDING;

      // How many pixels ScrollBar can move per step
      this._scrollBarScrollAmount = scrollBarTrackLength / contentTravelSteps;

      // content origin Y
      this._contentMinY = DEFAULT_CONTENT_Y_PADDING;
      // Y to reveal the last of content
      this._contentMaxY = this._contentMinY - contentTravelAmount;
      // scrollBar origin Y
      this._scrollBarMinY = DEFAULT_SCROLL_PADDING;
      // amount of scrollBar travel of Y to reveal the last of content
      this._scrollBarMaxY = scrollBarTrackLength;
      return;
    }
  }

  private isScrollable() {
    return this.h <= this.getContentHeight();
  }

  private getContentHeight() {
    return Math.max(this._Content.finalH, this._Content.h);
  }

  private animateMovement(scrollBarY: number, contentY: number) {
    this._ScrollBar.patch({
      smooth: { y: scrollBarY },
    });
    this._Content.patch({
      smooth: { y: contentY },
    });
  }

  private updateScrollBarIndicator() {
    if (this.isScrollable()) {
      this._ScrollBar.patch({
        smooth: {
          alpha: 1,
        },
      });
    }
  }
}
