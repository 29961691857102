import { AppData, Lightning } from '@lightningjs/sdk';
import ChannelTile from 'components/common/tiles/ChannelTile';
import { ExpandableList } from 'components/common/ExpandableList';
import HorizontalFeaturedTile from 'components/common/tiles/HorizontalFeaturedTile';
import PortraitTile from 'components/common/tiles/PortraitTile';
import SizedRowList, {
  ListTypes,
  ROW_LIST_OFFSET_Y,
  SizedRowListTemplateSpec,
} from 'components/common/SizedRowList';
import VerticalFeaturedTile from 'components/common/tiles/VerticalFeaturedTile';
import ContinueTile from 'components/common/tiles/ContinueTile';
import VideoTile from 'components/common/tiles/VideoTile';
import { Lane, Swimlanes } from 'services/cwData';
import { ChannelImages, PlayableMediaImages } from 'types/api/media';
import constants from '../../../../static/constants.json';
import { IndexData, LightningBuilder } from 'types/lightning';
import {
  WatchHistoryRowList,
  WatchHistoryRowListTemplateSpec,
} from 'components/pages/content-hub/WatchHistoryRowList';
import { SwimlaneRow } from './SwimlaneRow';
import { CollectionVisibilityItem } from './ContentHubPage';
import {
  HoverableComponent,
  HoverableComponentSignalMap,
  HoverableComponentTypeConfig,
} from 'components/common/HoverableComponent';
import SquareContentHubTile from 'components/common/tiles/SquareContentHubTile';
import VerticalContentHubTile from 'components/common/tiles/VerticalContentHubTile';
import HorizontalContentHubTile from 'components/common/tiles/HorizontalContentHubTile';

const PAGE_PADDING_X = constants.ui.contentHubPagePaddingX;
const SWIMLANES_SPACING = 15;
export const SWIMLANES_HEIGHT = 1080;

const HIGHLIGHT_POSITION_OFFSET = constants.ui.highlightPositionOffset;

export interface CarouselSectionTemplateSpec
  extends Lightning.Component.TemplateSpec {
  data: Swimlanes | null;

  Wrapper: {
    Swimlanes: typeof ExpandableList;
  };
}

interface CarouselSectionSignalMap extends HoverableComponentSignalMap {
  scrollToPromo(): void;
  scrollToContent(options?: { index?: number; scrollTarget?: number }): void;
  firstLaneScroll(options?: {
    scrollTarget?: number;
    laneHeight?: number;
  }): void;
}

interface CarouselSectionTypeConfig extends HoverableComponentTypeConfig {
  SignalMapType: CarouselSectionSignalMap;
}

export default class CarouselSection
  extends HoverableComponent<
    CarouselSectionTemplateSpec,
    CarouselSectionTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<CarouselSectionTemplateSpec>
{
  private _data: CarouselSectionTemplateSpec['data'] = null;

  // Internal properties
  private _continueLaneIndex: number | undefined;
  private _continueLaneAdded = false;
  private _continueSizedRowList:
    | Lightning.Element.PatchTemplate<WatchHistoryRowListTemplateSpec>
    | undefined;
  private _promoIndices: number[] = [];

  private _Wrapper = this.getByRef('Wrapper')!;
  private _Swimlanes = this._Wrapper.getByRef('Swimlanes')!;

  get data() {
    return this._data;
  }

  set data(data: CarouselSectionTemplateSpec['data']) {
    this._data = data;

    this._continueLaneIndex = undefined;
    this._continueLaneAdded = false;
    this._continueSizedRowList = undefined;

    this.updateSwimlanes();
  }

  get index() {
    const mainIndex = this._Swimlanes.index;
    const crossIndex = this._Swimlanes.items[mainIndex]?.index;
    return { mainIndex, crossIndex };
  }

  setCrossIndex({
    mainIndex,
    crossIndex,
  }: {
    mainIndex: number | undefined;
    crossIndex: number | undefined;
  }) {
    if (mainIndex === undefined || crossIndex === undefined) return;

    const item = this._Swimlanes.items[mainIndex];
    if (item?._List) this._Swimlanes.items[mainIndex]._List.index = crossIndex;
  }

  get currentRow() {
    return this._Swimlanes.currentItem;
  }

  get scrollHeight() {
    return (
      SWIMLANES_HEIGHT * 0.75 +
      this._Swimlanes.itemWrappers[this._Swimlanes.length - 1].y
    );
  }

  get swimlaneWrapperY() {
    return this._Swimlanes.wrapper.y;
  }

  static override _template(): Lightning.Component.Template<CarouselSectionTemplateSpec> {
    return {
      w: 1920,
      h: 1080,
      Wrapper: {
        w: 1920 * 2,
        h: 1080,
        x: -1920 + HIGHLIGHT_POSITION_OFFSET, // No horizontal clipping required
        clipping: true,
        Swimlanes: {
          x: 1920 - HIGHLIGHT_POSITION_OFFSET,
          y: -HIGHLIGHT_POSITION_OFFSET,
          w: 1920,
          h: SWIMLANES_HEIGHT,
          type: ExpandableList,
          direction: 'column',
          spacing: SWIMLANES_SPACING,
          signals: {
            onIndexChanged: '_onIndexChanged',
            onListFocus: '_onListFocus',
          },
        },
      },
    };
  }

  override _setup() {
    super._setup();
    this._Swimlanes.scroll = this.handleSwimlaneScroll.bind(this);
  }

  override _active() {
    window.collectionVisibilityService.setModifyItems(
      this.modifyCollectionVisibilityItems.bind(this),
    );
  }

  override _getFocused() {
    return this._Swimlanes;
  }

  override _handleHover(target: any) {
    if (target === this._Wrapper || AppData?.device.hoverDisabled())
      return true;
    return super._handleHover(target);
  }

  override _handleUnhover(target: any) {
    if (target === this._Wrapper) return true;
    return super._handleUnhover(target);
  }

  private modifyCollectionVisibilityItems(
    items: CollectionVisibilityItem[],
  ): CollectionVisibilityItem[] {
    const promoIndicesClone = [...this._promoIndices];
    promoIndicesClone.sort();

    const isWatchHistoryEmpty = AppData?.historyService.isWatchHistoryEmpty();

    let promosSkipped = 0;
    return items.map(item => {
      const { mainIndex, ...rest } = item.obj;

      if (mainIndex === undefined) {
        return item;
      } else {
        if (mainIndex > (promoIndicesClone[0] ?? Infinity)) {
          promoIndicesClone.shift();
          promosSkipped += 1;
        }

        const emptyContinueLaneOffset =
          isWatchHistoryEmpty &&
          mainIndex > (this._continueLaneIndex ?? Infinity)
            ? 1
            : 0;

        return {
          obj: {
            ...rest,
            mainIndex: mainIndex - promosSkipped - emptyContinueLaneOffset,
          },
        };
      }
    });
  }

  private updateSwimlanes() {
    if (!this._data) return;

    const items: LightningBuilder<
      typeof SizedRowList | typeof WatchHistoryRowList,
      SizedRowListTemplateSpec
    >[] = [];

    this._data.lanes.forEach((lane, laneIndex) => {
      if (lane.laneType === 'promos') {
        // Skip promo lane (Handled by content hub page)
        this._promoIndices.push(laneIndex);
      } else if (lane.laneType === 'continue-watching') {
        // Continue sized row list is added to swimlanes later in lifecycle
        this._continueLaneIndex = items.length;

        this._continueSizedRowList = this.buildContinueWatchingRowList(
          lane as Lane,
          laneIndex,
        );
      } else {
        const rowList = this.setupSizedRowList(lane as Lane, laneIndex);
        if (rowList) items.push(rowList);
      }
    });

    this._Swimlanes.items = items;
  }

  private handleSwimlaneScroll(itemWrapper: any, indexData: IndexData) {
    const { index } = indexData;
    if (this._Swimlanes.isHovered) {
      this._Swimlanes.isHovered = false;
      return this._Swimlanes.lastY || 0;
    }
    const scrollTarget =
      -this._Swimlanes.scrollTransition.targetValue + SWIMLANES_HEIGHT;

    if (index === 0) {
      const firstLaneWrapper = this._Swimlanes.itemWrappers[0];
      this.signal('firstLaneScroll', {
        scrollTarget,
        laneHeight: firstLaneWrapper?._h,
      });
    } else {
      this.signal('scrollToContent', { index, scrollTarget });
    }
    this._Swimlanes.lastIndex = index;
    const listY = index < 2 ? 0 : this._Swimlanes.items[0].h - itemWrapper.y;
    this._Swimlanes.lastY = listY;
    return listY;
  }

  private setupSizedRowList(lane: Lane, laneIndex: number) {
    let listType: ListTypes;
    let imageKey: keyof PlayableMediaImages | keyof ChannelImages;
    let expandedImageKey: keyof PlayableMediaImages | undefined;

    if (lane.itemType === 'show' || lane.itemType === 'tivo-api') {
      if (lane.presentation === 'vertical-expanding') {
        listType = VerticalFeaturedTile;
        imageKey = 'image_vertical';
        expandedImageKey = 'image_vertical_featured';
      } else if (lane.presentation === 'horizontal-expanding') {
        listType = HorizontalFeaturedTile;
        imageKey = 'image_promo';
        expandedImageKey = 'image_show_featured';
      } else {
        listType = PortraitTile;
        imageKey = 'image_vertical';
      }
    } else if (lane.itemType === 'video') {
      listType = VideoTile;
      imageKey = 'thumbnail';
    } else if (lane.itemType === 'channel') {
      listType = ChannelTile;
      imageKey = 'homeThumbnail';
    } else if (lane.itemType === 'content-hub') {
      if (lane.presentation === 'square') {
        listType = SquareContentHubTile;
      } else if (lane.presentation === 'vertical') {
        listType = VerticalContentHubTile;
      } else {
        listType = HorizontalContentHubTile;
      }
      imageKey = 'thumbnail';
    } else {
      return null;
    }

    return this.buildSizedRowList(
      lane,
      laneIndex,
      // @ts-ignore
      listType,
      imageKey,
      expandedImageKey,
    );
  }

  private buildSizedRowList(
    lane: Lane,
    laneIndex: number,
    listType: ListTypes,
    imageKey: keyof PlayableMediaImages | keyof ChannelImages,
    expandedImageKey?: keyof PlayableMediaImages,
  ) {
    const items = lane.items;

    return {
      type: SwimlaneRow,
      listType: listType,
      w: 1920 - PAGE_PADDING_X - constants.sizing.navbar.collapsedWidth,
      h: listType.height + ROW_LIST_OFFSET_Y,
      label: lane.title,
      itemWidth: listType.width,
      expandedItemWidth: listType.expandedWidth,
      laneIndex,
      imageKey,
      expandedImageKey,
      laneItems: items,
    };
  }

  private buildContinueWatchingRowList(lane: Lane, laneIndex: number) {
    return {
      type: WatchHistoryRowList,
      listType: ContinueTile,
      w: 1920 - PAGE_PADDING_X - constants.sizing.navbar.collapsedWidth,
      h: ContinueTile.height + ROW_LIST_OFFSET_Y,
      label: lane.title,
      itemWidth: ContinueTile.width,
      expandedItemWidth: ContinueTile.expandedWidth,
      laneIndex, // this must be set before laneItems
      enableRequests: true,
      itemIndex: 0,
    };
  }

  async updateContinueLane() {
    const continueLaneIndex = this._continueLaneIndex;
    if (continueLaneIndex === undefined) return;

    const continueLaneItems =
      await AppData?.historyService.getContinueLaneItems(
        1,
        constants.watchHistory.pageSize,
      );

    if (continueLaneItems?.length) {
      if (!this._continueLaneAdded) {
        // Continue lane has not been added, add it to swimlanes
        this._Swimlanes.addAt(this._continueSizedRowList, continueLaneIndex);
        this._continueLaneAdded = true;
        // Set new indices after adding continue lane
        this._Swimlanes.setItemsIndex();
      }

      const continueLane = this._Swimlanes.items[continueLaneIndex];
      continueLane.laneItems = continueLaneItems;
      continueLane.enableRequests = true;
    } else if (this._continueLaneAdded) {
      this._Swimlanes.removeAt(continueLaneIndex);
      this._continueLaneAdded = false;
    }
  }
}
