import { Colors, Lightning } from '@lightningjs/sdk';
import Button from 'components/common/Button';
import { getImageTextureObj } from 'support/generalUtils';
import { translate } from 'support/translate';

interface FastPlayerControlsTemplateSpec
  extends Lightning.Component.TemplateSpec {
  CcAudio: typeof Button;
  BackToGuide: typeof Button;
}

interface FastPlayerControlsSignalMap extends Lightning.Component.SignalMap {
  ccAudio(): void;
  backToGuide(): void;
}

interface FastPlayerControlsTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: FastPlayerControlsSignalMap;
}

const CC_AUDIO_ICON_SIZE = 29;

const CC_AUDIO_ICON_FOCUSED = getImageTextureObj(
  'static/images/playback/audio-cc-icon-focused.svg',
  CC_AUDIO_ICON_SIZE,
  CC_AUDIO_ICON_SIZE,
);
const CC_AUDIO_ICON_UNFOCUSED = getImageTextureObj(
  'static/images/playback/audio-cc-icon-unfocused.svg',
  CC_AUDIO_ICON_SIZE,
  CC_AUDIO_ICON_SIZE,
);

const BACK_TO_GUIDE_ICON_WIDTH = 41;
const BACK_TO_GUIDE_ICON_HEIGHT = 29;

const BACK_TO_GUIDE_ICON_FOCUSED = getImageTextureObj(
  'static/images/epg/back-to-channel-guide-icon-focused.svg',
  BACK_TO_GUIDE_ICON_WIDTH,
  BACK_TO_GUIDE_ICON_HEIGHT,
);
const BACK_TO_GUIDE_ICON_UNFOCUSED = getImageTextureObj(
  'static/images/epg/back-to-channel-guide-icon-unfocused.svg',
  BACK_TO_GUIDE_ICON_WIDTH,
  BACK_TO_GUIDE_ICON_HEIGHT,
);

const CONTROL_W = 1580;
const CONTROL_H = 60;

const BUTTON_H = 60;
const BUTTON_FONT_SIZE = 23;
const BUTTON_RADIUS = 7;

const BUTTON_MARGIN = 13;
const BUTTON_PADDING_HORIZONTAL = 30;

export default class FastPlayerControls
  extends Lightning.Component<
    FastPlayerControlsTemplateSpec,
    FastPlayerControlsTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<FastPlayerControlsTemplateSpec>
{
  private _CcAudio = this.getByRef('CcAudio')!;
  private _BackToGuide = this.getByRef('BackToGuide')!;

  static override _template(): Lightning.Component.Template<FastPlayerControlsTemplateSpec> {
    return {
      w: CONTROL_W,
      h: CONTROL_H,
      flex: {
        direction: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },

      BackToGuide: {
        type: Button,
        label: translate('epg.backToGuide'),
        action: '$backToGuide',
        signals: { $backToGuide: true, $onHover: '$onHover' },
        height: BUTTON_H,
        fontSize: BUTTON_FONT_SIZE,
        radius: BUTTON_RADIUS,
        margin: BUTTON_MARGIN,
        paddingH: BUTTON_PADDING_HORIZONTAL,
        startIcon: {
          focused: BACK_TO_GUIDE_ICON_FOCUSED,
          unfocused: BACK_TO_GUIDE_ICON_UNFOCUSED,
        },
        backgroundColor: {
          unfocused: Colors('buttonInactive').alpha(0.8).get(),
        },
      },
      CcAudio: {
        type: Button,
        label: translate('playback.closedCaption'),
        action: '$ccAudio',
        signals: { $ccAudio: true, $onHover: '$onHover' },
        height: BUTTON_H,
        fontSize: BUTTON_FONT_SIZE,
        radius: BUTTON_RADIUS,
        margin: BUTTON_MARGIN,
        paddingH: BUTTON_PADDING_HORIZONTAL,
        startIcon: {
          focused: CC_AUDIO_ICON_FOCUSED,
          unfocused: CC_AUDIO_ICON_UNFOCUSED,
        },
        backgroundColor: {
          unfocused: Colors('buttonInactive').alpha(0.8).get(),
        },
      },
    };
  }

  override _active() {
    this._setState('BackToGuideButton');
  }

  override _inactive() {
    this._setState('');
  }

  private $ccAudio() {
    this.signal('ccAudio');
  }

  private $backToGuide() {
    this.signal('backToGuide');
  }

  private $onHover(target: any) {
    switch (target) {
      case this._BackToGuide:
        this._setState('BackToGuideButton');
        break;
      case this._CcAudio:
        this._setState('CcAudioMenu');
        break;
    }
  }

  static override _states() {
    return [
      class BackToGuideButton extends this {
        override _handleRight() {
          this._setState('CcAudioMenu');
        }

        override _getFocused() {
          return this._BackToGuide;
        }
      },
      class CcAudioMenu extends this {
        override _handleLeft() {
          this._setState('BackToGuideButton');
        }

        override _getFocused() {
          return this._CcAudio;
        }
      },
    ];
  }
}
