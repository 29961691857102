import { AxiosError } from 'axios';
import { ErrorType } from 'types/analytics';

class ApiServiceError extends Error {
  readonly isApiServiceError = true;
  override message: string;
  type: ErrorType;

  constructor(error: unknown, contentId?: string) {
    super();

    // this prevents duplicating error reporting when passing in an existing ApiServiceError
    if ((error as any)?.isServiceError) {
      this.message = (error as ApiServiceError).message;
      this.type = (error as ApiServiceError).type;
      return;
    }

    if (typeof error === 'string') {
      this.message = error;
      this.type = Object.values(ErrorType as object).includes(error)
        ? (error as ErrorType)
        : ErrorType.MISSING_CONTENT;
    } else {
      this.message = (error as AxiosError).message;
      this.type = this.getTypeFromAxiosError(error as AxiosError);
    }
  }

  private getTypeFromAxiosError(error: AxiosError): ErrorType {
    if (error.response === undefined) {
      return ErrorType.MISSING_CONTENT;
    } else if (error.response.status === 404) {
      return ErrorType.NOT_FOUND;
    }

    return ErrorType.MISSING_CONTENT;
  }
}

export default ApiServiceError;
