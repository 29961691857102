import { Registry } from '@lightningjs/sdk';

type TriggerEvent<T> = CustomEventInit<T>;

let MANAGER_ID = 0;

/**
 * Class creates and cleans up triggered promises
 * Triggered promises either resolve once their trigger function is called or reject if a timeout is exceeded
 */
export default class TriggeredPromiseManager {
  private readonly managerId: string;
  private readonly timeout: number;
  private readonly eventTarget = window as EventTarget;
  private eventNumber = 0;

  private events: Record<string, number> = {};

  constructor(timeout: number) {
    this.timeout = timeout;

    this.managerId = `${MANAGER_ID}`;
    MANAGER_ID += 1;
  }

  private newId() {
    const newId = `trigger_event_${this.managerId}_${this.eventNumber}`;

    this.eventNumber += 1;

    // this is an edge case that should never realistically occur
    if (this.eventNumber >= Number.MAX_SAFE_INTEGER) {
      this.eventNumber = 0;
    }

    return newId;
  }

  private removeEvent(
    id: string,
    fn: EventListenerOrEventListenerObject | null,
  ) {
    const timeout = this.events[id];
    this.eventTarget.removeEventListener(id, fn);
    Registry.clearTimeout(timeout);
    delete this.events[id];
  }

  create<T = unknown>() {
    const newId = this.newId();

    const promise = new Promise<T>((resolve, reject) => {
      const fn: EventListener = (event: TriggerEvent<T>) => {
        this.removeEvent(newId, fn);
        resolve(event.detail!);
      };

      // if we don't trigger the promise within the timeout we reject
      const timeout = Registry.setTimeout(() => {
        this.removeEvent(newId, fn);
        reject(`triggered promise timed out id:${newId}`);
      }, this.timeout);

      this.eventTarget.addEventListener(newId, fn);

      this.events[newId] = timeout;
    });

    return { id: newId, promise };
  }

  trigger(id: string, passedData?: unknown) {
    if (!(id in this.events)) return;

    const triggerEvent = new CustomEvent(id, {
      detail: passedData,
    });
    this.eventTarget.dispatchEvent(triggerEvent);
  }
}
