import { MediaIdentifier } from 'types/api/media';

export type TivoSearchResponse = {
  groups: TivoGroup[];
  hitCount: number;
};

export type TivoDetailsRecommendedResponse = {
  rows: TivoRow[];
};

export type TivoTrendingResponse = {
  rows: TivoTrendingRow[];
};

export type TivoRecommendedResponse = {
  rows: TivoRow[];
};

type TivoRow = {
  groups: TivoGroup[];
};

type TivoTrendingRow = {
  items: Hit[];
};

type TivoGroup = {
  hitCount: number;
  hits: Hit[];
};

type Hit = {
  id: string;
  metadata?: {
    slug: string;
  };
  objectType: 'Series' | 'Movie' | 'Episode';
  title: {
    en: string;
  };
};

export const parseMediaIdentifersFromSearchResponse = (
  data: TivoSearchResponse,
) => {
  const mediaIdentifiers: MediaIdentifier[] = [];
  data.groups.forEach(group => {
    group.hits.forEach(hit => {
      mediaIdentifiers.push(mediaIdentifier(hit));
    });
  });

  return mediaIdentifiers;
};

export const parseMediaIdentifersFromDetailsRecommended = (
  data: TivoDetailsRecommendedResponse,
) => {
  const mediaIdentifiers: MediaIdentifier[] = [];
  data.rows.forEach(row => {
    row.groups.forEach(group => {
      group.hits.forEach(hit => {
        mediaIdentifiers.push(mediaIdentifier(hit));
      });
    });
  });

  return mediaIdentifiers;
};

export const parseMediaIdentifiersFromTrending = (
  data: TivoTrendingResponse,
) => {
  const mediaIdentifiers: MediaIdentifier[] = [];
  data.rows.forEach(row => {
    row.items.forEach(hit => {
      mediaIdentifiers.push(mediaIdentifier(hit));
    });
  });

  return mediaIdentifiers;
};

export const parseMediaIdentifiersFromRecommended = (
  data: TivoRecommendedResponse,
) => {
  const mediaIdentifiers: MediaIdentifier[] = [];
  data.rows.forEach(row => {
    row.groups.forEach(group => {
      group.hits.forEach(hit => {
        mediaIdentifiers.push(mediaIdentifier(hit));
      });
    });
  });

  return mediaIdentifiers;
};

const mediaIdentifier = (hit: Hit): MediaIdentifier => {
  switch (hit.objectType) {
    case 'Episode':
      return {
        guid: hit.id,
        objectType: hit.objectType,
      };
    case 'Movie':
      return {
        guid: hit.id,
        slug: hit.metadata?.slug,
        objectType: hit.objectType,
      };
    case 'Series':
      return {
        slug: hit.metadata?.slug,
        objectType: hit.objectType,
      };
  }
};
