import { getFontFaceFromStyle } from './textUtils';
import constants from '../../static/constants.json';

export const STANDARD_BUTTON_SETTING = {
  padding: 15,
  paddingH: 30,
  height: 60,
  lineHeight: 30,
  fontFace: getFontFaceFromStyle('bold'),
  fontSize: 23,
  // (head.unitsPerEm − hhea.Ascender − hhea.Descender) / (2 × head.unitsPerEm)
  // fontBaselineRatio is too high. Negative the ratio to more closely align with spec.
  // For different fonts, use https://opentype.js.org/font-inspector.html for new values
  fontBaselineRatio:
    -(
      constants.font.robotoBold.unitsPerEm -
      constants.font.robotoBold.ascender -
      constants.font.robotoBold.descender
    ) /
    (2 * constants.font.robotoBold.unitsPerEm),
  fontColor: {
    focused: 'activeText',
    unfocused: 'text',
  },
  backgroundColor: {
    focused: 'highlight',
    unfocused: 'buttonInactive',
  },
  margin: 13,
  radius: 7,
};

export const LARGE_BUTTON_SETTING = {
  padding: 15,
  paddingH: 100,
  height: 70,
  lineHeight: 34,
  fontFace: getFontFaceFromStyle('bold'),
  fontSize: 29,
  fontBaselineRatio:
    -(
      constants.font.robotoBold.unitsPerEm -
      constants.font.robotoBold.ascender -
      constants.font.robotoBold.descender
    ) /
    (2 * constants.font.robotoBold.unitsPerEm),
  fontColor: {
    focused: 'activeText',
    unfocused: 'text',
  },
  backgroundColor: {
    focused: 'highlight',
    unfocused: 'buttonInactive',
  },
  margin: 13,
  radius: 7,
};
