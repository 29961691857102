import { Colors, Lightning } from '@lightningjs/sdk';
import Tile, { TileTemplateSpec } from './Tile';
import { Video, Episode } from 'types/api/media';
import { getFontFaceFromStyle } from 'support/textUtils';
import {
  isMovie,
  getSeasonEpisodeFormatted,
  isMediaExpiring,
  getExpiringDateString,
  isVideo,
} from 'support/contentUtils';
import MediaProgressBar from '../progressBar/MediaProgressBar';
import { STANDARD_FADE } from 'support/animations';
import { SelectItemContext } from 'types/events';
import { getImageTextureObj } from 'support/generalUtils';
import constants from '../../../../static/constants.json';
import Badge from '../Badge';
import { translate } from 'support/translate';
import { StaticViewContexts } from 'types/analytics';
import {
  forceImageNoBadge,
  updateImageSize,
} from '../../../support/cwImageUtils';

export interface ContinueTileTemplateSpec extends TileTemplateSpec {
  data: Episode | Video;

  ShowThumbnail: Lightning.Texture;
  PlayButton: Lightning.Texture;
  Badge: typeof Badge;
  ProgressBar: typeof MediaProgressBar;
  TextContainer: {
    SeasonEpisode: Lightning.Texture;
    Title: Lightning.Texture;
  };
}

const TILE_WIDTH = 414;
const TILE_HEIGHT = 313;

const TILE_HIGHLIGHT_WIDTH = TILE_WIDTH;
const TILE_HIGHLIGHT_HEIGHT = 240;

const BADGE_PADDING = 5;

const PROGRESS_BAR_WIDTH = MediaProgressBar.defaultWidth;
const PROGRESS_BAR_HEIGHT = MediaProgressBar.defaultHeight;

const PROGRESS_BAR_X = (TILE_WIDTH - PROGRESS_BAR_WIDTH) / 2;
const PROGRESS_BAR_Y = TILE_HIGHLIGHT_HEIGHT - PROGRESS_BAR_HEIGHT - 10;

const TEXT_CONTAINER_Y = TILE_HIGHLIGHT_HEIGHT + 15;
const TEXT_FONT_SIZE = 23;
const TEXT_MAX_LINES = 1;

const PLAY_BUTTON_WIDTH = 34;
const PLAY_BUTTON_HEIGHT = 43;

export default class ContinueTile
  extends Tile<ContinueTileTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<ContinueTileTemplateSpec>
{
  private _ShowThumbnail = this.getByRef('ShowThumbnail')!;
  private _PlayButton = this.getByRef('PlayButton')!;
  private _Badge = this.getByRef('Badge')!;
  private _ProgressBar = this.getByRef('ProgressBar')!;
  private _TextContainer = this.getByRef('TextContainer')!;
  private _SeasonEpisode = this._TextContainer.getByRef('SeasonEpisode')!;
  private _Title = this._TextContainer.getByRef('Title')!;

  override get title() {
    if (!this._data) return '';

    return [
      this._Title.text?.text ?? '', // Show title
      this._SeasonEpisode.text?.text ?? '', // Season # episode #
      isMovie(this._data) ? '' : this._data.title ?? '', // Episode title
      this._ProgressBar.title ?? '', // Progress percent
    ];
  }

  override set data(data: ContinueTileTemplateSpec['data']) {
    super.data = data;

    const showThumbnail = updateImageSize(
      data.images.image_show_thumbnail,
      TILE_WIDTH,
    );
    const src = forceImageNoBadge(showThumbnail);

    const { seriesName } = data;
    this._ShowThumbnail.patch({ src });
    this._Title.patch({ text: { text: seriesName } });
    this._ProgressBar.patch({ mediaItem: data });

    const visible = this._ProgressBar.progressPercent > 0;
    this._ProgressBar.patch({ visible });

    if (isVideo(data)) {
      const video = data as Video;
      const isNew = video.isNew;
      const expiring = isMediaExpiring(video);

      if (isNew) {
        this._Badge.label = translate('episode.new');
      } else if (expiring) {
        this._Badge.label = getExpiringDateString(video);
      }
    }

    if (!isMovie(data)) {
      const seasonEpisode = getSeasonEpisodeFormatted(data);
      this._SeasonEpisode.patch({ text: { text: seasonEpisode } });
    }
  }

  override get action(): SelectItemContext['action'] {
    return 'play';
  }

  static override get width() {
    return TILE_WIDTH;
  }

  static override get height() {
    return TILE_HEIGHT;
  }

  static override get highlightWidth() {
    return TILE_HIGHLIGHT_WIDTH;
  }

  static override get highlightHeight() {
    return TILE_HIGHLIGHT_HEIGHT;
  }

  static override _template(): Lightning.Component.Template<ContinueTileTemplateSpec> {
    const playButtonTexture = getImageTextureObj(
      'static/images/playback/player-play-icon.svg',
      PLAY_BUTTON_WIDTH,
      PLAY_BUTTON_HEIGHT,
    );

    return {
      ...super._template(),
      ShowThumbnail: {
        alpha: 1,
        zIndex: 2,
        w: this.highlightWidth,
        h: this.highlightHeight,
        transitions: STANDARD_FADE,
      },
      PlayButton: {
        alpha: constants.ui.invisible,
        mount: 0.5,
        x: TILE_HIGHLIGHT_WIDTH / 2,
        y: TILE_HIGHLIGHT_HEIGHT / 2,
        ...playButtonTexture,
      },
      Badge: {
        zIndex: 4,
        type: Badge,
        mountX: 1,
        x: w => w - BADGE_PADDING,
        y: BADGE_PADDING,
      },
      ProgressBar: {
        type: MediaProgressBar,
        zIndex: 3,
        x: PROGRESS_BAR_X,
        y: PROGRESS_BAR_Y,
      },
      TextContainer: {
        y: TEXT_CONTAINER_Y,
        flex: { direction: 'column' },
        SeasonEpisode: {
          text: {
            fontSize: TEXT_FONT_SIZE,
            textColor: Colors('text').get(),
          },
        },
        Title: {
          w: TILE_WIDTH,
          text: {
            fontFace: getFontFaceFromStyle('bold'),
            fontSize: TEXT_FONT_SIZE,
            maxLines: TEXT_MAX_LINES,
            textColor: Colors('text').get(),
          },
        },
      },
    };
  }

  override _active() {
    super._active();

    this.updateImageUrl();
    this.updatePlayButton();
  }

  override _focus() {
    this.updateImageUrl();
    this.updatePlayButton();
  }

  override _unfocus() {
    this.updateImageUrl();
    this.updatePlayButton();
  }

  private updateImageUrl() {
    const showThumbnailAlpha = this.hasFocus() ? constants.ui.invisible : 1;
    this._ShowThumbnail.setSmooth('alpha', showThumbnailAlpha);
  }

  private updatePlayButton() {
    const playButtonAlpha = this.hasFocus() ? 0.6 : constants.ui.invisible;
    this._PlayButton.setSmooth('alpha', playButtonAlpha);
  }

  override _handleEnter() {
    this.fireAncestors('$onTileSelected', this._data, {
      action: this.action,
      imageUrl: this._Image.src,
      // Continue watching tiles are only available in home page
      viewContext: StaticViewContexts.HOME_CONTINUE_WATCHING,
    });
  }
}
