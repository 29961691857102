import { Carousel, Grid, List } from '@lightningjs/ui';

const setItemsIndexHelper = (items: unknown) => {
  if (Array.isArray(items)) {
    items.forEach((item, index) => {
      if (typeof item === 'object' && item) {
        item.itemIndex = index;
      }
    });
  }
};

export class ListWithIndexing extends List {
  get items() {
    return super.items;
  }

  set items(items: any) {
    setItemsIndexHelper(items);
    super.items = items;
  }

  override add(item: any) {
    const addedItem = super.add(item);
    setItemsIndexHelper(this.items);
    return addedItem;
  }

  setItemsIndex() {
    setItemsIndexHelper(this.items);
  }
}

export class CarouselWithIndexing extends Carousel {
  get items() {
    return super.items;
  }

  set items(items: any) {
    setItemsIndexHelper(items);
    super.items = items;
  }

  override add(item: any) {
    const addedItem = super.add(item);
    setItemsIndexHelper(this.items);
    return addedItem;
  }
}

export class GridWithIndexing extends Grid {
  get items() {
    return super.items;
  }

  set items(items: any) {
    setItemsIndexHelper(items);
    super.items = items;
  }

  override add(item: any) {
    const addedItem = super.add(item);
    setItemsIndexHelper(this.items);
    return addedItem;
  }
}
