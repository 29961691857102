import { Platform } from 'models/platforms/platform';
import { CcSettings } from './deviceIntegration';
import { Router, Storage } from '@lightningjs/sdk';
import { DeviceInfo } from 'models/platforms/deviceInfo';
import { DefaultKeyMapping } from 'models/platforms/defaultKeyMappings';
import { Manufacturer } from 'models/platforms/manufacturer';
import {
  getHomeHistoryEntry,
  getLiveHistoryEntry,
  getLivePlaybackHistoryEntry,
  navigateHome,
} from 'support/routerUtils';
import { RoutePath } from 'config/routes';

export abstract class AbstractDeviceIntegration {
  protected abstract readonly platform: Platform;
  protected deviceId = '';
  protected isLat: 0 | 1 = 0;

  protected deviceInfo: DeviceInfo = {
    manufacturer: Manufacturer.UNKNOWN,
    model: '',
    osVersion: '',
    adId: '',
    isLat: 0,
    ifaType: '',
    ip: '',
  };

  protected _ccSettings: Partial<CcSettings> = {};

  abstract getDeviceId(): string;

  abstract getPerformanceMode(): boolean;

  abstract getAppId(): string;

  abstract load(): Promise<void>;

  abstract beforeAppClose(callback: (showPopup: boolean) => void): void;

  abstract closeApp(): void;

  abstract handleDeepLink(): boolean;

  abstract handleNetworkChange(callback: (arg: boolean) => void): void;

  abstract setScreenSaver(state: boolean): void;

  abstract getAnnouncerEnabled(): boolean;

  abstract handleAnnouncerChange(callback: (arg: boolean) => void): void;

  getPlatform() {
    return this.platform;
  }

  getKeyMapping(): Record<number, string> {
    return DefaultKeyMapping;
  }

  async getDeviceInfo(): Promise<DeviceInfo> {
    return this.deviceInfo;
  }

  getCcSettings(): Partial<CcSettings> {
    return this._ccSettings;
  }

  setLocalStorage<T>(key: string, value: T) {
    Storage.set(key, JSON.stringify(value));
  }

  getLocalStorage<T>(key: string) {
    return JSON.parse(Storage.get(key)) as T | null;
  }

  getLocalStorageRaw<T>(key: string) {
    return Storage.get(key) as T | null;
  }

  removeLocalStorage(key: string) {
    Storage.remove(key);
  }

  setSessionStorage<T>(key: string, value: T) {
    const valueAsString = JSON.stringify(value);
    if (!sessionStorage) {
      console.warn('Session Storage Not Available, using local storage');
      Storage.set(key, valueAsString);
    } else {
      sessionStorage.setItem(key, valueAsString);
    }
  }

  getSessionStorage<T>(key: string) {
    let result: string | null;
    if (!sessionStorage) {
      console.warn('Session Storage Not Available, using local storage');
      result = Storage.get(key);
    } else {
      result = sessionStorage.getItem(key);
    }

    if (result === null) return null;

    return JSON.parse(result) as T;
  }

  removeSessionStorage(key: string) {
    if (!sessionStorage) {
      console.warn('Session Storage Not Available, using local storage');
      Storage.remove(key);
    } else {
      sessionStorage.removeItem(key);
    }
  }

  getIsLat(): 0 | 1 {
    return this.isLat;
  }

  hoverDisabled(): boolean {
    return false; // if hovering is possible, we won't prevent it by default
  }

  /**
   * Handles deep link URL. Navigates `Home` if the URL cannot be parsed.
   * @param url URL in some form of `[http|https]://[DOMAIN]/#[RoutePath]/[...params]`
   */
  protected handleDeepLinkURL(url: string) {
    try {
      // Path will be the `[RoutePath]/[...params]` portion of the URL
      let path: string;

      try {
        path = new URL(url).hash;
      } catch (e) {
        // Assume the URL is the path if parsing URL fails
        path = url;
      }

      // Remove `#` from path if present
      if (path.startsWith('#')) path = path.slice(1);

      const routePath = Object.values(RoutePath).find(routePath =>
        path.startsWith(routePath),
      );

      if (!routePath) {
        navigateHome();
      } else if (
        routePath === RoutePath.PLAY &&
        path.startsWith(`${RoutePath.PLAY}/live`)
      ) {
        const splitHash = path.split('/');

        let id: string | undefined;
        // If the length of the hash is three, assume the last param is the ID
        if (splitHash.length === 3) id = splitHash.pop();

        Router.setHistory([
          getHomeHistoryEntry(),
          getLiveHistoryEntry(),
          getLivePlaybackHistoryEntry(id),
        ]);
        Router.back();
      } else {
        Router.navigate(path);
      }
    } catch (e) {
      // Navigate home if we're not able to parse and use the URL
      navigateHome();
    }
  }
}
