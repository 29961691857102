import { AppData } from '@lightningjs/sdk';
import { updatePrivacyStatus } from './analytics/reportingServicePage';

export type Privacy = {
  privacyViewed: boolean; // viewed privacy setting
  optOut: boolean; // opt out to not selling
};

type Ccpa = `1${'-' | 'Y' | 'N'}${'-' | 'Y' | 'N'}${'-' | 'Y' | 'N'}`;

enum CcpaStatus {
  OFF = '1YNN', // has not viewed (privacyViewed false)
  OFF_VIEWED = '1YNN', // navigating to the screen with the toggle does not provide sufficient notice per our legal counsel, so the string is not any different from OFF
  ON = '1YYN', // Viewed and Not Sell Data (privacyViewed true, optOut true)
}

enum rdpStatus {
  OFF = 0, // has not viewed (privacyViewed false)
  OFF_VIEWED = 0, // Viewed and Sell Data (privacyViewed true, optOut false/null)
  ON = 1, // Viewed and Not Sell Data (privacyViewed true, optOut true)
}
const PRIVACY_DEFAULT = {
  privacyViewed: true, // defaults to true
  optOut: false,
};

export default class PrivacyService {
  private privacySetting: Privacy | undefined;

  private lazyInit() {
    if (this.privacySetting) return;

    this.setDefault();
  }

  private updatePrivacySettings() {
    if (this.privacySetting !== undefined) {
      AppData!.storageService.privacy.set(this.privacySetting);
    }
  }

  setDefault() {
    const privacySetting = AppData!.storageService.privacy.get();
    this.privacySetting = privacySetting ?? PRIVACY_DEFAULT;
  }

  /** Updates analytic's privacy status on app start */
  initPrivacyStatus() {
    this.lazyInit();

    const optOut = this.privacySetting!.optOut;
    updatePrivacyStatus(!optOut);
  }

  setDoNotSell(optOut: boolean) {
    this.lazyInit();

    this.privacySetting!.optOut = optOut;

    updatePrivacyStatus(!optOut);
    this.updatePrivacySettings();
  }

  getDoNotSell(): boolean {
    this.lazyInit();

    return !!AppData?.device.getIsLat() || !!this.privacySetting!.optOut;
  }

  // The DNS settings in the app, it's independent of the device opt-out setting
  getAppDoNotSell(): boolean {
    this.lazyInit();

    return !!this.privacySetting!.optOut;
  }

  // GoogleIMA: Restricted Data Processing
  // https://developers.google.com/interactive-media-ads/docs/sdks/html5/dai/ccpa
  getRdpTag(): 0 | 1 {
    this.lazyInit();

    return !!AppData?.device.getIsLat() || !!this.privacySetting!.optOut
      ? rdpStatus.ON
      : rdpStatus.OFF_VIEWED;
  }

  // GoogleIMA: IAB Signal
  // https://developers.google.com/interactive-media-ads/docs/sdks/html5/dai/ccpa
  getIabUsPrivacy(): Ccpa {
    this.lazyInit();

    return this.privacySetting!.optOut ? CcpaStatus.ON : CcpaStatus.OFF_VIEWED;
  }
}
