import { Lightning, Colors, Registry, Router, AppData } from '@lightningjs/sdk';
import { formatVideoTime, getVideoTimeTts } from 'support/contentUtils';
import { translate } from 'support/translate';
import { StateEvent } from 'types/lightning';
import { AdCuePoint } from 'components/pages/playback/AdManager';
import { debounce, getImageTextureObj } from 'support/generalUtils';
import { constants } from 'aliases';
import Button from 'components/common/Button';
import FocusableIcon from 'components/common/FocusableIcon';
import ProgressBar from 'components/pages/playback/ProgressBar';
import {
  HoverableComponent,
  HoverableComponentSignalMap,
} from 'components/common/HoverableComponent';
import { Platform } from 'models/platforms/platform';

const PLAY_ICON = 'static/images/playback/player-play-icon.svg';
const PAUSE_ICON = 'static/images/playback/player-pause-icon.svg';
const RE_10S_ICON = 'static/images/playback/re-10s-icon.svg';
const FF_10S_ICON = 'static/images/playback/ff-10s-icon.svg';
const START_OVER_ICON = 'static/images/playback/start-over-icon.svg';
const CC_AUDIO_ICON_FOCUSED =
  'static/images/playback/audio-cc-icon-focused.svg';
const CC_AUDIO_ICON_UNFOCUSED =
  'static/images/playback/audio-cc-icon-unfocused.svg';

const FOCUSED_ALPHA = 1;
const UNFOCUSED_ALPHA = 0.5;

const PROGRESS_BAR_WIDTH = constants.ui.progressBarWidth;
const PLAY_PAUSE_X = PROGRESS_BAR_WIDTH / 2;
const ICON_Y = 132;

const ANNOUNCE_DELAY = 500;
const ANNOUNCE_SEEKING_INTERVAL = 5000;

const DEFAULT_SEEK_RATE = 1;
const DEFAULT_SEEK_SPEED = 0;
const SKIP_INTERVAL = 10;
const SEEK_RATES = [10, 20, 40, 60, 120];
const SCRUB_RATE = 60;
const SCRUB_DELAY = 1000;

const BUTTON_H = 60;
const BUTTON_FONT_SIZE = 23;
const BUTTON_RADIUS = 7;

const BUTTON_MARGIN = 13;
const BUTTON_PADDING_HORIZONTAL = 30;

const START_OVER_ICON_UNFOCUSED = getImageTextureObj(START_OVER_ICON, 28, 30);

const START_OVER_ICON_FOCUSED = {
  ...getImageTextureObj(START_OVER_ICON, 28, 30),
  shader: { type: Lightning.shaders.Inversion, amount: 1 },
};

interface VodControlsTemplateSpec extends Lightning.Component.TemplateSpec {
  duration: number;
  progress: number;
  seekRate: number;
  seekSpeed: number;
  finalSeekPosition: number | null;
  isAdPlaying: boolean;
  adCuePoints: AdCuePoint[];
  onAnnounceStarted?: () => void;
  onAnnounceEnded?: () => void;

  ProgressBar: typeof ProgressBar;
  StartOver: typeof Button;
  SkipBackward: typeof FocusableIcon;
  PlayPauseIcon: typeof FocusableIcon;
  SkipForward: typeof FocusableIcon;
  CcAudio: typeof Button;
}

interface VodControlsSignalMap extends HoverableComponentSignalMap {
  skip(increment: number): void;
  seek(position: number): void;
  seekPause(): void;
  pause(): void;
  play(): void;
  seeking(): void;
  startOver(): void;
  ccAudio(): void;
  onControlHover(): void;
  announceProgressBar(phrase: string): void;
}

interface VodControlsTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: VodControlsSignalMap;
}

export default class VodControls
  extends HoverableComponent<VodControlsTemplateSpec, VodControlsTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<VodControlsTemplateSpec>
{
  private _duration = 0;
  private _progress = 0;
  private _seekRate = DEFAULT_SEEK_RATE;
  private _seekSpeed = DEFAULT_SEEK_SPEED;
  private _finalSeekPosition: VodControlsTemplateSpec['finalSeekPosition'] =
    null;
  private _isVideoPlaying = false;
  private _isAdPlaying = false;
  private _adCuePoints: VodControlsTemplateSpec['adCuePoints'] = [];

  private _seekInterval: number | null = null;
  private _seekAnnounceInterval: number | null = null;
  private _savedState = '';

  private _ProgressBar = this.getByRef('ProgressBar')!;
  private _StartOver = this.getByRef('StartOver')!;
  private _SkipBackward = this.getByRef('SkipBackward')!;
  private _PlayPauseIcon = this.getByRef('PlayPauseIcon')!;
  private _SkipForward = this.getByRef('SkipForward')!;
  private _CcAudio = this.getByRef('CcAudio')!;

  private announceTts = debounce(
    this.handleAnnounce.bind(this),
    ANNOUNCE_DELAY,
  );

  get progress() {
    return this._progress;
  }

  set progress(progress: VodControlsTemplateSpec['progress']) {
    this._progress = progress;
    this._ProgressBar.progress = this.finalSeekPosition ?? this.progress;
  }

  get duration() {
    return this._duration;
  }

  set duration(duration: VodControlsTemplateSpec['duration']) {
    this._duration = duration;
    this._ProgressBar.duration = duration;
    this._ProgressBar.progress = this.finalSeekPosition ?? this.progress;
  }

  get seekRate() {
    return this._seekRate;
  }

  set seekRate(seekRate: VodControlsTemplateSpec['seekRate']) {
    this._seekRate = seekRate;
    this._ProgressBar.seekRate = seekRate;
  }

  get seekSpeed() {
    return this._seekSpeed;
  }

  set seekSpeed(seekSpeed: VodControlsTemplateSpec['seekSpeed']) {
    this._seekSpeed = seekSpeed;
    this._ProgressBar.seekSpeed = seekSpeed;
    this._ProgressBar.progress = this.finalSeekPosition ?? this.progress;
  }

  get finalSeekPosition() {
    return this._finalSeekPosition;
  }

  set finalSeekPosition(
    finalSeekPosition: VodControlsTemplateSpec['finalSeekPosition'],
  ) {
    this._finalSeekPosition = finalSeekPosition;
    if (finalSeekPosition === null) return;

    this._ProgressBar.progress = finalSeekPosition;
  }

  get isAdPlaying() {
    return this._isAdPlaying;
  }

  set isAdPlaying(isAdPlaying: VodControlsTemplateSpec['isAdPlaying']) {
    this._isAdPlaying = isAdPlaying;
    this.shouldShowAdControls(isAdPlaying);
    if (isAdPlaying) this._setState('PlayPause');
  }

  get adCuePoints() {
    return this._adCuePoints;
  }

  set adCuePoints(adCuePoints: VodControlsTemplateSpec['adCuePoints']) {
    this._adCuePoints = adCuePoints;
    this._ProgressBar.adCuePoints = adCuePoints;
  }

  static override _template(): Lightning.Component.Template<VodControlsTemplateSpec> {
    return {
      w: PROGRESS_BAR_WIDTH,
      ProgressBar: {
        type: ProgressBar,
        signals: {
          $onHover: '$onHover',
          $onCursorClick: '$onCursorClick',
        },
        passSignals: {
          announceProgressBar: true,
        },
      },
      StartOver: {
        type: Button,
        action: '$startOver',
        signals: { $startOver: true, $onHover: '$onHover' },
        backgroundColor: {
          unfocused: Colors('buttonInactive').alpha(0.8).get(),
        },
        fontSize: BUTTON_FONT_SIZE,
        height: BUTTON_H,
        label: translate('playback.startOver'),
        margin: BUTTON_MARGIN,
        mountY: 0.5,
        paddingH: BUTTON_PADDING_HORIZONTAL,
        radius: BUTTON_RADIUS,
        startIcon: {
          focused: START_OVER_ICON_FOCUSED,
          unfocused: START_OVER_ICON_UNFOCUSED,
        },
        y: ICON_Y,
      },
      SkipBackward: {
        type: FocusableIcon,
        title: translate('ttsPrompts.skipBackwards'),
        mount: 0.5,
        x: PLAY_PAUSE_X - 165,
        y: ICON_Y,
        w: 64,
        h: 64,
        icon: RE_10S_ICON,
        action: '$skipPlayback',
        signals: { $skipPlayback: '$skipPlayback', $onHover: '$onHover' },
      },
      PlayPauseIcon: {
        type: FocusableIcon,
        title: translate('ttsPrompts.playButton'),
        mount: 0.5,
        x: PLAY_PAUSE_X,
        y: ICON_Y,
        w: 49,
        h: 61,
        icon: PLAY_ICON,
        action: '$handlePlayPause',
        signals: { $handlePlayPause: '$handlePlayPause', $onHover: '$onHover' },
      },
      SkipForward: {
        type: FocusableIcon,
        title: translate('ttsPrompts.skipForwards'),
        mount: 0.5,
        x: PLAY_PAUSE_X + 165,
        y: ICON_Y,
        w: 64,
        h: 64,
        icon: FF_10S_ICON,
        action: '$skipPlayback',
        signals: { $skipPlayback: '$skipPlayback', $onHover: '$onHover' },
      },
      CcAudio: {
        type: Button,
        action: '$ccAudio',
        signals: { $ccAudio: true, $onHover: '$onHover' },
        backgroundColor: {
          unfocused: Colors('buttonInactive').alpha(0.8).get(),
        },
        fontSize: BUTTON_FONT_SIZE,
        height: BUTTON_H,
        label: translate('playback.closedCaption'),
        margin: BUTTON_MARGIN,
        mountX: 1,
        mountY: 0.5,
        paddingH: BUTTON_PADDING_HORIZONTAL,
        radius: BUTTON_RADIUS,

        startIcon: {
          focused: CC_AUDIO_ICON_FOCUSED,
          unfocused: CC_AUDIO_ICON_UNFOCUSED,
        },
        y: ICON_Y,
        x: PROGRESS_BAR_WIDTH,
      },
    };
  }

  override _setup() {
    this._setState('PlayPause');
  }

  override _disable() {
    this._setState('PlayPause');
  }

  override _focus() {
    this._setState(this._savedState);

    if (this._isVideoPlaying) {
      this._PlayPauseIcon.title = translate('ttsPrompts.pauseButton');
    } else {
      this._PlayPauseIcon.title = translate('ttsPrompts.playButton');
    }
  }

  override _unfocus() {
    this._setState('');

    this._PlayPauseIcon.title = '';
  }

  onRewind() {
    if (this._isAdPlaying) return;
    this._setState('ProgressBar', ['rewind']);
  }

  onFastForward() {
    if (this._isAdPlaying) return;
    this._setState('ProgressBar', ['fastforward']);
  }

  override _handleMediaStop() {
    Router.back();
  }

  private saveState() {
    this._savedState = this._getState();
  }

  private shouldShowAdControls(show: boolean) {
    this._StartOver.visible = !show;
    this._SkipBackward.visible = !show;
    this._SkipForward.visible = !show;
    this._CcAudio.visible = !show;
    show
      ? this._ProgressBar.hideAdPodMarkers()
      : this._ProgressBar.showAdPodMarkers();
  }

  private handleAnnounce(phrase: string) {
    this.fireAncestors('$announce', phrase, { append: true });
  }

  private announceSeeking() {
    this.announceTts(
      translate(
        `ttsPrompts.${this.seekRate > 0 ? 'fastForward' : 'rewind'}`,
        Math.abs(this.seekSpeed).toString(),
      ),
    );
  }

  private $handlePlayPause() {
    this._PlayPauseIcon.title = '';

    if (this._isVideoPlaying) {
      this.announceTts(translate('ttsPrompts.paused'));
      this.signal('pause');
    } else {
      const progress = this.progress;
      const timestamp = getVideoTimeTts(formatVideoTime(progress));

      this.announceTts(translate('ttsPrompts.playing', timestamp));
      this.signal('play');
    }
  }

  private $startOver() {
    this.signal('startOver');
  }

  private $ccAudio() {
    this.signal('ccAudio');
  }

  private $skipPlayback() {
    switch (this._getState()) {
      case 'SkipBackward':
        this.signal('skip', -SKIP_INTERVAL);
        break;
      case 'SkipForward':
        this.signal('skip', SKIP_INTERVAL);
        break;
    }
  }

  private $onHover(target: Lightning.Component) {
    switch (target) {
      case this._ProgressBar:
        this._setState('ProgressBar');
        break;
      case this._StartOver:
        this._setState('StartOver');
        break;
      case this._PlayPauseIcon:
        this._setState('PlayPause');
        break;
      case this._SkipBackward:
        this._setState('SkipBackward');
        break;
      case this._SkipForward:
        this._setState('SkipForward');
        break;
      case this._CcAudio:
        this._setState('CcAudio');
        break;
    }
    // Reset overlay idle timer on hover
    this.signal('onControlHover');
  }

  updateFromPause() {
    this._PlayPauseIcon.w = 49;
    this._PlayPauseIcon.h = 61;
    this._PlayPauseIcon.icon = PLAY_ICON;
    this._isVideoPlaying = false;
  }

  updateFromPlay() {
    this._PlayPauseIcon.w = 39;
    this._PlayPauseIcon.h = 60;
    this._PlayPauseIcon.icon = PAUSE_ICON;
    this._isVideoPlaying = true;
  }

  isSeeking() {
    return this.seekRate !== 1;
  }

  static override _states() {
    return [
      class ProgressBar extends this {
        private hasScrubbed = false;
        private seekRatePressed = false;
        private prevState = '';
        private initialPlayPauseState:
          | keyof Pick<VodControlsSignalMap, 'play' | 'pause'>
          | null = null;
        private scrubPosition: null | number = null;

        private finishScrubTimeout: null | (() => void) = null;

        override $enter(event: StateEvent, interactionEvent?: string) {
          this.saveState();
          this.hasScrubbed = false;
          if (this._isAdPlaying) {
            this._setState('PlayPause'); // prevent entering state when ad is playing
            return;
          }

          this.prevState = event.prevState;

          this.finishScrubTimeout = debounce(
            this.finishSeeking.bind(this),
            SCRUB_DELAY,
          );

          if (interactionEvent === 'rewind') {
            this.handleRewind();
          } else if (interactionEvent === 'fastforward') {
            this.handleFastForward();
          }
        }

        override $exit() {
          this.cleanup();
        }

        $onCursorClick(
          target: Lightning.Component,
          localCoords: { x: number; y: number },
        ) {
          // If progressBar is clicked, seek to target progress.
          if (target === this._ProgressBar.playbackProgressBar) {
            const targetPoint =
              localCoords.x / this._ProgressBar.playbackProgressBarWidth;
            const seekTarget = targetPoint * this.duration;
            this.signal('seek', seekTarget);

            // Reset overlay idle timer on hover after seeking.
            this.signal('onControlHover');
          }
        }

        override _getFocused() {
          return this._ProgressBar;
        }

        override _inactive() {
          this._setState('PlayPause');
          this.cleanup();
        }

        override _handleDown() {
          if (this.isSeeking()) this._handleEnter();
          this._setState(this.prevState || 'PlayPause');
        }

        override _handleLeft() {
          this.scrub('backwards');
        }

        override _handleRight() {
          this.scrub('forwards');
        }

        override _handleEnter() {
          if (!this._isVideoPlaying) {
            this.finishSeeking();
          }
          this.$handlePlayPause();
        }

        override _handleBack() {
          if (!this.isSeeking()) return false;
          this._handleEnter();
        }

        override onRewind() {
          this.handleRewind();
        }

        override onFastForward() {
          this.handleFastForward();
        }

        override _handleMediaPlay() {
          if (this.isSeeking()) this.finishSeeking('play');
          else this.signal('play');

          this._setState('PlayPause');
        }

        override _handleMediaPause() {
          if (this.isSeeking()) this.finishSeeking('pause');
          else this.signal('pause');

          this._setState('PlayPause');
        }

        override _handleMediaRewindRelease() {
          this.seekRatePressed = false;
        }

        override _handleMediaFastForwardRelease() {
          this.seekRatePressed = false;
        }

        private handleRewind() {
          if (this.seekRatePressed || this._isAdPlaying) return;
          this.seekRatePressed = true;

          this.startSeekSpeed('rewind');
        }

        private handleFastForward() {
          if (this.seekRatePressed || this._isAdPlaying) return;
          this.seekRatePressed = true;

          this.startSeekSpeed('fastForward');
        }

        private scrubAnnounce(position: number) {
          const time = getVideoTimeTts(formatVideoTime(position));
          this.announceTts(time);
        }

        private scrub(direction: 'forwards' | 'backwards') {
          if (!this.initialPlayPauseState) {
            this.initialPlayPauseState = this._isVideoPlaying
              ? 'play'
              : 'pause';
          }
          this.signal('seekPause');

          const scrubIncrement =
            direction === 'forwards' ? SCRUB_RATE : -SCRUB_RATE;

          const nextScrubPosition =
            (this.scrubPosition ?? this.progress) + scrubIncrement;

          if (nextScrubPosition < 0) {
            this.scrubPosition = 0;
          } else if (nextScrubPosition >= this.duration) {
            this.scrubPosition = this.duration;
          } else {
            this.scrubPosition = nextScrubPosition;
          }

          // cancel previous TTS before announcing scrub positions (cancels progress bar focus announce)
          if (!this.hasScrubbed) {
            this.fireAncestors('$announcerCancel');
            this.hasScrubbed = true;
          }
          this.scrubAnnounce(this.scrubPosition);

          this.finishScrubTimeout?.();
          this.finalSeekPosition = this.scrubPosition;
        }

        private startSeekSpeed(direction: 'fastForward' | 'rewind') {
          const forward = direction === 'fastForward';
          let newSeekRate = DEFAULT_SEEK_RATE,
            newSeekSpeed = DEFAULT_SEEK_SPEED;

          // Stop seeking if user loops through all the seek rates (assumes SEEK_RATES is not empty)
          if (
            (forward && this.seekRate >= SEEK_RATES[SEEK_RATES.length - 1]!) ||
            (!forward && this.seekRate <= -SEEK_RATES[SEEK_RATES.length - 1]!)
          ) {
            return this.finishSeeking();
          }

          if (!this.initialPlayPauseState) {
            this.initialPlayPauseState = this._isVideoPlaying
              ? 'play'
              : 'pause';
          }
          this.signal('pause');
          if (this._seekInterval) Registry.clearInterval(this._seekInterval);
          if (this._seekAnnounceInterval) {
            Registry.clearInterval(this._seekAnnounceInterval);
            this._seekAnnounceInterval = null;
          }

          // Seek rate will increase based on the current seek rate
          // (ie. 10 -> 20 -> 30)
          for (let i = 0; i < SEEK_RATES.length; i++) {
            newSeekRate = forward ? SEEK_RATES[i]! : -SEEK_RATES[i]!;
            newSeekSpeed = i + 1; // start at displaying 1x speed

            if (forward) {
              if (this.seekRate < SEEK_RATES[i]!) break;
            } else {
              if (this.seekRate > -SEEK_RATES[i]!) break;
            }
          }

          this.seekRate = newSeekRate;
          this.seekSpeed = newSeekSpeed;
          this._seekInterval = Registry.setInterval(
            this.onIncrementSeek.bind(this),
            1000,
          );

          // Exclude seeking announcement for Vizio as it doesn't support announceEnded event
          if (AppData!.device.getPlatform() !== Platform.VIZIO) {
            // Wait for current announcements (progressBar + initial announceSeeking),
            // then set up 5 seconds interval
            this.announceSeeking();
            this.stage.once<any>('announceEnded', () => {
              if (!this._seekAnnounceInterval) {
                this._seekAnnounceInterval = Registry.setInterval(() => {
                  this.announceSeeking();
                }, ANNOUNCE_SEEKING_INTERVAL);
              }
            });
          }
        }

        private onIncrementSeek() {
          this.signal('seeking');

          if (this.finalSeekPosition === null) {
            this.finalSeekPosition = this.progress;
          }
          this.finalSeekPosition += this.seekRate;

          // Play video once user reaches either the start or end of video while seeking
          if (
            this.finalSeekPosition < 0 ||
            this.finalSeekPosition >= this.duration
          ) {
            this.finishSeeking();
            this.signal('play');
          }
        }

        private cleanup() {
          this.seekRatePressed = false;
          this.seekRate = DEFAULT_SEEK_RATE;
          this.seekSpeed = DEFAULT_SEEK_SPEED;
          this.finalSeekPosition = null;
          this.initialPlayPauseState = null;
          this.scrubPosition = null;
          this._ProgressBar.progress = this.progress;

          if (this._seekInterval) Registry.clearInterval(this._seekInterval);
          if (this._seekAnnounceInterval)
            Registry.clearInterval(this._seekAnnounceInterval);
        }

        private finishSeeking(forceState?: 'play' | 'pause') {
          if (this.finalSeekPosition !== null) {
            this.signal('seek', this.finalSeekPosition);
          }
          if (forceState) {
            this.signal(forceState);
          } else if (this.initialPlayPauseState) {
            this.signal(this.initialPlayPauseState);
          }
          this.cleanup();
        }
      },

      class StartOver extends this {
        override $enter() {
          this.saveState();
        }

        override _getFocused() {
          return this._StartOver;
        }

        override _handleUp() {
          this._setState('ProgressBar');
        }

        override _handleRight() {
          this._setState('SkipBackward');
        }
      },

      class SkipBackward extends this {
        override $enter() {
          this.saveState();
          this._SkipBackward.smooth = { alpha: FOCUSED_ALPHA };
        }

        override $exit() {
          this._SkipBackward.smooth = { alpha: UNFOCUSED_ALPHA };
        }

        override _getFocused() {
          return this._SkipBackward;
        }

        override _handleUp() {
          this._setState('ProgressBar');
        }

        override _handleLeft() {
          this._setState('StartOver');
        }

        override _handleRight() {
          this._setState('PlayPause');
        }
      },

      class PlayPause extends this {
        override $enter() {
          this.saveState();
          this._PlayPauseIcon.smooth = { alpha: FOCUSED_ALPHA };
        }

        override $exit() {
          this._PlayPauseIcon.smooth = { alpha: UNFOCUSED_ALPHA };
        }

        override _getFocused() {
          return this._PlayPauseIcon;
        }

        override _handleUp() {
          if (this._isAdPlaying) return;
          this._setState('ProgressBar');
        }

        override _handleLeft() {
          if (this._isAdPlaying) return;
          this._setState('SkipBackward');
        }

        override _handleRight() {
          if (this._isAdPlaying) return;
          this._setState('SkipForward');
        }
      },

      class SkipForward extends this {
        override $enter() {
          this.saveState();
          this._SkipForward.smooth = { alpha: FOCUSED_ALPHA };
        }

        override $exit() {
          this._SkipForward.smooth = { alpha: UNFOCUSED_ALPHA };
        }

        override _getFocused() {
          return this._SkipForward;
        }

        override _handleUp() {
          this._setState('ProgressBar');
        }

        override _handleLeft() {
          this._setState('PlayPause');
        }

        override _handleRight() {
          this._setState('CcAudio');
        }
      },
      class CcAudio extends this {
        override $enter() {
          this.saveState();
        }

        override _getFocused() {
          return this._CcAudio;
        }

        override _handleUp() {
          this._setState('ProgressBar');
        }

        override _handleLeft() {
          this._setState('SkipForward');
        }
      },
    ];
  }
}
