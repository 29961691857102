import { Colors, Lightning } from '@lightningjs/sdk';
import Tile, { TileTemplateSpec } from './Tile';
import { Show, Video } from 'types/api/media';
import { getFontFaceFromStyle, withSeparator } from 'support/textUtils';
import RatingBadge from '../RatingBadge';
import {
  getDurationHourMinutesNoSpaceFormatted,
  getSeasonEpisodeFormatted,
  isMovie,
  isSeries,
  isShow,
  isVideo,
} from 'support/contentUtils';
import { translate } from 'support/translate';
import { SEARCH_TILE_FADE } from 'support/animations';
import { getImageTextureObj } from 'support/generalUtils';

const TILE_WIDTH = 365;
const TILE_HEIGHT = 326;
const TILE_HIGHLIGHT_WIDTH = 365;
const TILE_HIGHLIGHT_HEIGHT = 209;

const TILE_INFO_OFFSET_Y = 10;

const TILE_INFO_TEXT_STYLE: Lightning.textures.TextTexture.Settings = {
  maxLines: 1,
  wordWrapWidth: TILE_WIDTH,
};

const SERIES_GENRES_LIMIT = 3;

export interface SearchTileTemplateSpec extends TileTemplateSpec {
  data: Show | Video;

  FocusedOverlay: {
    OverlayLabelContainer: {
      GoToIcon: Lightning.textures.ImageTexture;
      SelectLabel: Lightning.textures.TextTexture;
    };
  };
  MediaInfo: {
    MediaType: Lightning.textures.TextTexture;
    Title: Lightning.textures.TextTexture;
    Metadata: {
      Rating: typeof RatingBadge;
      Others: Lightning.textures.TextTexture;
    };
  };
}

export default class SearchTile
  extends Tile<SearchTileTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<SearchTileTemplateSpec>
{
  private _FocusedOverlay = this.getByRef('FocusedOverlay')!;

  private _OverlayLabelContainer = this._FocusedOverlay.getByRef(
    'OverlayLabelContainer',
  )!;
  private _GoToIcon = this._OverlayLabelContainer.getByRef('GoToIcon')!;
  private _SelectLabel = this._OverlayLabelContainer.getByRef('SelectLabel')!;

  private _MediaInfo = this.getByRef('MediaInfo')!;

  private _MediaType = this._MediaInfo.getByRef('MediaType')!;
  private _Title = this._MediaInfo.getByRef('Title')!;
  private _Metadata = this._MediaInfo.getByRef('Metadata')!;

  private _Rating = this._Metadata.getByRef('Rating')!;
  private _Others = this._Metadata.getByRef('Others')!;

  static override get width() {
    return TILE_WIDTH;
  }

  static override get height() {
    return TILE_HEIGHT;
  }

  static override get highlightWidth() {
    return TILE_HIGHLIGHT_WIDTH;
  }

  static override get highlightHeight() {
    return TILE_HIGHLIGHT_HEIGHT;
  }

  override get title() {
    return [
      this._MediaType.text?.text ?? '',
      this._data?.title ?? '',
      this._Rating.text?.text ?? '',
      this._Others.text?.text ?? '',
      this._SelectLabel.text?.text ?? '',
    ];
  }

  override get action() {
    if (!this._data) return super.action;

    return isShow(this._data) ? 'details' : 'play';
  }

  override set data(data: SearchTileTemplateSpec['data']) {
    super.data = data;

    this.updateOverlayLabel();
    this.updateMediaInfo();
  }

  static override _template(): Lightning.Component.Template<SearchTileTemplateSpec> {
    return {
      ...super._template(),
      FocusedOverlay: {
        w: TILE_WIDTH,
        h: TILE_HIGHLIGHT_HEIGHT,
        rect: true,
        transitions: SEARCH_TILE_FADE,
        color: Colors('background').alpha(0.8).get(),
        alpha: 0,
        OverlayLabelContainer: {
          x: w => w / 2,
          y: h => h / 2,
          mount: 0.5,
          flex: {
            direction: 'row',
            alignItems: 'center',
          },
          GoToIcon: {
            y: -3,
            ...getImageTextureObj(
              'static/images/arrow-right-light.svg',
              26,
              22,
            ),
            flexItem: { marginRight: 13 },
          },
          SelectLabel: {
            text: {
              fontSize: 21,
              fontFace: getFontFaceFromStyle('bold'),
              ...TILE_INFO_TEXT_STYLE,
            },
          },
        },
      },
      MediaInfo: {
        y: TILE_HIGHLIGHT_HEIGHT + TILE_INFO_OFFSET_Y,
        flex: { direction: 'column' },
        MediaType: {
          color: Colors('text').get(),
          text: {
            fontSize: 21,
            fontFace: getFontFaceFromStyle('regular'),
            ...TILE_INFO_TEXT_STYLE,
          },
        },
        Title: {
          color: Colors('text').get(),
          text: {
            fontSize: 28,
            fontFace: getFontFaceFromStyle('bold'),
            ...TILE_INFO_TEXT_STYLE,
          },
        },
        Metadata: {
          flex: { direction: 'row' },
          Rating: {
            type: RatingBadge,
            size: 'small',
            flexItem: { marginRight: 7 },
            badgeColor: Colors('text').get(),
          },
          Others: {
            color: Colors('text').get(),
            text: {
              fontSize: 21,
              fontFace: getFontFaceFromStyle('regular'),
              ...TILE_INFO_TEXT_STYLE,
            },
          },
        },
      },
    };
  }

  override _focus() {
    this._FocusedOverlay.setSmooth('alpha', 1);
  }

  override _unfocus() {
    this._FocusedOverlay.setSmooth('alpha', 0);
  }

  private updateOverlayLabel() {
    if (isVideo(this._data)) {
      this._SelectLabel.patch({
        text: { text: translate('search.goToEpisode') },
      });
    } else if (isSeries(this._data)) {
      this._SelectLabel.patch({
        text: { text: translate('search.goToSeries') },
      });
    } else if (isMovie(this._data)) {
      this._SelectLabel.patch({
        text: { text: translate('search.goToMovie') },
      });
    }
  }

  private updateMediaInfo() {
    this._Title.patch({ text: { text: this._data.title } });
    this._Rating.rating = this._data.rating;

    if (isShow(this._data)) {
      const show = this._data as Show;
      const isMediaSeries = isSeries(this._data);
      const mediaType = isMediaSeries
        ? translate('search.series')
        : translate('search.movie');
      const otherMetadata = isMediaSeries
        ? this.getSeriesMetadataText(show)
        : this.getMovieMetadataText(show);

      this._MediaType.patch({ text: { text: mediaType } });
      this._Others.patch({ text: { text: otherMetadata } });
    } else if (isVideo(this._data)) {
      const video = this._data as Video;

      this._MediaType.patch({
        text: {
          text: `${getSeasonEpisodeFormatted(video, ' ')} ${video.seriesName}`,
        },
      });
      this._Others.patch({
        text: { text: this.getEpisodeMetadataText(video) },
      });
    } else {
      // TODO: handle channels
    }
  }

  private getMovieMetadataText(movie: Show) {
    return withSeparator(
      movie.releaseYear,
      getDurationHourMinutesNoSpaceFormatted(movie),
      movie.genres[0] ?? '',
    );
  }

  private getSeriesMetadataText(series: Show) {
    return series.genres.slice(0, SERIES_GENRES_LIMIT).join(', ');
  }

  private getEpisodeMetadataText(episode: Video) {
    return withSeparator(
      getDurationHourMinutesNoSpaceFormatted(episode),
      episode.genre ?? '',
    );
  }
}
