import { AppData, Lightning } from '@lightningjs/sdk';
import PortraitTile from 'components/common/tiles/PortraitTile';
import SizedRowList, {
  ROW_LIST_OFFSET_Y,
} from 'components/common/SizedRowList';
import { Show } from 'types/api/media';

const HORIZONTAL_PADDING = 96;
const TOP_MARGIN = 24;

export interface RecommendedSectionPageSpec
  extends Lightning.Component.TemplateSpec {
  recommended: Show[];
  RecommendedList: typeof SizedRowList;
}

export default class RecommendedSection
  extends Lightning.Component<RecommendedSectionPageSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<RecommendedSectionPageSpec>
{
  private _recommended: Show[] = [];

  private _RecommendedList = this.getByRef('RecommendedList')!;

  get recommended() {
    return this._recommended;
  }

  set recommended(recommended: Show[]) {
    this._recommended = recommended;

    this._RecommendedList.visible = !!recommended.length;
    this._RecommendedList.items = recommended.map(this.buildTile);
    this._RecommendedList.label =
      AppData?.tivoEndpoints?.details_recommended_v2.title;
  }

  static override _template(): Lightning.Component.Template<RecommendedSectionPageSpec> {
    return {
      RecommendedList: {
        type: SizedRowList,
        x: HORIZONTAL_PADDING,
        y: TOP_MARGIN,
        w: 1920 - 2 * HORIZONTAL_PADDING,
        h: PortraitTile.height + ROW_LIST_OFFSET_Y,
        // @ts-ignore
        listType: PortraitTile,
        itemWidth: PortraitTile.width,
        passSignals: {
          $onHover: '$onRecommendedHover',
        },
      },
    };
  }

  override _getFocused() {
    return this._RecommendedList;
  }

  private buildTile(show: Show) {
    return {
      imageUrl: show.images.image_vertical,
      data: show,
    };
  }
}
