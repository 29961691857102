import { InputField as BaseInputField } from '@lightningjs/ui';

export default class InputField extends BaseInputField {
  private _PreLabel = this.getByRef('PreLabel')!;
  private _Cursor = this.getByRef('Cursor')!;
  private _PostLabel = this.getByRef('PostLabel')!;

  /**
   * Reusing most of the original function. Added tweaks to when the description is used as
   * well as how the X positions are set when there is no pre-text
   */
  _update(index = 0) {
    const input = this._input;
    const hasInput = input.length > 0;

    // Only use description when cursor is not visible
    let pre = this.cursorVisible ? '' : this._description;
    let post = '';

    if (hasInput) {
      pre = input.substring(0, index);
      post = input.substring(index, input.length);

      if (this._passwordMode) {
        pre = this._passwordMask.repeat(pre.length);
        post = this._passwordMask.repeat(post.length);
      }
    }

    this.patch({
      PreLabel: { text: { text: pre } },
      PostLabel: { text: { text: post } },
    });

    if (this.h === 0) {
      this._PreLabel.loadTexture();
      this.h = this._PreLabel.renderHeight;
    }

    // _labelTxLoaded() won't be correct when pre is empty since it will use the previous
    // rendered width to calculate X positions. It also won't run when pre string is empty
    if (!pre) {
      this._Cursor.x = this._cursorX;
      this._PostLabel.x =
        this._cursorX + this._Cursor.w * (1 - this._Cursor.mountX);
    }

    this._cursorIndex = index;
  }

  override _unfocus() {
    // Update on unfocused so the description is updated if cursor is visible
    this._update(this._cursorIndex);
  }

  override _focus() {
    // Update on focused so the cursor blinks correctly if there is input
    this._update(this._cursorIndex);
  }

  // Updated handle left/right functions. They are triggered when the user:
  // 1. Presses physical remote left/right key (Keyboard event is provided)
  // 2. Presses virtual keyboard's left/right key (No keyboard event is provided)
  override _handleLeft(e?: KeyboardEvent) {
    // Keyboard event is only provided when it's a physical left key press
    if (e !== undefined) return false;

    const previousIndex = this._cursorIndex;
    const nextIndex = Math.max(0, previousIndex - 1);
    this._update(nextIndex);
  }

  override _handleRight(e?: KeyboardEvent) {
    // Keyboard event is only provided when it's a physical right key press
    if (e !== undefined) return false;

    const previousIndex = this._cursorIndex;
    const nextIndex = Math.min(this._input.length, previousIndex + 1);
    this._update(nextIndex);
  }
}
