import { Lightning, AppData, Metadata, Colors } from '@lightningjs/sdk';
import { translate } from 'support/translate';

export interface SupportSectionTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Background: Lightning.Texture;
  Content: {
    SupportSection: {
      SupportTitle: Lightning.Texture;
      SupportDescription: Lightning.Texture;
    };
    AppInfoSection: {
      AppInfoTitle: Lightning.Texture;
      AppInfoDescription: Lightning.Texture;
    };
  };
}

const BACKGROUND_WIDTH = 808;

const CONTENT_X = 20;
const CONTENT_PADDING_Y = 16;

const TITLE_FONT_SIZE = 32;

const DESCRIPTION_MARGIN_TOP = 29;
const DESCRIPTION_HEIGHT = 82;
const DESCRIPTION_FONT_SIZE = 20;
const DESCRIPTION_LINE_HEIGHT = 30;

export default class SupportSection
  extends Lightning.Component<SupportSectionTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<SupportSectionTemplateSpec>
{
  private _Background = this.getByRef('Background')!;
  private _Content = this.getByRef('Content')!;
  private _SupportSection = this._Content.getByRef('SupportSection')!;
  private _SupportTitle = this._SupportSection.getByRef('SupportTitle')!;
  private _SupportDescription =
    this._SupportSection.getByRef('SupportDescription')!;

  private _AppInfoSection = this._Content.getByRef('AppInfoSection')!;
  private _AppInfoTitle = this._AppInfoSection.getByRef('AppInfoTitle')!;
  private _AppInfoDescription =
    this._AppInfoSection.getByRef('AppInfoDescription')!;

  get title() {
    const supportTitle = this._SupportTitle.text?.text;
    const supportDescription = this._SupportDescription.text?.text;
    const appInfoTitle = this._AppInfoTitle.text?.text;
    const appInfoDescription = this._AppInfoDescription.text?.text;

    return [supportTitle, supportDescription, appInfoTitle, appInfoDescription];
  }

  static override _template(): Lightning.Component.Template<SupportSectionTemplateSpec> {
    return {
      Background: {
        alpha: 0,
        rect: true,
        w: BACKGROUND_WIDTH,
        color: Colors('highlight').get(),
      },
      Content: {
        x: CONTENT_X,
        y: CONTENT_PADDING_Y,
        flex: { direction: 'column' },
        SupportSection: {
          flex: { direction: 'column' },
          SupportTitle: {
            text: {
              text: translate('settings.Support.support'),
              fontSize: TITLE_FONT_SIZE,
            },
          },
          SupportDescription: {
            flexItem: { marginTop: DESCRIPTION_MARGIN_TOP },
            h: DESCRIPTION_HEIGHT,
            text: {
              text: translate('settings.Support.supportDescription'),
              fontSize: DESCRIPTION_FONT_SIZE,
              lineHeight: DESCRIPTION_LINE_HEIGHT,
            },
          },
        },
        AppInfoSection: {
          flex: { direction: 'column' },
          AppInfoTitle: {
            text: {
              text: translate('settings.Support.appInfo'),
              fontSize: TITLE_FONT_SIZE,
            },
          },
          AppInfoDescription: {
            flexItem: { marginTop: DESCRIPTION_MARGIN_TOP },
            h: DESCRIPTION_HEIGHT,
            text: {
              fontSize: DESCRIPTION_FONT_SIZE,
              lineHeight: DESCRIPTION_LINE_HEIGHT,
              advancedRenderer: true,
            },
          },
        },
      },
    };
  }

  override async _active() {
    const deviceInfoPromise = AppData?.device.getDeviceInfo();

    const osVersion = deviceInfoPromise
      ? (await deviceInfoPromise).osVersion
      : 'Unknown';
    const appVersion = Metadata.appVersion() ?? 'Unknown';
    const appInfoDescription = translate(
      'settings.Support.appInfoDescription',
      osVersion,
      appVersion,
    );
    this._AppInfoDescription.patch({ text: { text: appInfoDescription } });

    // Wait for description texture to finish loading before updating background height
    this._AppInfoDescription.on('txLoaded', () => {
      this.stage.update();
      this._Background.patch({ h: this._Content.finalH + CONTENT_PADDING_Y });
    });
  }

  override _inactive() {
    this._AppInfoDescription.removeAllListeners('txLoaded');
  }

  override _focus() {
    this._Background.setSmooth('alpha', 0.15);
  }

  override _unfocus() {
    this._Background.setSmooth('alpha', 0);
  }
}
