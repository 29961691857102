import { AppData } from '@lightningjs/sdk';
import Lightning from '@lightningjs/sdk/src/Lightning';
import { Platform } from 'models/platforms/platform';

const ENTER_STRING = 'Enter';

export type LocalCoordsFromClick = {
  x: number;
  y: number;
};

export async function dispatchEnter() {
  const keyCodeMap = AppData?.device.getKeyMapping();

  if (!keyCodeMap) return;

  const keyCode = Number(
    Object.keys(keyCodeMap).find(
      key => keyCodeMap[Number(key)] === ENTER_STRING,
    ),
  );

  const deviceInfo = await AppData?.device.getDeviceInfo();
  const osVersion = deviceInfo?.osVersion
    ? parseInt(deviceInfo.osVersion)
    : undefined;

  if (
    AppData?.device.getPlatform() === Platform.LG &&
    osVersion !== undefined &&
    osVersion <= 4
  ) {
    const enterKeydownEvent = document.createEvent('KeyboardEvent');

    enterKeydownEvent.initKeyboardEvent(
      'keydown', // typeArg
      true, // bubblesArg
      true, // cancelableArg
      window, // viewArg
      ENTER_STRING, // keyArg
      0, // locationArg
      false, // ctrlKey
      false, // altKey
      false, // shiftKey
      false, // metaKey
    );

    Object.defineProperty(enterKeydownEvent, 'keyCode', { value: keyCode });
    Object.defineProperty(enterKeydownEvent, 'which', { value: keyCode });

    document.dispatchEvent(enterKeydownEvent);
  } else {
    const enterKeyEvent = new KeyboardEvent('keydown', {
      key: ENTER_STRING,
      code: ENTER_STRING,
      keyCode: keyCode,
      which: keyCode,
      bubbles: true,
      cancelable: true,
    });

    document.dispatchEvent(enterKeyEvent);
  }
}

export function appendCollision(element: Lightning.Element) {
  if (!element.collision) element.collision = true;
  if (
    element.ref !== 'App' &&
    element.parent &&
    (!element.parent.collision ||
      (typeof element.parent.collision === 'number' &&
        element.parent.collision === 2))
  ) {
    appendCollision(element.parent);
  }
}

export interface HoverableComponentSignalMap
  extends Lightning.Component.SignalMap {
  $onHover(target: unknown): void;
  $onUnHover(target: unknown): void;
  $onCursorClick(target: unknown, localCoords: LocalCoordsFromClick): void;
}

export interface HoverableComponentTypeConfig
  extends Lightning.Component.TypeConfigLoose {
  SignalMapType: HoverableComponentSignalMap;
}

export class HoverableComponent<
  TemplateSpec extends Lightning.Component.TemplateSpec = Lightning.Component.TemplateSpec,
  TypeConfig extends Lightning.Component.TypeConfig &
    HoverableComponentTypeConfig = Lightning.Component.TypeConfig &
    HoverableComponentTypeConfig,
> extends Lightning.Component<TemplateSpec, TypeConfig> {
  private hovered = false;

  override _setup() {
    super._setup();
    appendCollision(this);
  }

  _handleClick(target: any, localCoords: LocalCoordsFromClick) {
    (this as HoverableComponent).signal('$onCursorClick', target, localCoords);
    if (!this.hovered) return;

    // fire off promise without waiting
    dispatchEnter();
  }

  _handleHover(target: any) {
    if (AppData?.device.hoverDisabled()) {
      return true;
    }

    this.hovered = true;
    (this as HoverableComponent).signal('$onHover', target);

    return false;
  }

  _handleUnhover(target: any) {
    if (AppData?.device.hoverDisabled()) {
      return true;
    }

    this.hovered = false;
    (this as HoverableComponent).signal('$onUnhover', target);

    return false;
  }
}
