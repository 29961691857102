import { Lightning } from '@lightningjs/sdk';
import { getImageTextureObj } from 'support/generalUtils';

const SPINNER_SIZE = 50;
const SPINNER_TEXTURE = getImageTextureObj(
  'static/images/loading_indicator.svg',
  SPINNER_SIZE,
  SPINNER_SIZE,
);

export interface LoadingSpinnerTemplateSpec
  extends Lightning.Component.TemplateSpec {
  show: boolean;
  isLoadingScreen: boolean;
}

export default class LoadingSpinner
  extends Lightning.Component<LoadingSpinnerTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<LoadingSpinnerTemplateSpec>
{
  private _animation: Lightning.types.Animation | null = null;
  private _isLoadingScreen = false;

  set show(value: boolean) {
    if (this._animation) {
      value ? this._animation.start() : this._animation.stop();
    }

    this.patch({
      visible: value,
    });
  }

  get isLoadingScreen() {
    return this._isLoadingScreen;
  }

  set isLoadingScreen(isLoadingScreen: boolean) {
    this._isLoadingScreen = isLoadingScreen;
  }

  static override _template(): Lightning.Component.Template<LoadingSpinnerTemplateSpec> {
    return {
      mount: 0.5,
      rect: true,
      ...SPINNER_TEXTURE,
    };
  }

  override _enable() {
    if (this.isLoadingScreen) this.application.emit('loadingScreen', true);

    if (this._animation) return;

    this._animation = this.animation({
      duration: 1,
      repeat: -1,
      stopMethod: 'immediate',
      actions: [
        {
          p: 'rotation',
          v: { sm: 0, 0: 0, 1: 2 * Math.PI },
        },
      ],
    });
    this._animation.start();
  }

  override _disable() {
    if (this.isLoadingScreen) this.application.emit('loadingScreen', false);
  }
}
