import { AppData } from '@lightningjs/sdk';
import mParticle from '@mparticle/web-sdk';

export const createMParticleProduct = (args: {
  item_id: string;
  item_name: string;
  item_brand?: string;
  item_list_name?: string;
  item_category?: number;
  item_variant?: string;
  customProductAttributes?: mParticle.SDKEventAttrs;
}): mParticle.Product => {
  const {
    item_id,
    item_name,
    item_brand,
    item_list_name,
    item_category,
    item_variant,
    customProductAttributes,
  } = args;

  const Source_Site = getSourceSite();

  const product: mParticle.Product = mParticle.eCommerce.createProduct(
    item_name, // name
    item_id, // sku
    0, // price
    1, // quantity
    item_variant, // variant
    item_list_name, // category
    item_brand, // brand
    item_category, // position
    Source_Site, // coupon
    customProductAttributes, // attributes
  );

  return product;
};

export const createMParticlePromotion = (args: {
  id: string;
  creative?: string;
  name?: string;
  position?: number;
}): mParticle.Promotion => {
  const { id, creative, name, position } = args;

  const promotion = mParticle.eCommerce.createPromotion(
    id,
    creative,
    name,
    position,
  );

  return promotion;
};

export const getSourceSite = () => {
  return AppData!.device.getPlatform() ?? '';
};
