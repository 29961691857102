import { Colors, Lightning } from '@lightningjs/sdk';
import { getSeasonEpisodeFormatted, isSeries } from 'support/contentUtils';
import { getFontFaceFromStyle } from 'support/textUtils';
import { Episode, Live, Video } from 'types/api/media';

const TITLE_FONT_SIZE = 39;
const METADATA_FONT_SIZE = 29;

export interface VideoDetailsTemplateSpec
  extends Lightning.Component.TemplateSpec {
  mediaContent: Video | Live | null;

  Title: Lightning.textures.TextTexture;
  SeasonEpisode: Lightning.textures.TextTexture;
}

export default class VideoDetails
  extends Lightning.Component<VideoDetailsTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<VideoDetailsTemplateSpec>
{
  private _mediaContent: VideoDetailsTemplateSpec['mediaContent'] = null;

  private _Title = this.getByRef('Title')!;
  private _SeasonEpisode = this.getByRef('SeasonEpisode')!;

  get mediaContent() {
    return this._mediaContent;
  }

  set mediaContent(mediaContent: VideoDetailsTemplateSpec['mediaContent']) {
    this._mediaContent = mediaContent;
    this.updateDetails();
  }

  static override _template(): Lightning.Component.Template<VideoDetailsTemplateSpec> {
    return {
      flex: { direction: 'column' },
      Title: {
        text: {
          fontFace: getFontFaceFromStyle('black'),
          fontSize: TITLE_FONT_SIZE,
          textColor: Colors('text').get(),
        },
      },
      SeasonEpisode: {
        text: {
          fontFace: getFontFaceFromStyle('medium'),
          fontSize: METADATA_FONT_SIZE,
          textColor: Colors('text').get(),
        },
      },
    };
  }

  private updateDetails() {
    this._Title.patch({ text: { text: this.mediaContent?.title ?? '' } });

    if (this.mediaContent && isSeries(this.mediaContent)) {
      this._SeasonEpisode.patch({
        text: { text: getSeasonEpisodeFormatted(this.mediaContent as Episode) },
      });
    } else {
      this._SeasonEpisode.patch({ text: { text: '' } });
    }
  }
}
