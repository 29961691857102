import { Lightning } from '@lightningjs/sdk';
import Grid from 'components/common/Grid';
import { HoverableComponent } from 'components/common/HoverableComponent';
import WatchHistoryRecommendationTile from 'components/common/tiles/WatchHistoryRecommendationTile';
import { getFontFaceFromStyle } from 'support/textUtils';
import { translate } from 'support/translate';
import { Show } from 'types/api/media';

const FONT_SIZE = 32;
const GRID_LABEL_Y_OFFSET = 164;
const INITIAL_GRID_POSITION = { x: 9, y: 238 };

export interface WatchHistoryNoResultsTemplateSpec
  extends Lightning.Component.TemplateSpec {
  recommended: Show[];

  Wrapper: {
    Header: Lightning.textures.TextTexture;
    GridLabel: Lightning.textures.TextTexture;
  };
  RecommendedGrid: typeof Grid;
}

export default class WatchHistoryNoResults
  extends HoverableComponent<WatchHistoryNoResultsTemplateSpec>
  implements
    Lightning.Component
      .ImplementTemplateSpec<WatchHistoryNoResultsTemplateSpec>
{
  private _recommended: WatchHistoryNoResultsTemplateSpec['recommended'] = [];

  private _Wrapper = this.getByRef('Wrapper')!;
  private _Header = this._Wrapper.getByRef('Header')!;
  private _GridLabel = this._Wrapper.getByRef('GridLabel')!;
  private _RecommendedGrid = this.getByRef('RecommendedGrid')!;

  get recommended() {
    return this._recommended;
  }

  set recommended(
    recommended: WatchHistoryNoResultsTemplateSpec['recommended'],
  ) {
    this._recommended = recommended;
    this._RecommendedGrid.items = recommended.map(mediaItem => ({
      type: WatchHistoryRecommendationTile,
      imageUrl: mediaItem.images.image_show_thumbnail,
      data: mediaItem,
    }));

    this._GridLabel.visible = !!recommended.length;
  }

  static override _template(): Lightning.Component.Template<WatchHistoryNoResultsTemplateSpec> {
    return {
      w: 813,
      h: 892,
      clipping: true,
      Wrapper: {
        Header: {
          text: {
            text: translate('settings.WatchHistory.noResults'),
            fontSize: FONT_SIZE,
            fontFace: getFontFaceFromStyle('bold'),
          },
        },
        GridLabel: {
          y: GRID_LABEL_Y_OFFSET,
          text: {
            text: translate('settings.WatchHistory.noResultsTitle'),
            fontSize: FONT_SIZE,
            fontFace: getFontFaceFromStyle('regular'),
          },
        },
      },
      RecommendedGrid: {
        ...INITIAL_GRID_POSITION,
        w: 813,
        h: 639,
        type: Grid,
        columns: 2,
        spacing: 20,
        crossSpacing: 38,
        // @ts-ignore
        listType: WatchHistoryRecommendationTile,
        signals: {
          onIndexChanged: true,
          $onHover: '$onHover',
        },
      },
    };
  }

  override _getFocused() {
    return this._RecommendedGrid;
  }

  override _inactive() {
    this._Wrapper.y = 0;
    this._RecommendedGrid.y = INITIAL_GRID_POSITION.y;
  }

  onIndexChanged(indexData: any) {
    if (this._RecommendedGrid.scrollCount > 0) {
      this._Wrapper.setSmooth('y', -INITIAL_GRID_POSITION.y);
    } else {
      this._Wrapper.setSmooth('y', 0);
    }
  }
}
