import { AppData, Lightning, Registry, VideoPlayer } from '@lightningjs/sdk';
import Player from './Player';
import { EpgChannel, Live, Video } from 'types/api/media';
import { PageId } from 'types/pageId';
import {
  ErrorType,
  PlaybackEvent,
  SeekType,
  ViewContext,
} from 'types/analytics';
import {
  reportAdMetadata,
  reportBackgrounded,
  reportBitrate,
  reportContentMetadata,
  reportForegrounded,
  reportHeartbeat,
  reportPlayback,
  reportPlaybackError,
} from 'services/analytics/reportingServiceVideo';
import { Ad, CustomEvent, ImaEvent } from 'types/player';
import {
  getCreditStartTime,
  getCurrentEpgProgram,
  isLive,
  isLiveProgram,
} from 'support/contentUtils';
import { Merge } from 'types';
import { secondsToMilliseconds } from 'support/dateUtils';
import constants from '../../../../static/constants.json';
import { ListItemContext } from '../../../types/events';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PlayerEventConsumerTemplateSpec
  extends Lightning.Component.TemplateSpec {}

interface VideoEvent {
  event: Event;
  videoElement: HTMLMediaElement;
}

export default class PlayerEventConsumer
  extends Lightning.Component<any>
  implements
    Lightning.Component.ImplementTemplateSpec<PlayerEventConsumerTemplateSpec>
{
  private _Player: Player | null = null;
  private _video: Video | null = null;
  private _liveStream: Live | null = null;

  private _isInitialized = false;
  private _isBuffering = false;
  private _isAdPlaying = false;
  private _hasPassedMidpoint = false;
  private _hasStarted = false;
  private _shortHeartbeatIntervalId: number | null = null;
  private _longHeartbeatIntervalId: number | null = null;
  private _epgProgramUpdateTimeoutId: number | null = null;
  private _isVideoViewReported = false;
  private _consecutivePlays = 0;
  private _canAddManualTextTrack = true;

  seekType: SeekType | null = null;

  static override _template(): Lightning.Component.Template<PlayerEventConsumerTemplateSpec> {
    return {};
  }

  private startHeartbeat() {
    this._shortHeartbeatIntervalId = Registry.setInterval(() => {
      const currentTime = this.getRelativeTimeSeconds();

      if (this._isAdPlaying || this._liveStream) return;

      const isPastMidpoint =
        this._video && currentTime > Number(this._video.durationSecs) / 2;

      if (isPastMidpoint && !this._hasPassedMidpoint) {
        this._hasPassedMidpoint = true;
        window.analytics.mParticle.video.reportMidPoint(currentTime);
      }
    }, 1000);
    this._longHeartbeatIntervalId = Registry.setInterval(() => {
      const currentTime = this.getRelativeTimeSeconds();
      reportHeartbeat(currentTime);
    }, 10000);
  }

  private stopHeartbeat() {
    if (this._shortHeartbeatIntervalId !== null) {
      Registry.clearInterval(this._shortHeartbeatIntervalId);
      this._shortHeartbeatIntervalId = null;
    }

    if (this._longHeartbeatIntervalId !== null) {
      Registry.clearInterval(this._longHeartbeatIntervalId);
      this._longHeartbeatIntervalId = null;
    }
  }

  private setIsAdPlaying(isAdPlaying: boolean) {
    this._isAdPlaying = isAdPlaying;
    this.signal('$setIsAdPlaying', isAdPlaying);
  }

  /** returns current ad time if ad is playing or content time if content is playing in seconds
   *
   * @returns current ad time or content time in seconds
   */
  private getRelativeTimeSeconds() {
    return this._isAdPlaying
      ? this._Player!.currentAdTime()
      : this._Player!.currentContentTime();
  }

  // automatically updates EPG program
  private setEpgProgramUpdates(
    channel: EpgChannel,
    viewContext: ViewContext | null,
  ) {
    if (this._epgProgramUpdateTimeoutId !== null) {
      Registry.clearTimeout(this._epgProgramUpdateTimeoutId);
    }

    const program = getCurrentEpgProgram(channel);
    if (!program?.endTime) return;

    const delay = new Date(program.endTime).valueOf() - new Date().valueOf();

    // this adds a 0 - 30 second delay, this is added so every user doesn't report at the same time
    const jitter = Math.random() * secondsToMilliseconds(30);

    this._epgProgramUpdateTimeoutId = Registry.setTimeout(() => {
      // We only update Conviva metadata
      window.analytics.conviva.setContentInfo(channel, viewContext);

      window.analytics.comscore.reportEpgProgramSwitch(channel);

      this.setEpgProgramUpdates(channel, viewContext);
    }, delay + jitter);
  }

  private reportVideoView(
    content: Merge<Merge<Video, Live>, EpgChannel>,
    audioLanguage: string | null,
  ) {
    if (this._isVideoViewReported) return;

    window.analytics.permutive.reportVideoView(
      content,
      audioLanguage,
      this._consecutivePlays,
    );
    this._isVideoViewReported = true;
  }

  private handleAdPodEnd() {
    // prevent calling multiple times
    if (!this._isAdPlaying) return;

    this.setIsAdPlaying(false);

    const relativeTime = this.getRelativeTimeSeconds();
    const rawTime = VideoPlayer.currentTime;

    reportPlayback(PlaybackEvent.AD_POD_END, { relativeTime, rawTime });
  }

  initialize(
    player: Player,
    mediaContent: Merge<Merge<Video, Live>, EpgChannel>,
    fromPageId: PageId | null,
    viewContext: ViewContext | null,
    listItemContext: ListItemContext | undefined,
    consecutivePlays: number,
  ) {
    this._Player = player;

    if (isLive(mediaContent)) {
      this._liveStream = mediaContent as Live;
    } else {
      this._video = mediaContent as Video;
    }

    this._isInitialized = true;
    this._hasStarted = false;
    this._hasPassedMidpoint = false;
    this.seekType = null;
    this._isVideoViewReported = false;
    this._consecutivePlays = consecutivePlays;
    this._canAddManualTextTrack = true;

    if (this._epgProgramUpdateTimeoutId !== null) {
      Registry.clearTimeout(this._epgProgramUpdateTimeoutId);
    }
    this._epgProgramUpdateTimeoutId = null;

    this.setIsAdPlaying(false);
    this.stopHeartbeat();

    reportContentMetadata({
      mediaContent,
      fromPageId,
      viewContext,
      listItemContext,
    });

    if (isLiveProgram(mediaContent)) {
      this.setEpgProgramUpdates(mediaContent as EpgChannel, viewContext);
    }

    window.backgroundingService.addBackgroundingAction(
      'reportBackgrounded',
      reportBackgrounded,
    );
    window.backgroundingService.addForegroundingAction(
      'reportForegrounded',
      reportForegrounded,
    );

    this._Player.beforeLoad = (
      videoEl: HTMLVideoElement,
      streamManager: object | null,
    ) => {
      window.analytics.conviva.setVideoPlayer(videoEl, mediaContent);
      window.analytics.conviva.setAdListener(streamManager);
    };

    this._Player.onStreamAdaption = (bitrate: number) => {
      reportBitrate(bitrate);
    };

    this._Player.onStreamLoaded = (url: string) => {
      window.analytics.conviva.setStreamUrl(url);
      window.analytics.conviva.reportCdn(url);

      const audio = AppData!.storageService.audio.get() ?? null;
      this.reportVideoView(mediaContent, audio);
    };

    this._Player.onPlaybackError = (isFatal: boolean, message: string) => {
      reportPlaybackError(isFatal, ErrorType.STREAM_ERROR, message);
      if (isFatal) this.signal('$fatalError');
    };

    this._Player.onStreamError = (isFatal: boolean, message: string) => {
      reportPlaybackError(isFatal, ErrorType.STREAM_ERROR, message);
      if (isFatal) this.signal('$fatalError');
    };

    this._Player.onManualTextTrackChange = (textTrack: TextTrack) => {
      this.signal('$onManualTextTrackChange', textTrack);
    };
  }

  endPlayback(args: { hasReachedEnd: boolean }) {
    const { hasReachedEnd } = args;
    if (this._epgProgramUpdateTimeoutId !== null) {
      Registry.clearTimeout(this._epgProgramUpdateTimeoutId);
    }

    if (!this._isInitialized) return;

    this._isInitialized = false;

    const relativeTime = this._Player!.currentContentTime();
    const rawTime = VideoPlayer.currentTime;

    window.backgroundingService.removeBackgroundingAction('reportBackgrounded');
    window.backgroundingService.removeForegroundingAction('reportForegrounded');

    this.stopHeartbeat();
    reportPlayback(
      PlaybackEvent.END,
      { relativeTime, rawTime },
      {
        hasEnded: hasReachedEnd,
        isAdPlaying: this._isAdPlaying,
      },
    );
  }

  /*
    Events listed: https://github.com/Metrological/metrological-sdk/blob/master/docs/plugins/videoplayer.md
    "$videoPlayerPause" is missing from this list, but seems to be implemented

    Note: the rawTime argument includes time from ads. For example if a video has a 40 second pre-roll then the
    rawTime will be 40 when the actual content starts playing
  */

  $videoPlayerAbort(videoEvt: VideoEvent, rawTime: number) {
    const relativeTime = this._Player!.currentAdTime();

    this.stopHeartbeat();
    reportPlayback(
      PlaybackEvent.END,
      { relativeTime, rawTime },
      {
        hasEnded: false,
        isAdPlaying: this._isAdPlaying,
      },
    );
  }

  $videoPlayerCanPlay(videoEvt: VideoEvent, rawTime: number) {
    const relativeTime = this.getRelativeTimeSeconds();

    if (this._isBuffering) {
      reportPlayback(PlaybackEvent.BUFFER_END, { relativeTime, rawTime });
      this._isBuffering = false;

      this.signal('$bufferEnd');
    }
  }

  $videoPlayerCanPlayThrough(videoEvt: VideoEvent, rawTime: number) {
    const relativeTime = this.getRelativeTimeSeconds();

    if (this._isBuffering) {
      reportPlayback(PlaybackEvent.BUFFER_END, { relativeTime, rawTime });
      this._isBuffering = false;

      this.signal('$bufferEnd');
    }
  }

  $videoPlayerWaiting(videoEvt: VideoEvent, rawTime: number) {
    const relativeTime = this.getRelativeTimeSeconds();

    reportPlayback(PlaybackEvent.BUFFER_START, { relativeTime, rawTime });
    this._isBuffering = true;

    this.signal('$bufferStart');
  }

  // $videoPlayerDurationChange(videoEvt: VideoEvent, rawTime: number) {
  //   console.log('[Video Player Event] videoPlayerDurationChange', currentTime);

  // }

  // $videoPlayerEmptied(videoEvt: VideoEvent, rawTime: number) {
  //   console.log('[Video Player Event] videoPlayerEmptied', currentTime);
  // }

  // $videoPlayerEncrypted(videoEvt: VideoEvent, rawTime: number) {
  //   console.log('[Video Player Event] videoPlayerEncrypted', currentTime);
  // }

  $videoPlayerEnded(videoEvt: VideoEvent, rawTime: number) {
    const relativeTime = this._Player!.currentAdTime();

    // PlaybackEvent.COMPLETE is called on content completion, this assumes there is no post-roll
    // If a post-roll is added this logic will need to be changed (primarily for Nielsen)
    reportPlayback(PlaybackEvent.COMPLETE, { relativeTime, rawTime });

    this.signal('$videoPlayerEnded');
  }

  // $videoPlayerError(videoEvt: VideoEvent, rawTime: number) {
  //   console.log('[Video Player Event] videoPlayerError', currentTime);
  // }

  // $videoPlayerInterruptBegin(videoEvt: VideoEvent, rawTime: number) {
  //   console.log('[Video Player Event] videoPlayerInterruptBegin', currentTime);
  // }

  // $videoPlayerInterruptEnd(videoEvt: VideoEvent, rawTime: number) {
  //   console.log('[Video Player Event] videoPlayerInterruptEnd', currentTime);
  // }

  // $videoPlayerLoadedData(videoEvt: VideoEvent, rawTime: number) {
  //   console.log('[Video Player Event] videoPlayerLoadedData', currentTime);
  // }

  // $videoPlayerLoadedMetadata(videoEvt: VideoEvent, rawTime: number) {
  //   console.log('[Video Player Event] videoPlayerLoadedMetadata', currentTime);
  // }

  // $videoPlayerLoadStart(videoEvt: VideoEvent, rawTime: number) {
  //   console.log('[Video Player Event] videoPlayerLoadStart', currentTime);
  // }

  $videoPlayerPlay(videoEvt: VideoEvent, rawTime: number) {
    const relativeTime = this.getRelativeTimeSeconds();

    this.signal('$videoPlayerPlay');

    if (!this._hasStarted) {
      const relativeStartTime = this._Player?.startTime ?? relativeTime;
      const rawStartTime =
        this._Player?.getStreamTimeForContentTime(relativeStartTime) ?? rawTime;

      reportPlayback(PlaybackEvent.START, {
        relativeTime: relativeStartTime,
        rawTime: rawStartTime,
      });
      this.startHeartbeat();
      this._hasStarted = true;
      this.signal('$addToCartEvent');
    } else {
      reportPlayback(PlaybackEvent.RESUME, { relativeTime, rawTime });
    }
  }

  $videoPlayerPause(videoEvt: VideoEvent, rawTime: number) {
    const relativeTime = this.getRelativeTimeSeconds();

    this.signal('$videoPlayerPause');

    reportPlayback(PlaybackEvent.PAUSE, { relativeTime, rawTime });
  }

  // $videoPlayerPlaying(videoEvt: VideoEvent, rawTime: number) {
  // }

  // $videoPlayerProgress(videoEvt: VideoEvent, rawTime: number) {
  // }

  // $videoPlayerRatechange(videoEvt: VideoEvent, rawTime: number) {
  // }

  $videoPlayerSeeked(videoEvt: VideoEvent, rawTime: number) {
    const relativeTime = this._Player!.currentContentTime(); // We shouldn't be able to seek during ads

    reportPlayback(PlaybackEvent.SEEK_END, { relativeTime, rawTime });
  }

  $videoPlayerSeeking(videoEvt: VideoEvent, rawTime: number) {
    // this is the time we're seeking to
    const relativeTime = this._Player!.currentContentTime(); // We shouldn't be able to seek during ads

    reportPlayback(
      PlaybackEvent.SEEK_START,
      { relativeTime, rawTime },
      this.seekType ?? undefined,
    );

    this.signal('$videoPlayerSeeking', rawTime, relativeTime);
  }

  // $videoPlayerStalled(videoEvt: VideoEvent, rawTime: number) {
  // }

  $videoPlayerTimeUpdate(videoEvt: VideoEvent, rawTime: number) {
    if (!this._Player || !(this._video || this._liveStream)) return;

    const relativeTime = this.getRelativeTimeSeconds();

    let hasReachedCredits = false;

    if (
      this._video &&
      this._Player.currentContentTime() >= getCreditStartTime(this._video)
    ) {
      hasReachedCredits = true;
      reportPlayback(PlaybackEvent.CREDITS_REACHED, { relativeTime, rawTime });
    }

    // EL-512 - Edge case for HLS Player not having subtitleTracks
    if (!!this._Player.liveStreamType && this._canAddManualTextTrack) {
      this._canAddManualTextTrack = !this._Player.handleManualTextTrack();
    }

    this.signal('$videoPlayerTimeUpdate', relativeTime, hasReachedCredits);
  }

  // $videoPlayerVolumeChange(videoEvt: VideoEvent, rawTime: number) {
  // }

  // $videoPlayerClear(videoEvt: VideoEvent, rawTime: number) {
  // }

  $videoPlayerEvent(type: string, event: any, ...arg: any) {
    this.signal('$videoPlayerEvent', type, event);

    switch (type) {
      case CustomEvent.BEFORE_SEEK: {
        const relativeTime = this._Player!.currentContentTime(); // We shouldn't be able to seek during ads
        const rawTime = VideoPlayer.currentTime;

        reportPlayback(PlaybackEvent.BEFORE_SEEK, { relativeTime, rawTime });
        break;
      }
      case CustomEvent.SKIP_AD_POD: {
        this.handleAdPodEnd();
        break;
      }
      case ImaEvent.STARTED: {
        const ad: Ad | undefined = event?.getAd();
        const duration = this._Player!.adDuration();
        this.setIsAdPlaying(true);

        if (ad && duration !== null) {
          const relativeTime = this._Player!.currentContentTime(); // we pass content time because we know ad time is 0
          const rawTime = VideoPlayer.currentTime;

          if (ad.getAdPodInfo().getAdPosition() === 1) {
            reportPlayback(PlaybackEvent.AD_POD_START, {
              relativeTime,
              rawTime,
            });
          }

          reportAdMetadata(ad);
          reportPlayback(PlaybackEvent.AD_START, { relativeTime, rawTime }, ad);
        }
        break;
      }
      case ImaEvent.COMPLETE: {
        const relativeTime = this.getRelativeTimeSeconds();
        const rawTime = VideoPlayer.currentTime;

        reportPlayback(PlaybackEvent.AD_END, { relativeTime, rawTime });

        break;
      }
      case ImaEvent.AD_BREAK_ENDED: {
        this.handleAdPodEnd();
        break;
      }
      default:
        break;
    }
  }
}
