import { AppData } from '@lightningjs/sdk';
import constants from '../../static/constants.json';

/**
 * Build image URL for CW images with the template imageBuilderUrl
 *
 * @param imageTypeId refers to IMAGE TYPE ID of ticket CMS-454
 * @param width the width of the image
 * @param imageId [promo|show|guid|epgchannel] + content id - refer to REQUIRED PARAMS of ticket CMS-454
 * @param extension the extension of the image [jpg|png] - png for logos, jpg for others
 * @returns the URL of the image
 */
const buildCwImageUrl = (
  imageTypeId: string, // refers to IMAGE TYPE ID of ticket CMS-454
  width: string,
  imageId: string, // [promo | show | guid | epgchannel] + content id
  extension: 'jpg' | 'png',
  badge = '',
) => {
  const imageUrl = constants.api.imageBuilderUrl
    .replace('{image_type_id}', imageTypeId)
    .replace('{width}', width)
    .replace('{image_id}', imageId);

  return `${AppData!.images.baseUrl}/${imageUrl}${badge}.${extension}`;
};

// promo
export const getPromoHeroLandscapeUrl = (
  width: number | string,
  id: string,
) => {
  return buildCwImageUrl('hero_landscape', width.toString(), id, 'jpg');
};

export const getPromoHeroLogoUrl = (width: number | string, id: string) => {
  return buildCwImageUrl('hero_logo', width.toString(), id, 'png');
};

export const getPromoHubLandscapeUrl = (width: number | string, id: string) => {
  return buildCwImageUrl('hub_landscape', width.toString(), id, 'jpg');
};

// content hub
/**
 * Formats provided thumbnail URL with provided width. Assumes the image URL is already
 * fully formed, only missing `{width}` property
 *
 * @param url The base thumbnail URL for Content Hub
 * @param width Width of the image we want
 * @returns Formatted Content Hub thumbnail URL of specified width
 */
export const updateContentHubThumbnailUrl = (
  url: string,
  width: number | string,
) => {
  return url.replace('{width}', `${width}`);
};

// show
export const getShowLogoUrl = (slug: string, width: number | string = 565) => {
  return buildCwImageUrl('show_logo', width.toString(), `show_${slug}`, 'png');
};

export const getShowLogoWhiteUrl = (
  slug: string,
  width: number | string = 565,
) => {
  return buildCwImageUrl(
    'show_logo_white',
    width.toString(),
    `show_${slug}`,
    'png',
  );
};

export const getShowVerticalUrl = (
  slug: string,
  badgeId = '',
  width: number | string = 500,
) => {
  return buildCwImageUrl(
    'show_vertical',
    width.toString(),
    `show_${slug}`,
    'jpg',
    badgeId ? `.${badgeId}` : '',
  );
};

export const getFeaturedVerticalExpandedUrl = (
  slug: string,
  width: number | string = 1280,
) => {
  return buildCwImageUrl(
    'featured_vertical_expanded',
    width.toString(),
    `show_${slug}`,
    'jpg',
  );
};

export const getFeaturedHorizontalExpandedUrl = (
  slug: string,
  width: number | string = 1280,
) => {
  return buildCwImageUrl(
    'featured_horizontal_expanded',
    width.toString(),
    `show_${slug}`,
    'jpg',
  );
};

export const getShowFeaturedUrl = (
  slug: string,
  width: number | string = 1920,
) => {
  return buildCwImageUrl(
    'show_featured',
    width.toString(),
    `show_${slug}`,
    'jpg',
  );
};

export const getShowPromoUrl = (
  slug: string,
  badgeId = '',
  width: number | string = 1600,
) => {
  return buildCwImageUrl(
    'show_promo',
    width.toString(),
    `show_${slug}`,
    'jpg',
    badgeId ? `.${badgeId}` : '',
  );
};

export const getShowThumbnailUrl = (
  slug: string,
  width: number | string = 1200,
) => {
  return buildCwImageUrl(
    'show_thumbnail',
    width.toString(),
    `show_${slug}`,
    'jpg',
  );
};

// channel
export const getChannelThumbnailUrl = (
  slug: string,
  badgeId = '',
  width: number | string = 800,
) => {
  return buildCwImageUrl(
    'channel_thumbnail',
    width.toString(),
    `epgchannel_${slug}`,
    'jpg',
    badgeId ? `.${badgeId}` : '',
  );
};

// guid
export const getVideoGuidUrl = (guid: string, width: number | string = 720) => {
  return buildCwImageUrl(
    'video_thumbnail',
    width.toString(),
    `guid_${guid}`,
    'jpg',
  );
};

export const updateImageSize = (imageUrl: string | undefined, w: number) => {
  const width = Math.round(w / 100) * 100;
  return imageUrl?.replace(/\.w_\d+\./, `.w_${width}.`);
};

export const forceImageNoBadge = (imageUrl: string | undefined) => {
  if (!imageUrl || imageUrl.match(/\.badge_\d+-\d+\./)) return imageUrl;
  return imageUrl.replace(/\.jpg$/, '.badge_0-0.jpg');
};
