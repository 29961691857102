import { Colors, Lightning } from '@lightningjs/sdk';
import Button from 'components/common/Button';
import { translate } from 'support/translate';

interface LiveControlsTemplateSpec extends Lightning.Component.TemplateSpec {
  CcAudio: typeof Button;
  LiveStatus: {
    Icon: object;
    Label: object;
  };
}

interface LiveControlsSignalMap extends Lightning.Component.SignalMap {
  ccAudio(): void;
}

interface LiveControlsTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: LiveControlsSignalMap;
}

const CC_AUDIO_ICON_FOCUSED =
  'static/images/playback/audio-cc-icon-focused.svg';
const CC_AUDIO_ICON_UNFOCUSED =
  'static/images/playback/audio-cc-icon-unfocused.svg';

const CONTROL_W = 1600;
const CONTROL_H = 82;

const LIVE_STATUS_ICON_DIAMETER = 25;
const LIVE_STATUS_TEXT_X = LIVE_STATUS_ICON_DIAMETER + 20;

const BUTTON_H = 60;
const BUTTON_FONT_SIZE = 23;
const BUTTON_RADIUS = 7;

const BUTTON_MARGIN = 13;
const BUTTON_PADDING_HORIZONTAL = 30;

export default class LiveControls
  extends Lightning.Component<LiveControlsTemplateSpec, LiveControlsTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<LiveControlsTemplateSpec>
{
  private _CcAudio = this.getByRef('CcAudio')!;

  static override _template(): Lightning.Component.Template<LiveControlsTemplateSpec> {
    return {
      w: CONTROL_W,
      h: CONTROL_H,
      flex: {
        direction: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },

      LiveStatus: {
        Icon: {
          mountY: 0.5,
          w: LIVE_STATUS_ICON_DIAMETER,
          h: LIVE_STATUS_ICON_DIAMETER,
          rect: true,
          color: Colors('liveIndicator').get(),
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: LIVE_STATUS_ICON_DIAMETER / 2,
          },
        },
        Label: {
          mountY: 0.5,
          x: LIVE_STATUS_TEXT_X,
          text: {
            text: translate('playback.liveNow'),
            fontSize: 39,
            textColor: Colors('white').get(),
          },
        },
      },
      CcAudio: {
        type: Button,
        label: translate('playback.closedCaption'),
        action: '$ccAudio',
        signals: { $ccAudio: true },
        height: BUTTON_H,
        fontSize: BUTTON_FONT_SIZE,
        radius: BUTTON_RADIUS,
        margin: BUTTON_MARGIN,
        paddingH: BUTTON_PADDING_HORIZONTAL,
        startIcon: {
          focused: CC_AUDIO_ICON_FOCUSED,
          unfocused: CC_AUDIO_ICON_UNFOCUSED,
        },
        backgroundColor: {
          unfocused: Colors('buttonInactive').alpha(0.8).get(),
        },
      },
    };
  }

  override _setup() {
    this._CcAudio.setStartIcon(CC_AUDIO_ICON_UNFOCUSED);
  }

  override _active() {
    this._setState('NoSelection');
  }

  private $ccAudio() {
    this.signal('ccAudio');
  }

  static override _states() {
    return [
      class NoSelection extends this {
        override $enter() {
          this._CcAudio.setStartIcon(CC_AUDIO_ICON_UNFOCUSED);
        }

        override _handleRight() {
          this._setState('CcAudioMenu');
        }
      },
      class CcAudioMenu extends this {
        override $enter() {
          this._CcAudio.setStartIcon(CC_AUDIO_ICON_FOCUSED);
        }

        override _handleLeft() {
          this._setState('NoSelection');
        }

        override _getFocused() {
          return this._CcAudio;
        }
      },
    ];
  }
}
