import { Colors, Lightning } from '@lightningjs/sdk';
import { Key as BaseKey, Keyboard } from '@lightningjs/ui';

import { translate } from 'support/translate';
import { getImageTextureObj } from 'support/generalUtils';
import { appendCollision, dispatchEnter } from '../HoverableComponent';

export enum KeyboardLayouts {
  LOWERCASE_ABC = 'abc',
  UPPERCASE_ABC = 'ABC',
  NUMERIC = '123?',
  SYMBOLS = '=/<',
}

const DEFAULT_KEY_WIDTH = 57;
const DEFAULT_KEY_HEIGHT = 64;
const DEFAULT_FONT_SIZE = 32;
const BACKGROUND_RADIUS = 9;

export class Key extends BaseKey {
  get title() {
    return this.data.label;
  }

  static get width() {
    return DEFAULT_KEY_WIDTH;
  }

  static get height() {
    return DEFAULT_KEY_HEIGHT;
  }

  override _setup() {
    appendCollision(this);
    super._setup();

    this.patch({
      backgroundColors: {
        unfocused: Colors('buttonInactive').get(),
        focused: Colors('highlight').get(),
      },
      background: {
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: BACKGROUND_RADIUS,
        },
      },
      labelColors: {
        unfocused: Colors('text').get(),
        focused: Colors('activeText').get(),
      },
      label: {
        mountY: 0.4,
        text: {
          fontSize: DEFAULT_FONT_SIZE,
        },
      },
    });
    this.zIndex = 2; // At point of Keyboard Usage in SearchPage, zIndex is 1, this needs to be at 2 or Hover will fail
  }

  _handleClick(target: any) {
    dispatchEnter();
  }

  _handleHover(target: Key) {
    const keyWrapper = this.parent;
    if (keyWrapper && 'keyboard' in keyWrapper) {
      const keyboard = keyWrapper.keyboard as Keyboard;
      keyboard.focus(target.data.origin);
      this._refocus();
    }
  }
}

const LAYOUT_FONT_SIZE = 22;

export class LayoutKey extends Key {
  override get title() {
    return translate(
      'keyboard.changeLayoutDescription',
      this.getLayoutString(),
    );
  }

  override _setup() {
    super._setup();

    this.patch({
      label: {
        text: {
          fontSize: LAYOUT_FONT_SIZE,
        },
      },
    });
  }

  private getLayoutString() {
    switch (this.data.label) {
      case KeyboardLayouts.LOWERCASE_ABC:
        return translate('keyboard.lowercaseLayout');
      case KeyboardLayouts.UPPERCASE_ABC:
        return translate('keyboard.uppercaseLayout');
      case KeyboardLayouts.NUMERIC:
        return translate('keyboard.numericLayout');
      case KeyboardLayouts.SYMBOLS:
        return translate('keyboard.symbolLayout');
      default:
        return '';
    }
  }
}

const DEFAULT_ICON_WIDTH = 30;
const DEFAULT_ICON_HEIGHT = 30;

export class IconKey extends Key {
  private _icon = '';
  private _iconSizing = {
    width: DEFAULT_ICON_WIDTH,
    height: DEFAULT_ICON_HEIGHT,
  };

  set icon(icon: string) {
    this._icon = icon;
    this._update();
  }

  get icon() {
    return this._icon;
  }

  set iconSizing(sizing: { width?: number; height?: number }) {
    this._iconSizing = {
      width: sizing.width ?? this._iconSizing.width,
      height: sizing.height ?? this._iconSizing.height,
    };
    this._update();
  }

  get iconSizing() {
    return this._iconSizing;
  }

  _update() {
    super._update();
    const icon = this._icon;
    const { width, height } = this._iconSizing;

    this.patch({
      label: {
        ...getImageTextureObj(icon, width, height),
      },
    });
  }
}
