import { Lightning } from '@lightningjs/sdk';
import { PageId } from 'types/pageId';

import Page from '../../Page';
import RecommendedSection from './RecommendedSection';
import { Episode, FeaturedTrailer, Show } from 'types/api/media';
import ShowcaseSection from '../../common/ShowcaseSection';
import { getImageTextureObj } from 'support/generalUtils';
import { navigateToPlay } from 'support/routerUtils';
import { StaticViewContexts } from 'types/analytics';
import { ListItemContext } from 'types/events';

export interface MovieDetailsPageSpec extends Lightning.Component.TemplateSpec {
  pageData: {
    show: Show;
    recommended: Show[];
    listItemContext: ListItemContext;
    fromPageId: PageId;
  };

  Content: {
    Sections: {
      ShowcaseSection: typeof ShowcaseSection;
      RecommendedSection: typeof RecommendedSection;
    };
    Logo: Lightning.Texture;
  };
}

const RECOMMENDED_SECTION_OFFSET = 213;

const LOGO_X_SHOWCASE = 96;
const LOGO_Y_SHOWCASE = 85;
const LOGO_W_SHOWCASE = 565;
const LOGO_H_SHOWCASE = 230;

export default class MovieDetailsPage
  extends Page<MovieDetailsPageSpec>
  implements Lightning.Component.ImplementTemplateSpec<MovieDetailsPageSpec>
{
  protected override _pageId = PageId.MOVIE_DETAIL;
  protected override _viewContext = StaticViewContexts.MOVIE_DETAIL_PAGE;
  private _listItemContext: ListItemContext | undefined = undefined;

  private _movie: Show | null = null;
  private _featuredEpisode: Episode | null = null;
  private _featuredTrailer: FeaturedTrailer | null = null;

  private _Content = this.getByRef('Content')!;
  private _Logo = this._Content.getByRef('Logo')!;
  private _Sections = this._Content.getByRef('Sections')!;
  private _ShowcaseSection = this._Sections.getByRef('ShowcaseSection')!;
  private _RecommendedSection = this._Sections.getByRef('RecommendedSection')!;

  static override _template(): Lightning.Component.Template<MovieDetailsPageSpec> {
    return {
      ...super._template(),
      Content: {
        h: 1080,
        Sections: {
          flex: { direction: 'column' },
          ShowcaseSection: {
            type: ShowcaseSection,
            signals: {
              $playContent: '$playContent',
              $playTrailer: '$playTrailer',
              $onShowcaseHover: '$onShowcaseHover',
            },
          },
          RecommendedSection: {
            type: RecommendedSection,
            signals: {
              $onRecommendedHover: '$onRecommendedHover',
            },
          },
        },
        Logo: {
          x: LOGO_X_SHOWCASE,
          y: LOGO_Y_SHOWCASE,
        },
      },
    };
  }

  override _onDataProvided() {
    const { show, recommended, listItemContext } = this.pageData;

    const { featuredTrailer } = show;
    this._movie = show;
    this._featuredTrailer = featuredTrailer?.id ? featuredTrailer : null;

    if (listItemContext) {
      this._listItemContext = listItemContext;
    }

    this._ShowcaseSection.patch({ show });
    this._RecommendedSection.recommended = recommended;
    this.updateLogo();
  }

  private updateFeaturedEpisode() {
    if (!this._movie) return;
    const { firstEpisode } = this._movie;
    this._featuredEpisode = firstEpisode;
    this._ShowcaseSection.patch({ featuredEpisode: this._featuredEpisode });
  }

  override _setup() {
    super._setup();
    this._setState('ShowcaseSection');
  }

  override _onChanged() {
    this.updateFeaturedEpisode();
    this._setState('ShowcaseSection');
  }

  override _handleUp() {
    this._setState('ShowcaseSection');
  }

  override _handleDown() {
    if (this.pageData.recommended.length) {
      this._setState('RecommendedSection');
    }
  }

  override _inactive() {
    this._setState('ShowcaseSection');
  }

  private updateLogo() {
    const images = this._movie!.images;
    const logoSrc = images.image_logo ?? images.image_logo_white ?? '';
    const logoTexture = getImageTextureObj(
      logoSrc,
      LOGO_W_SHOWCASE,
      LOGO_H_SHOWCASE,
    );

    this._Logo.patch(logoTexture);
  }

  $onRecommendedHover() {
    if (this._getState() === 'ShowcaseSection') {
      this._setState('RecommendedSection');
    }
  }

  $onShowcaseHover() {
    if (this._getState() === 'RecommendedSection') {
      this._setState('ShowcaseSection');
    }
  }

  $playContent() {
    this._featuredEpisode &&
      navigateToPlay(
        this._featuredEpisode.guid,
        {
          viewContext: this.viewContext,
          listItemContext: this._listItemContext,
          pageId: this.pageId,
        },
        'show',
      );
  }

  $playTrailer() {
    this._featuredTrailer &&
      navigateToPlay(this._featuredTrailer.id, {
        viewContext: this.viewContext,
        listItemContext: this._listItemContext,
        pageId: this.pageId,
      });
  }

  static override _states() {
    return [
      class ShowcaseSection extends this {
        override $enter() {
          this._Content.setSmooth('y', 0);
        }

        override _getFocused() {
          return this._ShowcaseSection;
        }
      },
      class RecommendedSection extends this {
        override $enter() {
          this._Content.setSmooth('y', -RECOMMENDED_SECTION_OFFSET);
        }

        override _getFocused() {
          return this._RecommendedSection;
        }

        override _handleBack() {
          this._setState('ShowcaseSection');
        }
      },
    ];
  }
}
