import { Lightning, Registry } from '@lightningjs/sdk';

export class LongTextAnnouncerService {
  private component: Lightning.Component;
  private paragraphs: string[];
  private nextParagraphDelay: number;
  private currentParagraphIndex: number;
  private readingTimeout: number | null;

  constructor(
    component: Lightning.Component,
    text: string,
    title?: string,
    nextParagraphDelay = 1000,
  ) {
    this.component = component;
    this.nextParagraphDelay = nextParagraphDelay;
    this.currentParagraphIndex = 0;
    this.readingTimeout = null;
    if (title) {
      this.paragraphs = [title, ...this.splitIntoParagraphs(text)];
    } else {
      this.paragraphs = this.splitIntoParagraphs(text);
    }
  }

  private splitIntoParagraphs(text: string): string[] {
    return text
      .split(/\n\s*\n/)
      .map(para => para.trim())
      .filter(para => para.length > 0);
  }

  startReading() {
    this.stopReading();
    this.currentParagraphIndex = 0;
    this.readNextParagraph();
  }

  stopReading() {
    if (this.readingTimeout !== null) {
      Registry.clearTimeout(this.readingTimeout);
      this.readingTimeout = null;
    }
  }

  private readNextParagraph(): void {
    const shouldContinueReading =
      this.currentParagraphIndex < this.paragraphs.length &&
      this.component.hasFocus();

    if (shouldContinueReading) {
      const announcement = this.paragraphs[this.currentParagraphIndex];

      const isAppend = this.currentParagraphIndex > 0;
      this.component.fireAncestors('$announce', announcement, {
        append: isAppend,
      });

      this.currentParagraphIndex++;
      this.readingTimeout = Registry.setTimeout(
        () => this.readNextParagraph(),
        this.nextParagraphDelay,
      );
    }
  }
}
