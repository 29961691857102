import { Lightning } from '@lightningjs/sdk';
import { EpgChannel, EpgProgram } from 'types/api/media';
import EpgCell from './EpgCell';
import constants from '../../../../static/constants.json';
import ChannelBadge from 'components/common/ChannelBadge';
import { getCurrentEpgProgram } from 'support/contentUtils';
import { IndexData } from 'types/lightning';
import { hoursToMilliseconds } from 'support/dateUtils';
import { List } from '@lightningjs/ui';
import { filterExpiredEpgPrograms } from 'services/cwData';
import { ListWithIndexing } from 'components/common/CollectionWrappersWithIndexing';
import { HoverableListItem } from 'components/common/HoverableListItem';

const LOGO_DIMENSIONS = {
  w: constants.ui.epgLogoWidth,
  h: constants.ui.epgLogoHeight,
};
const PROGRAMS_OFFSET_X = LOGO_DIMENSIONS.w + constants.ui.epgSpacing;
const TIMELINE_TAB_WIDTH = constants.ui.epgWidthPerThirtyMin;
const VISIBLE_TABS_WIDTH = TIMELINE_TAB_WIDTH * 5;
// Showing 12 hours in 30 minute blocks (12 * 2)
const MAX_VISIBLE_TABS_WIDTH = TIMELINE_TAB_WIDTH * 12 * 2 - VISIBLE_TABS_WIDTH;

interface EpgRowTemplateSpec extends Lightning.Component.TemplateSpec {
  epgChannel: EpgChannel | null;
  currentCell: EpgCell | null;
  isSelected: boolean;
  scrollTarget: number;
  preventScroll: boolean;
  updateTime: { startTime: Date; currentTime: Date };

  Logo: typeof ChannelBadge;
  ProgramsWrapper: {
    Programs: typeof List;
  };
}

export default class EpgRow
  extends HoverableListItem<EpgRowTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<EpgRowTemplateSpec>
{
  private _epgChannel: EpgRowTemplateSpec['epgChannel'] = null;
  private _isSelected = false;
  private _preventScroll = true;

  // Internal properties
  private _startTime?: Date;
  private _currentTime?: Date;
  private _shouldUpdate = true;
  private _focusIndex = 0;
  private _focusProcessing = false;
  private _scrollTarget = 0;

  private _Logo = this.getByRef('Logo')!;
  private _ProgramsWrapper = this.getByRef('ProgramsWrapper')!;
  private _Programs = this._ProgramsWrapper.getByRef('Programs')!;

  get epgChannel() {
    return this._epgChannel;
  }

  set epgChannel(epgChannel: EpgRowTemplateSpec['epgChannel']) {
    this._epgChannel = epgChannel;
    this.setRowChannel();
    this.updateRow();
  }

  get currentCell() {
    return (this._Programs.currentItem as EpgCell | undefined) ?? null;
  }

  get isSelected() {
    return this._isSelected;
  }

  set isSelected(isSelected: EpgRowTemplateSpec['isSelected']) {
    this._isSelected = isSelected;
    this._Logo.selected = isSelected;

    this._Programs.items.forEach(
      (cell: EpgCell) => (cell.rowSelected = isSelected),
    );
  }

  get programs() {
    return this._Programs;
  }

  set scrollTarget(scrollTarget: number) {
    this.preventScroll = true;
    this._scrollTarget = scrollTarget;
    this._Programs.wrapper.fastForward('x');
    this._Programs.wrapper.setSmooth('x', this._scrollTarget);
  }

  set updateTime(time: EpgRowTemplateSpec['updateTime']) {
    const { startTime, currentTime } = time;
    if (!startTime) return;

    if (!this._shouldUpdate) {
      // Should only update if already unset
      this._shouldUpdate = this._startTime?.getTime() !== startTime.getTime();
    }
    this._startTime = startTime;
    this._currentTime = currentTime;

    this.updateRow();
  }

  set preventScroll(preventScroll: boolean) {
    this._preventScroll = preventScroll;
    this._Programs.scroll = this._preventScroll
      ? this.scrollRestricted.bind(this)
      : this.scrollByKey.bind(this);
  }

  get preventScroll() {
    return this._preventScroll;
  }

  static override _template(): Lightning.Component.Template<EpgRowTemplateSpec> {
    return {
      Logo: {
        type: ChannelBadge,
        ...LOGO_DIMENSIONS,
      },
      ProgramsWrapper: {
        x: PROGRAMS_OFFSET_X,
        w: w => w - PROGRAMS_OFFSET_X,
        h: h => h,
        clipping: true,
        Programs: {
          type: ListWithIndexing,
          itemType: EpgCell,
          spacing: constants.ui.epgSpacing,
          forceLoad: true,
          signals: {
            onIndexChanged: '$onIndexChanged',
          },
        },
      },
    };
  }

  override _setup() {
    super._setup();
    this._Programs._inactive = () => {
      // The default _inactive state cancels any queued reposition calls. A side-effect
      // of this operation is that any time rows are deemed inactive (For example, when
      // they are offscreen because we've scrolled too far right) they will not update.
      // Now, programs list will always update when reposition is called.
      this._Programs._collectGarbage(true);
    };
  }
  override _active() {
    // wrapper x is being reset on requestItem so we should update it back instantly without animation.
    this._Programs.wrapper.fastForward('x');
    this._Programs.wrapper.patch({ x: this._scrollTarget });
    this.updateRow();
  }

  override _getFocused() {
    return this._Programs;
  }

  override _focus() {
    //set the scrollFunction
    this.setupScrollFocus();
    this._focusProcessing = true;
    //set the index of the best cell
    this._Programs.setIndex(this._focusIndex);
  }

  private $onProgramSelect(epgProgram: EpgProgram) {
    const currentProgram = getCurrentEpgProgram(this.epgChannel!);
    if (currentProgram?.startTime === epgProgram.startTime) {
      this.fireAncestors('$onChannelSelect', this.epgChannel!);
    } else {
      this.fireAncestors('$onProgramSelect', epgProgram, this.epgChannel!);
    }
  }

  private setRowChannel() {
    this._Logo.logo = {
      focused: this.epgChannel?.images.logoFocused ?? '',
      unfocused: this.epgChannel?.images.logoUnfocused,
    };
    this._Logo.logoSizing = {
      width: LOGO_DIMENSIONS.w,
      height: LOGO_DIMENSIONS.h,
    };
    this._Programs.w = this._ProgramsWrapper.w;
  }

  private updateRow() {
    if (
      !this.epgChannel ||
      !this._startTime ||
      !this._currentTime ||
      !this.active
    )
      return;

    if (this._shouldUpdate) {
      this.updateCellItems();
      this._shouldUpdate = false;
    } else {
      this.updateCellTimes();
    }
  }

  private updateCellItems() {
    const programs = this.updateChannelPrograms();

    let index = this._Programs.index;
    if (this._Programs.hasItems) {
      const initialProgramsLength = this._Programs.items.length;
      const newProgramsLength = programs.length;
      const programsDelta = initialProgramsLength - newProgramsLength;

      // Make sure the index is always >= 0
      index = Math.max(0, index - programsDelta);
    }

    this._Programs.clear();
    this._Programs.items = programs.map(this.buildEpgProgram.bind(this));
    this._Programs.index = index;
    this._Programs.reposition();
  }

  private updateChannelPrograms(): EpgProgram[] {
    let programs = this.epgChannel!.programs;
    // Filter expired programs from channel (Global)
    programs = filterExpiredEpgPrograms(programs, this._startTime!);

    const endTime = this.getEndTime();
    // Filter programs that go past EPG end time (Local)
    const filteredPrograms =
      (programs
        .map(program => {
          const programStartTime = new Date(program.startTime);
          if (programStartTime < endTime) return { ...program };
        })
        .filter(program => !!program) as EpgProgram[]) ?? [];

    // Ensure last program end time is less than the EPG end time
    if (filteredPrograms.length) {
      const finalProgram = filteredPrograms[filteredPrograms.length - 1]!;
      const finalProgramEndTime = new Date(finalProgram.endTime);
      if (finalProgramEndTime > endTime)
        finalProgram.endTime = endTime.toString();
    }

    return filteredPrograms;
  }

  private updateCellTimes() {
    const programs = this._Programs.items as EpgCell[];

    for (const program of programs) {
      const programStartTime = new Date(program.epgProgram!.startTime);
      const epgCurrentTime = this._currentTime!;

      if (programStartTime > epgCurrentTime) break;
      program.updateTime = {
        startTime: this._startTime!,
        currentTime: this._currentTime!,
      };
    }
  }

  private buildEpgProgram(
    program: EpgProgram,
    index: number,
    programs: EpgProgram[],
  ) {
    const currentTime = this._currentTime!;
    const startTime = this._startTime!;
    const updateTime = { startTime, currentTime };

    const epgCellRowInfo = {
      channelTitle: this.epgChannel?.title ?? '',
      index,
      rowLength: programs.length,
    };

    return {
      w: constants.ui.epgWidthPerThirtyMin, // width > 0 must be provided here for cell to render
      h: constants.ui.epgCellHeight,
      epgProgram: program,
      updateTime,
      epgCellRowInfo,
      rowSelected: this._isSelected,
    };
  }

  private setupScrollFocus() {
    this.preventScroll = this._preventScroll;
  }

  private getEndTime() {
    const startTime = this._startTime ?? new Date();
    const endTime = startTime.getTime() + hoursToMilliseconds(12);
    return new Date(endTime);
  }

  private scrollByKey(itemWrapper: any, indexData: IndexData) {
    const { previousIndex, index } = indexData;

    const cellStart = Math.ceil(itemWrapper.assignedX);
    const cellW = Math.round(itemWrapper.w + constants.ui.epgSpacing);
    const cellEnd = cellStart + cellW;
    const scrolledRowStartX = Math.abs(this._Programs.wrapper.getSmooth('x')); // first visible time
    const scrolledRowEndX = scrolledRowStartX + VISIBLE_TABS_WIDTH;

    let scrollValue = -scrolledRowStartX;

    if (cellStart >= scrolledRowStartX && cellEnd <= scrolledRowEndX) {
      // 1: Cell is contained within visible tabs

      scrollValue = -scrolledRowStartX;
    } else if (this._focusProcessing) {
      // 2. Refocus (Navigating Up Down or Refocus from Player, Navbar & Modal)

      if (cellStart < scrolledRowStartX || cellStart > scrolledRowEndX) {
        // 2.1 Cell is partially hidden on the left
        scrollValue = -cellStart;
      } else if (
        cellEnd > scrolledRowEndX &&
        (cellStart >= scrolledRowStartX + TIMELINE_TAB_WIDTH * 4 ||
          cellW > TIMELINE_TAB_WIDTH * 2)
      ) {
        // 2.2: End of cell outside pass last visible time -AND-
        // ( Start of cell is over 2 hours past first visible time -OR-
        //   Cell is over 1 hour long )
        scrollValue = -cellStart;
      }
    } else if (
      (cellStart < scrolledRowStartX && cellEnd < scrolledRowStartX) ||
      (cellStart > scrolledRowEndX && cellEnd > scrolledRowEndX)
    ) {
      // 3. Edge Case: Just In case Completely off the map for some reason

      scrollValue = -cellStart;
    } else if (previousIndex > index) {
      // 4: Navigating LEFT

      if (
        (cellStart < scrolledRowStartX && cellEnd > scrolledRowStartX) ||
        cellW > VISIBLE_TABS_WIDTH
      ) {
        // 4.1: Cell Start is before first visible time but Cell End is after first visible time -OR-
        //      Cell is bigger than 2.5 hours
        scrollValue = -cellStart;
      } else {
        // 4.2: scroll 2.5 hours or 0 if at the beginning
        scrollValue =
          scrolledRowStartX - VISIBLE_TABS_WIDTH < 0
            ? 0
            : -scrolledRowStartX + VISIBLE_TABS_WIDTH;
      }
    } else if (previousIndex < index) {
      // 5. Navigating RIGHT

      if (
        cellEnd > scrolledRowEndX &&
        (cellStart >= scrolledRowStartX + TIMELINE_TAB_WIDTH * 4 ||
          cellW < TIMELINE_TAB_WIDTH * 2)
      ) {
        // 5.2: End of cell outside pass last visible time -AND-
        // ( Start of cell is over 2 hours past first visible time -OR-
        //   Cell is over 1 hour long )
        scrollValue = -cellStart;
      }
    }
    this._focusProcessing = false;
    // scroll here
    this.scrollTarget = Math.max(scrollValue, -MAX_VISIBLE_TABS_WIDTH);

    // if previous index is the same, manually trigger onIndexChanged. It won't otherwise.
    if (previousIndex === index)
      this.$onIndexChanged({
        previousIndex,
        index,
        dataLength: this._Programs.items.length,
      });
    return this._scrollTarget;
  }

  private scrollRestricted(itemWrapper: any, indexData: IndexData) {
    const { previousIndex, index } = indexData;

    const scrolledRowStartX = Math.abs(this._Programs.wrapper.getSmooth('x')); // first visible time

    const scrollValue = -scrolledRowStartX;
    this._focusProcessing = false;
    this.scrollTarget = Math.max(scrollValue, -MAX_VISIBLE_TABS_WIDTH);

    // if previous index is the same, manually trigger onIndexChanged. It won't otherwise.
    if (previousIndex === index)
      this.$onIndexChanged({
        previousIndex,
        index,
        dataLength: this._Programs.items.length,
      });

    return this._scrollTarget;
  }

  setIndex(index: number) {
    this._focusIndex = index;
  }

  $onIndexChanged(indexData: IndexData) {
    const { index } = indexData;
    this._focusIndex = index;

    this.fireAncestors('$onProgramIndexChanged', this._scrollTarget);
  }
}
