import { AppData, Metadata } from '@lightningjs/sdk';
import { Merge } from 'types';
import { EpgChannel, Live, Video } from 'types/api/media';
import { Ad } from 'types/player';
import { AdType } from './reportingServiceVideo';
import {
  getCurrentEpgProgram,
  isLive,
  isLiveProgram,
} from 'support/contentUtils';

export default class NielsenAnalytics {
  private readonly nielsenInstance: any;

  private isPaused = false;
  private isAdPlaying = false;
  private isLive = false;
  private contentMetaData: unknown;
  private adMetaData: unknown;
  private sessionId: string | null = null;
  private relativeAdStartTime = 0;
  private isSeeking = false;

  constructor() {
    if (AppData === undefined) {
      throw new Error('AppData not set');
    }
  }

  private lazyUpdateSessionId() {
    if (this.sessionId === null) {
      this.sessionId =
        Date.now().toString(36) + Math.random().toString(36).substring(2);
    }
  }

  private async createRequest(
    event: 'playhead' | 'complete' | 'delete',
    position: number,
    type: 'content' | 'ad' | '',
    metadata: unknown,
  ) {
    if (!AppData) return;

    const deviceInfo = await AppData.device.getDeviceInfo();

    this.lazyUpdateSessionId();

    const payload = {
      devInfo: {
        devId: deviceInfo.adId,
        apn: Metadata.appId(),
        apv: Metadata.appVersion(),
        uoo: `${AppData.privacyService.getDoNotSell()}`,
      },

      metadata: {
        static: {}, // object for measuring static content
        content: type === 'content' ? metadata : {},
        ad: type === 'ad' ? metadata : {},
      },

      event,
      position: `${position}`,
      type, //"content" or "ad"
      utc: `${Date.now()}`, //unix timestamp in milliseconds
    };

    // TODO: remove before PR merge
    if (!AppData.isProduction) {
      console.log('[Nielsen]', payload);
    }

    const request = `${AppData.nielsen.baseUrl}/${AppData.nielsen.appId}/${
      this.sessionId
    }/a?b=${encodeURIComponent(JSON.stringify(payload))}`;

    // Nielsen wants us to send the request as an image request to circumvent CORS issues
    const img = new Image();

    img.src = request;
  }

  private setVideoMetadata(content: Video) {
    this.isLive = false;
    const airDate = this.formatAirDate(new Date(content.airDate));
    const metaData = {
      type: 'content',
      assetid: content.guid,
      program: content.title,
      title: content.title,
      length: content.durationSecs ?? 0,
      airdate: airDate,
      isfullepisode: content.isFullEpisode ? 'y' : 'n',
      crossId1: content.tmsId,
      adloadtype: '2',
      hasAds: '1', // we will always assume to have ads
      progen: content.nielsenGenre, // program genre abbreviation,
    };

    this.contentMetaData = metaData;
  }

  private setLiveMetadata(content: Live) {
    this.isLive = true;
    const airDate = this.formatAirDate(new Date(content.startTime));
    const metaData = {
      type: 'content',
      assetid: content.analytics.guid,
      program: content.analytics?.title,
      title: content.title,
      length: content.durationSecs ?? 0, // for live programs, if duration is unknown pass 0
      airdate: airDate,
      isfullepisode: 'y',
      adloadtype: '2',
      hasAds: '1', // we will always assume to have ads
      progen: content.analytics?.nielsenGenre,
    };

    this.contentMetaData = metaData;
  }

  private setEpgProgramMetadata(channel: EpgChannel) {
    this.isLive = true;
    const epgProgram = getCurrentEpgProgram(channel);
    const airDate = epgProgram
      ? this.formatAirDate(new Date(epgProgram.startTime))
      : '';
    const metaData = {
      type: 'content',
      assetid: epgProgram?.assetId ?? '',
      program: epgProgram?.title ?? '',
      title: epgProgram?.title ?? '',
      length: epgProgram?.durationSecs ?? 0, // for live programs, if duration is unknown pass 0
      airdate: airDate,
      isfullepisode: 'y',
      adloadtype: '2',
      hasAds: '1', // we will always assume to have ads
    };

    this.contentMetaData = metaData;
  }

  private reportPlayhead(currentTime: number) {
    if (this.isAdPlaying) {
      this.createRequest(
        'playhead',
        this.getPlayhead(currentTime),
        'ad',
        this.adMetaData,
      );
    } else {
      this.createRequest(
        'playhead',
        this.getPlayhead(currentTime),
        'content',
        this.contentMetaData,
      );
    }
  }

  setContentMetadata(content: Merge<Merge<Video, Live>, EpgChannel>) {
    if (isLiveProgram(content)) {
      this.setEpgProgramMetadata(content as EpgChannel);
    } else if (isLive(content)) {
      this.setLiveMetadata(content as Live);
    } else {
      this.setVideoMetadata(content as Video);
    }
  }

  setAdMetadata(ad: Ad, adType: AdType) {
    let type;
    if (adType === 'pre-roll') {
      type = 'preroll';
    } else if (adType === 'mid-roll') {
      type = 'midroll';
    } else {
      type = 'postroll';
    }
    const assetid = ad.getAdId();

    this.adMetaData = { type, assetid };
  }

  handleHeartbeat(currentTime: number) {
    if (this.isPaused) return;

    this.reportPlayhead(currentTime);
  }

  reportStart(currentTime: number) {
    this.isPaused = false;
    this.isAdPlaying = false;
    this.reportPlayhead(currentTime);
  }

  reportPaused(currentTime: number) {
    this.isPaused = true;
    this.reportPlayhead(currentTime);
  }

  reportResume() {
    this.isPaused = false;
  }

  reportBeforeSeek(contentTime: number) {
    this.isSeeking = true;
    this.reportPlayhead(contentTime);
  }

  reportSeekEnd(contentTime: number) {
    // this prevents the case where we snapback to an ad causing us to report the ad time
    if (!this.isSeeking) return;

    this.isSeeking = false;
    this.reportPlayhead(contentTime);
  }

  reportAdPodStart(contentTime: number) {
    // check if we're seeking into an ad
    if (this.isSeeking) {
      this.reportSeekEnd(contentTime);
    }

    this.isAdPlaying = true;
    this.relativeAdStartTime = 0;

    this.reportPlayhead(0);
  }

  reportAdStart() {
    if (this.isAdPlaying) {
      this.reportPlayhead(0);
    }
  }

  reportAdEnd(adTime: number) {
    console.log('[Nielsen] ad end');
    this.reportPlayhead(adTime);
    this.relativeAdStartTime = adTime;
  }

  reportAdPodEnd(contentTime: number) {
    console.log('[Nielsen] ad pod end');
    this.isAdPlaying = false;

    this.reportPlayhead(contentTime);
  }

  reportEnd(currentTime: number, hasCompleted: boolean) {
    if (hasCompleted) return;

    // We report the playhead and nothing else
    this.reportPlayhead(currentTime);
  }

  reportComplete(currentTime: number) {
    if (this.isAdPlaying) return;

    this.reportPlayhead(currentTime);

    // "complete" will only be reported for content
    this.createRequest(
      'complete',
      this.getPlayhead(currentTime),
      'content',
      this.contentMetaData,
    );
  }

  updatePrivacyStatus(ConsentStateOn: boolean) {
    // TODO: confirm how er should handle opting-out/in (the documented way only works in browser)
    // docs: https://engineeringportal.nielsen.com/docs/DCR_Video_Browser_SDK#Privacy_and_Opt-Out
  }

  endSession() {
    this.createRequest('delete', 0, '', {});
  }

  private getPlayhead(time: number) {
    if (this.isLive && !this.isAdPlaying) {
      return Math.floor(Date.now() / 1000); // we use the current Unix timestamp for live content
    } else if (this.isAdPlaying) {
      const adjustedTime = time - this.relativeAdStartTime;

      return Math.floor((adjustedTime < 0 ? 0 : adjustedTime) / 1000);
    }

    return Math.floor(time / 1000);
  }

  // formats to "yyyymmdd hh:mm:ss"
  private formatAirDate(date: Date): string {
    const year = `${date.getFullYear()}`;
    let month = `${date.getMonth() + 1}`;
    let day = `${date.getDay() + 1}`;

    let hour = `${date.getHours()}`;
    let minute = `${date.getMinutes()}`;
    let second = `${date.getSeconds()}`;

    if (month.length === 1) month = `0${month}`;
    if (day.length === 1) day = `0${day}`;
    if (hour.length === 1) hour = `0${hour}`;
    if (minute.length === 1) minute = `0${minute}`;
    if (second.length === 1) second = `0${second}`;

    const ymd = `${year}${month}${day}`;
    const hms = `${hour}:${minute}:${second}`;

    return `${ymd} ${hms}`;
  }
}
