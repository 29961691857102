import { Lightning, Registry } from '@lightningjs/sdk';
import BillboardProgressBar from 'components/common/progressBar/BillboardProgressBar';
import Billboard from 'components/common/Billboard';
import { PromoLane } from 'services/cwData';
import { CarouselWithSpeech } from 'components/common/CollectionWrappersWithSpeech';
import Router from '@lightningjs/sdk/src/Router';
import { constants } from 'aliases';
import ContentHubTitle from 'components/common/ContentHubTitle';

export interface BillboardCarouselTemplateSpec
  extends Lightning.Component.TemplateSpec {
  lane: PromoLane | null;
  fade: number;
  isHero: boolean;
  Content: {
    BillboardCarousel: typeof CarouselWithSpeech;
    BillboardIndicators: Lightning.Component;
    Title: typeof ContentHubTitle;
  };
}

const ROW_HEIGHT = 601;

const BILLBOARD_INDICATOR_Y = 494;

const BILLBOARD_INDICATOR_MARGIN = 10;
const BILLBOARD_WIDTH = 1758;

const NEXT_BILLBOARD_MILLISECONDS = 5000;
const NEXT_BILLBOARD_DELAY_MILLISECONDS = 1000;

const CONTENT_PADDING_X = constants.ui.contentHubPagePaddingX;
const TITLE_HEIGHT = 52;

const TITLE_BOTTOM_Y = 536;

export default class BillboardCarousel
  extends Lightning.Component<BillboardCarouselTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<BillboardCarouselTemplateSpec>
{
  private _lane: BillboardCarouselTemplateSpec['lane'] = null;
  private _fade = 0;
  private _pauseAutoScroll = false;
  private _autoScrollOnResume = false;
  private _hasNavbar = true;
  private _pageTitle = '';

  private _Content = this.getByRef('Content')!;
  private _BillboardCarousel = this._Content.getByRef('BillboardCarousel')!;
  private _BillboardIndicators = this._Content.getByRef('BillboardIndicators')!;
  private _Title = this._Content.getByRef('Title')!;

  private nextBillboardTimeoutId: number | null = null;

  isHero = false;

  get lane() {
    return this._lane;
  }

  set lane(lane: PromoLane | null) {
    this._BillboardIndicators.visible = !!lane && lane.items.length > 1;
    this._pauseAutoScroll = !this._BillboardIndicators.visible;
    this._lane = lane;
    this.updateBillboards();
  }

  get fade() {
    return this._fade;
  }

  set fade(fade: number) {
    this._fade = fade;
    this.updateBillboards();
  }

  set hasNavbar(hasNavbar: boolean) {
    this._hasNavbar = hasNavbar;
  }

  set pageTitle(pageTitle: string) {
    this._pageTitle = pageTitle;
  }

  static get height() {
    return ROW_HEIGHT;
  }

  static override _template(): Lightning.Component.Template<BillboardCarouselTemplateSpec> {
    return {
      Content: {
        BillboardCarousel: {
          type: CarouselWithSpeech,
          direction: 'row',
          itemType: Billboard,
          scroll: 0,
          signals: {
            onIndexChanged: '_onIndexChanged',
          },
          w: 1920,
        },
        BillboardIndicators: {
          x: CONTENT_PADDING_X,
          y: BILLBOARD_INDICATOR_Y,
          flex: { direction: 'row' },
        },
        Title: {
          type: ContentHubTitle,
          w: BILLBOARD_WIDTH - CONTENT_PADDING_X * 2,
          h: TITLE_HEIGHT,
          y: TITLE_BOTTOM_Y,
        },
      },
    };
  }

  override _getFocused() {
    return this._BillboardCarousel;
  }

  override _active() {
    window.backgroundingService.addBackgroundingAction(
      'billboardBackgrounded',
      this._onBackgrounded.bind(this),
    );
    window.backgroundingService.addForegroundingAction(
      'billboardForegrounded',
      this._onForegrounded.bind(this),
    );
    if (this._BillboardIndicators.visible) {
      this._pauseAutoScroll = false;
      this.setBillboardIndicatorSelected(this._BillboardCarousel.index, true);
    }
  }

  override _inactive() {
    window.backgroundingService.removeBackgroundingAction(
      'billboardBackgrounded',
    );
    window.backgroundingService.removeForegroundingAction(
      'billboardForegrounded',
    );
    this.resetTimeoutId();
    this.setBillboardIndicatorSelected(this._BillboardCarousel.index, false);
  }

  private _onBackgrounded() {
    this._pauseAutoScroll = true;
  }

  private _onForegrounded() {
    if (!this._BillboardIndicators.visible) return;

    this._pauseAutoScroll = false;
    if (this._autoScrollOnResume) {
      this._autoScrollOnResume = false;

      // needs to delay this as calling right too early causes animations to be skipped,
      // billboard indicators would be completely filled unintentionally.
      this.nextBillboardTimeoutId = Registry.setTimeout(
        this.autoScrollRight.bind(this),
        NEXT_BILLBOARD_DELAY_MILLISECONDS,
      );
    }
  }

  private _onIndexChanged(event: { index: number; previousIndex: number }) {
    this.resetTimeoutId();
    this.setBillboardIndicatorSelected(event.index, true);
    this.setBillboardIndicatorSelected(event.previousIndex, false);
  }

  private updateBillboards() {
    if (!this.lane) return;

    this._Title.isBillboard = true;
    this._Title.hasNavbar = this._hasNavbar;
    this._Title.title = this.pageTitle;

    const isSinglePromo = this.lane.items.length === 1;
    const Billboards = this.lane.items.map((item, index) => ({
      type: Billboard,
      hasNavbar: this._hasNavbar,
      isSinglePromo,
      mediaItem: item,
      fade: this.fade,
      isHero: this.isHero,
      carouselIndex: index,
      notifyWhenFullyVisible: true,
      w: this.hasNavbar ? Billboard.width : 1920,
    }));

    this._BillboardCarousel.items = Billboards;

    if (this._BillboardIndicators.visible) {
      const BillboardIndicators = this.lane.items.map(() => ({
        type: BillboardProgressBar,
        duration: NEXT_BILLBOARD_MILLISECONDS / 1000,
        delay: NEXT_BILLBOARD_DELAY_MILLISECONDS / 1000,
        flexItem: { marginRight: BILLBOARD_INDICATOR_MARGIN },
      }));

      this._BillboardIndicators.children = BillboardIndicators;
    }
  }

  private setBillboardIndicatorSelected(index: number, selected: boolean) {
    if (index < 0 || !this._BillboardIndicators.visible) return;
    this._BillboardIndicators.childList
      .getAt(index)
      ?.patch({ selected: selected });

    if (selected) {
      this.resetTimeoutId();
      this.nextBillboardTimeoutId = Registry.setTimeout(
        this.autoScrollRight.bind(this),
        NEXT_BILLBOARD_MILLISECONDS + NEXT_BILLBOARD_DELAY_MILLISECONDS,
      );
    }
  }

  private resetTimeoutId() {
    if (this.nextBillboardTimeoutId !== null) {
      Registry.clearTimeout(this.nextBillboardTimeoutId);
      this.nextBillboardTimeoutId = null;
    }
  }

  private autoScrollRight() {
    if (Router.isNavigating()) return;
    if (this._pauseAutoScroll) {
      this._autoScrollOnResume = true;
      return;
    }
    this._BillboardCarousel.right();
  }
}
