import { Lightning } from '@lightningjs/sdk';
import FocusableText from './FocusableText';
import { HoverableListItem } from '../HoverableListItem';

export interface TabTemplateSpec extends Lightning.Component.TemplateSpec {
  label: string;
  activeTab: boolean;
  labelStyles: {
    focused: Lightning.textures.TextTexture.Settings;
    unfocused: Lightning.textures.TextTexture.Settings;
    active: Lightning.textures.TextTexture.Settings;
  };
  containerStyles: {
    focused: Lightning.Element.PatchTemplate;
    unfocused: Lightning.Element.PatchTemplate;
    active: Lightning.Element.PatchTemplate;
  };
  onLoad?: () => void;

  Tab: {
    Label: typeof FocusableText;
  };
}

export default class Tab
  extends HoverableListItem<TabTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<TabTemplateSpec>
{
  private _label = '';
  private _activeTab = false;
  private _labelStyles = { focused: {}, unfocused: {}, active: {} };
  private _containerStyles = { focused: {}, unfocused: {}, active: {} };
  private _onLoad: TabTemplateSpec['onLoad'];
  private _hasRenderedOnce = false;

  private _Tab = this.getByRef('Tab')!;
  private _Label = this._Tab.getByRef('Label')!;

  get title() {
    return this.label;
  }

  get label() {
    return this._label;
  }

  set label(label: string) {
    this._label = label;
    this._Label.patch({ text: { text: label } });
  }

  get activeTab() {
    return this._activeTab;
  }

  set activeTab(activeTab: TabTemplateSpec['activeTab']) {
    this._activeTab = activeTab;
    this.setTabStyle();
  }

  get labelStyles() {
    return this._labelStyles;
  }

  set labelStyles(labelStyles: TabTemplateSpec['labelStyles']) {
    this._Label.styles = {
      focused: labelStyles.focused,
      unfocused: labelStyles.unfocused,
    };
    this._labelStyles = labelStyles;
  }

  get containerStyles() {
    return this._containerStyles;
  }

  set containerStyles(containerStyles: TabTemplateSpec['containerStyles']) {
    this._containerStyles = containerStyles;
  }

  get onLoad() {
    return this._onLoad;
  }

  set onLoad(onLoad: TabTemplateSpec['onLoad']) {
    this._onLoad = onLoad;
  }

  static override _template(): Lightning.Component.Template<TabTemplateSpec> {
    return {
      Tab: {
        Label: {
          type: FocusableText,
        },
      },
    };
  }

  override _setup() {
    super._setup();
    this._Label.on('txLoaded', () => {
      this.w = this._Label.finalW || this._Label.w;
      this.h = this._Label.finalH || this._Label.h;

      // Note: this emit event does not seem to be catchable by the parent so we will use this callback instead
      // this.emit('txLoaded', this._Label.text!);
      if (!this._hasRenderedOnce) {
        this.onLoad?.();
        this._hasRenderedOnce = true;
      }
    });

    this.setTabStyle();
  }

  override _focus() {
    this.setTabStyle();
  }

  override _unfocus() {
    this.setTabStyle();
  }

  override _handleEnter() {
    this.fireAncestors('$onTabSelected', this);
  }

  private setTabStyle() {
    if (this.hasFocus()) {
      this.patch(this.containerStyles.focused);
      this._Label._focus();
    } else {
      this.patch(this.containerStyles.unfocused);
      this._Label._unfocus();
    }
    // 1. if the tab is not active, we should not use the active style
    // 2. if the tab is focused, we should use the focused style rather than the active style
    if (!this.activeTab || this.hasFocus()) return;

    const activeStyle = {
      ...this._Label.styles.focused,
      ...this.labelStyles.active,
    };
    const activeContainerStyle = {
      ...this.containerStyles.focused,
      ...this.containerStyles.active,
    };
    this.patch(activeContainerStyle);
    this._Label.patch({ text: activeStyle });
  }
}
