export const loadImaDaiScript = async () => {
  return new Promise<void>((resolve) => {
  const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://imasdk.googleapis.com/js/sdkloader/ima3_dai.js';
    script.onload = () => {
      if (
        typeof window.google === 'undefined' ||
        !window.google.ima?.dai?.api?.StreamManager
      ) {
        // TODO error handling if google ima scripts did not load on boot
        throw new Error('ima3_dai.js failed to load');
      }
      resolve();
    };
    document.head.appendChild(script);
  })
}