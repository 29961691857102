import { Colors, Lightning } from '@lightningjs/sdk';
import { STANDARD_EXPANSION, STANDARD_TRANSLATION } from 'support/animations';

const HIGHLIGHT_RADIUS = 13;
const HIGHLIGHT_STROKE = 4;

export default class Highlight
  extends Lightning.Component
  implements Lightning.Component
{
  static override _template() {
    return {
      rect: true,
      color: Colors('transparent').get(),
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: HIGHLIGHT_RADIUS,
        stroke: HIGHLIGHT_STROKE,
        strokeColor: Colors('highlight').get(),
      },
      transitions: { ...STANDARD_TRANSLATION, ...STANDARD_EXPANSION },
    };
  }
}
