import { AppData } from '@lightningjs/sdk';
// @ts-ignore
import processEnv from 'processEnv';
import { AnnouncerIntegration } from './platforms/AbstractAnnouncerIntegration';
import { createTizenAnnouncerIntegration } from './platforms/tizen/TizenAnnouncerIntegration';
import { createVizioAnnouncerIntegration } from './platforms/vizio/VizioAnnouncerIntegration';
import { createLGAnnouncerIntegration } from './platforms/lg/LGAnnouncerIntegration';
import { createUnknownAnnouncerIntegration } from './platforms/unknown/UnknownAnnouncerIntegration';

let AnnouncerIntegrationClass: AnnouncerIntegration;
switch (processEnv.APP_PLATFORM) {
  case 'tizen':
    AnnouncerIntegrationClass = createTizenAnnouncerIntegration();
    break;
  case 'vizio':
    AnnouncerIntegrationClass = createVizioAnnouncerIntegration();
    break;
  case 'lg':
    AnnouncerIntegrationClass = createLGAnnouncerIntegration();
    break;
  case 'unknown':
  default:
    AnnouncerIntegrationClass = createUnknownAnnouncerIntegration();
    break;
}

export default class Announcer extends AnnouncerIntegrationClass {
  override announcerEnabled = AppData!.device.getAnnouncerEnabled();
  private _tempAnnouncerValue = this.announcerEnabled;
  private onSpeakEvent: ((isSpeaking: boolean) => void) | null = null;
  private isSpeaking = false;

  protected initializeAnnouncer() {
    this.announcerEnabled = AppData!.device.getAnnouncerEnabled();
    this.stage.on<any>('announceEnded', this.onVoiceOutDone.bind(this));

    this.setupAnnouncerChange();
  }

  protected announcerHandleLoadingStart() {
    this._tempAnnouncerValue = this.announcerEnabled;
    this.announcerEnabled = false;
  }

  protected announcerHandleLoadingEnd() {
    this.announcerEnabled = this._tempAnnouncerValue;
  }

  getIsSpeaking = () => {
    return this.announcerEnabled && this.isSpeaking;
  };

  setOnSpeakEvent = (fn: (isSpeaking: boolean) => void) => {
    this.onSpeakEvent = fn;
  };

  removeOnSpeakEvent = () => {
    this.onSpeakEvent = null;
  };

  override _setup() {
    AppData!.announcerService = {
      getIsSpeaking: this.getIsSpeaking,
      setOnSpeakEvent: this.setOnSpeakEvent,
      removeOnSpeakEvent: this.removeOnSpeakEvent,
    };
  }

  override onVoiceOutStart() {
    if (this.isSpeaking) return;

    this.isSpeaking = true;
    this.onSpeakEvent?.(true);
  }

  override onVoiceOutDone() {
    if (!this.isSpeaking) return;

    this.isSpeaking = false;
    this.onSpeakEvent?.(false);
  }

  private setupAnnouncerChange(): void {
    AppData?.device.handleAnnouncerChange((announcerEnabled: boolean) => {
      this.announcerEnabled = announcerEnabled;
    });
  }
}
