import Tile, { TileTemplateSpec } from './Tile';
import { Show } from 'types/api/media';

export interface WatchHistoryRecommendationTileTemplateSpec
  extends TileTemplateSpec {
  data: Show;
}

const TILE_WIDTH = 377;
const TILE_HEIGHT = 212;

export default class WatchHistoryRecommendationTile extends Tile<WatchHistoryRecommendationTileTemplateSpec> {
  static override get width() {
    return TILE_WIDTH;
  }

  static override get height() {
    return TILE_HEIGHT;
  }

  static override get highlightWidth() {
    return TILE_WIDTH;
  }

  static override get highlightHeight() {
    return TILE_HEIGHT;
  }
}
