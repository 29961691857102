// Commented out keys are not registered, and do not yet have custom usage within the app
export const CommonKeyMapping = {
  // numbers
  48: '0',
  49: '1',
  50: '2',
  51: '3',
  52: '4',
  53: '5',
  54: '6',
  55: '7',
  56: '8',
  57: '9',
  // 189: 'Minus',

  // Media Control Keys
  19: 'MediaPause',
  412: 'MediaRewind',
  413: 'MediaStop',
  415: 'MediaPlay',
  417: 'MediaFastForward',
  418: 'MediaTrackNext',
  419: 'MediaTrackPrevious',

  // 33: 'PageUp',
  // 34: 'PageDown',
  // 500: 'PrevCh',

  // color
  // 403: 'ColorF0Red',
  // 404: 'ColorF1Green',
  // 405: 'ColorF2Yellow',
  // 406: 'ColorF3Blue',
} as const;
