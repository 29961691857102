import FeaturedTile from './FeaturedTile';

const TILE_WIDTH = 385;
const TILE_HEIGHT = 577;

const TILE_WIDTH_EXPANDED = 1022;
const TILE_HEIGHT_EXPANDED = 577;

const CONTENT_PADDING_Y = 56;
const DETAILS_OFFSET_Y = 192;

const LOGO_WIDTH = 463;
const LOGO_HEIGHT = 189;

const BUTTON_Y = 219;

export default class VerticalFeaturedTile extends FeaturedTile {
  static override get width() {
    return TILE_WIDTH;
  }

  static override get height() {
    return TILE_HEIGHT;
  }

  static override get highlightWidth() {
    return TILE_WIDTH;
  }

  static override get highlightHeight() {
    return TILE_HEIGHT;
  }

  static override get expandedWidth() {
    return TILE_WIDTH_EXPANDED;
  }

  static override get expandedHeight() {
    return TILE_HEIGHT_EXPANDED;
  }

  static override get contentPaddingY() {
    return CONTENT_PADDING_Y;
  }

  static override get detailsOffsetY() {
    return DETAILS_OFFSET_Y;
  }

  static override get logoWidth() {
    return LOGO_WIDTH;
  }

  static override get logoHeight() {
    return LOGO_HEIGHT;
  }

  static override get buttonY() {
    return BUTTON_Y;
  }
}
