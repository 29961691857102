import { Lightning, Colors, AppData } from '@lightningjs/sdk';
import { getImageTextureObj } from 'support/generalUtils';
import { STANDARD_FADE } from 'support/animations';
import { HoverableListItem } from 'components/common/HoverableListItem';
import constants from '../../../static/constants.json';

export interface NavBarTabTemplateSpec
  extends Lightning.Component.TemplateSpec {
  slug: string;
  icons: {
    active: string;
    inactive: string;
  };
  selected: boolean;
  title: string;
  showTitle: boolean;
  paddingTop: number;

  Icon: {
    Active: Lightning.Texture;
    Inactive: Lightning.Texture;
  };
  FocusIndicator: Lightning.Texture;
  Title: Lightning.Element;
}

const INVISIBLE = constants.ui.invisible;
const VISIBLE = 1;

const ICON_SIZE = 40;
const ICON_MARGIN_LEFT = 56;
const ICON_MARGIN_RIGHT = 20;

const FOCUS_INDICATOR_HEIGHT = 45;
const FOCUS_INDICATOR_WIDTH = 7;
const FOCUS_INDICATOR_MARGIN_RIGHT = 30;

const TITLE_FONT_SIZE = 24;

const TAB_TRANSITION = {
  w: {
    duration: 0.3,
    timingFunction: 'ease-out',
  },
} as const;

export default class NavBarTab
  extends HoverableListItem<NavBarTabTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<NavBarTabTemplateSpec>
{
  private _slug = '';
  private _icons: NavBarTabTemplateSpec['icons'] | null = null;
  private _selected = false;

  private _Icon = this.getByRef('Icon')!;
  private _ActiveIcon = this._Icon.getByRef('Active')!;
  private _InactiveIcon = this._Icon.getByRef('Inactive')!;

  private _FocusIndicator = this.getByRef('FocusIndicator')!;
  private _Title = this.getByRef('Title')!;

  set slug(slug: string) {
    this._slug = slug;
  }

  get slug() {
    return this._slug;
  }

  set icons(icons: NavBarTabTemplateSpec['icons']) {
    this._icons = icons;
    this.updateIconTextures();
    this.updateIconAlpha();
  }

  set selected(selected: boolean) {
    this._selected = selected;
    this.updateFocusIndicator();
  }

  get title() {
    return this._Title.text?.text ?? '';
  }

  set title(title: string) {
    this._Title.patch({ text: { text: title } });
  }

  set showTitle(show: boolean) {
    const alpha = show ? 1 : 0;
    this._Title.setSmooth('alpha', alpha);
  }

  set paddingTop(paddingTop: number) {
    this.patch({ y: paddingTop });
  }

  static override _template(): Lightning.Component.Template<NavBarTabTemplateSpec> {
    return {
      zIndex: 1,
      flex: {
        direction: 'row',
        alignItems: 'center',
      },
      rect: true,
      color: Colors('transparent').get(),
      transitions: TAB_TRANSITION,
      Icon: {
        w: ICON_SIZE,
        h: ICON_SIZE,
        Active: {
          alpha: INVISIBLE,
        },
        Inactive: {
          alpha: VISIBLE,
        },
        flexItem: {
          marginLeft: ICON_MARGIN_LEFT,
          marginRight: ICON_MARGIN_RIGHT,
        },
      },
      FocusIndicator: {
        flexItem: {
          marginRight: FOCUS_INDICATOR_MARGIN_RIGHT,
        },
        alpha: INVISIBLE,
        w: FOCUS_INDICATOR_WIDTH,
      },
      Title: {
        transitions: STANDARD_FADE,
        alpha: INVISIBLE,
        y: 2, // Offset for centering text
        text: {
          fontSize: TITLE_FONT_SIZE,
          textColor: Colors('text').get(),
        },
      },
    };
  }

  override _getFocused() {
    return this;
  }

  override _focus() {
    this.updateBackground();
    this.updateFocusIndicator();
    this.updateIconAlpha();
    this.updateTitle();
  }

  override _unfocus() {
    this.updateBackground();
    this.updateFocusIndicator();
    this.updateIconAlpha();
    this.updateTitle();
  }

  override _handleEnter() {
    if (this._selected) return true; // prevent action

    return false; // bubble this up to the NavBar
  }

  override _handleHover() {
    if (AppData?.device.hoverDisabled()) {
      return true;
    }

    return super._handleHover(this);
  }

  override _handleClick() {
    if (this._selected) return true; // prevent action

    return false; // bubble this up to the NavBar
  }

  updateBackground() {
    const color = this.hasFocus()
      ? Colors('highlight').get()
      : Colors('transparent').get();
    this.patch({ color });
  }

  updateFocusIndicator() {
    if (!this._selected) {
      this._FocusIndicator.patch({ alpha: INVISIBLE });
    } else {
      let focusIndicatorPath =
        'static/images/navigation/navigation-selection-indicator';
      focusIndicatorPath += this.hasFocus() ? '-black.svg' : '-hot-sauce.svg';

      const focusIndicatorTexture = getImageTextureObj(
        focusIndicatorPath,
        FOCUS_INDICATOR_WIDTH,
        FOCUS_INDICATOR_HEIGHT,
      );
      this._FocusIndicator.patch({
        ...focusIndicatorTexture,
        alpha: VISIBLE,
      });
    }
  }

  updateIconTextures() {
    if (!this._icons) return;
    const { active, inactive } = this._icons;

    const activeIconTexture = getImageTextureObj(active, ICON_SIZE, ICON_SIZE);
    this._ActiveIcon.patch(activeIconTexture);

    const inactiveIconTexture = getImageTextureObj(
      inactive,
      ICON_SIZE,
      ICON_SIZE,
    );
    this._InactiveIcon.patch(inactiveIconTexture);
  }

  updateIconAlpha() {
    const activeIconAlpha = this.hasFocus() ? VISIBLE : INVISIBLE;
    this._ActiveIcon.patch({ alpha: activeIconAlpha });

    const inactiveIconAlpha = this.hasFocus() ? INVISIBLE : VISIBLE;
    this._InactiveIcon.patch({ alpha: inactiveIconAlpha });
  }

  updateTitle() {
    const textColor = this.hasFocus()
      ? Colors('activeText').get()
      : Colors('text').get();

    this._Title.patch({ text: { textColor } });
  }
}
