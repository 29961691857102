// @ts-ignore
import processEnv from 'processEnv';
import TriggeredPromiseManager from 'support/TriggeredPromiseManager';
import {
  AddHeader,
  ContinueWatchingItem,
  DeleteHeader,
  GetHeader,
  RequestOptions,
  ServiceRequestData,
  ServiceResponseData,
} from 'types/tizen';
import { TizenDeviceIntegration } from './TizenDeviceIntegration';

type MessageKey =
  | 'logs'
  | 'getResult'
  | 'addResult'
  | 'deleteResult'
  | 'getError'
  | 'addError'
  | 'deleteError';
type RequestKey = 'get' | 'add' | 'delete';

const REQUEST_TIMEOUT = 5000; // queries on ~2024 TV's can take longer then 2 seconds

const DEFAULT_OPTIONS = {
  hostname: 'localhost',
  port: 9013,
} as const;

// docs: https://docs.tizen.org/application/web/guides/applications/service-app/
export class TizenServiceIntegration {
  private readonly triggeredPromiseManager: TriggeredPromiseManager;
  private readonly decoder = new TextDecoder('utf-8');
  private readonly appId: string;
  private readonly packageId: string;
  private readonly serviceId: string;
  private readonly headerAppId: `${string}.${string}`;

  constructor(tizenIntegration: TizenDeviceIntegration) {
    this.packageId = tizenIntegration.getPackageId();
    this.appId = tizenIntegration.getAppId().split('.')[1]!;
    this.serviceId = processEnv['APP_BG_SERVICE_ID'] as string;
    this.headerAppId = `${this.packageId}.${this.appId}`;

    this.triggeredPromiseManager = new TriggeredPromiseManager(REQUEST_TIMEOUT);

    //messages from BG service
    const localPort = tizen.messageport.requestLocalMessagePort(
      `${this.packageId}_CW_PORT`,
    );

    localPort.addMessagePortListener((res, replyPort) => {
      for (let i = 0; i < res.length; i++) {
        const currentRes = res[i];
        if (currentRes === undefined) continue;

        const key = currentRes.key as MessageKey;

        if (key === 'logs') {
          console.log('[CW Service][Service]', currentRes.value);
        } else if (
          key === 'getResult' ||
          key === 'addResult' ||
          key === 'deleteResult'
        ) {
          const value = currentRes.value;

          const { requestId, data: response }: ServiceResponseData =
            typeof value === 'string' ? JSON.parse(value) : value;

          let passedData: unknown = response?.data;
          if (response?.type === 'Buffer') {
            const buffer = new Uint8Array(response.data as number[]);

            passedData = JSON.parse(this.decoder.decode(buffer));
          }

          this.triggeredPromiseManager.trigger(requestId, passedData);

          console.log(
            `[CW Service][Client] ${key} service response requestId:${requestId} data:${JSON.stringify(
              passedData,
            )}`,
          );
        } else if (
          key === 'getError' ||
          key === 'addError' ||
          key === 'deleteError'
        ) {
          console.error('[CW Service][Service] ', currentRes.value);
        } else {
          console.log(`[CW Service][Client] unhandled message: ${key}`);
        }
      }
    });
  }

  async getItems(): Promise<ContinueWatchingItem[]> {
    const { id, promise } = this.triggeredPromiseManager.create<
      ContinueWatchingItem[] | undefined
    >();

    console.log(`[CW Service][client] getItems ${id}`);

    const options: RequestOptions<GetHeader> = {
      ...DEFAULT_OPTIONS,
      path: '/?module=EBHelper&func=GetItems',
      method: 'GET',
      headers: { app_id: this.headerAppId },
    };

    this.launchAppControl('get', { requestId: id, options });

    const result = await promise;
    return result ?? [];
  }

  async addItem(item: ContinueWatchingItem) {
    const { id, promise } = this.triggeredPromiseManager.create();

    console.log(`[CW Service][client] addItem ${id}`);

    const options: RequestOptions<AddHeader> = {
      ...DEFAULT_OPTIONS,
      path: '/?module=EBHelper&func=AddItem',
      headers: {
        app_id: this.headerAppId,
        additional_data: '',
        field: '0',
        ...item,
      },
    };

    const requestData: ServiceRequestData<AddHeader> = {
      requestId: id,
      options,
    };

    this.launchAppControl('add', requestData);
    return promise;
  }

  async deleteItem(contentId: string) {
    const { id, promise } = this.triggeredPromiseManager.create();

    console.log(`[CW Service][client] deleteItem ${id}`);

    const options: RequestOptions<DeleteHeader> = {
      ...DEFAULT_OPTIONS,
      path: '/?module=EBHelper&func=DeleteItem',
      headers: {
        app_id: this.headerAppId,
        field: '0',
        content_id: contentId,
      },
    };

    const requestData: ServiceRequestData<DeleteHeader> = {
      requestId: id,
      options,
    };

    this.launchAppControl('delete', requestData);
    return promise;
  }

  async deleteAllItems() {
    const { id, promise } = this.triggeredPromiseManager.create();

    console.log(`[CW Service][client] deleteAllItems ${id}`);

    const options: RequestOptions<DeleteHeader> = {
      ...DEFAULT_OPTIONS,
      path: '/?module=EBHelper&func=DeleteItem',
      headers: {
        app_id: this.headerAppId,
        field: '0',
        content_id: 'ALL',
      },
    };

    const requestData: ServiceRequestData<DeleteHeader> = {
      requestId: id,
      options,
    };

    this.launchAppControl('delete', requestData);
    return promise;
  }

  //Launch CW BG service
  private launchAppControl<T>(key: RequestKey, data: ServiceRequestData<T>) {
    const paramAppId = `${this.packageId}.${this.serviceId}`;
    const dataString = JSON.stringify(data);

    const appData = new tizen.ApplicationControlData(key, [dataString]);
    const appControl = new tizen.ApplicationControl(
      `http://tizen.org/appcontrol/operation/${this.appId}`,
      null,
      null,
      null,
      [appData],
    );

    /*
     * Launch ApplicationControl
     */
    tizen.application.launchAppControl(
      appControl,
      paramAppId,
      () => {
        console.log(
          `[CW Service][Client] service with data ${key}: ${dataString}`,
        );
      },
      error => {
        console.log(
          '[CW Service][Client] service with data error : ' +
            JSON.stringify(error),
        );
      },
    );
  }
}
