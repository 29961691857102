import { debounce } from 'support/generalUtils';

type SetFormIndices = `${number},${number},${number}`;
type ModifyItems<T = unknown> = (items: Item<T>[]) => Item<T>[];
type ReportItems<T = unknown> = (items: Item<T>[]) => void;
export type Item<T = unknown> = { obj: T };

const REPORT_DELAY = 1000;

export default class CollectionVisibilityService {
  private itemsToReport: Record<SetFormIndices, unknown> = {};
  private reportedItems: Record<SetFormIndices, true> = {};

  private reportItems: ReportItems | null = null;
  private modifyItems: ModifyItems | null = null;

  private debounceReportItems = debounce(
    this.handleReportItems.bind(this),
    REPORT_DELAY,
  );

  setModifyItems<T>(modifyItems: ModifyItems<T>) {
    this.modifyItems = modifyItems as ModifyItems;
  }

  initialize<T>(reportItems: ReportItems<T>) {
    this.reportItems = reportItems as ReportItems;
  }

  reset() {
    this.itemsToReport = {};
    this.reportedItems = {};

    this.reportItems = null;
    this.modifyItems = null;
  }

  onFullyVisibleItem(args: {
    row: number;
    col: number;
    group?: number;
    obj: unknown;
  }) {
    const { row, col, group, obj } = args;
    const key = this.indicesToSetForm(row, col, group ?? 0);

    if (this.reportedItems[key]) return;

    this.itemsToReport[key] = obj;
    this.debounceReportItems();
  }

  private handleReportItems() {
    if (!this.reportItems) return;

    const items: Item[] = [];

    Object.entries(this.itemsToReport).forEach(([indices, obj]) => {
      this.reportedItems[indices as SetFormIndices] = true;

      items.push({ obj });
    });

    if (this.modifyItems) {
      this.reportItems(this.modifyItems(items));
    } else {
      this.reportItems(items);
    }

    this.itemsToReport = {};
  }

  private indicesToSetForm(
    row: number,
    col: number,
    group: number,
  ): SetFormIndices {
    return `${row},${col},${group}`;
  }
}
