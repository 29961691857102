import { Colors, Lightning } from '@lightningjs/sdk';
import ToggleButton from 'components/common/ToggleButton';
import { getFocusDepth } from 'support/generalUtils';
import { getFontFaceFromStyle } from 'support/textUtils';
import { translate } from 'support/translate';

const SECTION_PADDING = 28;
const PAGE_PADDING = 89;
const TOGGLE_LABEL_BUTTON_SPACING = 30;

export interface ToggleSettingSectionTemplateSpec
  extends Lightning.Component.TemplateSpec {
  getToggleState(): boolean;
  label: string;
  description: string;

  Toggle: {
    Wrapper: {
      Label: Lightning.textures.TextTexture;
      Button: typeof ToggleButton;
    };
  };
  Description: Lightning.textures.TextTexture;
}

interface ToggleSettingSectionSignalMap extends Lightning.Component.SignalMap {
  onChanged(isActive: boolean): void;
}

interface ToggleSettingSectionTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: ToggleSettingSectionSignalMap;
}

export default class ToggleSettingSection
  extends Lightning.Component<
    ToggleSettingSectionTemplateSpec,
    ToggleSettingSectionTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<ToggleSettingSectionTemplateSpec>
{
  private _getToggleState = () => false;
  private _label = '';
  private _description = '';

  private _Toggle = this.getByRef('Toggle')!;
  private _Wrapper = this._Toggle.getByRef('Wrapper')!;
  private _ToggleLabel = this._Wrapper.getByRef('Label')!;
  private _ToggleButton = this._Wrapper.getByRef('Button')!;
  private _Description = this.getByRef('Description')!;

  get title() {
    return [
      this.label,
      translate(
        'ttsPrompts.toggleButton',
        this.getToggleState()
          ? translate('ttsPrompts.off')
          : translate('ttsPrompts.on'),
      ),
      this.description,
    ];
  }

  get getToggleState() {
    return this._getToggleState;
  }

  set getToggleState(
    getToggleState: ToggleSettingSectionTemplateSpec['getToggleState'],
  ) {
    this._getToggleState = getToggleState;
  }

  get label() {
    return this._label;
  }

  set label(label: ToggleSettingSectionTemplateSpec['label']) {
    this._label = label;
    this._ToggleLabel.patch({ text: { text: label } });
  }

  get description() {
    return this._description;
  }

  set description(
    description: ToggleSettingSectionTemplateSpec['description'],
  ) {
    this._description = description;
    this._Description.patch({ text: { text: description } });
  }

  static override _template(): Lightning.Component.Template<ToggleSettingSectionTemplateSpec> {
    return {
      flex: { direction: 'column' },
      Toggle: {
        h: 60,
        rect: true,
        color: Colors('transparent').get(),
        Wrapper: {
          h: h => h,
          flex: {
            direction: 'row',
            alignItems: 'center',
            paddingLeft: SECTION_PADDING,
            paddingRight: SECTION_PADDING,
          },
          Label: {
            y: 2,
            text: {
              fontFace: getFontFaceFromStyle('regular'),
              fontSize: 32,
              textColor: Colors('text').get(),
            },
          },
          Button: {
            type: ToggleButton,
            flexItem: { marginLeft: TOGGLE_LABEL_BUTTON_SPACING },
            signals: {
              $onCursorClick: '$onCursorClick',
            },
          },
        },
      },
      Description: {
        x: SECTION_PADDING,
        flexItem: { marginTop: 17 },
        text: {
          fontFace: getFontFaceFromStyle('regular'),
          fontSize: 20,
          lineHeight: 30,
          textColor: Colors('text').get(),
          advancedRenderer: true,
        },
      },
    };
  }

  override _setup() {
    this._Toggle.w = 1920 - PAGE_PADDING - (this.parent?.parent?.x ?? 0);
    this._Description.text!.wordWrapWidth =
      1920 - SECTION_PADDING - PAGE_PADDING - (this.parent?.parent?.x ?? 0);
  }

  override _active() {
    this._ToggleButton.isActive = this.getToggleState();
  }

  override _focus() {
    this._Toggle.color = Colors('buttonInactive').get();
  }

  override _unfocus() {
    this._Toggle.color = Colors('transparent').get();
  }

  override _getFocused() {
    return this;
  }

  override _handleEnter() {
    const toggleState = !this.getToggleState();

    this._ToggleButton.isActive = toggleState;
    this.signal('onChanged', toggleState);

    this.fireAncestors(
      '$announcerRefresh',
      getFocusDepth(this.application, this),
    );
  }

  $onCursorClick(target: unknown) {
    this._handleEnter();
  }
}
