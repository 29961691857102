import { Lightning } from '@lightningjs/sdk';
import RadioRowGroup from 'components/common/RadioRowGroup';
import { translate } from 'support/translate';

const ROW_SPACING = 76;

export interface CcAudioMenuTemplateSpec
  extends Lightning.Component.TemplateSpec {
  cc: string[];
  audio: string[];
  ccIndex: number;
  audioIndex: number;

  Subtitles: typeof RadioRowGroup;
  Audio: typeof RadioRowGroup;
}

interface CcAudioMenuSignalMap extends Lightning.Component.SignalMap {
  onSubtitlesChange(option: string): void;
  onAudioChange(option: string): void;
}

interface CcAudioMenuTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: CcAudioMenuSignalMap;
}

export default class CcAudioMenu
  extends Lightning.Component<CcAudioMenuTemplateSpec, CcAudioMenuTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<CcAudioMenuTemplateSpec>
{
  private _cc: CcAudioMenuTemplateSpec['cc'] = [];
  private _audio: CcAudioMenuTemplateSpec['audio'] = [];

  private _Subtitles = this.getByRef('Subtitles')!;
  private _Audio = this.getByRef('Audio')!;

  get title() {
    return translate('ttsPrompts.ccAudioMenu');
  }

  get cc() {
    return this._cc;
  }

  set cc(cc: CcAudioMenuTemplateSpec['cc']) {
    this._cc = cc;
    this._Subtitles.options = cc.map(languageKey =>
      translate(`closedCaption.${languageKey}`),
    );
    this._Subtitles.visible = !!cc.length;
  }

  get audio() {
    return this._audio;
  }

  set audio(audio: CcAudioMenuTemplateSpec['audio']) {
    this._audio = audio;
    this._Audio.options = audio.map(languageKey =>
      translate(`closedCaption.${languageKey}`),
    );
    this._Audio.visible = audio ? audio.length > 1 : false;
  }

  get ccIndex() {
    return this._Subtitles.selectedIndex;
  }

  set ccIndex(ccIndex: number) {
    this._Subtitles.selectedIndex = ccIndex;
  }

  get audioIndex() {
    return this._Audio.selectedIndex;
  }

  set audioIndex(audioIndex: number) {
    this._Audio.selectedIndex = audioIndex;
  }

  static override _template(): Lightning.Component.Template<CcAudioMenuTemplateSpec> {
    return {
      flex: { direction: 'column' },
      Subtitles: {
        type: RadioRowGroup,
        label: translate('closedCaption.subtitles'),
        signals: { onChange: '_onSubtitlesChange', $onHover: true },
        visible: false,
      },
      Audio: {
        type: RadioRowGroup,
        label: translate('closedCaption.audio'),
        flexItem: { marginTop: ROW_SPACING },
        signals: { onChange: '_onAudioChange', $onHover: true },
        visible: false,
      },
    };
  }

  override _setup() {
    this._setState('Subtitles');
  }

  private _onSubtitlesChange(index: number) {
    this.signal('onSubtitlesChange', this.cc[index] ?? '');
  }

  private _onAudioChange(index: number) {
    this.signal('onAudioChange', this.audio[index] ?? '');
  }

  $onHover(target: any) {
    switch (target) {
      case this._Subtitles:
        this._setState('Subtitles');
        break;
      case this._Audio:
        this._setState('Audio');
    }
  }

  static override _states(): Lightning.Component.Constructor<
    Lightning.Component<
      Lightning.Component.TemplateSpecLoose,
      Lightning.Component.TypeConfigLoose
    >
  >[] {
    return [
      class Subtitles extends this {
        override _getFocused() {
          return this._Subtitles;
        }

        override _handleDown() {
          if (this._Audio.visible) this._setState('Audio');
        }
      },
      class Audio extends this {
        override _getFocused() {
          return this._Audio;
        }

        override _handleUp() {
          if (this._Subtitles.visible) this._setState('Subtitles');
        }
      },
    ];
  }
}
