import { Colors, Lightning } from '@lightningjs/sdk';
import { translate } from 'support/translate';

export interface OnDemandBadgeTemplateSpec
  extends Lightning.Component.TemplateSpec {
  fontSize: number;

  Background: Lightning.textures.RectangleTexture;
  OnDemandText: Lightning.textures.TextTexture;
}

export default class OnDemandBadge
  extends Lightning.Component<OnDemandBadgeTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<OnDemandBadgeTemplateSpec>
{
  private _Background = this.getByRef('Background');
  private _OnDemandText = this.getByRef('OnDemandText')!;

  get fontSize() {
    return this._OnDemandText.text!.fontSize;
  }

  set fontSize(fontSize: OnDemandBadgeTemplateSpec['fontSize']) {
    this._OnDemandText.patch({ text: { fontSize } });
  }

  static override _template(): Lightning.Component.Template<OnDemandBadgeTemplateSpec> {
    return {
      Background: {
        rect: true,
        y: -2, // Adjustment for LightningJS centering issues
        w: (w: number) => w,
        h: (h: number) => h,
        color: Colors('white').get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 5,
        },
      },
      OnDemandText: {
        x: (w: number) => w / 2,
        y: (h: number) => h / 2,
        mount: 0.5,
        text: {
          fontSize: 12,
          text: translate('global.onDemand'),
          textColor: Colors('activeText').get(),
        },
      },
    };
  }
}
