import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponse,
  Method,
} from 'axios';
import ApiServiceError from '../support/ApiServiceError';
import { AppData } from '@lightningjs/sdk';

export enum Endpoints {
  CACHES = 'images/c/headers/',
  CONFIG = 'feed/app-2/config/',
  SWIMLANES = 'feed/app-2/landing/swimlanes/',
  EPG = 'feed/app-2/landing/epg/',
  SHOWS = 'feed/app-2/shows/',
  VIDEOS = 'feed/app-2/videos/',
  VIDEO_METADATA = 'feed/app-2/video-meta/',
  VIDEOS_METADATA = 'feed/app-2/videos-meta/',
  TERMS_OF_USE = 'feed/app-2/content/terms-of-use/',
  PRIVACY_POLICY = 'feed/app-2/content/privacy-policy/',
  NIELSEN_MEASUREMENT = 'feed/app-2/content/nielsen-measurement/',
}

export type HttpOptions = {
  data?: unknown;
  params?: unknown;
  headers?: AxiosRequestHeaders;
};

export default class CwApiClient {
  private basePath: string | null = null;
  private auth: string | null = null;

  async request<TResponse = unknown>(
    method: Method,
    endpoint: string,
    options?: HttpOptions,
  ) {
    this.lazyInit();

    const headers = {
      Accept: 'application/json',
      Authorization: this.auth!,
      'Content-Type': 'application/json',
      ...options?.headers,
    };

    try {
      return await axios.request<any, AxiosResponse<TResponse, any>>({
        baseURL: this.basePath!,
        url: endpoint,
        method,
        headers,
        data: options?.data,
        params: options?.params,
        responseType: 'json',
      });
    } catch (e) {
      throw new ApiServiceError(e as AxiosError, endpoint);
    }
  }

  // We initialize lazily to wait for AppData to be constructed
  private lazyInit() {
    if (this.basePath !== null || this.auth !== null) {
      return;
    }

    this.basePath = AppData?.api.baseUrl ?? '';
    const authToken = btoa(`${AppData!.api.username}:${AppData!.api.password}`);
    this.auth = `Basic ${authToken}`;
  }
}
