import { Colors, Lightning } from '@lightningjs/sdk';
import { timeTo12HourClockHoursMinutes } from 'support/dateUtils';
import { getFontFaceFromStyle } from 'support/textUtils';

export interface EpgProgressIndicatorTemplateSpec
  extends Lightning.Component.TemplateSpec {
  time: Date;

  Content: {
    RedIndicator: Lightning.textures.RectangleTexture;
    Time: Lightning.textures.TextTexture;
  };
  BottomIndicator: Lightning.textures.ImageTexture;
}

const CONTENT_HEIGHT = 34;
const CONTENT_X_PADDING = 5;

const RED_INDICATOR_SIZING = 9;
const RED_INDICATOR_RADIUS = 4;
const RED_INDICATOR_MARGIN_RIGHT = 5;

const TIME_FONT_SIZE = 18;

const BOTTOM_INDICATOR_SIZING = 9;
const BOTTOM_INDICATOR_Y = CONTENT_HEIGHT - BOTTOM_INDICATOR_SIZING / 2 - 1;

export default class EpgProgressIndicator
  extends Lightning.Component<EpgProgressIndicatorTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<EpgProgressIndicatorTemplateSpec>
{
  private _Content = this.getByRef('Content')!;
  private _Time = this._Content.getByRef('Time')!;

  set time(time: Date) {
    const date = timeTo12HourClockHoursMinutes(time, true);
    this._Time.patch({ text: date });
  }

  static override _template(): Lightning.Component.Template<EpgProgressIndicatorTemplateSpec> {
    return {
      Content: {
        flex: {
          direction: 'row',
          alignItems: 'center',
          paddingLeft: CONTENT_X_PADDING,
          paddingRight: CONTENT_X_PADDING,
        },
        mountX: 0.5,
        h: CONTENT_HEIGHT,
        rect: true,
        color: Colors('epgProgressIndicator').get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 4,
        },
        RedIndicator: {
          flexItem: { marginRight: RED_INDICATOR_MARGIN_RIGHT },
          w: RED_INDICATOR_SIZING,
          h: RED_INDICATOR_SIZING,
          rect: true,
          color: Colors('liveIndicator').get(),
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: RED_INDICATOR_RADIUS,
          },
        },
        Time: {
          text: {
            fontSize: TIME_FONT_SIZE,
            fontFace: getFontFaceFromStyle('bold'),
          },
        },
      },
      BottomIndicator: {
        y: BOTTOM_INDICATOR_Y,
        mountX: 0.5,
        rotation: Math.PI / 4,
        w: BOTTOM_INDICATOR_SIZING,
        h: BOTTOM_INDICATOR_SIZING,
        rect: true,
        color: Colors('epgProgressIndicator').get(),
      },
    };
  }
}
