import { Platform } from 'models/platforms/platform';
import { Manufacturer } from 'models/platforms/manufacturer';
import {
  AdditionalTizenKeyMapping,
  TizenKeyMapping,
} from 'models/platforms/tizenKeyMappings';
import {
  TizenWindow,
  TvInfoMenuKey,
  TvInfoMenuValue,
} from 'models/platforms/tizenWindow';
import { AppData, Registry } from '@lightningjs/sdk';
import Router from '@lightningjs/sdk/src/Router';
import {
  CcEdgeType,
  translateCcSettingToCss,
} from 'config/platforms/deviceIntegration';
import { ApplicationInstance } from '@lightningjs/sdk/src/Launch';
import {
  getHomeHistoryEntry,
  getLiveEventHistoryEntry,
  getLivePlaybackHistoryEntry,
  getMovieDetailsHistoryEntry,
  getPageIdFromHash,
  getSeriesDetailsHistoryEntry,
  getVodPlaybackHistoryEntry,
  navigateToChannel,
  navigateToContentHub,
} from 'support/routerUtils';
import { PageId } from 'types/pageId';
import { constants } from 'aliases';
import { AbstractDeviceIntegration } from 'config/platforms/AbstractDeviceIntegration';
import { TizenServiceIntegration } from './TizenServiceIntegration';

type TvInfoMenuValueStringMap = Partial<Record<TvInfoMenuValue, string>>;
type TvInfoMenuValueEdgeTypeMap = Partial<Record<TvInfoMenuValue, CcEdgeType>>;
type TvInfoMenuValueNumberMap = Partial<Record<TvInfoMenuValue, number>>;

/**
 * @member CHANNEL Navigates to EPG page
 * @member CONTENT_HUB Navigates to Content Hub page
 * @member EPISODE Navigates to VOD Playback page
 * @member LIVE Navigates to Live Playback page
 * @member LIVE_EVENT Navigates to Live Event Details or Live page
 * @member MOVIE Navigates to Movie Details page
 * @member SERIES Navigates to Series Details page
 **/
enum PAYLOAD_CONTENT_TYPE {
  CHANNEL = 'channel',
  CONTENT_HUB = 'content_hub',
  EPISODE = 'episode',
  LIVE = 'live',
  LIVE_EVENT = 'live_event',
  MOVIE = 'movie',
  SERIES = 'series',
}

type ChannelDeepLinkPayload = {
  content_type: PAYLOAD_CONTENT_TYPE.CHANNEL;
  id?: string;
};
type ContentHubDeepLinkPayload = {
  content_type: PAYLOAD_CONTENT_TYPE.CONTENT_HUB;
  id: string;
};
type EpisodeDeepLinkPayload = {
  content_type: PAYLOAD_CONTENT_TYPE.EPISODE;
  id: string;
};
type LiveDeepLinkPayload = {
  content_type: PAYLOAD_CONTENT_TYPE.LIVE;
  id?: string;
};
type LiveEventDeepLinkPayload = {
  content_type: PAYLOAD_CONTENT_TYPE.LIVE_EVENT;
  id?: string;
};
type MovieDeepLinkPayload = {
  content_type: PAYLOAD_CONTENT_TYPE.MOVIE;
  id: string;
};
type SeriesDeepLinkPayload = {
  content_type: PAYLOAD_CONTENT_TYPE.SERIES;
  id: string;
  season?: number;
};

type DeepLinkPayload =
  | ChannelDeepLinkPayload
  | ContentHubDeepLinkPayload
  | EpisodeDeepLinkPayload
  | LiveDeepLinkPayload
  | LiveEventDeepLinkPayload
  | MovieDeepLinkPayload
  | SeriesDeepLinkPayload;

const FLASH_INTERVAL = constants.timers.subtitleFlashingInterval;
const PERFORMANCE_MODE_MODELS = constants.performanceMode.tizen;

export class TizenDeviceIntegration extends AbstractDeviceIntegration {
  readonly platform = Platform.TIZEN;

  readonly tizen = window.tizen;
  readonly colorMap: TvInfoMenuValueStringMap = {
    [TvInfoMenuValue.CAPTION_COLOR_BLACK]: '#000000',
    [TvInfoMenuValue.CAPTION_COLOR_BLUE]: '#0000ff',
    [TvInfoMenuValue.CAPTION_COLOR_CYAN]: '#00ffff',
    [TvInfoMenuValue.CAPTION_COLOR_DEFAULT]: '#000000',
    [TvInfoMenuValue.CAPTION_COLOR_GREEN]: '#00ff00',
    [TvInfoMenuValue.CAPTION_COLOR_MAGENTA]: '#ff00ff',
    [TvInfoMenuValue.CAPTION_COLOR_RED]: '#ff0000',
    [TvInfoMenuValue.CAPTION_COLOR_WHITE]: '#ffffff',
    [TvInfoMenuValue.CAPTION_COLOR_YELLOW]: '#ffff00',
  };
  readonly opacityMap: TvInfoMenuValueStringMap = {
    [TvInfoMenuValue.CAPTION_OPACITY_DEFAULT]: 'ff',
    [TvInfoMenuValue.CAPTION_OPACITY_FLASH]: 'ff',
    [TvInfoMenuValue.CAPTION_OPACITY_HIGHLY_TRANSLUCENT]: '54',
    [TvInfoMenuValue.CAPTION_OPACITY_SLIGHTLY_TRANSLUCENT]: 'ac',
    [TvInfoMenuValue.CAPTION_OPACITY_SOLID]: 'ff',
    [TvInfoMenuValue.CAPTION_OPACITY_TRANSLUCENT]: '80',
    [TvInfoMenuValue.CAPTION_OPACITY_TRANSPARENT]: '00',
  };
  readonly fontSizeMap: TvInfoMenuValueNumberMap = {
    [TvInfoMenuValue.CAPTION_SIZE_DEFAULT]: 48,
    [TvInfoMenuValue.CAPTION_SIZE_EXTRA_LARGE]: 72,
    [TvInfoMenuValue.CAPTION_SIZE_LARGE]: 60,
    [TvInfoMenuValue.CAPTION_SIZE_SMALL]: 24,
    [TvInfoMenuValue.CAPTION_SIZE_STANDARD]: 36,
  };
  readonly fontFaceMap: TvInfoMenuValueStringMap = {
    [TvInfoMenuValue.CAPTION_FONT_DEFAULT]: 'Roboto',
    [TvInfoMenuValue.CAPTION_FONT_STYLE0]: 'Roboto',
    [TvInfoMenuValue.CAPTION_FONT_STYLE1]: 'Courier',
    [TvInfoMenuValue.CAPTION_FONT_STYLE2]: 'Times New Roman',
    [TvInfoMenuValue.CAPTION_FONT_STYLE3]: 'Helvetica',
    [TvInfoMenuValue.CAPTION_FONT_STYLE4]: 'Arial',
    [TvInfoMenuValue.CAPTION_FONT_STYLE5]: 'Dom',
    [TvInfoMenuValue.CAPTION_FONT_STYLE6]: 'Coronet',
    // @ts-ignore Additional font style not found in docs
    [TvInfoMenuValue.CAPTION_FONT_STYLE7]: 'Engravers Gothic',
  };
  readonly edgeTypeMap: TvInfoMenuValueEdgeTypeMap = {
    [TvInfoMenuValue.CAPTION_EDGE_DEPRESSED]: '2px 0px 2px',
    [TvInfoMenuValue.CAPTION_EDGE_DROP_SHADOWED]: '-4px -4px 4px',
    [TvInfoMenuValue.CAPTION_EDGE_NONE]: '0px 0px 0px',
    [TvInfoMenuValue.CAPTION_EDGE_RAISED]: '-2px -2px 2px',
    [TvInfoMenuValue.CAPTION_EDGE_UNIFORM]: '0px 0px 4px',
  };
  readonly fontStyleMap: TvInfoMenuValueStringMap = {
    [TvInfoMenuValue.CAPTION_STYLE_BOLD]: 'bold',
    [TvInfoMenuValue.CAPTION_STYLE_DEFAULT]: 'normal',
    [TvInfoMenuValue.CAPTION_STYLE_ITALIC]: 'italic',
  };
  readonly serviceIntegration: TizenServiceIntegration;

  private _announcerChangeCallback: ((arg: boolean) => void) | null = null;
  private _flashTimer: number | null = null;
  private _flashOn = true;

  constructor() {
    super();
    this.deviceInfo.manufacturer = Manufacturer.SAMSUNG;

    this.serviceIntegration = new TizenServiceIntegration(this);
  }

  override getKeyMapping() {
    return TizenKeyMapping;
  }

  override getDeviceId(): string {
    if (!this.deviceId) {
      this.deviceId = this.tizen.systeminfo.getCapability(
        'http://tizen.org/system/tizenid',
      );
    }

    return this.deviceId;
  }

  override getPerformanceMode(): boolean {
    return PERFORMANCE_MODE_MODELS.includes(this.deviceInfo.model);
  }

  override getAppId(): string {
    const { id } = this.tizen.application.getAppInfo();
    return id;
  }

  getPackageId(): string {
    const { packageId } = this.tizen.application.getAppInfo();
    return packageId;
  }

  getAppName() {
    const { name } = this.tizen.application.getAppInfo();
    return name;
  }

  getAppIconPath() {
    const { iconPath } = this.tizen.application.getAppInfo();
    return iconPath;
  }

  override async load() {
    return new Promise<void>((resolve, reject) => {
      // To load Tizen web APIs, we need a script call in the heading of our index.html file. Since LightningJS only
      // utilizes one document to create the app, the document will always be the index.html file. Once the script is
      // loaded, we can resolve the window.webapis call. Read more about utilizing web APIs in the following link:
      // https://developer.samsung.com/smarttv/develop/api-references/samsung-product-api-references.html
      const webapisElem = document.createElement('script');
      webapisElem.src = '$WEBAPIS/webapis/webapis.js';
      document.getElementsByTagName('head')[0]?.appendChild(webapisElem);

      webapisElem.onerror = reject;
      webapisElem.onload = () => {
        const webapis = (window as unknown as TizenWindow).webapis;
        if (webapis && webapis.productinfo) {
          const { productinfo, adinfo, network } = webapis;

          this.deviceInfo.osVersion = this.tizen.systeminfo.getCapability(
            'http://tizen.org/feature/platform.version',
          );

          this.deviceInfo.model = productinfo.getModel();
          this.deviceInfo.adId = adinfo.getTIFA();
          this.deviceInfo.isLat = adinfo.isLATEnabled() ? 1 : 0;
          this.isLat = this.deviceInfo.isLat;
          this.deviceInfo.ifaType = 'tifa';
          this.deviceInfo.ip = network.getIp();
        }

        // Listen for deep links
        Registry.addEventListener(window, 'appcontrol', () => {
          // If user navigates via deep link on the splash page, we do not need to handle it here since
          // user will be redirected once splash page has finished loading
          if (getPageIdFromHash(Router.getActiveHash() ?? '') === PageId.SPLASH)
            return;

          AppData!.isDeepLinkEntry = true;
          this.handleDeepLink();
        });

        if (webapis) {
          const { tvinfo } = webapis;
          Object.values(TvInfoMenuKey).forEach(menuKey => {
            try {
              // Initialize CC Settings
              this.updateCcSetting(menuKey, tvinfo.getMenuValue(menuKey));

              // Listen for CC settings changes
              tvinfo.addCaptionChangeListener(menuKey, menuKey =>
                this.updateCcSetting(menuKey, tvinfo.getMenuValue(menuKey)),
              );
            } catch (e) {
              // Some Tizen devices may not support all available menu keys, and will be caught here
            }
          });
        }

        // Register additional keys that will take effect when pressed on the remote
        // Note: this also overrides their default functionality
        this.tizen.tvinputdevice.registerKeyBatch(
          Object.values(AdditionalTizenKeyMapping),
        );

        resolve();
      };
    });
  }

  override beforeAppClose(callback: (showPopup: boolean) => void) {
    /**
     * For Tizen's Deep link Return Key Policy:
     * After the user enters the application through a deep link, the “Return/Exit” key click must
     * be implemented to perform the following actions:
     * ...
     * - From the home page of an application, clicking the “Return/Exit” key must
     * terminate the application without any confirmation popup. The user is returned to Smart Hub Preview.
     * */
    if (AppData?.isDeepLinkEntry) {
      // Based on Tizen's deep link return key policy, we will close the app when user presses back on the nav bar
      callback(false);
    }
    callback(true);
  }

  override closeApp() {
    this.tizen.application.getCurrentApplication().exit();
  }

  private isStringifiedJson(data: unknown) {
    if (typeof data !== 'string') return false;

    try {
      const parsedData = JSON.parse(data);
      return !!parsedData && typeof parsedData === 'object';
    } catch (e) {
      return false;
    }
  }

  /**
   * Performs a depth first search on the deep link payload to build a deep link payload
   *
   * @param actionData The data we are searching to build a deep link payload
   * @returns A deep link payload. Return `undefined` if no payload can be built
   */
  private parseDeepLinkPayload(
    actionData: unknown,
  ): DeepLinkPayload | undefined {
    const availableContentTypes: string[] = Object.values(PAYLOAD_CONTENT_TYPE);
    let contentType: PAYLOAD_CONTENT_TYPE | undefined;
    let id: string | undefined;
    let season: number | undefined;

    const uncheckedNodes = [actionData];
    while (uncheckedNodes.length) {
      let currentNode = uncheckedNodes.pop();

      // Attempt to parse current node to JSON object
      if (this.isStringifiedJson(currentNode)) {
        currentNode = JSON.parse(currentNode as string);
      }

      // We can't use the current node if it's undefined or not an object
      if (!currentNode || typeof currentNode !== 'object') continue;

      if (
        'content_type' in currentNode &&
        typeof currentNode['content_type'] === 'string' &&
        availableContentTypes.includes(currentNode['content_type'])
      ) {
        contentType = currentNode['content_type'] as PAYLOAD_CONTENT_TYPE;
      }

      if ('id' in currentNode && typeof currentNode['id'] === 'string') {
        id = currentNode['id'] as string;
      }

      if (
        'season' in currentNode &&
        typeof currentNode['season'] === 'number'
      ) {
        season = currentNode['season'] as number;
      }

      if (contentType) {
        const deepLinkPayload = this.buildDeepLinkPayload({
          contentType,
          id,
          season,
        });
        if (deepLinkPayload) return deepLinkPayload;
      }

      Object.values(currentNode).forEach(value => {
        uncheckedNodes.push(value);
      });
    }

    return undefined;
  }

  private buildDeepLinkPayload(data: {
    contentType: PAYLOAD_CONTENT_TYPE;
    id?: string;
    season?: number;
  }): DeepLinkPayload | undefined {
    const { contentType, id, season } = data;
    switch (contentType) {
      case 'channel':
      case 'live':
      case 'live_event':
        // ID is optional
        return {
          content_type: contentType,
          id,
        };
      case 'series':
        // ID is not optional, but season is
        if (!id) return undefined;
        return {
          content_type: contentType,
          id,
          season,
        };
      case 'content_hub':
      case 'episode':
      case 'movie':
        // ID is not optional
        if (!id) return undefined;
        return {
          content_type: contentType,
          id,
        };
    }
  }

  override handleDeepLink() {
    try {
      const requestedAppControl = this.tizen.application
        .getCurrentApplication()
        .getRequestedAppControl();

      const appControlData = requestedAppControl.appControl.data;
      const payload = appControlData.find(item => {
        return item.key == 'PAYLOAD';
      })!;

      const payloadData = payload.value[0]!;
      const actionData = JSON.parse(payloadData).values as string;

      // The logic above is interpreted directly from Tizen's deep link documentation.
      // However, we have not confirmed how Tizen will interpret the deep link payload.
      // Therefore, we created a deep link payload parser that performs a wide check on
      // the payload to search for the pertinent deep link values `content_type` and `id`
      const parsedDeepLinkPayload = this.parseDeepLinkPayload(actionData);
      if (!parsedDeepLinkPayload) return false;

      const { content_type, id } = parsedDeepLinkPayload;

      // We can't set history and navigate since the last page we came from gets added to
      // the top of the history stack. Workaround by setting the history and routing back
      switch (content_type) {
        case 'channel':
          // Assume ID is channel slug
          navigateToChannel(id);
          break;
        case 'series':
          // Assume ID is series slug
          Router.setHistory([
            getHomeHistoryEntry(),
            getSeriesDetailsHistoryEntry(id, parsedDeepLinkPayload.season),
          ]);
          Router.back();
          break;
        case 'movie':
          // Assume ID is movie slug
          Router.setHistory([
            getHomeHistoryEntry(),
            getMovieDetailsHistoryEntry(id),
          ]);
          Router.back();
          break;
        case 'episode':
          // Assume ID is episode GUID
          Router.setHistory([
            getHomeHistoryEntry(),
            getVodPlaybackHistoryEntry(id),
          ]);
          Router.back();
          break;
        case 'live':
          // Assume ID is event slug
          Router.setHistory([
            getHomeHistoryEntry(),
            getLiveEventHistoryEntry(id),
            getLivePlaybackHistoryEntry(id),
          ]);
          Router.back();
          break;
        case 'live_event':
          // Assume ID is event slug
          Router.setHistory([
            getHomeHistoryEntry(),
            getLiveEventHistoryEntry(id),
          ]);
          Router.back();
          break;
        case 'content_hub':
          // Assume ID is hub slug
          navigateToContentHub(id);
          break;
        default:
          return false;
      }

      Router.focusPage();
      return true;
    } catch (e) {
      return false;
    }
  }

  override handleNetworkChange(callback: (arg: boolean) => void) {
    const webapis = (window as unknown as TizenWindow).webapis;
    const network = webapis && webapis.network;
    if (network) {
      network.addNetworkStateChangeListener(function (state: number) {
        callback(state === network.NetworkState.GATEWAY_CONNECTED);
      });
    }
  }

  /**
   * @param state
   * true - enable screensaver
   * false - disable screensaver
   */
  override setScreenSaver(state: boolean) {
    const webapis = (window as unknown as TizenWindow).webapis;
    const appCommon = webapis && webapis.appcommon;
    if (!appCommon) return;

    const onOffScreenSaver = state
      ? appCommon.AppCommonScreenSaverState.SCREEN_SAVER_ON
      : appCommon.AppCommonScreenSaverState.SCREEN_SAVER_OFF;
    appCommon.setScreenSaver(onOffScreenSaver);
  }

  override getAnnouncerEnabled() {
    /**
     * Assume announcer is off until webapis are loaded. Then, we can confirm
     * the announcer's state with the announcer change callback
     */
    return false;
  }

  handleAnnouncerChange(callback: (arg: boolean) => void): void {
    this._announcerChangeCallback = callback;
  }

  private updateCcSetting(
    key: (typeof TvInfoMenuKey)[keyof typeof TvInfoMenuKey],
    value: TvInfoMenuValue,
  ) {
    const style =
      document.getElementById('caption-styles') ??
      document.createElement('style');
    style.id = 'caption-styles';

    switch (key) {
      case TvInfoMenuKey.CAPTION_FG_COLOR_KEY:
        // Default font color to white
        if (value === TvInfoMenuValue.CAPTION_COLOR_DEFAULT) {
          value = TvInfoMenuValue.CAPTION_COLOR_WHITE;
        }

        this._ccSettings.color = this.colorMap[value];
        break;
      case TvInfoMenuKey.CAPTION_BG_COLOR_KEY:
        this._ccSettings.backgroundColor = this.colorMap[value];
        break;
      case TvInfoMenuKey.CAPTION_FG_OPACITY_KEY:
        this._ccSettings.colorFlashing =
          value === TvInfoMenuValue.CAPTION_OPACITY_FLASH;

        this._ccSettings.colorOpacity = this.opacityMap[value];
        break;
      case TvInfoMenuKey.CAPTION_BG_OPACITY_KEY:
        this._ccSettings.backgroundFlashing =
          value === TvInfoMenuValue.CAPTION_OPACITY_FLASH;

        this._ccSettings.backgroundOpacity = this.opacityMap[value];
        break;
      case TvInfoMenuKey.CAPTION_FONT_SIZE_KEY:
        this._ccSettings.fontSize = this.fontSizeMap[value];
        break;
      case TvInfoMenuKey.CAPTION_ONOFF_KEY:
        this._ccSettings.enabled = value === TvInfoMenuValue.CAPTION_ON;
        ApplicationInstance?.emit(
          'ccChange',
          value === TvInfoMenuValue.CAPTION_ON,
        );
        break;
      case TvInfoMenuKey.CAPTION_FONT_STYLE_KEY:
        this._ccSettings.fontFace = this.fontFaceMap[value];
        break;
      case TvInfoMenuKey.CAPTION_EDGE_TYPE_KEY:
        this._ccSettings.edgeType = this.edgeTypeMap[value];
        break;
      case TvInfoMenuKey.CAPTION_EDGE_COLOR_KEY:
        this._ccSettings.edgeColor = this.colorMap[value];
        break;
      case TvInfoMenuKey.CAPTION_WINDOW_COLOR_KEY:
        // Not used by default
        if (value === TvInfoMenuValue.CAPTION_COLOR_DEFAULT) {
          this._ccSettings.windowColor = undefined;
          break;
        }

        this._ccSettings.windowColor = this.colorMap[value];
        break;
      case TvInfoMenuKey.CAPTION_WINDOW_OPACITY_KEY:
        this._ccSettings.windowFlashing =
          value === TvInfoMenuValue.CAPTION_OPACITY_FLASH;

        this._ccSettings.windowOpacity = this.opacityMap[value];
        break;
      case TvInfoMenuKey.CAPTION_STYLE_KEY:
        this._ccSettings.fontStyle = this.fontStyleMap[value];
        break;
      case TvInfoMenuKey.VOICE_GUIDE_KEY:
        this._announcerChangeCallback?.(value === 1);
        break;
      default:
        break;
    }

    if (
      !this._flashTimer &&
      (this._ccSettings.backgroundFlashing || this._ccSettings.colorFlashing)
    ) {
      this._flashTimer = Registry.setInterval(() => {
        if (this._ccSettings.colorFlashing) {
          this._ccSettings.colorOpacity = this._flashOn ? 'ff' : '00';
        }
        if (this._ccSettings.backgroundFlashing) {
          this._ccSettings.backgroundOpacity = this._flashOn ? 'ff' : '00';
        }
        if (this._ccSettings.windowFlashing) {
          this._ccSettings.windowOpacity = this._flashOn ? 'ff' : '00';
        }

        const style =
          document.getElementById('caption-styles') ??
          document.createElement('style');
        style.id = 'caption-styles';
        style.innerHTML = translateCcSettingToCss(this._ccSettings);
        document.head.appendChild(style);

        this._flashOn = !this._flashOn;
      }, FLASH_INTERVAL);
    } else {
      if (this._flashTimer) {
        Registry.clearInterval(this._flashTimer);
        this._flashTimer = null;
      }
      style.innerHTML = translateCcSettingToCss(this._ccSettings);
      document.head.appendChild(style);
    }
  }
}
