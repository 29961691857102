import { AppData, Colors, Lightning } from '@lightningjs/sdk';
import Button from 'components/common/Button';
import { getImageTextureObj } from 'support/generalUtils';
import {
  getPromoHeroLandscapeUrl,
  getPromoHeroLogoUrl,
  getPromoHubLandscapeUrl,
} from 'support/cwImageUtils';
import { Platform } from 'models/platforms/platform';
import { PromoLaneItem } from 'services/cwData';
import VisibilityIndicator from 'components/common/VisibilityIndicator';
import { constants } from 'aliases';

export interface BillboardTemplateSpec
  extends Lightning.Component.TemplateSpec {
  mediaItem: PromoLaneItem | null;
  isHubBackground: boolean;
  fade: number;
  isHero: boolean;
  carouselIndex: number | undefined;
  Background: {
    BackgroundContain: Lightning.textures.ImageTexture;
  };
  Gradient: Lightning.Component;
  Content: {
    Logo: {
      LogoContain: Lightning.textures.ImageTexture;
    };
    Description: Lightning.textures.TextTexture;
    Button: typeof Button;
  };
  VisibilityIndicator: typeof VisibilityIndicator;
}

const BILLBOARD_WIDTH = 1758;
const BILLBOARD_HEIGHT = 703;

const GRADIENT_WIDTH = BILLBOARD_WIDTH;
const GRADIENT_HEIGHT = BILLBOARD_HEIGHT * 0.35; // Gradient starts 35% up
const GRADIENT_Y = BILLBOARD_HEIGHT - GRADIENT_HEIGHT;

const BACKGROUND_WIDTH = 1758;
const BACKGROUND_HEIGHT = 703;

const CONTENT_PADDING_X = constants.ui.contentHubPagePaddingX;

const TITLE_TOP_Y = 60;
const TITLE_HEIGHT = 52;

const LOGO_X = 285;
const LOGO_Y = 60;
const LOGO_WIDTH = 565;
const LOGO_HEIGHT = 230;

const DESCRIPTION_Y = 343;
const DESCRIPTION_HEIGHT = 30;
const DESCRIPTION_FONT_SIZE = 26;

const BUTTON_Y = 408;
const BUTTON_FONT_SIZE = 23;
const BUTTON_PADDING = 12;
const BUTTON_MARGIN = 12;
const BUTTON_HEIGHT = 57;

export default class Billboard
  extends Lightning.Component<BillboardTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<BillboardTemplateSpec>
{
  private _mediaItem: PromoLaneItem | null = null;
  private _backgroundImageUri = '';
  private _isHubBackground = false;
  private _notifyWhenFullyVisible = false;
  private _hasNavbar = true;
  private _isSinglePromo = false;

  private _Background = this.getByRef('Background')!;
  private _Gradient = this.getByRef('Gradient')!;
  private _BackgroundContain = this._Background.getByRef('BackgroundContain')!;
  private _Content = this.getByRef('Content')!;
  private _Logo = this._Content.getByRef('Logo')!;
  private _LogoContain = this._Logo.getByRef('LogoContain')!;
  private _Button = this._Content.getByRef('Button')!;
  private _Description = this._Content.getByRef('Description')!;
  protected _VisibilityIndicator = this.getByRef('VisibilityIndicator')!;

  isHero = false;
  carouselIndex: number | undefined;

  static get width() {
    return BILLBOARD_WIDTH;
  }

  static get height() {
    return BILLBOARD_HEIGHT;
  }

  get mediaItem() {
    return this._mediaItem;
  }

  set mediaItem(mediaItem: PromoLaneItem | null) {
    this._mediaItem = mediaItem;
    if (!mediaItem) return;

    const promoButton = mediaItem.promoButton ?? '';
    const promoDescription = mediaItem.promoDescription ?? '';

    this.updateBackground(mediaItem);
    this.updateLogo(mediaItem);
    this.updateDescription(promoDescription);
    this.updateButton(promoButton);

    this._VisibilityIndicator.patch({
      visible: this._notifyWhenFullyVisible && !!this._mediaItem,
    });
  }

  get notifyWhenFullyVisible() {
    return this._notifyWhenFullyVisible;
  }

  set notifyWhenFullyVisible(notifyWhenFullyVisible: boolean) {
    this._notifyWhenFullyVisible = notifyWhenFullyVisible;

    this._VisibilityIndicator.patch({
      visible: this._notifyWhenFullyVisible && !!this._mediaItem,
    });
  }

  set fade(fade: number) {
    // FadeOut for images does not work on Vizio devices
    if (fade === 0 || AppData!.device.getPlatform() === Platform.VIZIO) {
      this._Background.patch({ shader: {} });
    } else {
      this._Background.patch({
        shader: {
          type: Lightning.shaders.FadeOut,
          bottom: fade,
        },
      });
    }
  }

  get isHubBackground() {
    return this._isHubBackground;
  }

  set isHubBackground(isHubBackground: boolean) {
    this._isHubBackground = isHubBackground;
  }

  set hasNavbar(hasNavbar: boolean) {
    this._hasNavbar = hasNavbar;
  }

  set isSinglePromo(isSinglePromo: boolean) {
    this._isSinglePromo = isSinglePromo;
  }

  static override _template(): Lightning.Component.Template<BillboardTemplateSpec> {
    return {
      Background: {
        w: BACKGROUND_WIDTH,
        h: BACKGROUND_HEIGHT,
        BackgroundContain: {},
      },
      Gradient: {
        y: GRADIENT_Y,
        w: GRADIENT_WIDTH,
        h: GRADIENT_HEIGHT,
        rect: true,
        colorTop: Colors('appBackground').alpha(0).get(),
        colorBottom: Colors('appBackground').get(),
      },
      Content: {
        x: CONTENT_PADDING_X,
        Logo: {
          x: LOGO_X,
          w: LOGO_WIDTH,
          h: LOGO_HEIGHT,
          LogoContain: {},
        },
        Description: {
          y: DESCRIPTION_Y,
          h: DESCRIPTION_HEIGHT,
          text: {
            fontSize: DESCRIPTION_FONT_SIZE,
            textColor: Colors('text').get(),
          },
        },
        Button: {
          type: Button,
          y: BUTTON_Y,
          height: BUTTON_HEIGHT,
          fontSize: BUTTON_FONT_SIZE,
          padding: BUTTON_PADDING,
          margin: BUTTON_MARGIN,
          signals: {
            $billboardSelected: '$billboardSelected',
          },
        },
      },
      VisibilityIndicator: {
        type: VisibilityIndicator,
        w: BACKGROUND_WIDTH,
        h: BACKGROUND_HEIGHT,
        signals: { indicateFullyVisible: '$indicateFullyVisible' },
        visible: false,
      },
    };
  }

  override _getFocused() {
    return this._Button;
  }

  private updateBackground(mediaItem: PromoLaneItem) {
    const width = this._hasNavbar ? Billboard.width : 1920;

    this._backgroundImageUri = this._isHubBackground
      ? getPromoHubLandscapeUrl(width, mediaItem.promoImageId)
      : getPromoHeroLandscapeUrl(width, mediaItem.promoImageId);

    this._BackgroundContain.patch(
      getImageTextureObj(this._backgroundImageUri, width, BACKGROUND_HEIGHT),
    );
    this._Background.patch({ w: width });
    this._Gradient.patch({ w: width });
  }

  private updateLogo(mediaItem: PromoLaneItem) {
    const logoY = this._isSinglePromo ? TITLE_TOP_Y + TITLE_HEIGHT : LOGO_Y;
    this._Logo.patch({
      x: 0,
      y: logoY,
    });

    this._LogoContain.patch(
      getImageTextureObj(
        getPromoHeroLogoUrl(LOGO_WIDTH, mediaItem.promoImageId),
        LOGO_WIDTH,
        LOGO_HEIGHT,
      ),
    );
  }

  private updateDescription(descriptionText: string) {
    this._Description.patch({ text: { text: descriptionText } });
  }

  private updateButton(buttonText: string) {
    if (!buttonText) {
      this._Button.patch({ visible: false, action: undefined });
    } else {
      this._Button.patch({
        visible: true,
        action: '$billboardSelected',
        label: buttonText,
      });
    }
  }

  private $indicateFullyVisible() {
    this.handleVisibilitySignal();
  }

  protected handleVisibilitySignal() {
    if (this._notifyWhenFullyVisible && this._mediaItem) {
      this.fireAncestors(
        '$onBillboardFullyVisible',
        this._mediaItem,
        this.carouselIndex ?? 0,
      );
    }
  }

  $billboardSelected() {
    if (!this._mediaItem) return;
    this.fireAncestors(
      '$onBillboardSelect',
      this._mediaItem,
      this.isHero,
      this._backgroundImageUri,
      this.carouselIndex,
    );
  }
}
