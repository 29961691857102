import { Colors, Lightning } from '@lightningjs/sdk';
import { getImageTextureContainObj } from 'support/generalUtils';

export interface ChannelBadgeTemplateSpec
  extends Lightning.Component.TemplateSpec {
  selected: boolean;
  logo: {
    focused?: string;
    unfocused?: string;
  };
  logoSizing: {
    width: number;
    height: number;
  };
  backgroundAlpha: number;
  borderRadius: number;

  Background: Lightning.textures.RectangleTexture;
  Logo: Lightning.textures.ImageTexture;
}

const DEFAULT_LOGO_WIDTH = 80;
const DEFAULT_LOGO_HEIGHT = 48;
const DEFAULT_BORDER_RADIUS = 8;

export default class ChannelBadge
  extends Lightning.Component<ChannelBadgeTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<ChannelBadgeTemplateSpec>
{
  private _selected = false;
  private _logo: ChannelBadgeTemplateSpec['logo'] = {};
  private _logoSizing: ChannelBadgeTemplateSpec['logoSizing'] = {
    width: DEFAULT_LOGO_WIDTH,
    height: DEFAULT_LOGO_HEIGHT,
  };
  private _borderRadius = DEFAULT_BORDER_RADIUS;

  private _Logo = this.getByRef('Logo')!;
  private _Background = this.getByRef('Background')!;

  get selected() {
    return this._selected;
  }

  set selected(selected: ChannelBadgeTemplateSpec['selected']) {
    this._selected = selected;
    this._Background.color = selected
      ? Colors('highlight').get()
      : Colors('alternateBackground').get();
    this.updateImage();
  }

  get logo() {
    return this._logo;
  }

  set logo(logo: ChannelBadgeTemplateSpec['logo']) {
    this._logo = {
      focused: logo.focused ?? this._logo.focused,
      unfocused: logo.unfocused ?? this._logo.unfocused,
    };
    this.updateImage();
  }

  get logoSizing() {
    return this._logoSizing;
  }

  set logoSizing(logoSizing: ChannelBadgeTemplateSpec['logoSizing']) {
    this._logoSizing = logoSizing;
    this.updateImage();
  }

  get borderRadius() {
    return this._borderRadius;
  }

  set borderRadius(borderRadius: ChannelBadgeTemplateSpec['borderRadius']) {
    this._borderRadius = borderRadius;
    this.shader = {
      type: Lightning.shaders.RoundedRectangle,
      radius: borderRadius,
    };
  }

  set backgroundAlpha(alpha: number) {
    this._Background.patch({ alpha });
  }

  static override _template(): Lightning.Component.Template<ChannelBadgeTemplateSpec> {
    return {
      clipping: true,
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: DEFAULT_BORDER_RADIUS,
      },
      Background: {
        rect: true,
        color: Colors('alternateBackground').get(),
        w: (w: number) => w,
        h: (h: number) => h,
      },
      Logo: {
        x: (w: number) => w / 2,
        y: (h: number) => h / 2,
        mount: 0.5,
      },
    };
  }

  override _focus() {
    this.updateImage();
  }

  override _unfocus() {
    this.updateImage();
  }

  private updateImage() {
    const selected = this.selected;
    const { focused, unfocused } = this._logo;
    const { width, height } = this._logoSizing;

    let texture = {};
    if (selected && focused) {
      texture = getImageTextureContainObj(focused, width, height);
    } else if (!selected && unfocused) {
      texture = getImageTextureContainObj(unfocused, width, height);
    }
    this._Logo.patch(texture);
  }
}
