import { Colors } from '@lightningjs/sdk';
import ProgressBar, { ProgressBarTemplateSpec } from './ProgressBar';

export interface BillboardProgressBarTemplateSpec
  extends ProgressBarTemplateSpec {
  selected: boolean;
  duration: number;
  delay: number;
}

const DEFAULT_DURATION = 5;
const DEFAULT_DELAY = 1;
const RESET_DURATION = 0;
const RESET_DELAY = 0;

const DEFAULT_WIDTH = 12;
const DEFAULT_HEIGHT = 12;

const EXPANDED_WIDTH = 50;

export default class BillboardProgressBar extends ProgressBar<BillboardProgressBarTemplateSpec> {
  override _width = DEFAULT_WIDTH;
  private _transitionDuration = DEFAULT_DURATION;
  private _transitionDelay = DEFAULT_DELAY;
  private _activated = false;

  static override get defaultWidth() {
    return DEFAULT_HEIGHT;
  }

  static override get defaultHeight() {
    return DEFAULT_HEIGHT;
  }

  set selected(selected: boolean) {
    if (selected) {
      this.expandTransition();
    } else {
      this.resetTransition();
    }
  }

  set duration(duration: number) {
    this._transitionDuration = duration;
  }

  set delay(delay: number) {
    this._transitionDelay = delay;
  }

  override _setup() {
    super._setup();
    this._ProgressBar.patch({ color: Colors('white').get() });
  }

  override _firstEnable() {
    this._activated = true;
  }

  private expandTransition() {
    if (!this._activated) return;

    this.setSmooth('width', EXPANDED_WIDTH);
    this.setSmooth('progressPercent', 1, {
      delay: this._transitionDelay,
      duration: this._transitionDuration,
      timingFunction: 'linear',
    });
  }

  private resetTransition() {
    if (!this._activated) return;

    this.setSmooth('width', DEFAULT_WIDTH);
    this.setSmooth('progressPercent', 0, {
      delay: RESET_DELAY,
      duration: RESET_DURATION,
    });
  }
}
