import {
  Channel,
  Episode,
  PlayableMediaImages,
  Live,
  Season,
  Show,
  Video,
  ShowVideos,
  ShowNextEpisodes,
  EpgProgram,
  EpgChannel,
  ChannelImages,
  DrmMediaItem,
  TivoApiScreenItem,
  LiveEvent,
  LiveEventChannel,
} from 'types/api/media';
import { AppData } from '@lightningjs/sdk';
import { translate } from 'support/translate';
import {
  diffInHours,
  diffInMinutes,
  getClosestThirtyMinuteOffsetDate,
  hoursToMilliseconds,
} from 'support/dateUtils';
import {
  getChannelThumbnailUrl,
  getFeaturedHorizontalExpandedUrl,
  getFeaturedVerticalExpandedUrl,
  getShowFeaturedUrl,
  getShowLogoUrl,
  getShowLogoWhiteUrl,
  getShowPromoUrl,
  getShowThumbnailUrl,
  getShowVerticalUrl,
  getVideoGuidUrl,
} from 'support/cwImageUtils';
import { Config, NavigationItem } from 'types/api/config';

export enum CwErrors {
  NOT_FOUND = 'Video not found',
}

export type CwResponse = {
  result: 'ok' | 'error';
  msg?: CwErrors | string; // Note that msg is only for errors
  [k: string]: any;
};

export type ChannelLaneItem = Channel & { contextType: 'channel' | 'tivo-api' };
export type ContentHubLaneItem = ContentHub & {
  contextType: 'content-hub' | 'tivo-api';
};
export type EpisodeHubLaneItem = Episode & {
  contextType: 'video' | 'tivo-api';
};
export type LiveEventLaneItem = LiveEvent & {
  contextType: 'event' | 'tivo-api';
};
export type PromoLaneItem = Promo & { contextType: 'promo' | 'tivo-api' };
export type ShowLaneItem = Show & { contextType: 'show' | 'tivo-api' };
export type VideoLaneItem = Video & { contextType: 'video' | 'tivo-api' };

export type LaneItem<
  LaneItemType =
    | ChannelLaneItem
    | ContentHubLaneItem
    | EpisodeHubLaneItem
    | LiveEventLaneItem
    | ShowLaneItem
    | VideoLaneItem,
> = LaneItemType;

export type Lane = {
  items: LaneItem[];
  itemType: 'event' | 'show' | 'video' | 'channel' | 'content-hub' | 'tivo-api';
  laneType:
    | 'promos'
    | 'continue-watching'
    | 'show-group'
    | 'tivo-api-screens'
    | 'channels'
    | 'latest-episodes';
  presentation:
    | 'promo'
    | 'horizontal'
    | 'vertical'
    | 'square'
    | 'vertical-expanding'
    | 'horizontal-expanding'
    | 'horizontal-episodic';
  slug: string;
  title: string;
  apiUrl?: string;
};

export type PromoLane = Omit<Lane, 'items' | 'itemType'> & {
  items: PromoLaneItem[];
  itemType: 'promo';
};

export type Swimlanes = {
  lanes: (Lane | PromoLane)[];
  lanesCount: number;
  layout: 'tabbed' | 'carousels' | 'grid';
  title: string;
  titleDisplayed: number;
};

export type ContentHub = {
  id: string;
  slug: string;
  title: string;
  images: { thumbnail: string };

  // internal
  type: 'content-hub';
};

export type PromoChannel = {
  id: string;
  title: string;
  slug: string;
  // internal
  type: 'PromoChannel';
};

export type Promo = (
  | Show
  | Video
  | Live
  | LiveEvent
  | ContentHub
  | PromoChannel
) & {
  promoImageId: string;
  promoButton: string;
  promoDescription: string;
};

const season = (data: any): Season => {
  return {
    id: data.availability_season_id,
    title: data.title,
    code: data.code,
    header: data.header,
    episodeCount: data.episode_count,
  };
};

const formatImageUrl = (url: string | undefined) => {
  try {
    if (!url || AppData?.isProduction) return url;
    const parsedUrl = new URL(url);
    return AppData?.images.baseUrl + parsedUrl.pathname;
  } catch (e) {
    // Cannot parse URL, return it as is
    return url;
  }
};

// build images with dynamic urls, and only return the ones that are used
const buildImagesArray = (data: any): PlayableMediaImages => {
  const slug =
    data.slug ||
    data.showSlug ||
    data.show_slug ||
    data.event_slug ||
    data.metadata?.slug ||
    data.metadata?.showSlug;
  if (!slug) return {};

  const imageArray: PlayableMediaImages = {
    image_logo: getShowLogoUrl(slug),
    image_logo_white: getShowLogoWhiteUrl(slug),
    image_vertical: getShowVerticalUrl(slug, data.badge_id),
    image_vertical_featured: getFeaturedVerticalExpandedUrl(slug),
    image_show_thumbnail: getShowThumbnailUrl(slug),
    image_show_featured: getFeaturedHorizontalExpandedUrl(slug),
    image_featured: getShowFeaturedUrl(slug),
    thumbnail: getVideoGuidUrl(data.guid),
    image_promo: getShowPromoUrl(slug, data.badge_id),
  };

  return imageArray;
};

export const getEpisode = (
  showSlug: string,
  seriesName: string,
  data: any,
): Episode | null => {
  if (!data) return null;

  return {
    airDate: data.airdate,
    description: data.description_long,
    duration: data.duration,
    durationSecs: data.duration_secs || data.durationsecs,
    episode: data.episode,
    episodeInSeason: data.episode_in_season,
    guid: data.guid,
    badgeId: data.badge_id ?? '',
    images: buildImagesArray(data),
    season: data.season,
    title: data.title,

    // Internal
    type: 'Episode',
    showSlug,
    seriesName,
  };
};

const video = (data: any): Video | null => {
  if (!data || Object.keys(data).length === 0) return null;

  const showSlug = data.show_slug ?? data.showSlug;
  const seriesName = data.series_name;

  return {
    title: data.title,
    description: data.description_long,
    id: data.guid,
    guid: data.guid,
    rating: data.rating,
    adZone: data.adZone,
    airDate: data.airdate,
    bcVideoId: data.bc_video_id,
    bifSdUrl: data.image_bif?.sd,
    bifHdUrl: data.image_bif?.hd,
    creditSecs: data.credits_secs,
    expireTime: data.expire_time,
    episode: data.episode,
    episodeInSeason: data.episode_in_season,
    duration: data.duration,
    durationSecs: data.duration_secs,
    genre: data.comscore_genre,
    imdbGenre: data.imdb_genres,
    isFullEpisode: data?.fullep === 1 ? true : false,
    badgeId: data.badge_id ?? '',
    images: buildImagesArray(data),
    isNew: data.is_new,
    mpxUrl: data.mpx_url,
    nextEpisode: getEpisode(showSlug, seriesName, data.next_episode),
    nielsenGenre: data.nielsen_genre,
    season: data.season,
    series: data.series,
    seriesName,
    seriesSlug: data.series_slug,
    seriesType: data.series_type,
    showSlug: showSlug,
    startTime: data.start_time,
    tmsId: data.tms_id,

    // used for Conviva (see analytics spreadsheet)
    contentType: data?.overlay ?? data?.type ?? '',
    brand: data.show_type,

    // Internal
    isLive: false,
    type: 'Video',
  };
};

const featuredTrailer = (data: any) => {
  return {
    id: data.video_guid,
    video: video(data.video_item),
    trailerUrl: data.video_mp4,
  };
};

const show = (data: any): Show | null => {
  if (!data || Object.keys(data).length === 0) return null;
  const images = buildImagesArray(data);
  const firstEpisode = getEpisode(data.slug, data.title, data.first_episode);
  const featuredTrailerData = data.featured_trailer?.video_item
    ? featuredTrailer(data.featured_trailer)
    : null;
  const seasons = data.seasons
    ?.filter((seasonData: any) => {
      return seasonData?.availability_season_id && seasonData?.episode_count;
    })
    .map((seasonData: any) => season(seasonData));

  const content = {
    title: data.title,
    description: data.summary_long ?? data.summary,
    rating: data.rating,
    id: data.slug,
    type: data.type,
    activeYears: data.active_years,
    releaseYear: data.release_year,
    badgeId: data.badge_id ?? '',
    images,
    firstEpisode,
    featuredTrailer: featuredTrailerData,
    genres: data.imdb_genres,
    totalEpisodes: data.episode_count,
    totalSeasons: data.season_count || 0,
    totalExtras: data.clip_count,
    showCast: data.show_cast,
    showProducers: data.show_producers,
    showDirectors: data.show_directors,
    showSlug: data.slug,
    seasons,
  };

  return content;
};

const tivoApiScreen = (data: any): TivoApiScreenItem | null => {
  if (!data || Object.keys(data).length === 0) return null;

  try {
    const metadata = data.metadata;
    const title = data.title.en || data.title;
    const slug = metadata.slug || metadata.showSlug;
    const firstEpisode = getEpisode(slug, title, metadata.firstEpisode);
    const images = buildImagesArray(data);
    const content = {
      title,
      description: data.description.en || data.description,
      rating: getRating(data.ratings),
      id: slug,
      type: metadata.seriesType,
      releaseYear: data.releaseYear,
      badgeId: data.badge_id ?? '',
      images,
      firstEpisode,
      genres: metadata.imdbGenres,
      totalSeasons: metadata.seasonCount || 0,
      showSlug: slug,

      // TivoApiScreenItem only
      duration: data.duration,
      objectType: data.objectType,
    };

    return content;
  } catch (e) {
    console.error('Error parsing Tivo API data', e, data);
    return null;
  }
};

const channel = (data: any): Channel | null => {
  if (!data || Object.keys(data).length === 0) return null;

  return {
    id: data.slug,
    title: data.title,
    images: getChannelImages(data),
    slug: data.slug,

    // Internal
    type: 'LiveChannel',
  };
};

const epgChannel = (data: any): EpgChannel => {
  const programs = (data.programs ?? []).map(epgProgram);
  const drmItems = (data.drm_media_items ?? []).map(drmMediaItem);

  return {
    adZone: data.ad_zone,
    id: data.slug,
    images: getChannelImages(data),
    genre: data.genre,
    programs,
    streamUrl: data.stream_url,
    streamType: data.stream_type,
    slug: data.slug,
    isDrm: data.has_drm === 1,
    drmItems,
    analytics: {
      genre: data.genre,
      guid: data.analytics_guid,
      slug: data.slug,
      title: data.title,
    },

    isLive: true,
    title: data.title,
    type: 'LiveProgramVideo',
  };
};

const getChannelImages = (data: any): ChannelImages => {
  return {
    homeThumbnail: getChannelThumbnailUrl(data.slug, data.badge_id),
    logoFocused: formatImageUrl(data.icon_focused_url),
    logoUnfocused: formatImageUrl(data.icon_unfocused_url),
    showcaseBackground: formatImageUrl(data.showcase_image_url),
  };
};

const epgProgram = (data: any): EpgProgram => {
  return {
    assetId: data.asset_id,
    category: data.category,
    convivaAssetName: data.conviva_asset_name,
    description: data.description,
    durationSecs: data?.duration_secs?.toString() ?? '',
    images: {
      iconUrl: data.icon_url,
      detailsUrl: data.details_image_url,
    },
    endTime: data.end_time,
    isOnDemand: data.on_demand,
    ratings: data.ratings,
    seriesSlug: data.series_slug,
    seriesType: data.series_type,
    startTime: data.start_time,
    subtitle: data.subtitle,
    title: data.title,
  };
};

const drmMediaItem = (data: any): DrmMediaItem => {
  return {
    mimeType: data.mimetype,
    pid: data.pid,
    selectorUrl: data.selector_url,
    streamUrl: data.streaming_url,
  };
};

/**
 * Cleanses the channel programming data. It will:
 * 1. Fill channels with empty programming data with placeholder programs
 * 2. Remove programs that have already expired (Finished before start time)
 * 3. Add placeholder programs before start time if necessary
 *
 * @param channels Channels we want to clean up
 * @param epgStartTime Should be a 30 minute interval (I.e. 12:00, 12:30, 1:00, 1:30, etc.).
 * If not, it will get converted
 *
 * @returns The cleansed channel data
 */
export const cleanupEpgChannelData = (
  channels: EpgChannel[],
  epgStartTime: Date,
): EpgChannel[] => {
  // Ensure the start date is in 30 minute interval format
  getClosestThirtyMinuteOffsetDate(epgStartTime);

  channels.forEach((channel: EpgChannel) => {
    let programs = channel.programs;
    const channelTitle = channel.title;

    if (programs.length) {
      programs = filterExpiredEpgPrograms(programs, epgStartTime);
      const firstItemStartTime = new Date(programs[0]!.startTime);

      if (firstItemStartTime > epgStartTime) {
        const programPadding = fillPlaceholderPrograms(
          channelTitle,
          epgStartTime,
          firstItemStartTime,
        );
        programs.unshift(...programPadding);
      }
    } else {
      programs = fillPlaceholderPrograms(channelTitle, epgStartTime);
    }
    channel.programs = programs;
  });

  return channels;
};

export const filterExpiredEpgPrograms = (
  programs: EpgProgram[],
  epgStartTime: Date,
): EpgProgram[] => {
  return programs.filter((program: EpgProgram) => {
    const programEndTime = new Date(program.endTime);
    return epgStartTime < programEndTime;
  });
};

const fillPlaceholderPrograms = (
  channelTitle: string,
  startTime: Date,
  endTime?: Date,
) => {
  const placeholderPrograms: EpgProgram[] = [];

  // Max placeholder hours we can fill (13 to avoid timing issues around half hour)
  const MAX_HOURS = 13;
  const MAX_MILLISECONDS = hoursToMilliseconds(MAX_HOURS);
  if (!endTime) endTime = new Date(startTime.getTime() + MAX_MILLISECONDS);

  let hoursToFill = Math.floor(diffInHours(endTime, startTime));
  // Account for cases where a programs start time is > 13 hours away
  if (hoursToFill > MAX_HOURS) hoursToFill = MAX_HOURS;

  // Fill in hours going backwards from the final end date
  while (hoursToFill > 0) {
    const tempStartMilliseconds: number =
      endTime.getTime() - hoursToMilliseconds(1);
    const tempStartTime = new Date(tempStartMilliseconds);

    const placeholderProgram = buildPlaceholderProgram(
      tempStartTime,
      endTime,
      channelTitle,
    );

    placeholderPrograms.unshift(placeholderProgram);
    endTime = tempStartTime;
    hoursToFill -= 1;
  }

  // Should always be 0 - 59 after reducing end date above
  const minutesToFill = diffInMinutes(endTime, startTime);

  if (minutesToFill) {
    const placeholderProgram = buildPlaceholderProgram(
      startTime,
      endTime,
      channelTitle,
    );
    placeholderPrograms.unshift(placeholderProgram);
  }

  return placeholderPrograms;
};

const buildPlaceholderProgram = (
  startTime: Date,
  endTime: Date,
  channelTitle: string,
): EpgProgram => {
  const program = epgProgram({});
  program.category = 'epg_filler';
  program.isPlaceholder = true;
  program.isOnDemand = false;
  program.title = translate('epg.fallbackTitle');
  program.subtitle = '';

  program.startTime = startTime.toISOString();
  program.endTime = endTime.toISOString();
  program.durationSecs = (
    (endTime.getTime() - startTime.getTime()) /
    1000
  ).toString();

  program.convivaAssetName = translate(
    'epg.fallbackConvivaAssetName',
    channelTitle,
    program.title,
    program.startTime,
  );
  return program;
};

const live = (data: any): Live | null => {
  if (!data || Object.keys(data).length === 0) return null;

  const analytics = data.analytics;

  return {
    adZone: data.ad_zone,
    id: data.internal_slug,
    title: data.event_name,
    description: data.event_description,
    durationSecs: `${data.duration_secs}`,
    isFullEpisode: false,
    slug: data.event_slug,
    startTime: data.start_date,
    badgeId: data.badge_id ?? '',
    images: buildImagesArray(data),

    // Internal
    progress: 0.0,
    isComplete: false,
    isLive: true,
    type: 'LiveVideo',

    // Live unique fields
    analytics: {
      comscoreGenre: analytics?.comscore_genre,
      nielsenGenre: analytics?.nielsen_genre,
      slug: analytics?.show_slug,
      title: analytics?.show_title,
      seriesCode: analytics?.series_code,
      guid: analytics?.video_guid,
      videoRating: analytics?.video_rating,
    },
    streamUrl: data.channel,
    preplayUrl: data.preplay,
    showcaseThumbnail: formatImageUrl(data.banners['livestream-bg']),
    showcaseLogo: formatImageUrl(data.banners['livestream-event-lockup']),
    buttonText: data.button,
    endDate: data.end_date,
    liveToggle: data.live_toggle,
    liveTuneIn: data.event_tunein,
  };
};

const liveEvent = (
  data: any,
  overlays: LiveEvent['overlays'],
): LiveEvent | null => {
  if (!data || Object.keys(data).length === 0) return null;

  const analytics = data.analytics ?? {};
  return {
    title: data.event_name,
    slug: data.event_slug,
    groupSlug: data.event_group_slug ?? data.group_slug,
    description: data.event_description,
    tuneIn: data.event_tunein,
    startDatePage: data.start_date_page,
    endDatePage: data.end_date_page,
    startDateStream: data.start_date_stream,
    endDateStream: data.end_date_stream,
    id: data.internal_slug,
    images: {
      eventThumbnail: formatImageUrl(data.banners.event_thumbnail),
      liveStreamBackground: formatImageUrl(data.banners.livestream_bg),
      eventLockup: formatImageUrl(data.banners.event_lockup),
      largeBillboard: formatImageUrl(data.banners['billboard-1920x1080']),
      mediumBillboard: formatImageUrl(data.banners['billboard-1920x360']),
      smallBillboard: formatImageUrl(data.banners['billboard-1920x768']),
      eventThumbnailTemplate: formatImageUrl(
        data.banners.event_thumbnail_url_template,
      ),
    },
    overlays,

    // Live unique fields
    analytics: {
      comscoreGenre: analytics.comscore_genre,
      guid: analytics.video_guid,
      nielsenGenre: analytics.nielsen_genre,
      seriesCode: analytics.series_code,
      slug: analytics.show_slug,
      title: analytics.show_title,
      videoRating: analytics.video_rating,
    },

    // Internal
    isLiveEvent: true,
  };
};

const liveEventChannel = (data: any): LiveEventChannel | null => {
  const event = liveEvent(data, data.overlays);
  if (!event) return null;

  return {
    ...event,

    // Unique to LiveEventChannel
    adZone: data.ad_zone,
    streamUrl: data.stream_url,
    durationSecs: data.duration_secs,
    preplayUrl: data.preplay,
    customParams: data.cust_params,
    preplayParams: data.preplay_params,
    internalSlug: data.internal_slug,
    eventKeywords: data.event_keywords,

    // Internal
    type: 'LiveEventChannel',
  };
};

const contentHub = (item: any): ContentHub | null => {
  if (!item || Object.keys(item).length === 0) return null;

  const thumbnail = buildContentHubImage(item);

  return {
    id: item.slug ?? '',
    slug: item.slug ?? '',
    title: item.title ?? '',
    images: { thumbnail },

    // Internal
    type: 'content-hub',
  };
};

const buildContentHubImage = (item: any) => {
  const baseUrl = AppData?.images.baseUrl ?? '';
  const template = item?.image_url_template ?? '';

  return `${baseUrl}${template}`;
};

const promoChannel = (data: any): PromoChannel | null => {
  if (!data || Object.keys(data).length === 0) return null;
  const slug = data.channel_slug || data.slug || data.show_slug;
  return {
    id: slug,
    title: data.title,
    slug,
    // Internal
    type: 'PromoChannel',
  };
};

const promo = (data: any): Promo | null => {
  let content;
  switch (data.promo_type) {
    case 'show':
      content = show(data);
      break;
    case 'video':
      content = video(data);
      break;
    case 'liveplayer':
      content = liveEvent(data.live_event, data.overlays);
      break;
    case 'content-hub':
      content = contentHub(data);
      break;
    case 'channel':
      content = promoChannel(data);
      break;
    default:
      content = null;
  }

  if (content === null) {
    return null;
  }

  const result = {
    ...content,
    promoImageId: data.promo_image_id as string,
    promoButton: data.promo_button as string,
    promoDescription: data.promo_description as string,
  };

  return result;
};

const createLaneItem = (
  item: any,
  itemType: Lane['itemType'] | PromoLane['itemType'],
) => {
  switch (itemType) {
    case 'event':
      return liveEvent(item.event, item.overlays);
    case 'show':
      return show(item);
    case 'video':
      return video(item);
    case 'promo':
      return promo(item);
    case 'channel':
      return channel(item);
    case 'content-hub':
      return contentHub(item);
    case 'tivo-api':
      return tivoApiScreen(item);
    default:
      return null;
  }
};

export const parseLane = (data: any): Lane | undefined => {
  if (!data || Object.keys(data).length === 0) return undefined;
  const itemType = data.item_type;
  const laneType = data.type;
  if (!itemType || !laneType) return undefined;

  const { presentation, slug, title } = data;
  const items: Array<LaneItem> = data.items
    .map((itemData: any) => {
      const laneItem = createLaneItem(
        { ...itemData, ...(data.overlays && { overlays: data.overlays }) },
        itemType,
      );
      if (!laneItem) return null;

      return {
        ...laneItem,
        contextType: itemType,
      };
    })
    .filter((itemData: LaneItem | undefined) => itemData);

  return {
    items,
    itemType,
    laneType,
    presentation,
    slug,
    title,
    apiUrl: data.api_url,
  };
};

export const parseSwimlanes = (data: any): Swimlanes | null => {
  if (!data || Object.keys(data).length === 0) return null;
  const {
    lanes_count: lanesCount,
    layout,
    title,
    title_displayed: titleDisplayed,
  } = data;
  const lanes = data.lanes
    .map(parseLane)
    .filter((lane: Lane | undefined) => lane);

  return {
    lanes,
    lanesCount,
    layout,
    title,
    titleDisplayed,
  };
};
export const parseShow = (data: any): Show | null => {
  return show(data.items[0]);
};
export const parseShowVideos = (data: any): ShowVideos => {
  const nextEpisodes: ShowNextEpisodes[] =
    data.next_episodes?.map((episode: any) => {
      return {
        date: episode.date,
        caption: episode.caption,
        episodeTitle: episode.episode_title,
        episodeNumber: episode.episode_number,
      };
    }) ?? [];

  return {
    items: data.items.map(video),
    nextEpisodes: nextEpisodes,
    title: data.title,
  };
};
export const parseVideo = (data: any): Video | null => {
  return video(data.video);
};
export const parseVideos = (data: any): Array<Video> => {
  return data.videos.map(video);
};

export const parseLiveEvent = (data: any): LiveEventChannel | null => {
  const liveEventData = { ...data.items[0], overlays: data.overlays };
  return liveEventChannel(liveEventData);
};

export const parseLiveEvents = (data: any): LiveEventChannel[] | null => {
  const overlays = data.overlays;
  return data.items
    .map((item: any) => {
      const liveEventData = { ...item, overlays };
      return liveEventChannel(liveEventData);
    })
    .filter((liveEvent: LiveEventChannel | null) => !!liveEvent);
};

export const parseLive = (data: any): Live | null => {
  return live(data);
};

export const parseEpgChannels = (data: any) => {
  const channels = data.channels.map(epgChannel) as EpgChannel[];
  const maxChannels = data.channel_count;
  return { channels, maxChannels };
};

export const parseTivoScreens = (data: any) => {
  const lanes = data.rows
    .map((row: any) => {
      row.type = 'tivo-api-screens';
      row.item_type = 'tivo-api'; // mParticle uses this value to determine the Tile_Source prop, should be updated if this type changes
      row.title = row.name;
      return parseLane(row);
    })
    .filter((lane: Lane | undefined) => lane);

  return lanes;
};

export const parseNavigationItems = (config: Config) => {
  const configNavItems = config.navigation.items;
  const isLiveStreamAvailable = config['live-stream'].live_toggle;
  let navItems: NavigationItem[];

  // If live stream is off remove it from the navigation items
  if (!isLiveStreamAvailable) {
    navItems = configNavItems.filter(navItem => navItem.slug !== 'Live');
  } else {
    navItems = configNavItems;
  }

  return navItems.map(navItem => {
    navItem.icons.svg_active = formatImageUrl(navItem.icons.svg_active);
    navItem.icons.svg_inactive = formatImageUrl(navItem.icons.svg_inactive);
    return navItem;
  });
};

const getRating = (ratings: any) => {
  if (!ratings) return undefined;
  return ratings.usTv?.value || ratings.mpaa?.value || ratings.genericRating;
};
