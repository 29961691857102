import { withSeparator } from 'support/textUtils';
import {
  getDurationMinutesFormatted,
  getSeasonEpisodeFormatted,
  isMovie,
} from 'support/contentUtils';
import HorizontalMediaItem from './HorizontalMediaItem';
import { Lightning } from '@lightningjs/sdk';
import ToggleButton from './ToggleButton';
import { forceImageNoBadge, updateImageSize } from '../../support/cwImageUtils';

export interface HorizontalWatchedItemTemplateSpec
  extends Lightning.Component.TemplateSpec {
  edit: boolean;
  EditToggle: typeof ToggleButton;
}

const WIDTH = 792; // without highlight padding

const IMAGE_WIDTH = 283;
const IMAGE_HEIGHT = 159;
const IMAGE_MARGIN_RIGHT = 21;

const CONTENT_Y = 8;

const PROGRESS_BAR_WIDTH = 269;
const PROGRESS_BAR_BOTTOM_PADDING = 6;

const TITLE_FONT_SIZE = 26;
const METADATA_FONT_SIZE = 20;

const TITLE_MAX_LINES = 1;
const METADATA_MAX_LINES = 3;

const EDIT_TOGGLE_X = WIDTH - 11;
const EDIT_TOGGLE_Y = 11;

export default class HorizontalWatchedItem extends HorizontalMediaItem<HorizontalWatchedItemTemplateSpec> {
  private _EditToggle = this.getByRef('EditToggle')!;

  private _edit = false;
  private _isToggleActive = false;

  static override get width() {
    return WIDTH;
  }

  static override get height() {
    return IMAGE_HEIGHT;
  }

  static override get imageSpacing() {
    return IMAGE_MARGIN_RIGHT;
  }

  static override get imageWidth() {
    return IMAGE_WIDTH;
  }

  static override get imageHeight() {
    return IMAGE_HEIGHT;
  }

  static override get progressBarWidth() {
    return PROGRESS_BAR_WIDTH;
  }

  static override get progressBarBottomPadding() {
    return PROGRESS_BAR_BOTTOM_PADDING;
  }

  static override get contentY() {
    return CONTENT_Y;
  }

  static override get titleTextStyle(): Lightning.textures.TextTexture.Settings {
    return {
      fontSize: TITLE_FONT_SIZE,
      maxLines: TITLE_MAX_LINES,
    };
  }

  static override get metadataTextStyle(): Lightning.textures.TextTexture.Settings {
    return {
      fontSize: METADATA_FONT_SIZE,
      maxLines: METADATA_MAX_LINES,
      lineHeight: METADATA_FONT_SIZE + 7,
    };
  }

  set edit(edit: boolean) {
    this._edit = edit;
    this._isToggleActive = false;
    this._EditToggle.patch({ visible: edit });
  }

  set isActive(isActive: boolean) {
    this._isToggleActive = isActive;

    this._EditToggle.patch({
      visible: this._edit,
      isActive: isActive,
    });
  }

  static override _template(): Lightning.Component.Template<HorizontalWatchedItemTemplateSpec> {
    return {
      ...super._template(),

      EditToggle: {
        type: ToggleButton,
        mountX: 1,
        x: EDIT_TOGGLE_X,
        y: EDIT_TOGGLE_Y,
        isActive: false,
        visible: false,
      },
    };
  }

  override _active() {
    super._active();
    this._EditToggle.patch({
      visible: this._edit,
      isActive: this._isToggleActive,
    });
  }

  override _focus() {
    super._focus();
    this._EpisodeImage.setSmooth('alpha', 1);
  }

  override _unfocus() {
    super._unfocus();
    this._EpisodeImage.setSmooth('alpha', 0);
  }

  override updateText() {
    if (!this.mediaItem) return;

    const video = this.mediaItem;
    const title = video.seriesName;
    let metadata = '';

    if (isMovie(video)) {
      metadata = getDurationMinutesFormatted(video);
    } else {
      const seasonEpisodeText = getSeasonEpisodeFormatted(video);
      const durationText = getDurationMinutesFormatted(video);
      const episodeName = video.title;

      metadata =
        withSeparator(seasonEpisodeText, durationText) + '\n' + episodeName;
    }

    this._Title.patch({ text: { text: title } });
    this._Metadata.patch({ text: { text: metadata } });
  }

  override updateImages() {
    if (!this.mediaItem) return;

    const episodeThumbnail = updateImageSize(
      this.mediaItem.images.thumbnail,
      IMAGE_WIDTH,
    );
    const showThumbnail = updateImageSize(
      this.mediaItem.images.image_show_thumbnail,
      IMAGE_WIDTH,
    );

    this._imageUrl = showThumbnail;
    this._EpisodeImage.patch({ src: forceImageNoBadge(episodeThumbnail) });
    this._SeriesImage.patch({
      alpha: 1,
      src: forceImageNoBadge(showThumbnail),
    });
  }

  override _handleEnter() {
    if (!this._edit) {
      this.fireAncestors('$onTileSelected', this._mediaItem, {
        action: 'play',
        imageUrl: this._EpisodeImage.src,
      });
    } else {
      this.fireAncestors('$toggleWatchItem', !this._isToggleActive, this);
    }
  }
}
