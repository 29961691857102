export enum ImaEvent {
  LOADED = 'loaded',
  AD_BREAK_STARTED = 'adBreakStarted',
  AD_BREAK_ENDED = 'adBreakEnded',
  AD_PERIOD_STARTED = 'adPeriodStarted',
  AD_PERIOD_ENDED = 'adPeriodEnded',
  AD_PROGRESS = 'adProgress',
  CUEPOINTS_CHANGED = 'cuepointsChanged',
  CLICK = 'click',
  ERROR = 'error',
  STARTED = 'started',
  FIRST_QUARTILE = 'firstquartile',
  MIDPOINT = 'midpoint',
  STREAM_INITIALIZED = 'streamInitialized',
  THIRD_QUARTILE = 'thirdquartile',
  COMPLETE = 'complete',
  SKIPPABLE_STATE_CHANGED = 'skippableStateChanged',
  SKIPPED = 'skip',
  VIDEO_CLICKED = 'videoClicked',
  PAUSED = 'paused',
  RESUMED = 'resumed',
}

export enum CustomEvent {
  BEFORE_SEEK = 'beforeSeek',
  SKIP_AD_POD = 'skipAdPod',
}

// see: https://developers.google.com/interactive-media-ads/docs/sdks/html5/dai/reference/js/Ad
export type Ad = {
  getAdId: () => string;
  getAdPodInfo: () => AdPodInfo;
  getDescription: () => string;
  getDuration: () => number;
  getTitle: () => string;
  [key: string]: () => unknown;
};

export type AdPodInfo = {
  getPodIndex: () => number;
  getAdPosition: () => number;
  [key: string]: () => unknown;
};

export type StreamManager = {
  [keys: string]: any;
  previousCuePointForStreamTime: (
    currentTime: any,
  ) => { start: number; end: number; played: boolean } | null;
};
