import axios from 'axios';
import { AppData } from '@lightningjs/sdk';

export type AdTagParams = {
  csid: string;
  guid: string;
  playerWidth: number;
  playerHeight: number;
};

export const getPauseAdImageUrls = async (params: AdTagParams) => {
  const { csid, guid, playerWidth, playerHeight } = params;
  let adImageUrl = '',
    adStart = '',
    adEnd = '';
  const impressionUrls = [];
  const currentTime = Date.now();

  const pauseAdUrl = AppData?.adParams?.platform.pauseads_url
    .replace(/\{\{Cachebuster\}\}/g, Math.floor(currentTime / 1000).toString())
    .replace(/\{\{CW_GUID\}\}/g, guid)
    .replace(/\{\{WIDTH\}\}/g, playerWidth.toString())
    .replace(/\{\{HEIGHT\}\}/g, playerHeight.toString())
    .replace(/\{\{CACHEBUSTER_CSID\}\}/g, csid)
    .replace(/\{\{CACHEBUSTER_STREAM\}\}/g, currentTime.toString().slice(-6));

  const { isLat, adId } = await AppData!.device.getDeviceInfo();

  try {
    const response = await axios.request({
      url: pauseAdUrl,
      method: 'GET',
    });
    const xml = response.data;

    const parser = new DOMParser();
    const parsedDoc = parser.parseFromString(xml, 'text/xml');

    const staticResources = parsedDoc.getElementsByTagName('StaticResource');
    for (const staticResource of staticResources) {
      if (
        staticResource.getAttribute('creativeType') === 'image/png' &&
        staticResource.textContent
      ) {
        adImageUrl = staticResource.textContent;
        break;
      }
    }
    const impressions = parsedDoc.getElementsByTagName('Impression');
    for (const impression of impressions) {
      if (impression.textContent) impressionUrls.push(impression.textContent);
    }

    const trackingEvents = parsedDoc.getElementsByTagName('vmap:Tracking');
    for (const trackingEvent of trackingEvents) {
      if (trackingEvent.textContent) {
        if (trackingEvent.getAttribute('event') === 'breakStart') {
          adStart = trackingEvent.textContent;
        }
        if (trackingEvent.getAttribute('event') === 'breakEnd') {
          adEnd = trackingEvent.textContent;
        }
      }
    }
  } catch (e) {
    // Ignore
    console.warn(e);
  }

  return { adImageUrl, impressionUrls, adStart, adEnd };
};

export const sendPauseAdImpressions = (urls: string[]) => {
  urls.forEach(url => {
    axios.request({
      url,
      method: 'GET',
    });
  });
};
