import { Colors, Lightning } from '@lightningjs/sdk';
import { getFontFaceFromStyle } from 'support/textUtils';

export interface RatingBadgeTemplateSpec
  extends Lightning.Component.TemplateSpec {
  rating: string;
  size: 'default' | 'extraSmall' | 'small' | 'medium';
  badgeColor: number;
}

const DEFAULT_SIZE = {
  HEIGHT: 32,
  FONT_SIZE: 20,
  PADDING_X: 7,
  PADDING_Y: 6,
};

const MEDIUM_SIZE = {
  HEIGHT: 40,
  FONT_SIZE: 25,
  PADDING_X: 11,
  PADDING_Y: 8,
};

const SMALL_SIZE = {
  HEIGHT: 30,
  FONT_SIZE: 18,
  PADDING_X: 8,
  PADDING_Y: 6,
};

const EXTRA_SMALL_SIZE = {
  HEIGHT: 22,
  FONT_SIZE: 12,
  PADDING_X: 8,
  PADDING_Y: 6,
};

export default class RatingBadge
  extends Lightning.Component<RatingBadgeTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<RatingBadgeTemplateSpec>
{
  private _style = DEFAULT_SIZE;

  set rating(rating: RatingBadgeTemplateSpec['rating']) {
    this.patch({ text: { text: rating } });
  }

  set size(size: RatingBadgeTemplateSpec['size']) {
    if (size === 'medium') {
      this._style = MEDIUM_SIZE;
    } else if (size === 'small') {
      this._style = SMALL_SIZE;
    } else if (size === 'extraSmall') {
      this._style = EXTRA_SMALL_SIZE;
    } else {
      this._style = DEFAULT_SIZE;
    }

    this.h = this._style.HEIGHT;
    this.patch({
      text: {
        h: this._style.HEIGHT,
        lineHeight: this._style.FONT_SIZE + this._style.PADDING_Y,
        fontSize: this._style.FONT_SIZE,
        paddingLeft: this._style.PADDING_X,
        paddingRight: this._style.PADDING_X,
      },
    });
  }

  set badgeColor(badgeColor: RatingBadgeTemplateSpec['badgeColor']) {
    if (!this.text || !this.shader) return;
    this.text.textColor = badgeColor;
    this.shader.strokeColor = badgeColor;
  }

  static override _template(): Lightning.Component.Template<RatingBadgeTemplateSpec> {
    return {
      y: -2, // Adjustment for LightningJS centering issues
      h: DEFAULT_SIZE.HEIGHT,
      text: {
        h: DEFAULT_SIZE.HEIGHT,
        lineHeight: DEFAULT_SIZE.FONT_SIZE + DEFAULT_SIZE.PADDING_Y,
        verticalAlign: 'middle',
        paddingLeft: DEFAULT_SIZE.PADDING_X,
        paddingRight: DEFAULT_SIZE.PADDING_X,
        fontSize: DEFAULT_SIZE.FONT_SIZE,
        fontFace: getFontFaceFromStyle('regular'),
      },
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: 5,
        stroke: 2,
        strokeColor: Colors('highlight').get(),
      },
    };
  }
}
