import { AppData } from '@lightningjs/sdk';
import axios, { AxiosError } from 'axios';
import ApiServiceError from 'support/ApiServiceError';
import { DrmMediaItem } from 'types/api/media';

type ComcastSigninResponse = {
  signInResponse: {
    duration: number;
    idleTimeout: number;
    token: string;
    userId: string;
    userName: string;
  };
};

export type DrmData = {
  hasDrm: boolean;
  licenseUrl?: string;
  videoSrc?: string;
};

const getSmilInfo = async (url: string) => {
  const smilText = await axios.get(url, { responseType: 'text' });

  const parser = new DOMParser();
  const smilXml = parser.parseFromString(smilText.data, 'application/xml');

  const videoSrc =
    smilXml.querySelector('video')?.getAttribute('src') ?? undefined;

  const isDrmXpath = '//*[@name="isDRM"]';
  const isDrmResult = smilXml.evaluate(
    isDrmXpath,
    smilXml,
    null,
    XPathResult.ANY_TYPE,
    null,
  );
  const isDrm = (isDrmResult.iterateNext() as Element | null)?.getAttribute(
    'value',
  );

  const trackingDataXpath = '//*[@name="trackingData"]';
  const trackingDataResult = smilXml.evaluate(
    trackingDataXpath,
    smilXml,
    null,
    XPathResult.ANY_TYPE,
    null,
  );
  const trackingData = (
    trackingDataResult.iterateNext() as Element | null
  )?.getAttribute('value');

  const result: {
    isDrm: boolean;
    pid?: string;
    aid?: string;
    videoSrc?: string;
  } = {
    isDrm: isDrm === 'true',
    videoSrc,
  };

  if (!trackingData && result.isDrm) return null;
  if (!trackingData) return result;

  trackingData.split('|').forEach(item => {
    if (item.startsWith('pid') || item.startsWith('aid')) {
      const [key, value] = item.split('=');
      result[key as 'pid' | 'aid'] = value!;
    }
  });

  return result;
};

const getComcastToken = async () => {
  if (!AppData?.comcast) return null;

  const { signinUrl, credentials } = AppData.comcast;

  const comcastResponse = await axios.get<ComcastSigninResponse>(signinUrl, {
    headers: { Authorization: `Basic ${credentials}` },
    params: {
      form: 'json',
      schema: '1.0',
    },
  });
  return comcastResponse.data.signInResponse.token;
};

export const getDrmData = async (url: string): Promise<DrmData | null> => {
  if (!AppData?.widevine) return null;

  try {
    const [smilInfo, comcastToken] = await Promise.all([
      getSmilInfo(url),
      getComcastToken(),
    ]);

    if (!smilInfo || !comcastToken) return null;

    let licenseUrl: string | undefined;
    if (smilInfo.isDrm) {
      licenseUrl = `${AppData?.widevine.licenseUrl}?form=json&schema=1.0&account=${AppData?.widevine.licenseAccountUrl}/${smilInfo.aid}&token=${comcastToken}&releasePid=${smilInfo.pid}`;
    }

    return { hasDrm: smilInfo.isDrm, videoSrc: smilInfo.videoSrc, licenseUrl };
  } catch (e: any) {
    if (e?.isApiServiceError) throw e;

    throw new ApiServiceError(e as AxiosError);
  }
};

export const getDashItem = (drmItems: DrmMediaItem[]) => {
  return drmItems.find(item => item.mimeType.includes('dash+xml'));
};
