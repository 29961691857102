import { Colors, Lightning } from '@lightningjs/sdk';
import { translate } from 'support/translate';

export interface ProgressBarTemplateSpec
  extends Lightning.Component.TemplateSpec {
  progressPercent: number; // 0 - 1
  width: number;
  height: number;

  Background: Lightning.Texture;
  ProgressBar: Lightning.Texture;
}

const DEFAULT_RADIUS = 6;

export default abstract class ProgressBar<
  TemplateSpec extends Lightning.Component.TemplateSpec = Lightning.Component.TemplateSpec,
> extends Lightning.Component<ProgressBarTemplateSpec & TemplateSpec> {
  protected _progressPercent: ProgressBarTemplateSpec['progressPercent'] = 0;
  // Dynamic width definition. Default value is overwritten by extending components
  protected _width: ProgressBarTemplateSpec['width'] = 0;

  protected _Background = (this as ProgressBar).getByRef('Background')!;
  protected _ProgressBar = (this as ProgressBar).getByRef('ProgressBar')!;

  get title() {
    const progress = Math.floor(this._progressPercent * 100);
    return translate('ttsPrompts.percentCompleted', progress);
  }

  get progressPercent() {
    return this._progressPercent;
  }

  protected set progressPercent(
    progress: ProgressBarTemplateSpec['progressPercent'],
  ) {
    // Progress percent should always be between 0 and 1
    if (progress > 1) {
      this._progressPercent = 1;
    } else if (progress < 0) {
      this._progressPercent = 0;
    } else {
      this._progressPercent = progress;
    }
    this.updateProgressWidth();
  }

  set width(width: ProgressBarTemplateSpec['width']) {
    this._width = width;
    this.updateProgressWidth();
  }

  get width() {
    return this._width;
  }

  static get defaultWidth() {
    // Overwritten by extending components
    return 0;
  }

  static get defaultHeight() {
    // Overwritten by extending components
    return 0;
  }

  static override _template(): Lightning.Component.Template<ProgressBarTemplateSpec> {
    return {
      w: this.defaultWidth,
      h: this.defaultHeight,
      Background: {
        w: (w: number) => w,
        h: (h: number) => h,
        rect: true,
        color: Colors('white').alpha(0.6).get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: DEFAULT_RADIUS,
        },
      },
      ProgressBar: {
        h: (h: number) => h,
        rect: true,
        color: Colors('appAccent').get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: DEFAULT_RADIUS,
        },
      },
    };
  }

  protected updateProgressWidth() {
    const progressPercent = this.progressPercent;
    const progressWidth = progressPercent * this.width;

    (this as ProgressBar).patch({ w: this.width });
    this._ProgressBar.patch({ w: progressWidth });
  }
}
