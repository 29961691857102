export enum PageId {
  UNKNOWN = 'Unknown',
  ERROR = 'Error',
  SPLASH = 'Splash',
  SEARCH = 'Search',
  LIVE = 'Live',
  CONTINUE = 'Continue',
  EPG = 'Channels',
  SETTINGS = 'Settings',
  MOVIE_DETAIL = 'Movie Detail',
  SERIES_DETAIL = 'Series Detail',
  CONTENT_HUB = 'Content Hub',
  PLAYBACK = 'Playback',
}
