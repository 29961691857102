import { AppData, Colors, Lightning, Registry } from '@lightningjs/sdk';
import { CcSettings } from 'config/platforms/deviceIntegration';
import { convertHexColor } from 'support/generalUtils';
import constants from '../../../../static/constants.json';

interface TextTrackDisplayTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Text: Lightning.Element;
}

const SCREEN_WIDTH = 1920;
const SCREEN_HEIGHT = 1080;
const TEXT_PADDING_H = 8;
const FONT_SIZE = 60;
const FLASH_INTERVAL = constants.timers.subtitleFlashingInterval;

const getDefaultTextColor = (alpha = 1) => Colors('text').alpha(alpha).get();
const getHighlightTextColor = (alpha = 0.5) =>
  Colors('black').alpha(alpha).get();

export default class TextTrackDisplay
  extends Lightning.Component<TextTrackDisplayTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<TextTrackDisplayTemplateSpec>
{
  private _paddingV = 0;
  private _flashOn = true;
  private _subtitleFlashingInterval: number | null = null;

  private _Text = this.getByRef('Text')!;

  static override _template() {
    return {
      w: SCREEN_WIDTH,
      y: SCREEN_HEIGHT,
      visible: true,
      mountY: 1,
      Text: {
        align: 'center',
        paddingLeft: TEXT_PADDING_H,
        paddingRight: TEXT_PADDING_H,
        y: 4,
        text: {
          textColor: getDefaultTextColor(),
          highlight: true,
          highlightColor: getHighlightTextColor(),
          fontSize: FONT_SIZE,
          lineHeight: FONT_SIZE * 1.5,
        },
      },
    };
  }

  override _setup() {
    this.setVerticalPadding(1);
    this.setDeviceStyle();

    this._Text.on('txLoaded', () => {
      this.h = this._Text.finalH + 2 * this._paddingV;
      this._Text.x = (SCREEN_WIDTH - this._Text.finalW) / 2;
    });
  }

  override _disable() {
    if (this._subtitleFlashingInterval !== null) {
      Registry.clearInterval(this._subtitleFlashingInterval);
    }
  }

  private setVerticalPadding(padding: number) {
    this._paddingV = padding;
    this._Text.y = padding + 4;
  }

  private setupFlashingStyles(
    settings: Partial<CcSettings>,
    appliedStyles: Lightning.textures.TextTexture.Settings,
  ) {
    if (this._subtitleFlashingInterval !== null) {
      Registry.clearInterval(this._subtitleFlashingInterval);
    }

    const { colorFlashing, backgroundFlashing } = settings;

    if (!colorFlashing && !backgroundFlashing) return;

    this._subtitleFlashingInterval = Registry.setInterval(() => {
      const styles: Lightning.textures.TextTexture.Settings = {};
      const appliedTextColor = appliedStyles.textColor ?? getDefaultTextColor();
      const appliedHighlightColor =
        appliedStyles.highlightColor ?? getHighlightTextColor();

      if (colorFlashing) {
        styles.textColor = this._flashOn ? appliedTextColor : 0x00ffffff;
      }

      if (backgroundFlashing) {
        styles.highlightColor = this._flashOn
          ? appliedHighlightColor
          : 0x00ffffff;
      }

      this._Text.patch({
        text: styles,
      });

      this._flashOn = !this._flashOn;
    }, FLASH_INTERVAL);
  }

  private setDeviceStyle() {
    if (!AppData) return;

    const settings = AppData.device.getCcSettings();

    const styles: Lightning.textures.TextTexture.Settings = {};

    if (settings.fontSize) styles.fontSize = settings.fontSize;
    if (settings.fontStyle) styles.fontStyle = settings.fontStyle;

    if (settings.color) {
      styles.textColor = convertHexColor(settings.color, settings.colorOpacity);
    } else if (settings.colorOpacity) {
      const alpha = Number(`0x${settings.colorOpacity}`) / 0xff;
      styles.textColor = getDefaultTextColor(alpha);
    }

    if (settings.backgroundColor) {
      styles.highlightColor = convertHexColor(
        settings.backgroundColor,
        settings.backgroundOpacity ?? '80',
      );
    } else if (settings.backgroundOpacity) {
      const alpha = Number(`0x${settings.backgroundOpacity}`) / 0xff;
      styles.highlightColor = getHighlightTextColor(alpha);
    }

    if (settings.edgeColor && settings.edgeType) {
      styles.shadow = true;
      styles.shadowColor = convertHexColor(settings.edgeColor);

      const shadowProps = settings.edgeType;
      const [offsetX, offsetY, blur] = shadowProps
        .split('px')
        .map(s => s.trim());

      styles.shadowOffsetX = Number(offsetX);
      styles.shadowOffsetY = Number(offsetY);
      styles.shadowBlur = Number(blur);
    } else {
      styles.shadow = false;
    }

    // Window settings are not feasible for this component and have been left out

    this._Text.patch({
      text: styles,
    });

    this.setupFlashingStyles(settings, styles);
  }

  reset() {
    this._Text.patch({ text: { text: '' } });
  }

  displayTextTrack(textTrack: TextTrack | undefined) {
    if (!textTrack) return;

    const cues = textTrack.activeCues ?? [];
    const cueText: string[] = [];
    for (let i = cues.length - 1; i >= 0; i--) {
      const cue = (cues[i] as VTTCue)?.text ?? '';
      cueText.push(cue);
    }

    this._Text.patch({ text: { text: cueText.join('\n') } });
  }
}
