import { Lightning, Colors, AppData, Router } from '@lightningjs/sdk';
import LoadingSpinner from './common/LoadingSpinner';
import { PageId } from 'types/pageId';
import { Media } from 'types/api/media';
import { SelectItemContext } from 'types/events';
import { StaticViewContexts, ViewContext } from 'types/analytics';
import { onTileSelectedHandler } from 'support/routerUtils';
import constants from '../../static/constants.json';
import { ContentHub } from 'services/cwData';

export interface PageTemplateSpec extends Lightning.Component.TemplateSpec {
  loaded: boolean;
  spinnerPosition: { x?: number; y?: number };
  Background: object;
  LoadingSpinner: typeof LoadingSpinner;
}

interface PageTypeConfig extends Lightning.Component.TypeConfig {
  IsPage: true;
}

interface ChildPageTemplateSpec<TData = unknown>
  extends Lightning.Component.TemplateSpec {
  pageId?: PageId;
  viewContext?: ViewContext;
  pageData?: TData;
}

export default class Page<
  TemplateSpec extends ChildPageTemplateSpec = ChildPageTemplateSpec,
  TypeConfig extends Lightning.Component.TypeConfig = Lightning.Component.TypeConfig,
> extends Lightning.Component<
  PageTemplateSpec & TemplateSpec,
  PageTypeConfig & TypeConfig
> {
  protected _pageId = PageId.UNKNOWN;
  // UNKNOWN view context should be overwritten for all playback events
  protected _viewContext = StaticViewContexts.UNKNOWN;
  protected _handleCcChange: ((ccEnabled: boolean) => void) | undefined;

  private _loaded = false;
  private _LoadingSpinner = (this as Page).getByRef('LoadingSpinner')!;
  private _Background = (this as Page).getByRef('Background')!;
  private _pageData: TemplateSpec['pageData'] = undefined;

  get pageId() {
    return this._pageId;
  }

  get viewContext() {
    return this._viewContext;
  }

  set pageData(content: TemplateSpec['pageData']) {
    this._pageData = content;
  }

  get pageData() {
    return this._pageData;
  }

  set spinnerPosition(value: { x?: number; y?: number }) {
    this._LoadingSpinner.patch({
      x: value?.x,
      y: value?.y,
    });
  }

  set loaded(value: boolean) {
    this._loaded = value;
    this._updateSpinner();
  }

  get loaded() {
    return this._loaded;
  }

  set backgroundColor(colorName: string) {
    const color = Colors(colorName).get();
    this._Background.patch({ color });
  }

  get isNavBarVisible(): boolean {
    const widgets = this.widgets as Router.WidgetContainer;
    return !!widgets.navbar.alpha;
  }

  set isNavBarVisible(value: boolean) {
    const widgets = this.widgets as Router.WidgetContainer;
    widgets.navbar.alpha = value ? 1 : 0;
  }

  override _handleLeft(): void | boolean | undefined {
    const widgets = this.widgets as Router.WidgetContainer;
    if (!widgets.navbar.active) return false;
    Router.focusWidget('NavBar');
  }

  static override _template(): Lightning.Component.Template<PageTemplateSpec> {
    return {
      Background: {
        w: 1920,
        h: 1080,
        rect: true,
        color: Colors('appBackground').get(),
      },
      LoadingSpinner: {
        type: LoadingSpinner,
        visible: false,
        x: 960,
        y: 540,
        zIndex: 1000,
      },
    };
  }

  override _setup() {
    this.loaded = true;
  }

  override _enable() {
    if (this.pageId == PageId.PLAYBACK || this.pageId == PageId.EPG) {
      this.application.on('ccChange', tvCcEnabled => {
        if (this._handleCcChange) {
          const appCcEnabled =
            AppData?.storageService.subtitle.get() !==
            constants.closedCaption.none;
          if (tvCcEnabled !== appCcEnabled) this._handleCcChange(tvCcEnabled);
        }
      });
    }
  }

  override _getFocused(): any {
    return this;
  }

  hideBackground() {
    this._Background.patch({ alpha: 0 });
  }

  showBackground() {
    this._Background.patch({ alpha: 1 });
  }

  txLoaded() {
    this._updateSpinner();
  }

  _updateSpinner() {
    if (this.loaded) {
      this._LoadingSpinner.show = false;
    } else {
      this._LoadingSpinner.show = true;
    }
  }

  $onTileSelected(data?: Media | ContentHub, context?: SelectItemContext) {
    if (!data) return;

    onTileSelectedHandler(data, {
      viewContext: this.viewContext,
      pageId: this.pageId,
      ...context,
    });
  }
}
