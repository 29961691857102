import { Lightning, Colors } from '@lightningjs/sdk';
import { HoverableListItem } from 'components/common/HoverableListItem';
import { getImageTextureObj } from 'support/generalUtils';
import { getFontFaceFromStyle } from 'support/textUtils';
import { Season } from 'types/api/media';

export interface SeasonTabTemplateSpec
  extends Lightning.Component.TemplateSpec {
  selected: boolean;
  seasonTitle: string;
  subheading: string;
  season: Season | null;

  Background: Lightning.Texture;
  Content: {
    FocusIndicator: Lightning.Texture;
    TextContainer: {
      Title: Lightning.Element;
      Subheading: Lightning.Element;
    };
  };
}

export const WIDTH = 350;
const HEIGHT_COLLAPSED = 70;
export const HEIGHT_EXPANDED = 98;

const CONTENT_X = 104;
const CONTENT_Y = 13;

const FOCUS_INDICATOR_WIDTH = 7;
const FOCUS_INDICATOR_HEIGHT = 45;
const FOCUS_INDICATOR_MARGIN_RIGHT = 38;

const TITLE_FONT_SIZE = 32;
const TITLE_HEIGHT = 42;
const SUBHEADING_FONT_SIZE = 20;

export default class SeasonTab
  extends HoverableListItem<SeasonTabTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<SeasonTabTemplateSpec>
{
  private _selected = false;
  private _season: Season | null = null;

  private _Background = this.getByRef('Background')!;
  private _Content = this.getByRef('Content')!;
  private _FocusIndicator = this._Content.getByRef('FocusIndicator')!;
  private _TextContainer = this._Content.getByRef('TextContainer')!;
  private _Title = this._TextContainer.getByRef('Title')!;
  private _Subheading = this._TextContainer.getByRef('Subheading')!;

  set selected(selected: boolean) {
    if (this._selected === selected) return;

    this._selected = selected;
    this.updateTabSizing();
    this.updateFocusIndicator();
    this.updateSubheader();
  }

  get title() {
    return [this._Title.text?.text ?? '', this._Subheading.text?.text ?? ''];
  }

  set seasonTitle(seasonTitle: string) {
    this._Title.patch({ text: { text: seasonTitle } });
  }

  set subheading(subheading: string) {
    this._Subheading.patch({ text: { text: subheading } });
  }

  set season(season: Season | null) {
    this._season = season;
  }

  get season() {
    return this._season;
  }

  static override _template(): Lightning.Component.Template<SeasonTabTemplateSpec> {
    const focusIndicatorTexture = getImageTextureObj(
      'static/images/navigation/navigation-selection-indicator-hot-sauce.svg',
      FOCUS_INDICATOR_WIDTH,
      FOCUS_INDICATOR_HEIGHT,
    );

    return {
      w: WIDTH,
      h: HEIGHT_COLLAPSED,
      Background: {
        alpha: 0,
        rect: true,
        w: (w: number) => w,
        h: (h: number) => h,
        color: Colors('highlight').get(),
      },
      Content: {
        x: CONTENT_X,
        y: CONTENT_Y,
        flex: { direction: 'row' },
        FocusIndicator: {
          alpha: 0,
          w: FOCUS_INDICATOR_WIDTH,
          ...focusIndicatorTexture,
        },
        TextContainer: {
          flex: {
            direction: 'column',
            alignItems: 'center',
          },
          Title: {
            h: TITLE_HEIGHT,
            text: {
              textColor: Colors('text').get(),
              fontSize: TITLE_FONT_SIZE,
            },
          },
          Subheading: {
            alpha: 0,
            text: {
              textColor: Colors('text').get(),
              fontSize: SUBHEADING_FONT_SIZE,
              fontFace: getFontFaceFromStyle('light'),
            },
          },
        },
      },
    };
  }

  override _getFocused() {
    return this;
  }

  override _focus() {
    this.updateBackground();
  }

  override _unfocus() {
    this.updateBackground();
  }

  private updateBackground() {
    const alpha = this.hasFocus() ? 0.2 : 0;
    this._Background.setSmooth('alpha', alpha);
  }

  private updateTabSizing() {
    const h = this._selected ? HEIGHT_EXPANDED : HEIGHT_COLLAPSED;
    this.setSmooth('h', h);
  }

  private updateFocusIndicator() {
    const alpha = this._selected ? 1 : 0;
    this._FocusIndicator.setSmooth('alpha', alpha);

    const marginRight = this._selected ? FOCUS_INDICATOR_MARGIN_RIGHT : 0;
    // @ts-ignore
    this._FocusIndicator.setSmooth('flexItem.marginRight', marginRight);
  }

  private updateSubheader() {
    const alpha = this._selected ? 1 : 0;
    this._Subheading.setSmooth('alpha', alpha);
  }
}
