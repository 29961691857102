import { Platform } from 'models/platforms/platform';
import { Registry } from '@lightningjs/sdk';

import metadata from '../../../../metadata.json';
import { AbstractDeviceIntegration } from '../AbstractDeviceIntegration';

export class UnknownDeviceIntegration extends AbstractDeviceIntegration {
  protected platform = Platform.UNKNOWN;

  getDeviceId(): string {
    return this.deviceId;
  }

  getPerformanceMode(): boolean {
    return false;
  }

  getAppId(): string {
    return metadata.identifier;
  }

  async load() {
    return Promise.resolve();
  }

  beforeAppClose(callback: (showPopup: boolean) => void) {
    callback(true);
  }

  closeApp() {
    // Not Supported
  }

  handleDeepLink() {
    // Not Supported
    return false;
  }

  handleNetworkChange(callback: (arg: boolean) => void) {
    Registry.addEventListener(window, 'online', function () {
      callback(true);
    });
    Registry.addEventListener(window, 'offline', function () {
      callback(false);
    });
  }

  setScreenSaver(state: boolean) {
    return;
  }

  getAnnouncerEnabled() {
    return false;
  }

  handleAnnouncerChange(callback: (arg: boolean) => void): void {
    // Not Supported
  }
}
