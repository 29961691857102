import { Language } from '@lightningjs/sdk';

export const translate = (key: string, ...args: any[]) => {
  const [firstKey, ...rest] = key.split('.');

  const rawValue: string | object = Language.translate(firstKey);

  let stringValue: string;
  if (typeof rawValue === 'string') {
    stringValue = rawValue; // LightingJS translate successful
  } else {
    stringValue =
      rest.reduce((obj, currentKey) => obj?.[currentKey], rawValue as any) ??
      rest.pop() ??
      firstKey;
  }

  if (!args) {
    return stringValue;
  }

  return stringValue.replace(/{(\d+)}/g, (match, index) => {
    const replacement = args[index];
    return typeof replacement !== 'undefined' ? replacement : match;
  });
};
