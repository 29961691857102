import { Lightning } from '@lightningjs/sdk';

import { STANDARD_TRANSLATION } from 'support/animations';
import Highlight from './Highlight';
import { ListWithSpeech } from './CollectionWrappersWithSpeech';
import { HoverableListItem } from './HoverableListItem';

interface ListItemTemplateSpec extends Lightning.Component.TemplateSpecLoose {
  w: number;
  h: number;
}

export interface SizedColumnListTemplateSpec
  extends Lightning.Component.TemplateSpec {
  items: Lightning.Element.PatchTemplate<ListItemTemplateSpec>[];
  index: number;
  forceLoad: boolean;
  highlightPadding: number;
  scrollMax: number;
  enableRequests: boolean;

  ListContainer: {
    List: typeof ListWithSpeech;
    Highlight: typeof Highlight;
  };
}

const DEFAULT_SCROLL_MAX = 1;
const DEFAULT_HIGHLIGHT_PADDING = 10;

export default class SizedColumnList
  extends HoverableListItem<SizedColumnListTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<SizedColumnListTemplateSpec>
{
  private _items: SizedColumnListTemplateSpec['items'] = [];
  private _highlightPadding = DEFAULT_HIGHLIGHT_PADDING;
  private _scrollMax = DEFAULT_SCROLL_MAX;
  private _enabled = false;

  private _ListContainer = this.getByRef('ListContainer')!;
  private _List = this._ListContainer.getByRef('List')!;
  private _Highlight = this._ListContainer.getByRef('Highlight')!;

  set items(items: SizedColumnListTemplateSpec['items']) {
    this._items = items;
    this._List.patch({ index: 0, items });
    this.update();
  }

  get items() {
    return this._List.items;
  }

  set highlightPadding(
    highlightPadding: SizedColumnListTemplateSpec['highlightPadding'],
  ) {
    this._highlightPadding = highlightPadding;
    this._List.patch({ x: highlightPadding, y: highlightPadding });
    this.update();
  }

  set scrollMax(scrollMax: SizedColumnListTemplateSpec['scrollMax']) {
    this._scrollMax = scrollMax;
    this.update();
  }

  set forceLoad(forceLoad: SizedColumnListTemplateSpec['forceLoad']) {
    this._List.forceLoad = forceLoad;
  }

  set index(index: SizedColumnListTemplateSpec['index']) {
    this._List.index = index;
  }

  get index() {
    return this._List.index;
  }

  set enableRequests(value: boolean) {
    this._List.enableRequests = value;
  }

  get length() {
    return this._List.length;
  }

  static override _template(): Lightning.Component.Template<SizedColumnListTemplateSpec> {
    return {
      ListContainer: {
        List: {
          type: ListWithSpeech,
          direction: 'column',
          spacing: DEFAULT_HIGHLIGHT_PADDING * 2,
          scrollTransition: { ...STANDARD_TRANSLATION },
          x: DEFAULT_HIGHLIGHT_PADDING,
          y: DEFAULT_HIGHLIGHT_PADDING,
          requestThreshold: 1,
          signals: {
            onRequestItems: '_onRequestItems',
          },
        },
        Highlight: {
          type: Highlight,
          visible: false,
        },
      },
    };
  }

  override _enable() {
    this._enabled = true;
    this._List.scrollCount = 0;
    this._List.lastY = 0;
    this.update();
  }

  override _disable() {
    this._enabled = false;
    this._List.scrollCount = 0;
  }

  override _getFocused() {
    return this._List;
  }

  override _focus() {
    this._Highlight.patch({ visible: true });
  }

  override _unfocus() {
    this._Highlight.patch({ visible: false });
  }

  private update() {
    if (!this._items.length || !this._enabled) return;

    const { highlightWidth, highlightHeight } = this.getHighlightSize();

    this._List.patch({ spacing: this._highlightPadding * 2 });
    this._Highlight.patch({
      w: highlightWidth,
      h: highlightHeight,
    });

    this._List.scroll = this.handleListScroll.bind(this);
  }

  private handleListScroll(itemWrapper: unknown, { index }: any) {
    const { highlightHeight } = this.getHighlightSize();

    // Subtract 1 from the max scroll index so it's also zero-indexed
    const maxScrollIndex = this._scrollMax - 1;

    let listY = 0;
    let highlightY = 0;

    if (this._List.isHovered) {
      // Scroll by hover
      highlightY = highlightHeight * index;

      // If list is scrolled down, exclude the scrolled heights.
      if (this._List.scrollCount > 0) {
        highlightY = highlightY - highlightHeight * this._List.scrollCount;
      }

      this._Highlight.setSmooth('y', highlightY);
      this._List.isHovered = false;

      // since we don't want the list to scroll with hover,
      // we should return 0 or same position as before.
      return this._List.lastY || 0;
    } else {
      // Scroll by key press
      if (index > maxScrollIndex) {
        listY = highlightHeight * (maxScrollIndex - index);
        highlightY = highlightHeight * maxScrollIndex;
      } else {
        highlightY = highlightHeight * index;
      }

      if (this._List.lastY > listY) {
        // List scrolled down. Calculate how many times it scrolled.
        const yDiff = this._List.lastY - listY;
        const totalScrolls = yDiff / highlightHeight;
        this._List.scrollCount += totalScrolls;
      } else if (this._List.lastY < listY) {
        // List scrolled up. Calculate how many times it scrolled.
        const yDiff = listY - this._List.lastY;
        const totalScrolls = yDiff / highlightHeight;
        this._List.scrollCount = Math.max(
          0,
          this._List.scrollCount - totalScrolls,
        );
      } else {
        // List didn't scroll
      }
      this._Highlight.setSmooth('y', highlightY);
      // Save current list's position
      this._List.lastY = listY;
      return listY;
    }
  }

  private getHighlightSize() {
    const { w, h } = this._items[0]!;
    const totalHighlightPadding = this._highlightPadding * 2;
    const highlightWidth = (w ?? 0) + totalHighlightPadding;
    const highlightHeight = (h ?? 0) + totalHighlightPadding;
    return { highlightWidth, highlightHeight };
  }

  getListItems(): unknown[] {
    return this._List.items;
  }

  clear() {
    this._List.clear();
  }
}
