import AbstractHistoryService from 'services/history/AbstractHistoryService';
import { TizenDeviceIntegration } from 'config/platforms/tizen/TizenDeviceIntegration';
import { Video } from 'types/api/media';
import { getVideoReleaseYear, isMovie } from 'support/contentUtils';
import StorageService from 'services/StorageService';
import { TizenServiceIntegration } from 'config/platforms/tizen/TizenServiceIntegration';
import {
  ContinueWatchingItem,
  ContinueWatchingMovieRating,
  ContinueWatchingSeriesRating,
} from 'types/tizen';

type TizenHistoryEntryKey = keyof Omit<
  ContinueWatchingItem,
  'Season' | 'Episode'
>;
/**
 * The structure of Tizen's continue watching history entry. Note that Tizen stores
 * every value as a string despite some being sent as numbers
 */
type TizenHistoryEntry = Record<TizenHistoryEntryKey, string>;

type TizenHistory = Pick<TizenHistoryEntry, 'content_id' | 'playback'>[];

type ConstructorArgs = {
  deviceIntegration: TizenDeviceIntegration;
  storageService: StorageService;
};

// We can only store up to 5 titles in Tizen's Continue Watching
const MAX_ITEMS = 5;

export default class TizenHistoryService extends AbstractHistoryService {
  private readonly serviceIntegration: TizenServiceIntegration;

  private appIconPath: string;
  private appId: string;
  private appName: string;

  /**
   * Local reference of Tizen's continue watching history. When `null`, assume
   * there is an ambiguous problem with obtaining history
   */
  private tizenHistory: TizenHistory | null = null;
  private finishedUpdatingOnBoot = false;
  private shareHistoryWithTizen = true;

  constructor({ deviceIntegration, storageService }: ConstructorArgs) {
    super({ storageService });

    this.serviceIntegration = deviceIntegration.serviceIntegration;

    this.appIconPath = deviceIntegration.getAppIconPath();
    this.appId = deviceIntegration.getAppId();
    this.appName = deviceIntegration.getAppName();
  }

  shouldUpdateHistoryOnBoot() {
    return true;
  }

  async updateHistoryOnBoot(): Promise<void> {
    this.shareHistoryWithTizen =
      this.storageService.shareHistoryWithTizen.get() ?? true;

    // Purge history data before attempting to update Tizen continue watching
    this.purgeInvalid();
    await this.syncTizenHistory();

    this.finishedUpdatingOnBoot = true;
  }

  override async updateHistory() {
    super.updateHistory();

    // Prevent augmenting Tizen history before it has been updated on boot. This
    // only occurs when we are migrating history from previous app or when syncing
    // Tizen history on boot
    if (!this.finishedUpdatingOnBoot) return;
    await this.syncTizenHistory();
  }

  shouldShareHistoryWithTizen() {
    return this.shareHistoryWithTizen;
  }

  async onShareHistoryWithTizenChanged(shareHistory: boolean) {
    // Don't do anything if share history state did not change
    if (this.shareHistoryWithTizen === shareHistory) return;

    this.storageService.shareHistoryWithTizen.set(shareHistory);
    this.shareHistoryWithTizen = shareHistory;
    await this.syncTizenHistory();
  }

  /**
   * Syncs Tizen's continue watching history by:
   * 1. Fetching the next 5 videos from watch history
   * 2. Removing any videos in Tizen's history not in the 5 fetched videos
   * 3. Adding any of the 5 fetched videos not already in Tizen's history
   */
  private async syncTizenHistory() {
    await this.initTizenHistory();

    const tizenHistory = this.tizenHistory;
    const shareHistoryWithTizen = this.shareHistoryWithTizen;

    // Do not sync if Samsung's access to watch history has been revoked
    if (!shareHistoryWithTizen) {
      // Clear history if Tizen history was not initialized or there is already content
      if (tizenHistory === null || tizenHistory.length)
        await this.clearTizenHistory();
      return;
    }

    const watchHistoryVideos = await this.getSortedWatchHistoryVideos(
      true,
      0,
      MAX_ITEMS,
    );

    let contentIdsToRemove: string[] | 'ALL' = [];
    let videosToAdd: Video[] = [];

    if (!tizenHistory) {
      // Assume there is an issue with Tizen continue watching storage, update all
      contentIdsToRemove = 'ALL';
      videosToAdd = watchHistoryVideos;
    } else {
      const contentIdsToKeep: string[] = [];
      for (const video of watchHistoryVideos) {
        const inTizenHistory = await this.isVideoInTizenHistory(video);

        if (inTizenHistory) {
          contentIdsToKeep.push(video.guid);
        } else {
          videosToAdd.push(video);
        }
      }

      tizenHistory.forEach(({ content_id }) => {
        const shouldKeepItem = contentIdsToKeep.includes(content_id);
        if (!shouldKeepItem) (contentIdsToRemove as string[]).push(content_id);
      });
    }

    if (contentIdsToRemove === 'ALL') {
      await this.clearTizenHistory();
    } else {
      await this.deleteContentIdsFromTizenHistory(contentIdsToRemove);
    }

    await this.addVideosToTizenHistory(videosToAdd);
  }

  private async isVideoInTizenHistory(video: Video): Promise<boolean> {
    const { guid, showSlug } = video;

    await this.initTizenHistory();
    const tizenHistory = this.tizenHistory;

    const historyEntry = tizenHistory?.find(
      ({ content_id }) => content_id === guid,
    );
    if (!historyEntry) return false;

    const historyProgress = parseInt(historyEntry.playback);
    const progress = Math.floor(this.getVideoProgress(showSlug, guid));
    return historyProgress === progress;
  }

  private async initTizenHistory() {
    // Already initialized
    if (this.tizenHistory) return;
    const tizenHistoryEntries = await this.getTizenHistoryEntries();

    this.tizenHistory = tizenHistoryEntries
      ? tizenHistoryEntries.map(({ content_id, playback }) => {
          return {
            content_id,
            playback: playback!,
          };
        })
      : null;
  }

  private async clearTizenHistory() {
    try {
      await this.serviceIntegration.deleteAllItems();
      this.tizenHistory = [];
    } catch (e) {
      console.error('[History Service] clearTizenHistory failed', e);
    }
  }

  private async deleteContentIdsFromTizenHistory(contentIds: string[]) {
    const deleteContentIdPromises = contentIds.map(
      this.deleteContentIdFromTizenHistory.bind(this),
    );
    await Promise.allSettled(deleteContentIdPromises);
  }

  private async deleteContentIdFromTizenHistory(contentId: string) {
    try {
      await this.serviceIntegration.deleteItem(contentId);

      if (this.tizenHistory) {
        const contentIndex = this.tizenHistory.findIndex(
          ({ content_id }) => content_id === contentId,
        );
        if (contentIndex > -1) this.tizenHistory.splice(contentIndex, 1);
      }
    } catch (e) {
      console.error(
        `[History Service] deleteContentIdsFromTizenHistory failed "content id":${contentId}`,
        e,
      );
    }
  }

  private async addVideosToTizenHistory(videos: Video[]) {
    const addVideoPromises = videos.map(this.addVideoToTizenHistory.bind(this));
    await Promise.allSettled(addVideoPromises);
  }

  private async addVideoToTizenHistory(video: Video) {
    const {
      durationSecs,
      episodeInSeason,
      guid,
      rating,
      season,
      seriesName,
      showSlug,
      title,
    } = video;

    const imageUrl = video.images.thumbnail ?? '';
    const contentTitle = isMovie(video) ? title : seriesName;
    const subtitle = isMovie(video) ? '' : title;

    const expirationTime = this.getVideoExpirationTime(showSlug, guid);
    const lastModified = this.getVideoLastModifiedTime(showSlug, guid);
    const progress = Math.floor(this.getVideoProgress(showSlug, guid));
    const releaseYear = getVideoReleaseYear(video);

    const item: ContinueWatchingItem = {
      app_name: this.appName,
      app_icon: this.appIconPath,
      content_id: guid,
      payload: `{"content_type":"episode","id":"${guid}"}`,
      image_url: imageUrl,
      content_title: contentTitle,
      sub_title: subtitle,
      rate: rating as
        | ContinueWatchingMovieRating
        | ContinueWatchingSeriesRating,
      release: `${releaseYear}`,
      duration: `${parseInt(durationSecs)}`,
      playback: `${progress}`,
      expiry: `${expirationTime}`,
      timestamp: `${lastModified}`,
      Season: `${season ? parseInt(season) : 0}`,
      Episode: `${episodeInSeason ? parseInt(episodeInSeason) : 0}`,
    };

    try {
      await this.serviceIntegration.addItem(item);

      if (this.tizenHistory) {
        this.tizenHistory.push({
          content_id: guid,
          playback: `${progress}`,
        });
      }
    } catch (e) {
      console.error(
        `[History Service] addVideoToTizenHistory failed "content id":${guid}`,
        e,
      );
    }
  }

  private async getTizenHistoryEntries(): Promise<ContinueWatchingItem[]> {
    try {
      const items = await this.serviceIntegration.getItems();
      return items;
    } catch (e) {
      console.error('[History Service] getTizenHistoryEntries failed', e);
      return [];
    }
  }
}
