import { Lightning } from '@lightningjs/sdk';
import { STANDARD_TRANSLATION } from 'support/animations';
import Highlight from './Highlight';
import { GridWithSpeech } from './CollectionWrappersWithSpeech';
import { ListTypes } from './SizedRowList';
import { IndexData, LightningBuilder } from 'types/lightning';
import { TileTemplateSpec } from './tiles/Tile';
import { HoverableComponent } from './HoverableComponent';

const TILE_SPACING = 30;
const BORDER_GAP = 6;

export interface GridTemplateSpec extends Lightning.Component.TemplateSpec {
  forceLoad: boolean;
  items: LightningBuilder<ListTypes, TileTemplateSpec>[];
  columns: number;
  spacing: number;
  crossSpacing: number;
  gcThreshold: number;
  listType: ListTypes;

  Grid: typeof GridWithSpeech;
  Highlight: typeof Highlight;
}

export default class Grid
  extends HoverableComponent<GridTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<GridTemplateSpec>
{
  private _Grid = this.getByRef('Grid')!;
  private _Highlight = this.getByRef('Highlight')!;

  get forceLoad() {
    return this._Grid.forceLoad;
  }

  set forceLoad(forceLoad: GridTemplateSpec['forceLoad']) {
    this._Grid.forceLoad = forceLoad;
  }

  get items() {
    return this._Grid.items;
  }

  set items(items: GridTemplateSpec['items']) {
    if (!items.length) {
      this._Grid.clear();
      return;
    }
    this._Grid.items = items;
  }

  set columns(columns: GridTemplateSpec['columns']) {
    this._Grid.columns = columns;
    this.updateGridScroll();
  }

  set spacing(spacing: GridTemplateSpec['spacing']) {
    this._Grid.spacing = spacing;
    this.updateGridScroll();
  }

  set crossSpacing(crossSpacing: GridTemplateSpec['crossSpacing']) {
    this._Grid.crossSpacing = crossSpacing;
    this.updateGridScroll();
  }

  set listType(type: ListTypes) {
    this._Highlight.patch({
      w: type.highlightWidth + 2 * BORDER_GAP,
      h: type.highlightHeight + 2 * BORDER_GAP,
      x: -BORDER_GAP,
      y: -BORDER_GAP,
    });
    this._Grid.itemType = type;
    this.updateGridScroll();
  }

  set gcThreshold(gcThreshold: number) {
    this._Grid.patch({ gcThreshold });
  }

  get index() {
    return this._Grid.index as number;
  }

  get scrollCount() {
    return this._Grid.scrollCount;
  }

  static override _template(): Lightning.Component.Template<GridTemplateSpec> {
    return {
      Grid: {
        type: GridWithSpeech,
        spacing: TILE_SPACING,
        scrollTransition: { ...STANDARD_TRANSLATION },
        passSignals: {
          onIndexChanged: true,
          onRequestItems: true,
        },
      },
      Highlight: {
        type: Highlight,
        visible: false,
      },
    };
  }

  override _getFocused() {
    return this._Grid;
  }

  override _focus() {
    this._Highlight.patch({ visible: this._Grid.hasItems });
  }

  override _unfocus() {
    this._Highlight.patch({ visible: false });
  }

  override _enable() {
    this._Grid.scrollCount = 0;
    this._Grid.lastY = 0;
  }

  override _disable() {
    this._Grid.scrollCount = 0;
    this._Grid.lastY = 0;
  }

  private updateGridScroll() {
    this._Grid.scroll = this.columnScrollFn.bind(this);
  }

  private columnScrollFn(itemWrapper: unknown, indexData: IndexData) {
    const { index } = indexData;
    const itemType = this._Grid.itemType as ListTypes;
    const rowHeight = itemType.height + this._Grid.spacing;

    // current row position
    const row = Math.floor(index / (this._Grid.columns ?? 1));

    // current column position
    const column = index % (this._Grid.columns ?? 1);

    // total number of rows
    const rowCount = Math.ceil(this._Grid.length / (this._Grid.columns ?? 1));

    // number of rows till reaching the last row (including the current row)
    const rowsRemaining = rowCount - row;

    // number of rows that can be seen (fit) on the grid
    const rowsFit = Math.floor(this.h / rowHeight);

    let highlightPosition;

    if (this._Grid.isHovered) {
      // Need to calculate highlight's Y position if the list is already scrolled down
      const highlightYOffset =
        this._Grid.scrollCount > 0 ? rowHeight * this._Grid.scrollCount : 0;
      highlightPosition = {
        x: column * (itemType.width + this._Grid.crossSpacing) - BORDER_GAP,
        y: row * rowHeight - BORDER_GAP - highlightYOffset,
      };

      this._Highlight.setSmooth('x', highlightPosition.x);
      this._Highlight.setSmooth('y', highlightPosition.y);

      this._Grid.isHovered = false;
      return this._Grid.lastY || 0;
    }

    // The grid should scroll when there are more rows than the grid can fit
    const shouldScrollGrid = rowsRemaining > rowsFit;

    // The focus highlight's y-position does not need to move if the grid scrolls,
    // otherwise it should move based on the number of rows remaining
    highlightPosition = {
      x: column * (itemType.width + this._Grid.crossSpacing) - BORDER_GAP,
      y: shouldScrollGrid
        ? -BORDER_GAP
        : (rowsFit - rowsRemaining) * rowHeight - BORDER_GAP,
    };
    this._Highlight.setSmooth('x', highlightPosition.x);
    this._Highlight.setSmooth('y', highlightPosition.y);

    const gridY = shouldScrollGrid
      ? -row * rowHeight
      : -(rowCount - rowsFit) * rowHeight;

    if (this._Grid.lastY > gridY) {
      // Scrolling down, update scrollCount
      const yDiff = this._Grid.lastY - gridY;
      const totalScrolls = yDiff / rowHeight;
      this._Grid.scrollCount += totalScrolls;
    } else if (this._Grid.lastY < gridY) {
      // Scrolling up, update scrollCount
      const yDiff = gridY - this._Grid.lastY;
      const totalScrolls = yDiff / rowHeight;
      this._Grid.scrollCount = Math.max(
        0,
        this._Grid.scrollCount - totalScrolls,
      );
    }
    this._Grid.lastY = gridY;
    return gridY;
  }

  clear() {
    this._Grid.clear();
  }

  getScrollPosition() {
    return this._Grid.scroll(undefined, { index: this._Grid.index });
  }

  /**
   * Tells grid to start using pagination. Note that if the onRequestItems handler returns
   * false (when there are no more items), pagination will be automatically turned off
   *
   * @param requestThreshold The amount of **rows** before the end of the grid before more
   * items are requested
   */
  usePagination(requestThreshold: number) {
    this._Grid.patch({
      enableRequests: true,
      requestThreshold,
    });
  }
}
