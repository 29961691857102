import { Colors, Lightning } from '@lightningjs/sdk';
import { SpeechType } from '@lightningjs/ui-components';
import { getImageTextureObj } from 'support/generalUtils';
import { getFontFaceFromStyle } from 'support/textUtils';
import { HoverableComponent } from './HoverableComponent';

const DEFAULT_HEIGHT = 70;
const DEFAULT_FONT_SIZE = 28;
const DEFAULT_PADDING = 21;
const DEFAULT_PADDING_H = 12;
const DEFAULT_MARGIN = 16;
const DEFAULT_RADIUS = 7;

export interface ButtonTemplateSpec extends Lightning.Component.TemplateSpec {
  height: number;
  title: SpeechType;
  label: string;
  action: string;
  actionArgs: unknown;
  backgroundColor: { focused?: number; unfocused?: number };
  fontColor: { focused?: number; unfocused?: number };
  fontSize: number;
  padding: number;
  paddingH: number;
  margin: number;
  minWidth: number;
  radius: number;
  startIcon: {
    focused?: string | Lightning.Element.PatchTemplate;
    unfocused?: string | Lightning.Element.PatchTemplate;
  };
  endIcon: {
    focused?: string | Lightning.Element.PatchTemplate;
    unfocused?: string | Lightning.Element.PatchTemplate;
  };

  StartIcon: object;
  Label: object;
  EndIcon: object;
}

export default class Button
  extends HoverableComponent<ButtonTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<ButtonTemplateSpec>
{
  private _title: SpeechType | null = null;
  private _label = '';
  private _action = '';
  private _actionArgs: ButtonTemplateSpec['actionArgs'];
  private _backgroundColor = {
    focused: Colors('highlight').get(),
    unfocused: Colors('buttonInactive').get(),
  };

  private _fontColor = {
    focused: Colors('activeText').get(),
    unfocused: Colors('text').get(),
  };
  private _fontSize = DEFAULT_FONT_SIZE;
  private _padding = DEFAULT_PADDING;
  private _paddingH = DEFAULT_PADDING_H;

  /**
   * set this variable to override button height
   */
  private _height = 0;

  private _margin = DEFAULT_MARGIN;
  private _minWidth = 0;
  private _radius = DEFAULT_RADIUS;
  private _startIcon: ButtonTemplateSpec['startIcon'] = {};
  private _endIcon: ButtonTemplateSpec['endIcon'] = {};

  protected _StartIcon = this.getByRef('StartIcon')!;
  protected _Label = this.getByRef('Label')!;
  protected _EndIcon = this.getByRef('EndIcon')!;

  get height() {
    return this._height;
  }

  set height(height: number) {
    this._height = height;
    this.h = height;
  }

  get title() {
    return this._title ?? this.label;
  }

  set title(title: SpeechType) {
    this._title = title;
  }

  get label() {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
    this._Label.patch({ text: { text: value } });
    this._Label.on('txLoaded', () => {
      this.updateButtonWidth();
      this._Label.removeAllListeners('txLoaded');
    });
  }

  set action(value: string) {
    this._action = value;
  }

  get actionArgs() {
    return this._actionArgs;
  }

  set actionArgs(actionArgs: ButtonTemplateSpec['actionArgs']) {
    this._actionArgs = actionArgs;
  }

  set position(value: { x?: number; y?: number; mount?: number }) {
    this.patch({ x: value.x, y: value.y, mount: value.mount });
  }

  set backgroundColor(value: { focused?: number; unfocused?: number }) {
    this._backgroundColor = {
      focused: value.focused ?? this._backgroundColor.focused,
      unfocused: value.unfocused ?? this._backgroundColor.unfocused,
    };

    if (this.hasFocus()) {
      this.patch({ color: this._backgroundColor.focused });
    } else {
      this.patch({ color: this._backgroundColor.unfocused });
    }
  }

  set fontColor(value: { focused?: number; unfocused?: number }) {
    this._fontColor = {
      focused: value.focused ?? this._fontColor.focused,
      unfocused: value.unfocused ?? this._fontColor.unfocused,
    };

    if (this.hasFocus()) {
      this._Label.patch({ text: { textColor: this._fontColor.focused } });
    } else {
      this._Label.patch({ text: { textColor: this._fontColor.unfocused } });
    }
  }

  set fontSize(value: number) {
    this._fontSize = value;
    this._Label.patch({
      text: {
        fontSize: value,
      },
    });

    this.updateButtonHeight();
  }

  set padding(value: number) {
    this._padding = value;
    this.updateButtonHeight();
  }

  get paddingH() {
    return this._paddingH;
  }

  set paddingH(paddingH: ButtonTemplateSpec['paddingH']) {
    this._paddingH = paddingH;
    this.updateButtonWidth();
  }

  get margin() {
    return this._margin;
  }

  set margin(value: number) {
    this._margin = value;
    this.updateButtonWidth();
  }

  get minWidth() {
    return this._minWidth;
  }

  set minWidth(minWidth: ButtonTemplateSpec['minWidth']) {
    this._minWidth = minWidth;
    this.w = Math.max(this.w, minWidth);
  }

  set radius(value: number) {
    this._radius = value;
    this.updateButtonRadius();
  }

  get startIcon() {
    return this._startIcon;
  }

  set startIcon(startIcon: ButtonTemplateSpec['startIcon']) {
    this._startIcon = startIcon;

    if (this.hasFocus()) {
      if (startIcon.focused) {
        this.setStartIcon(startIcon.focused);
      }
    } else {
      if (startIcon.unfocused) {
        this.setStartIcon(startIcon.unfocused);
      }
    }
  }

  get endIcon() {
    return this._endIcon;
  }

  set endIcon(endIcon: ButtonTemplateSpec['endIcon']) {
    this._endIcon = endIcon;

    if (this.hasFocus()) {
      if (endIcon.focused) {
        this.setEndIcon(endIcon.focused);
      }
    } else {
      if (endIcon.unfocused) {
        this.setEndIcon(endIcon.unfocused);
      }
    }
  }

  static override _template(): Lightning.Component.Template<ButtonTemplateSpec> {
    return {
      h: DEFAULT_HEIGHT,
      rect: true,
      color: Colors('buttonInactive').get(),
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: DEFAULT_RADIUS,
      },
      rtt: true,
      flex: {
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
      StartIcon: {},
      Label: {
        y: 2,
        text: {
          fontSize: DEFAULT_FONT_SIZE,
          textAlign: 'center',
          textColor: Colors('text').get(),
          fontFace: getFontFaceFromStyle('bold'),
        },
        flexItem: {
          marginLeft: DEFAULT_MARGIN,
          marginRight: DEFAULT_MARGIN,
        },
      },
      EndIcon: {},
    };
  }

  override _getFocused() {
    return this;
  }

  override _focus() {
    this.patch({
      color: this._backgroundColor.focused,
    });

    this._Label.patch({
      text: { textColor: this._fontColor.focused },
    });

    if (this.startIcon.focused) {
      this.setStartIcon(this.startIcon.focused);
    }
    if (this.endIcon.focused) {
      this.setEndIcon(this.endIcon.focused);
    }
  }

  override _unfocus() {
    this.patch({
      color: this._backgroundColor.unfocused,
    });

    this._Label.patch({
      text: { textColor: this._fontColor.unfocused },
    });

    if (this.startIcon.unfocused) {
      this.setStartIcon(this.startIcon.unfocused);
    }
    if (this.endIcon.unfocused) {
      this.setEndIcon(this.endIcon.unfocused);
    }
  }

  override _handleEnter() {
    this._action && this.signal(this._action, this.actionArgs);
  }

  private updateButtonHeight() {
    if (this.height) return;
    this.patch({ h: this._fontSize + 2 * this._padding });
  }

  protected updateButtonWidth() {
    const hasStartIcon = !!this._StartIcon.texture?._source?.lookupId;
    const hasEndIcon = !!this._EndIcon.texture?._source?.lookupId;

    const newWidth =
      this._StartIcon.renderWidth +
      this._Label.renderWidth +
      this._EndIcon.renderWidth +
      this._margin * 2 +
      this.paddingH * 2;

    this.w = Math.max(this.minWidth, newWidth);

    const labelMargins: any = {};

    if (hasStartIcon) {
      this._StartIcon.patch({
        flexItem: { marginLeft: this.margin },
      });
    } else {
      labelMargins.marginLeft = this.margin;
    }

    if (hasEndIcon) {
      this._EndIcon.patch({
        flexItem: { marginRight: this.margin },
      });
    } else {
      labelMargins.marginRight = this.margin;
    }

    this._Label.patch({
      flexItem: labelMargins,
    });
  }

  private updateButtonRadius() {
    this.patch({
      shader: { radius: this._radius },
    });
  }

  private setIconHelper(
    ref: Lightning.Element,
    value: string | Lightning.Element.PatchTemplate,
  ) {
    if (typeof value === 'string') {
      if (value) {
        ref.patch(getImageTextureObj(value, this._fontSize, this._fontSize));
      } else {
        ref.patch({ texture: undefined });
      }
    } else {
      ref.patch({ shader: undefined });
      ref.patch(value);
    }

    ref.once('txLoaded', () => {
      this.updateButtonWidth();
      ref.removeAllListeners('txUnloaded');
    });

    ref.once('txUnloaded', () => {
      this.updateButtonWidth();
      ref.removeAllListeners('txLoaded');
    });
  }

  setStartIcon(value: string | Lightning.Element.PatchTemplate) {
    this.setIconHelper(this._StartIcon, value);
  }

  setEndIcon(value: string | Lightning.Element.PatchTemplate) {
    this.setIconHelper(this._EndIcon, value);
  }
}
