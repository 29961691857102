import { CommonKeyMapping } from './commonKeyMappings';

// These keys do not need to be registered - done by default
const MandatoryTizenKeyMapping = {
  37: 'Left',
  38: 'Up',
  39: 'Right',
  40: 'Down',
  13: 'Enter',
  10009: 'Back',
};

export const AdditionalTizenKeyMapping = {
  ...CommonKeyMapping,
} as const;

export const TizenKeyMapping = {
  ...MandatoryTizenKeyMapping,
  ...AdditionalTizenKeyMapping,
};
