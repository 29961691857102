import MIGRATION_2_4_3 from 'services/migrations/2.4.3';
import { isVersionNewer } from 'support/generalUtils';

export type VersionMigration = {
  version: string;
  migrationFn: () => Promise<void>;
};

// Insert ascending migration functions into array
const migrations: readonly VersionMigration[] = [
  MIGRATION_2_4_3 /* add newer migration here */,
];

const getMigrationFunctions = (previousVersion: string) => {
  const migrationsStartIndex = migrations.findIndex(migration => {
    // find a migration version thats newer then the previous version
    return isVersionNewer(migration.version, previousVersion);
  });

  // if no migration versions are required return an empty array
  if (migrationsStartIndex === -1) return [];

  const missingMigrations = migrations.slice(migrationsStartIndex);
  return missingMigrations.map(migration => migration.migrationFn);
};

export default getMigrationFunctions;
