import { createAbstractAnnouncerIntegration } from '../AbstractAnnouncerIntegration';
import { SpeechType } from '@lightningjs/ui-components';

export const createLGAnnouncerIntegration = () => {
  return class LGAnnouncerIntegration extends createAbstractAnnouncerIntegration() {
    override _voiceOut(toSpeak: SpeechType) {
      this.updateLabel(toSpeak);
      this.speak();
    }

    private createAriaElement() {
      const ariaElement = document.createElement('span');
      ariaElement.id = 'ariaElement';
      ariaElement.setAttribute('aria-label', '');
      ariaElement.setAttribute('aria-live', 'assertive');
      ariaElement.tabIndex = 1;
      ariaElement.hidden = false;
      document.body.appendChild(ariaElement);
      return ariaElement;
    }

    private getAriaElement() {
      const ariaElement = document.getElementById('ariaElement');
      if (!ariaElement) return this.createAriaElement();
      return ariaElement;
    }

    private updateLabel(toSpeak: SpeechType) {
      const ariaElement = this.getAriaElement();
      ariaElement?.blur();

      if (toSpeak === undefined || typeof toSpeak === 'string') {
        ariaElement?.setAttribute('aria-label', (toSpeak as string) ?? '');
      } else if (Array.isArray(toSpeak)) {
        const message = toSpeak.join(' ');
        ariaElement?.setAttribute('aria-label', message);
      }
    }

    private speak() {
      const ariaElement = this.getAriaElement();
      ariaElement?.focus();
    }
  };
};
