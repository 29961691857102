import { Lightning } from '@lightningjs/sdk';
import { SpeechType } from '@lightningjs/ui-components';
import { getImageTextureObj } from 'support/generalUtils';
import { HoverableComponent } from './HoverableComponent';

const FOCUSED_ALPHA = 1;
const UNFOCUSED_ALPHA = 0.5;

export interface FocusableIconTemplateSpec
  extends Lightning.Component.TemplateSpec {
  title: SpeechType;
  icon: string | Lightning.Element.PatchTemplate;
  action: string;
}

export default class FocusableIcon
  extends HoverableComponent<FocusableIconTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<FocusableIconTemplateSpec>
{
  private _title: SpeechType;
  private _icon: FocusableIconTemplateSpec['icon'] = {
    focused: '',
    unfocused: '',
  };
  private _action = '';

  override set w(w: number) {
    super.w = w;
    this.updateIcon();
  }

  override get w(): number {
    return super.w;
  }

  override set h(h: number) {
    super.h = h;
    this.updateIcon();
  }

  override get h(): number {
    return super.h;
  }

  get title() {
    return this._title;
  }

  set title(title: FocusableIconTemplateSpec['title']) {
    this._title = title;
  }

  get icon() {
    return this._icon;
  }

  set icon(icon: FocusableIconTemplateSpec['icon']) {
    this._icon = icon;
    this.updateIcon();
  }

  set action(value: string) {
    this._action = value;
  }

  static override _template(): Lightning.Component.Template<Lightning.Component.TemplateSpecLoose> {
    return {
      alpha: UNFOCUSED_ALPHA,
    };
  }

  override _handleEnter() {
    this.signal(this._action);
  }

  override _focus() {
    this.setSmooth('alpha', FOCUSED_ALPHA);
  }

  override _unfocus() {
    this.setSmooth('alpha', UNFOCUSED_ALPHA);
  }

  private updateIcon() {
    if (typeof this.icon === 'string') {
      this.patch(getImageTextureObj(this.icon, this.w, this.h));
    } else {
      this.patch(this.icon);
    }
  }
}
