import { AppData, Lightning } from '@lightningjs/sdk';
import SizedRowList, {
  SizedRowListTemplateSpec,
} from '../../common/SizedRowList';
import { IndexData, LightningBuilder } from 'types/lightning';
import constants from '../../../../static/constants.json';
import { VideoLaneItem } from 'services/cwData';
import { PlayableMediaImages } from 'types/api/media';
import { TileParentContext } from './ContentHubPage';
import ContinueTile, {
  ContinueTileTemplateSpec,
} from 'components/common/tiles/ContinueTile';

export interface WatchHistoryRowListTemplateSpec
  extends SizedRowListTemplateSpec {
  laneIndex: number;
  laneItems: VideoLaneItem[];
}

export class WatchHistoryRowList
  extends SizedRowList
  implements
    Lightning.Component.ImplementTemplateSpec<WatchHistoryRowListTemplateSpec>
{
  _laneIndex: number | null = null;

  set laneIndex(laneIndex: number) {
    this._laneIndex = laneIndex;
  }

  set laneItems(items: VideoLaneItem[]) {
    const laneItems = this.buildListItems(items, 'thumbnail');
    super.items = laneItems;
  }

  async _onRequestItems(indexData: IndexData) {
    const { dataLength } = indexData;
    const requestPage =
      Math.ceil(dataLength / constants.watchHistory.pageSize) + 1;

    try {
      const continueLaneItems =
        await AppData?.historyService.getContinueLaneItems(
          requestPage,
          constants.watchHistory.pageSize,
        );

      if (continueLaneItems && continueLaneItems.length > 0) {
        return this.buildListItems(continueLaneItems, 'thumbnail');
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  private buildListItems(
    items: Array<VideoLaneItem>,
    imageKey: keyof PlayableMediaImages,
  ) {
    return items.map((item, index) => {
      const parentContext: TileParentContext = {
        crossIndex: index,
        mainIndex: this._laneIndex!,
        rowTitle: this.label ?? '',
        isTivoSource: false,
      };

      const result: LightningBuilder<
        typeof ContinueTile,
        ContinueTileTemplateSpec
      > = {
        data: item,
        notifyWhenFullyVisible: true,
        parentContext,
      };

      if (item.images && imageKey in item.images)
        result.imageUrl = item.images[imageKey as keyof typeof item.images];

      return result;
    });
  }
}
