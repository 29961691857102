import Lightning from '@lightningjs/sdk/src/Lightning';
import { VideoPlayer } from '@lightningjs/sdk';
import { ImaEvent, StreamManager } from 'types/player';

export interface AdCuePoint {
  start: number;
  end: number;
  played: boolean;
}

const CUE_STARTING_TOLERANCE = 2;
const AD_SECOND_OFFSET = 3;

interface AdManagerTemplateSpec extends Lightning.Component.TemplateSpec {
  cuePoints: Array<AdCuePoint> | undefined;
}

export default class AdManager
  extends Lightning.Component<AdManagerTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<AdManagerTemplateSpec>
{
  private _streamManager: StreamManager | undefined;
  private _cuePoints: Array<AdCuePoint> | undefined;

  get cuePoints() {
    return this._cuePoints;
  }

  initialize(streamManager: StreamManager) {
    this._cuePoints = undefined;
    this._streamManager = streamManager;
  }

  getCuePoint(time: number): AdCuePoint | null {
    const imaCuePoint =
      this._streamManager?.previousCuePointForStreamTime(time);
    if (!imaCuePoint) return null;

    const cuePoint = this._cuePoints?.find(
      cp => cp.start === imaCuePoint.start && cp.end === imaCuePoint.end,
    );

    if (cuePoint) {
      // Use both local and IMA cue point to determine if played
      imaCuePoint.played = imaCuePoint.played || cuePoint.played;
    }

    return imaCuePoint;
  }

  getCurrentCuePoint(): AdCuePoint | null {
    const playerTime = VideoPlayer.currentTime;
    const cuePoint = this.getCuePoint(
      // If the player time = cue point start time, IMA returns the previous cue
      // point, despite being inside an ad. Using slight offset to account for
      // this behavior
      playerTime + CUE_STARTING_TOLERANCE,
    );

    if (!cuePoint || playerTime + CUE_STARTING_TOLERANCE < cuePoint.start) {
      return null;
    }
    return cuePoint;
  }

  isAdPreroll(): boolean {
    if (!this._streamManager) return false;

    const cue = this.getCurrentCuePoint();
    return !!cue && cue.start === 0;
  }

  shouldSkipAdPod(hasStartedAds: boolean): boolean {
    // Do not skip if user is already watching ads (an ad pod's `played`
    // attribute will be true once an ad pod starts)
    if (hasStartedAds) return false;

    const cuePoint = this.getCurrentCuePoint();
    if (!cuePoint) return false;

    // There is a brief section of time where an ad pod is beginning but the ads
    // state is unset. Check if the cuePoint has already been played
    const playerCurrentTime = VideoPlayer.currentTime;
    return (
      cuePoint.played && playerCurrentTime <= cuePoint.end - AD_SECOND_OFFSET
    );
  }

  isPlayerInAdPod(): boolean {
    const cuePoint = this.getCurrentCuePoint();
    if (!cuePoint) return false;

    // Adding a slight offset to ad start / end time to account for any timing
    // issues
    const cuePointStart = cuePoint.start - AD_SECOND_OFFSET;
    const cuePointEnd = cuePoint.end + AD_SECOND_OFFSET;
    const playerCurrentTime = VideoPlayer.currentTime;
    return (
      cuePointStart <= playerCurrentTime && playerCurrentTime <= cuePointEnd
    );
  }

  adDuration(): number | null {
    if (!this._streamManager) return null;

    const cue = this.getCurrentCuePoint();
    if (!cue) return null;

    const { start, end } = cue;
    return end - start;
  }

  currentAdTime(): number {
    if (!this._streamManager) return 0;

    const cue = this.getCurrentCuePoint();
    if (!cue) return 0;

    const { start, end, played } = cue;
    const currentTime = VideoPlayer.currentTime - start;

    if (!played) return 0;
    if (end < currentTime) return end;
    return currentTime;
  }

  getRemainingAdTime(): number | null {
    if (!this._streamManager) return null;

    const cue = this.getCurrentCuePoint();
    if (!cue) return null;

    const { end } = cue;
    return end - Math.round(VideoPlayer.currentTime);
  }

  shouldCleanupAdPod(hasStartedAds: boolean): boolean {
    // No need to cleanup ad pod if we are not in an ad state
    if (!hasStartedAds) return false;

    // IMA will occasionally fail to send an AD_BREAK_ENDED event. Verify that
    // we are still playing an ad
    return !this.isPlayerInAdPod();
  }

  _onStreamEvent(event: any) {
    if (event.type !== ImaEvent.CUEPOINTS_CHANGED) return;
    const { cuepoints: imaCuePoints } = event.getStreamData();
    const cuePoints = this._cuePoints;

    if (cuePoints && cuePoints.length === imaCuePoints.length) {
      // If we have local cue points & our local and IMA cue point lengths match,
      // update our local cue points' played property
      (imaCuePoints as Array<AdCuePoint>).forEach((imaCuePoint, index) => {
        // The local and IMA cue point indexes should match
        const cuePoint = cuePoints[index];

        if (
          !cuePoint ||
          cuePoint.start !== imaCuePoint.start ||
          cuePoint.end !== imaCuePoint.end
        ) {
          return;
        } else {
          imaCuePoint.played = imaCuePoint.played || cuePoint.played;
        }
      });
    }

    this._cuePoints = imaCuePoints;
    this.signal('cuePointsChanged', this._cuePoints);
  }

  /**
   * IMA will sometimes fail to set an ad pod's `played` value. This usually occurs in
   * the `CUEPOINTS_CHANGED` IMA event, which happens at the start of an ad. This method
   * guarantees that upon completing an ad, the ad pod's `played` state will be `true`
   */
  adPodEnded() {
    const cuePoint = this.getCurrentCuePoint();
    if (!cuePoint || !this._cuePoints) return;

    const savedCuePointIndex = this._cuePoints.findIndex(
      cp => cp.start === cuePoint.start && cp.end === cuePoint.end,
    );
    if (savedCuePointIndex === -1) return;

    this._cuePoints[savedCuePointIndex]!.played = true;
    this.signal('cuePointsChanged', this._cuePoints);
  }
}
