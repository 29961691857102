import { AppData, Colors, Lightning } from '@lightningjs/sdk';
import { SpeechType } from '@lightningjs/ui-components';
import Button from 'components/common/Button';
import MoreInfo from 'components/common/MoreInfo';
import MoreInfoModal from 'components/common/MoreInfoModal';
import MediaProgressBar from 'components/common/progressBar/MediaProgressBar';
import {
  formatPlayMedia,
  getNextEpisodeAvailableFormatted,
  isMovie,
} from 'support/contentUtils';
import { getFontFaceFromStyle } from 'support/textUtils';
import { translate } from 'support/translate';
import { Show, ShowVideos, Episode } from 'types/api/media';
import MetadataLabel from './MetadataLabel';
import { getImageTextureObj } from 'support/generalUtils';
import constants from '../../../static/constants.json';

const INVISIBLE = constants.ui.invisible;
const VISIBLE = 1;

export interface ShowcaseSectionTemplateSpec
  extends Lightning.Component.TemplateSpec {
  episodes: Array<ShowVideos>;
  extras: ShowVideos | null;
  featuredEpisode: Episode | null;
  show: Show | null;

  Background: Lightning.Component;
  Content: {
    Metadata: typeof MetadataLabel;
    MoreInfo: typeof MoreInfo;
    TextContainer: any;
    ButtonContainer: {
      PlayButtonContainer: {
        PlayButton: typeof Button;
        ProgressBar: typeof MediaProgressBar;
      };
      TrailerButton: typeof Button;
    };
  };
  MoreInfoModal: typeof MoreInfoModal;
}

const SHOWCASE_SECTION_WIDTH = 1920;
export const SHOWCASE_SECTION_HEIGHT = 768;

const GRADIENT_PIVOT_X = -0.11;
const GRADIENT_PIVOT_Y = 1.18;

const CONTENT_X = 96;
const CONTENT_Y = 345;

const FONT_SIZE = 29;

const METADATA_PADDING = 5;
const MORE_INFO_PADDING = 20;

const TEXT_CONTAINER_DEFAULT_MARGIN = 85;

const NEXT_EPISODE_MARGIN_TOP = 30;
const NEXT_EPISODE_MARGIN_BOTTOM = 20;
const NEXT_EPISODE_RELEASE_HEIGHT = 34;

const CAST_MARGIN_TOP = 10;
const CAST_MARGIN_BOTTOM = 42;
const CAST_HEIGHT = 34;

const PLAY_BUTTON_MARGIN = 40;
const PLAY_BUTTON_WIDTH = 400;

const PROGRESS_BAR_MARGIN = 10;

const TRAILER_BUTTON_W = 252;

const BUTTON_HEIGHT = 70;

const PLAY_ICON_UNFOCUSED = getImageTextureObj(
  'static/images/playback/play-icon-light.svg',
  18,
  23,
);
const PLAY_ICON_FOCUSED = getImageTextureObj(
  'static/images/playback/play-icon-dark.svg',
  18,
  23,
);

export default class ShowcaseSection
  extends Lightning.Component<ShowcaseSectionTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<ShowcaseSectionTemplateSpec>
{
  private _extras: ShowVideos | null = null;
  private _featuredEpisode: Episode | null = null;
  private _show: Show | null = null;

  private _Background = this.getByRef('Background')!;
  private _Content = this.getByRef('Content')!;
  private _Metadata = this._Content.getByRef('Metadata')!;
  private _MoreInfo = this._Content.getByRef('MoreInfo')!;
  private _TextContainer = this._Content.getByRef('TextContainer')!;
  private _ButtonContainer = this._Content.getByRef('ButtonContainer')!;

  private _PlayButtonContainer = this._ButtonContainer.getByRef(
    'PlayButtonContainer',
  )!;
  private _PlayButton = this._PlayButtonContainer.getByRef('PlayButton')!;
  private _ProgressBar = this._PlayButtonContainer.getByRef('ProgressBar')!;
  private _TrailerButton = this._ButtonContainer.getByRef('TrailerButton')!;

  private _MoreInfoModal = this.getByRef('MoreInfoModal')!;

  get announce() {
    if (!this.show) return;

    return [
      this.show.title,
      this.show.rating,
      this.show.activeYears,
      this.show.genres.join(', '),
      this.show.description,
    ];
  }

  get show() {
    return this._show;
  }

  set show(show: Show | null) {
    this._show = show;
    if (!show) return;

    this.updateBackgroundImage(show);
    this.updateMetadata(show);
    this.updateMoreInfo(show);
    this.updateMoreInfoModal(show);
    this.updateTrailerButton(show);

    if (isMovie(show)) {
      this.setupCast(show);
    }
  }

  set episodes(episodes: Array<ShowVideos>) {
    this.updateNextEpisode(episodes);
  }

  set extras(extras: ShowVideos | null) {
    this._extras = extras;
    this.updatePlayButton();
  }

  set featuredEpisode(featuredEpisode: Episode | null) {
    this._featuredEpisode = featuredEpisode;

    // Order matters since progress is used to defined the play button's title
    this.updateProgressBar();
    this.updatePlayButton();
  }

  static override _template(): Lightning.Component.Template<ShowcaseSectionTemplateSpec> {
    return {
      w: SHOWCASE_SECTION_WIDTH,
      h: SHOWCASE_SECTION_HEIGHT,
      Background: {
        rect: true,
        w: SHOWCASE_SECTION_WIDTH,
        h: SHOWCASE_SECTION_HEIGHT,
        shader: {
          type: Lightning.shaders.RadialGradient,
          radius: SHOWCASE_SECTION_HEIGHT,
          pivot: [GRADIENT_PIVOT_X, GRADIENT_PIVOT_Y],
          outerColor: Colors('appBackground').alpha(INVISIBLE).get(),
          innerColor: Colors('appBackground').alpha(VISIBLE).get(),
        },
      },
      Content: {
        x: CONTENT_X,
        y: CONTENT_Y,
        flex: { direction: 'column' },
        Metadata: {
          type: MetadataLabel,
          flexItem: { marginBottom: METADATA_PADDING },
        },
        MoreInfo: {
          x: -MORE_INFO_PADDING,
          type: MoreInfo,
          action: '$showModal',
          signals: {
            $showModal: '$showModal',
            $onHover: '$onHover',
          },
        },
        TextContainer: {
          h: 0, // Adding height 0 so flexbox won't ignore it.
          flexItem: { marginBottom: TEXT_CONTAINER_DEFAULT_MARGIN },
        },
        ButtonContainer: {
          flex: { direction: 'row' },
          PlayButtonContainer: {
            flexItem: { marginRight: PLAY_BUTTON_MARGIN },
            flex: { direction: 'column' },
            w: PLAY_BUTTON_WIDTH,
            PlayButton: {
              type: Button,
              alpha: INVISIBLE,
              minWidth: PLAY_BUTTON_WIDTH,
              height: BUTTON_HEIGHT,
              label: translate('global.play'),
              action: '$playContent',
              endIcon: {
                focused: PLAY_ICON_FOCUSED,
                unfocused: PLAY_ICON_UNFOCUSED,
              },
              passSignals: {
                $playContent: '$playContent',
              },
              signals: {
                $onHover: '$onHover',
              },
            },
            ProgressBar: {
              flexItem: { marginTop: PROGRESS_BAR_MARGIN },
              type: MediaProgressBar,
              alpha: INVISIBLE,
              width: PLAY_BUTTON_WIDTH,
            },
          },
          TrailerButton: {
            type: Button,
            alpha: INVISIBLE,
            minWidth: TRAILER_BUTTON_W,
            height: BUTTON_HEIGHT,
            label: translate('global.trailer'),
            action: '$playTrailer',
            endIcon: {
              focused: PLAY_ICON_FOCUSED,
              unfocused: PLAY_ICON_UNFOCUSED,
            },
            passSignals: {
              $playTrailer: '$playTrailer',
            },
            signals: {
              $onHover: '$onHover',
            },
          },
        },
      },
      MoreInfoModal: {
        alpha: INVISIBLE,
        type: MoreInfoModal,
      },
    };
  }

  override _active() {
    this.updateInitialState();
  }

  override _inactive() {
    this._TextContainer.patch({ alpha: INVISIBLE });
    this._PlayButton.patch({ alpha: INVISIBLE });
    this._TrailerButton.patch({ alpha: INVISIBLE });
  }

  private updateInitialState() {
    if (this._PlayButton.alpha === VISIBLE) {
      this._setState('PlayButton');
    } else if (this._TrailerButton.alpha === VISIBLE) {
      this._setState('TrailerButton');
    } else {
      this._setState('MoreInfo');
    }
  }

  private updateBackgroundImage(show: Show) {
    const { images } = show;
    const src = images.image_featured ?? '';

    this._Background.patch({ src });
  }

  private updateMetadata(show: Show) {
    this._Metadata.patch({ mediaContent: show });
  }

  private updateMoreInfo(show: Show) {
    const { description } = show;
    this._MoreInfo.patch({ description });
  }

  private updateMoreInfoModal(show: Show) {
    this._MoreInfoModal.patch({ mediaContent: show });
  }

  private updateTrailerButton(show: Show) {
    const { featuredTrailer } = show;
    if (!featuredTrailer?.id) return;

    this._TrailerButton.patch({ alpha: VISIBLE });
    this.updateInitialState();
  }

  private updateNextEpisode(episodes: Array<ShowVideos>) {
    if (!episodes.length) return;

    const lastEpisodesList = episodes[episodes.length - 1]!;
    if (!lastEpisodesList.nextEpisodes.length) return;

    const { caption, date } = lastEpisodesList.nextEpisodes[0]!;
    if (!caption || !date) return;

    const nextEpisodeConnector = translate('seriesDetail.nextEpisodeConnector');
    const nextEpisodeCaption = `${caption}${nextEpisodeConnector}`;
    const nextEpisodeDate = getNextEpisodeAvailableFormatted(date);

    this._TextContainer.patch({
      alpha: VISIBLE,
      flexItem: {
        marginTop: NEXT_EPISODE_MARGIN_TOP,
        marginBottom: NEXT_EPISODE_MARGIN_BOTTOM,
      },
      flex: { direction: 'row' },
      h: NEXT_EPISODE_RELEASE_HEIGHT,
      NextEpisodeCaption: {
        text: {
          fontFace: getFontFaceFromStyle('light'),
          fontSize: FONT_SIZE,
          text: nextEpisodeCaption,
        },
      },
      NextEpisodeDate: {
        text: {
          fontFace: getFontFaceFromStyle('bold'),
          fontSize: FONT_SIZE,
          text: nextEpisodeDate,
        },
      },
    });
  }

  private updateProgressBar() {
    const mediaItem = this._featuredEpisode ?? undefined;
    this._ProgressBar.patch({ mediaItem });

    const alpha = this._ProgressBar.progressPercent > 0 ? VISIBLE : INVISIBLE;
    this._ProgressBar.patch({ alpha });
  }

  private updatePlayButton() {
    const extras = this._extras;
    const featuredEpisode = this._featuredEpisode;
    const show = this._show;

    let label = '';
    let title: SpeechType = '';

    if (featuredEpisode && show) {
      const { guid, showSlug } = featuredEpisode;
      const videoHistory = AppData?.historyService.getVideoHistory(
        showSlug,
        guid,
      );

      const featuredContent = isMovie(show) ? show : featuredEpisode;

      label = formatPlayMedia(featuredContent, {
        progress: videoHistory?.progress,
        isComplete: videoHistory?.isComplete,
      });

      const progress =
        this._ProgressBar.alpha === VISIBLE ? this._ProgressBar.title : '';
      title = [label, progress];
    } else if (extras && extras.items.length) {
      const playNowText = translate('global.playNow');
      label = playNowText;
      title = playNowText;
    } else {
      // No content, can't update play button
      return;
    }

    this._PlayButton.patch({ alpha: VISIBLE, label, title });
    this.updateInitialState();
  }

  private setupCast(show: Show) {
    const cast = show.showCast ?? '';
    if (!cast) return;

    const castText = translate('global.castWithNames', cast);
    this._TextContainer.patch({
      alpha: VISIBLE,
      flexItem: {
        marginTop: CAST_MARGIN_TOP,
        marginBottom: CAST_MARGIN_BOTTOM,
      },
      flex: { direction: 'row' },
      h: CAST_HEIGHT,
      Cast: {
        w: 674,
        text: {
          fontFace: getFontFaceFromStyle('light'),
          fontSize: FONT_SIZE,
          text: castText,
          maxLines: 1,
        },
      },
    });
  }

  private hideModal() {
    this._MoreInfoModal.patch({
      smooth: {
        alpha: INVISIBLE,
      },
    });
  }

  $showModal() {
    this._MoreInfoModal.patch({
      smooth: {
        alpha: VISIBLE,
      },
    });
    this._setState('MoreInfoModal');
  }

  $onHover(target: any) {
    switch (target) {
      case this._PlayButton:
        this._setState('PlayButton');
        break;
      case this._TrailerButton:
        this._setState('TrailerButton');
        break;
      case this._MoreInfo:
        this._setState('MoreInfo');
        break;
    }
    this.signal('$onShowcaseHover');
  }

  static override _states() {
    return [
      class MoreInfo extends this {
        override _getFocused() {
          return this._MoreInfo;
        }

        override _handleDown() {
          if (this._PlayButton.alpha === VISIBLE) {
            this._setState('PlayButton');
          } else if (this._TrailerButton.alpha === VISIBLE) {
            this._setState('TrailerButton');
          } else {
            return false;
          }
        }
      },

      class MoreInfoModal extends this {
        override _getFocused() {
          return this._MoreInfoModal;
        }

        override _handleBack() {
          this._setState('MoreInfo');
          this.hideModal();
        }

        override _handleDown() {
          return;
        }
      },
      class PlayButton extends this {
        override _getFocused() {
          return this._PlayButton;
        }

        override _handleUp() {
          this._setState('MoreInfo');
        }

        override _handleRight() {
          if (this._TrailerButton.alpha === VISIBLE) {
            this._setState('TrailerButton');
          }
        }
      },
      class TrailerButton extends this {
        override _getFocused() {
          return this._TrailerButton;
        }

        override _handleUp() {
          this._setState('MoreInfo');
        }

        override _handleLeft() {
          if (this._PlayButton.alpha === VISIBLE) {
            this._setState('PlayButton');
          }
        }
      },
    ];
  }
}
