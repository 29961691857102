import { AppData, Registry } from '@lightningjs/sdk';
import { AxiosResponse } from 'axios';
import { CacheInformation, CacheVersionData } from 'types/api/cacheInformation';
import { Config } from 'types/api/config';
import CwApiClient, { Endpoints } from './CwApiClient';
import { minutesToMilliseconds } from 'support/dateUtils';

type CacheStorageObj<TResponse> = {
  response: TResponse;
  timeoutDate: number;
};

type Cache<TResponse = unknown> = Record<string, CacheStorageObj<TResponse>>;

export interface CacheVersions {
  config: string;
  epg: string;
  promos: string;
  swimlanes: string;
  shows: string;
  showsGrouped: string;
  videos: string;
}

interface Timeouts {
  albumsTimeout: number;
  imagesTimeout: number;
  photosTimeout: number;
  promosTimeout: number;
  scheduleTimeout: number;
  showsTimeout: number;
  videosTimeout: number;
}

const DEFAULT_CACHE_TIMEOUT = minutesToMilliseconds(10); // 10 minutes
const DEFAULT_TIMEOUT = minutesToMilliseconds(15); // 15 minutes

export default class CwCache {
  private _client: CwApiClient;

  private _cacheVersions: Promise<CacheVersions> | CacheVersions | null = null;
  private _timeouts: Timeouts | null = null; // Timeout values are in ms

  constructor(client: CwApiClient) {
    this._client = client;
  }

  async useCacheBackup() {
    // Initialize cache versions so fetchCacheVersions & updateCacheVersions are
    // never triggered
    this._cacheVersions = {
      config: '',
      epg: '',
      promos: '',
      swimlanes: '',
      shows: '',
      showsGrouped: '',
      videos: '',
    };

    try {
      const cacheRequest = await fetch('../../static/cache/cache.json');

      if (!cacheRequest.ok) {
        throw Error('local cache request failed');
      }

      const cache: any = await cacheRequest.json();
      const maxDate = new Date(8640000000000000);

      // Use maximum date as cache timeout when using the cache backup
      Object.values(cache as Cache).forEach(cacheStorageObj => {
        cacheStorageObj.timeoutDate = maxDate.getTime();
      });
      AppData!.storageService.cache.set(cache);
    } catch (e) {
      console.warn(e);
    }
  }

  private async fetchCacheVersions(): Promise<CacheVersions> {
    const time = Math.floor(Date.now() / 1000);
    const endpoint = `${Endpoints.CACHES}/cacheversions_${time}.json`;

    const response = await this._client.request<CacheInformation>(
      'GET',
      endpoint,
    );

    return this.updateCacheVersions(response);
  }

  private updateCacheVersions(
    response: AxiosResponse<CacheInformation, any>,
  ): CacheVersions {
    Registry.setTimeout(() => {
      this._cacheVersions = this.fetchCacheVersions();
      this.cleanseCache();
    }, DEFAULT_CACHE_TIMEOUT);

    return this.parseCacheVersions(response.data);
  }

  private parseCacheVersions(cache: CacheInformation): CacheVersions {
    let versions: CacheVersionData;
    if (AppData?.isProduction) {
      versions = cache.cwtv.prod.versions;
    } else {
      versions = cache.cwtv.staging.versions;
    }

    return {
      config: versions.config,
      epg: versions.epg,
      promos: versions.promos,
      swimlanes: versions.swimlanes,
      shows: versions.swimlanes,
      showsGrouped: versions['shows-grouped'],
      videos: versions.videos,
    };
  }

  async getCacheVersion(cacheKey: keyof CacheVersions): Promise<string> {
    // Update cache versions if they haven't already been initialized
    if (this._cacheVersions === null)
      this._cacheVersions = this.fetchCacheVersions();

    const cacheVersions = await this._cacheVersions;
    return cacheVersions[cacheKey]!;
  }

  areTimeoutsInitialized(): boolean {
    return this._timeouts !== null;
  }

  updateTimeouts(config: Config): void {
    const timeouts = config.cache_timeouts;

    this._timeouts = {
      albumsTimeout: this.parseTimeout(timeouts.albums_feed_cache_timeout),
      imagesTimeout: this.parseTimeout(timeouts.images_cache_timeout),
      photosTimeout: this.parseTimeout(timeouts.photos_feed_cache_timeout),
      promosTimeout: this.parseTimeout(timeouts.promos_feed_cache_timeout),
      scheduleTimeout: this.parseTimeout(timeouts.schedule_feed_cache_timeout),
      showsTimeout: this.parseTimeout(timeouts.shows_feed_cache_timeout),
      videosTimeout: this.parseTimeout(timeouts.videos_feed_cache_timeout),
    };

    // Reset timeouts when config times out (DEFAULT_TIMEOUT is always used for config)
    Registry.setTimeout(() => {
      this._timeouts = null;
    }, DEFAULT_TIMEOUT);
  }

  /**
   * Parses timeout in minutes into milliseconds
   * @param timeout Timeout in minutes
   * @returns Timeout in milliseconds (defaults to 15 minutes)
   */
  private parseTimeout(timeout?: number) {
    if (!timeout) return DEFAULT_TIMEOUT;
    return minutesToMilliseconds(timeout);
  }

  /**
   * Get the timeout in milliseconds for a given cache
   * @param cacheKey The cache we want the timeout for
   * @returns Timeout value in milliseconds
   */
  getTimeout(cacheKey: keyof CacheVersions): number {
    const timeouts = this._timeouts;

    if (timeouts === null) return DEFAULT_TIMEOUT;
    switch (cacheKey) {
      case 'swimlanes':
      case 'promos':
      case 'epg':
        return timeouts.promosTimeout;
      case 'shows':
      case 'showsGrouped':
        return timeouts.showsTimeout;
      case 'config':
        return timeouts.scheduleTimeout;
      case 'videos':
        return timeouts.videosTimeout;
    }
  }

  private cleanseCache() {
    const cache: Cache = AppData!.storageService.cache.get();
    if (!cache) return;

    const currentDate = Date.now();
    Object.keys(cache).forEach(cacheObjKey => {
      const { timeoutDate } = cache[cacheObjKey]!;
      if (currentDate > timeoutDate) delete cache[cacheObjKey];
    });

    AppData!.storageService.cache.set(cache);
  }

  getCachedResponse<TResponse>(path: string): TResponse | null {
    try {
      const cache: Cache<TResponse> = AppData!.storageService.cache.get();
      if (!cache) return null;

      const storageObj = cache[path];
      if (!storageObj) return null;

      if (Date.now() > storageObj.timeoutDate) return null;
      return storageObj.response;
    } catch {
      return null;
    }
  }

  cacheResponse<TResponse = unknown>(
    path: string,
    response: TResponse,
    timeout: number,
  ): void {
    try {
      const cache: Cache = AppData!.storageService.cache.get() ?? {};

      const storageObj: CacheStorageObj<TResponse> = {
        response,
        timeoutDate: Date.now() + timeout,
      };
      cache[path] = storageObj;

      AppData!.storageService.cache.set(cache);
    } catch (e) {
      // caching failed, do not throw error
      console.error(e);
    }
  }
}
