import { CommonKeyMapping } from './commonKeyMappings';

const MandatoryLGKeyMapping = {
  37: 'Left',
  38: 'Up',
  39: 'Right',
  40: 'Down',
  13: 'Enter',
  461: 'Back',
};

export const AdditionalLGKeyMapping = {
  ...CommonKeyMapping,
} as const;

export const LGKeyMapping: Record<number, string> = {
  ...MandatoryLGKeyMapping,
  ...AdditionalLGKeyMapping,
};
