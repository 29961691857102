import { Colors, Lightning } from '@lightningjs/sdk';
import Button from './Button';
import { getFontFaceFromStyle } from 'support/textUtils';
import { getImageTextureObj } from 'support/generalUtils';
import { translate } from 'support/translate';

const SELECTED_ICON_FOCUSED = getImageTextureObj(
  'static/images/playback/cc_selected_inverse_icon.svg',
  22,
  22,
);
const SELECTED_ICON_UNFOCUSED = getImageTextureObj(
  'static/images/playback/cc_selected_icon.svg',
  22,
  22,
);
const UNSELECTED_PADDING = 65;
const SELECTED_PADDING = 42;

export interface RadioButtonTemplateSpec
  extends Lightning.Component.TemplateSpec {
  isSelected: boolean;
}

export default class CheckmarkButton extends Button {
  private _isSelected = false;

  override get title() {
    return `${this.label} ${
      this.isSelected ? translate('ttsPrompts.selected') : ''
    }`;
  }

  get isSelected() {
    return this._isSelected;
  }

  set isSelected(isSelected: RadioButtonTemplateSpec['isSelected']) {
    this._isSelected = isSelected;
    if (this.hasFocus()) {
      this.setStartIcon(isSelected ? SELECTED_ICON_FOCUSED : '');
    } else {
      this.setStartIcon(isSelected ? SELECTED_ICON_UNFOCUSED : '');
    }
  }

  override _setup() {
    super._setup();
    this.margin = 24;
    this.paddingH = 0;
    this.backgroundColor = {
      focused: Colors('white').get(),
      unfocused: Colors('transparent').get(),
    };
    this._Label.y = 4;
    this._Label.patch({ text: { fontFace: getFontFaceFromStyle('regular') } });
  }

  override _focus() {
    super._focus();
    if (this.isSelected) {
      this.paddingH = SELECTED_PADDING;
      this.setStartIcon(SELECTED_ICON_FOCUSED);
    } else {
      this.paddingH = UNSELECTED_PADDING;
    }
    this._Label.patch({ text: { fontFace: getFontFaceFromStyle('bold') } });
  }

  override _unfocus() {
    super._unfocus();
    this.paddingH = 0;
    if (this.isSelected) {
      this.setStartIcon(SELECTED_ICON_UNFOCUSED);
    }
    this._Label.patch({ text: { fontFace: getFontFaceFromStyle('regular') } });
  }

  protected override updateButtonWidth() {
    this.stage.update();

    const hasStartIcon = !!this._StartIcon.texture?._source?.lookupId;
    const startMargin = hasStartIcon ? this.margin : 0;

    const hasEndIcon = !!this._EndIcon.texture?._source?.lookupId;
    const endMargin = hasEndIcon ? this.margin : 0;

    let padding = 0;
    if (this.hasFocus()) {
      padding = (this.isSelected ? SELECTED_PADDING : UNSELECTED_PADDING) * 2;
    }

    const newWidth =
      this._StartIcon.finalW +
      startMargin +
      this._Label.finalW +
      this._EndIcon.finalW +
      endMargin +
      padding;

    this.setSmooth('w', Math.max(this.minWidth, newWidth));
    this._Label.patch({
      flexItem: { marginLeft: startMargin, marginRight: endMargin },
    });
  }
}
