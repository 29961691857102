import { Colors, Lightning } from '@lightningjs/sdk';

export default class Placeholder
  extends Lightning.Component
  implements Lightning.Component
{
  static override _template() {
    return {
      rect: true,
      color: Colors('buttonInactive').get(),
    };
  }
}
