import FeaturedTile from './FeaturedTile';

const TILE_WIDTH = 839;
const TILE_HEIGHT = 473;

const TILE_WIDTH_EXPANDED = 1181;
const TILE_HEIGHT_EXPANDED = 473;

const CONTENT_PADDING_Y = 0;
const DETAILS_OFFSET_Y = 139;

const LOGO_WIDTH = 405;
const LOGO_HEIGHT = 165;

const BUTTON_Y = 213;

export default class HorizontalFeaturedTile extends FeaturedTile {
  static override get width() {
    return TILE_WIDTH;
  }

  static override get height() {
    return TILE_HEIGHT;
  }

  static override get highlightWidth() {
    return TILE_WIDTH;
  }

  static override get highlightHeight() {
    return TILE_HEIGHT;
  }

  static override get expandedWidth() {
    return TILE_WIDTH_EXPANDED;
  }

  static override get expandedHeight() {
    return TILE_HEIGHT_EXPANDED;
  }

  static override get contentPaddingY() {
    return CONTENT_PADDING_Y;
  }

  static override get detailsOffsetY() {
    return DETAILS_OFFSET_Y;
  }

  static override get logoWidth() {
    return LOGO_WIDTH;
  }

  static override get logoHeight() {
    return LOGO_HEIGHT;
  }

  static override get buttonY() {
    return BUTTON_Y;
  }
}
