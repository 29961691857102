import { Colors, Lightning } from '@lightningjs/sdk';
import { OVERLAY_FADE } from 'support/animations';
import constants from '../../../../static/constants.json';

interface AbstractVideoOverlayTemplateSpec
  extends Lightning.Component.TemplateSpec {
  alpha: number;
  TopGradient: object;
  BottomGradient: object;
}

type This = AbstractVideoOverlay<AbstractVideoOverlayTemplateSpec>;

const GRADIENT_WIDTH = 1920;
const TOP_GRADIENT_HEIGHT = 391;
const BOTTOM_GRADIENT_HEIGHT = 1080;

const INVISIBLE = constants.ui.invisible;

export default abstract class AbstractVideoOverlay<
  TemplateSpec extends Lightning.Component.TemplateSpec,
  TypeConfig extends Lightning.Component.TypeConfig = Lightning.Component.TypeConfig,
> extends Lightning.Component<
  AbstractVideoOverlayTemplateSpec & TemplateSpec,
  TypeConfig
> {
  static override _template(): Lightning.Component.Template<AbstractVideoOverlayTemplateSpec> {
    return {
      alpha: 0,
      transitions: OVERLAY_FADE,
      TopGradient: {
        w: GRADIENT_WIDTH,
        h: TOP_GRADIENT_HEIGHT,
        colorTop: Colors('background').get(),
        colorBottom: Colors('transparent').get(),
        rect: true,
      },
      BottomGradient: {
        w: GRADIENT_WIDTH,
        h: BOTTOM_GRADIENT_HEIGHT,
        colorTop: Colors('transparent').get(),
        colorBottom: Colors('background').get(),
        rect: true,
      },
    };
  }

  hide() {
    (this as This).setSmooth('alpha', INVISIBLE);
  }

  show() {
    (this as This).setSmooth('alpha', 1);
  }
}
